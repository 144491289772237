import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {general} from "../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {useGetPatientAttachment} from "../../../../../hook/request/ehr/patientAttachment";
import Modal from "../../../general/modal";
import BoxLoader from "../../../general/boxLoader";
import Icon from "../../../general/icon";
import Image from "../../../general/image";
import {MtoJDateFormat} from "../../../../../utils/date";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

type ShowPatientAttachmentModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
  handleShowModal: any;
}

type ModalInfoProps = {
  id: string,
  patientId: string,
  patientData?: any,
}

const ShowPatientAttachmentModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
 handleShowModal,
}: ShowPatientAttachmentModalProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    patientId: '',
    patientData: ''
  });
  const attachmentDataInitialState = {
    name: '',
    description: '',
    attachment:{
      id: 0,
      fileName: '',
      fileSize: 0,
      fileType: {
        id: 0,
        name: ''
      },
      url: '',
    },
    updateDate: null
  }
  const [attachmentData, setAttachmentData] = useState(attachmentDataInitialState);

  // services
  const getPatientAttachmentRequest = useGetPatientAttachment();

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'showPatientAttachmentModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData?.id
      }
      handleGetPatientAttachment(data)
    }
  }, [modalData, modalName])

  const handleGetPatientAttachment = (data: any) => {
    data.id && getPatientAttachmentRequest.mutate(data);
  }

  useEffect(() => {
    if (getPatientAttachmentRequest?.data?.data?.result) {
      const {
          name, description, attachment, updateDate
        } = getPatientAttachmentRequest.data.data.result;
      setAttachmentData({
        name: name,
        description: description,
        attachment:{
          id: attachment?.id,
          fileName: attachment?.fileName,
          fileSize: attachment?.fileSize,
          fileType: {
            id: attachment?.fileType?.id,
            name: attachment?.fileType?.name
          },
          url: attachment?.url,
        },
        updateDate: updateDate,
      })
    }
  }, [getPatientAttachmentRequest.data])

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
    setAttachmentData(attachmentDataInitialState);
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          getPatientAttachmentRequest?.isPending
        ) && <BoxLoader type="cover"/>
      }
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center font-16 font-weight-bold">
              {attachmentData?.attachment?.fileName}
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <div className="row">
          <div className="col-12 px-2 px-lg-4">
            <div className="form-group mb-4 d-flex justify-content-center">
              <a href={attachmentData?.attachment?.url} className='rounded bg-gray4'>
                <Image
                  src={attachmentData?.attachment?.url}
                  className={`${attachmentData?.attachment?.url ? 'rounded border bg-white' : 'd-none'}`}
                />
              </a>
            </div>
          </div>
        </div>
        <div className='mb-4 fs-3 px-1'>
          <div className=" px-2 flex-fill align-content-center d-flex">
            <span className="d-flex align-items-center title-dot font-12 text-gray2">
              نام فایل:
              <span className='text-black me-1'>
                {attachmentData?.name}
              </span>
            </span>
            <span className="d-flex align-items-center title-dot font-12 text-gray2 me-4">
              تاریخ افزودن:
              <span className='text-black me-1'>
                {MtoJDateFormat(attachmentData?.updateDate)}
              </span>
            </span>
            {(isProvider || findAccessInAccessList('EHR_ATTACHMENTS_DELETE')) &&
              <div className="d-flex align-items-center justify-content-center justify-content-center me-auto">
                <span
                  onClick={() => handleShowModal('deletePatientAttachmentModal', {id: modalInfo?.id, handleSetShowModal: handleSetShowModal})}
                  className="btn btn-gray rounded d-flex align-items-center fw-bold px-3">
                  <span className="d-inline-flex font-25 ms-2"><Icon name="trash"/></span>
                  حذف فایل
                </span>
              </div>
            }
          </div>
          <div className='border-top mt-2 mx-2'>
            <span className="d-flex align-items-center title-dot font-12 text-gray2 mt-4">
              توضیحات:
              <span className='text-black me-1'>
                {attachmentData?.description}
              </span>
            </span>
          </div>
        </div>
      </div>

    </Modal>
  )
    ;
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPatientAttachmentModal);


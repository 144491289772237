import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import Icon from "../../../../components/general/icon";
import SearchBox from "../../../../components/general/searchBox";
import Pagination from "../../../../components/general/table/pagination";
import PatientProgramCardsBox from "../../../../components/patients/ehr/program/dataList/patientProgramCardsBox";
import PatientProgramsTable from "../../../../components/patients/ehr/program/dataList/patientProgramsTable";
import {useGetPatientPrograms} from "../../../../../hook/request/ehr/patientProgram";
import {general} from "../../../../../store/action";
import {connect, useSelector} from "react-redux";
import DeletePatientProgramModal from "../../../../components/patients/ehr/program/deletePatientProgramModal";
import AddPatientProgramModal from "../../../../components/patients/ehr/program/addPatientProgramModal";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";


const dataListTitles = [
  "عنوان برنامه",
  "تاریخ و ساعت شروع برنامه",
  "تاریخ و ساعت پایان برنامه",
  "محل برگزاری",
  "وضعیت برنامه",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type programsListProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const ProgramsList = ({
  patientData,
  openTab,
  handleShowModal,
  visitEHRCheck = false,
}: programsListProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [programsData, setProgramsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getProgramsRequest = useGetPatientPrograms(getQueryParams(filterValues), openTab === 'programs');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getProgramsRequest?.data?.data?.result &&
    setProgramsData(getProgramsRequest?.data?.data.result)
  }, [getProgramsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const _filterValues = {
      ...filterValues,
      [name]: value,
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    const _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی برنامه"
            />
          </div>
          {((visitEHRCheck && isProvider) || findAccessInAccessList('EHR_PROGRAM_CREATE')) &&
            <div
              className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <span onClick={() => handleShowModal('addPatientProgramModal', {patientId: id, patientData: patientData})}
                    className="btn btn-outline-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                افزودن برنامه
              </span>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <PatientProgramCardsBox
                dataList={programsData}
                dataListTitles={dataListTitles}
                isRequesting={getProgramsRequest.isPending}
                readonly={!visitEHRCheck}
              /> :
              <PatientProgramsTable
                dataList={programsData}
                dataListTitles={dataListTitles}
                isRequesting={getProgramsRequest.isPending}
                readonly={!visitEHRCheck}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...programsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      {openTab === 'programs' && <AddPatientProgramModal/>}
      <DeletePatientProgramModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ProgramsList);

import { combineReducers } from 'redux';
import loginReducer from './login';
import logoutReducer from './logout';

const auth = combineReducers({
  loginReducer,
  logoutReducer
});

export default auth;

import {Dropdown, DropdownButton, DropdownHeader} from "react-bootstrap";
import Icon from "../general/icon";
import dropdownHeaderBg from "../../../assets/images/webp/dropdown-header-bg.webp";
import React, {useEffect, useState} from "react";
import OnlineStatusIcon from "../../../assets/images/svgComponents/icon/onlineStatusIcon";
import {PROVIDER_VISIT_STATUS_ENUM} from "../../../constants/constant/enum";
import {useDoctorVisitStatusChange, useGetDoctorVisitStatus} from "../../../hook/request/doctors";
import {Nav, Tab} from "react-bootstrap";
import {TGIdNameData} from "../../../types/general";
import OnlineStatusDropdownWaitingRoomList from "../general/onlineStatusDropdown/onlineStatusDropdownWaitingRoomList";
import AnnouncementsList from "../general/onlineStatusDropdown/annoucementsList";
import {useSelector} from "react-redux";

type OnlineStatusDropdownProps = {
  openVideoCallOverlay: any;
}

const OnlineStatusDropdown = ({
  openVideoCallOverlay
}: OnlineStatusDropdownProps) => {

  const [status, setStatus] = useState<TGIdNameData>({
    id: PROVIDER_VISIT_STATUS_ENUM.OFFLINE.value,
    name: PROVIDER_VISIT_STATUS_ENUM.OFFLINE.label
  })
  const [openTab, setOpenTab] = useState('latestAnnouncements')
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider); // Access the ID from the Redux store


  const doctorVisitStatusChangeRequest = useDoctorVisitStatusChange();
  const getDoctorVisitStatus = useGetDoctorVisitStatus()

  useEffect(() => {
    getDoctorVisitStatus.mutate({})
  }, []);

  useEffect(() => {
    if(getDoctorVisitStatus?.data?.data?.result) {
      const {id, name} = getDoctorVisitStatus?.data?.data?.result;
      setStatus({id, name});
    }
  }, [getDoctorVisitStatus?.data?.data?.result]);

  useEffect(() => {
    if(doctorVisitStatusChangeRequest?.data?.data?.result) {
      const {id, name} = doctorVisitStatusChangeRequest?.data?.data?.result
      setStatus({id, name});
    }

  }, [doctorVisitStatusChangeRequest?.data?.data?.result]);

  const handleVisitStatusChange = (status: number) => {
    doctorVisitStatusChangeRequest.mutate({id: status});
  }

  const determineTextColor = () => {
    switch (status?.id) {
      case PROVIDER_VISIT_STATUS_ENUM.ONLINE.value:
        return 'text-green2'
      case PROVIDER_VISIT_STATUS_ENUM.ONLINE_FOR_MY_VISIT.value:
        return 'text-orange'
      case PROVIDER_VISIT_STATUS_ENUM.OFFLINE.value:
        return 'text-red'
    }
  }

  return(
    <DropdownButton
      className="online-status-menu"
      title={
        <span className="btn btn-white d-flex font-25 rounded-circle p-2">
          {isProvider ?
            <OnlineStatusIcon status={status?.id}/> :
            <Icon name='notification-on'/>
          }
        </span>
      }
      align={"end"}
    >
      <Tab.Container
        id="online-status-dropdown-tabs"
        activeKey={openTab}
        onSelect={(eventKey) => setOpenTab(eventKey || openTab || 'latestAnnouncements')}
      >
      <DropdownHeader
        style={{
          background: `url(${dropdownHeaderBg}) no-repeat center center`,
          backgroundSize: "cover",
          borderTopLeftRadius: 10,
          width: '300px'
        }}
      >
        {isProvider &&
          <div className='d-flex me-3'>
            <div className="d-flex align-items-center font-15 fw-bold text-black">
              وضعیت من
            </div>
            <DropdownButton
              className="visit-status-menu mx-1"
              title={
                <div className='d-flex bg-white justify-content-between align-items-center rounded-3 p-1'
                     style={{width: '130px'}}>
                  <OnlineStatusIcon status={status?.id}/>
                  <span
                    className={`fw-bold mx-2 ${determineTextColor()}`}>{status?.id === PROVIDER_VISIT_STATUS_ENUM.ONLINE_FOR_MY_VISIT.value ? 'صرفا آنلاین' : status?.name}</span>
                  <span className='d-flex align-items-center justify-content-center font-18'>
                  <Icon name='chevron-down'/>
                </span>
                </div>
              }
              align={"end"}
            >
              <Dropdown.Item
                className='d-flex align-items-center'
                onClick={() => handleVisitStatusChange(PROVIDER_VISIT_STATUS_ENUM.ONLINE.value)}>
                <OnlineStatusIcon status={PROVIDER_VISIT_STATUS_ENUM.ONLINE.value}/>
                <div className='fw-bold text-green2 me-2'>آنلاین</div>
              </Dropdown.Item>
              <Dropdown.Item
                className='d-flex align-items-center'
                onClick={() => handleVisitStatusChange(PROVIDER_VISIT_STATUS_ENUM.ONLINE_FOR_MY_VISIT.value)}
              >
                <OnlineStatusIcon status={PROVIDER_VISIT_STATUS_ENUM.ONLINE_FOR_MY_VISIT.value}/>
                <div className='fw-bold text-orange me-2'>
                  آنلاین برای ویزیت‌های خودم
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                className='d-flex align-items-center'
                onClick={() => handleVisitStatusChange(PROVIDER_VISIT_STATUS_ENUM.OFFLINE.value)}
              >
                <OnlineStatusIcon status={PROVIDER_VISIT_STATUS_ENUM.OFFLINE.value}/>
                <div className='fw-bold text-red me-2'>آفلاین</div>
              </Dropdown.Item>
            </DropdownButton>
          </div>
        }
        <Nav variant={'underline'} justify>
          <div className='d-flex w-100 align-items-end'>
            <Nav.Item>
              <Nav.Link eventKey="latestAnnouncements" className=" text-white">
                اعلانات
              </Nav.Link>
            </Nav.Item>
            {isProvider &&
              <Nav.Item>
                <Nav.Link eventKey="waitingRoom" className=" text-white">
                  لیست اتاق انتظار
                </Nav.Link>
              </Nav.Item>
            }
          </div>
        </Nav>
      </DropdownHeader>
        <div>
          <Tab.Content>
            <Tab.Pane eventKey="latestAnnouncements" style={{height: '300px'}}>
              <AnnouncementsList openTab={openTab}/>
            </Tab.Pane>
            {isProvider &&
              <Tab.Pane eventKey="waitingRoom">
                <OnlineStatusDropdownWaitingRoomList openTab={openTab} openVideoCallOverlay={openVideoCallOverlay}/>
              </Tab.Pane>
            }
          </Tab.Content>
        </div>
        {/* <Dropdown.Item as="button">تاریخچۀ ورود به سیستم</Dropdown.Item> */}
      </Tab.Container>
    </DropdownButton>
  )
}

export default OnlineStatusDropdown;

import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import SearchBox from "../../../../components/general/searchBox";
import Pagination from "../../../../components/general/table/pagination";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";
import {useGetPatientEHRPrescriptions} from "../../../../../hook/request/ehr/patientSummary";
import PatientPrescriptionCards
  from "../../../../components/patients/ehr/prescription/dataList/patientPrescriptionCards";
import ShowPatientPrescriptionModal
  from "../../../../components/patients/ehr/prescription/showPatientPrescriptionModal";

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type prescriptionsListProps = {
  openTab?: string;
}

const PrescriptionsCardsList = ({
 openTab,
}: prescriptionsListProps) => {

  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [prescriptionsData, setPrescriptionsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getPrescriptionsRequest = useGetPatientEHRPrescriptions(getQueryParams(filterValues), openTab === 'prescriptions');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getPrescriptionsRequest?.data?.data?.result &&
    setPrescriptionsData(getPrescriptionsRequest?.data?.data.result)
  }, [getPrescriptionsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const _filterValues = {
      ...filterValues,
      [name]: value,
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    const _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی نسخه"
            />
          </div>
        </section>
        <section className="table-cont table-responsive">
          <PatientPrescriptionCards
            dataList={prescriptionsData}
            isRequesting={getPrescriptionsRequest.isPending}
          />
          <Pagination
            paginationData={{...filterValues, ...prescriptionsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <ShowPatientPrescriptionModal/>
      {/*<DeletePatientPrescriptionModal/>*/}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PrescriptionsCardsList);

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {general} from "../../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../../store/selector/general";
import Modal from "../../../../general/modal";
import BoxLoader from "../../../../general/boxLoader";
import Icon from "../../../../general/icon";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import SearchBox from "../../../../general/searchBox";
import {useGetPrescriptionParaclinicalServiceItems} from "../../../../../../hook/request/paraclinical";
import ParaclinicalItemBox from "./paraclinicalItemBox";
import {ButtonGroup, Nav, Tab, ToggleButton} from "react-bootstrap";
import {PARACLINICAL_PRESCRIPTION_SERVICE_TYPE} from "../../../../../../constants/constant/enum";
import {useWindowSize} from "../../../../../../hook/useWindowSize";



type AddPrescriptionParaclinicalItemModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  type?: string;
  issuerType?: number;
}

type ModalInfoProps = {
  id: string;
  visitId: string;
  insurance: number;
  patientData?: any;
  index?: number;
  item?: any;
  handleParaclinicalModalSubmit: any;
  fieldValue: any;
}


const AddPrescriptionParaclinicalItemModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPrescriptionParaclinicalItemModalProps) => {

  //<editor-fold desc="States">
  const [width] = useWindowSize();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    visitId: '',
    patientData: '',
    handleParaclinicalModalSubmit: null,
    fieldValue: null,
    insurance: 10
  });
  const filterValuesInitialState = {
    pageNumber: "0",
    perPage: "30",
    search: '',
    type: '0',
    issuerType: 0
  }
  const { getQueryParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "30",
    search: modalInfo?.item?.name,
    type: '0',
  });
  const [fieldValue, setFieldValue] = useState([]);
  const [prescriptionParaclinicalItemList, setPrescriptionParaclinicalItemList] = useState([]);
  const [language, setLanguage] = useState('en');
  //</editor-fold>

  //<editor-fold desc="Services">
  const getPrescriptionParaclinicalItemListRequest = useGetPrescriptionParaclinicalServiceItems(
    getQueryParams(filterValues),(modalName == 'addPrescriptionParaclinicalItemModal' && modalInfo.insurance !== 0));
  //</editor-fold>

  //<editor-fold desc="UseEffects">
  useEffect(() => {
    showState && getPrescriptionParaclinicalItemListRequest.refetch();
  }, [filterValues])

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addPrescriptionParaclinicalItemModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  useEffect(() => {
    setFilterValues({...filterValuesInitialState, search: modalInfo?.item?.service?.name, issuerType: modalInfo?.insurance})
  }, [modalInfo]);

  useEffect(() => {
    if (getPrescriptionParaclinicalItemListRequest?.data?.data?.result) {
      const { data } = getPrescriptionParaclinicalItemListRequest.data.data.result
      setPrescriptionParaclinicalItemList(data);
    }
  }, [getPrescriptionParaclinicalItemListRequest.data])
  //</editor-fold>

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue([])
    setFilterValues(filterValuesInitialState);
    handleHideModal();
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    let _filterValues = {
      ...filterValues,
      pageNumber: "0",
      perPage: filterValues?.perPage,
      search: value
    }
    setFilterValues(_filterValues)
  }

  const addPrescriptionParaclinicalItems = () => {
    handleSetShowModal();
    modalInfo.handleParaclinicalModalSubmit(fieldValue, modalInfo?.index ?? -1)
  }

  const entries = Object.entries(PARACLINICAL_PRESCRIPTION_SERVICE_TYPE);

  const _allParaclinicalItems = prescriptionParaclinicalItemList.map((item: any, key: number) => {
    return (
      <ParaclinicalItemBox
        key={key}
        searchStr={filterValues?.search}
        language={language}
        item={item}
        setFieldValue={setFieldValue}
        fieldValue={fieldValue}
      />
    )
  })

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
      fullscreen={width <= 900 ? true : 'false'}
    >
      {getPrescriptionParaclinicalItemListRequest?.isLoading && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              جستجوی پاراکلینیک
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <div className="d-flex px-2">
          <div className="form-group ms-md-auto w-100">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="نام خدمت"
              autoFocus={true}
            />
          </div>
          <ButtonGroup className="d-flex align-items-stretch mb-3 m-md-0">
            <ToggleButton
              className='d-flex align-items-center rounded mx-1'
              id="fa"
              type="radio"
              variant="outline-primary"
              checked={language === 'fa'}
              value="fa"
              onChange={(e) => setLanguage(e.currentTarget.value)}
            >
              فارسی
            </ToggleButton>
            <ToggleButton
              className='d-flex align-items-center rounded font-en mx-1'
              id="en"
              type="radio"
              variant="outline-primary"
              checked={language === 'en'}
              value="en"
              onChange={(e) => setLanguage(e.currentTarget.value)}
            >
              EN
            </ToggleButton>
          </ButtonGroup>
        </div>
        <div className="d-flex justify-content-end px-2 my-2">
          <Tab.Container
            id="paraclincial-category-tabs"
            defaultActiveKey="0"
            onSelect={(eventKey) => setFilterValues({...filterValues, type: eventKey || '0'})}
          >
            <Nav variant={"pills"} className='d-flex overflow-x-auto custom-scrollbar prescription-category-nav'>
              <div className='d-flex rounded'>
                {
                  entries.map(([key, {value, label}]) => {
                    return (
                      <Nav.Item key={value}>
                        <Nav.Link eventKey={value} className='text-nowrap font-12 me-1'>{label}</Nav.Link>
                      </Nav.Item>
                    )
                  })
                }
                <Nav.Item>
                  <Nav.Link eventKey={'0'} className='text-nowrap font-12 mx-1'>همه</Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </Tab.Container>
        </div>
        <div className='h-500 custom-scrollbar ltr px-2'>
          {_allParaclinicalItems}
        </div>
        <div className="col-12 d-flex justify-content-end mt-4">
          <div className="d-flex w-100 align-items-stretch form-group px-2">
            <button
              type='submit'
              className="btn btn-primary rounded d-flex w-100 justify-content-center align-items-center"
              onClick={addPrescriptionParaclinicalItems}
            >
              {"افزودن به نسخه"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
);
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPrescriptionParaclinicalItemModal);
import React from "react";
import {Form} from "react-bootstrap";


type FavoriteMedicationItemRowItemProps = {
  item?: any;
  onChange?: any
}


const FavoriteMedicationItemRowItem = ({
 item,
 onChange,
}: FavoriteMedicationItemRowItemProps) => {

  const handleCheckboxChange = (e: any) => {
    console.log(e.target)
    const { checked } = e.target;
    onChange(checked, item);
  };

  return (
    <div className="background-gray-hover border-bottom px-2">
      <Form.Check
        className='py-3'
      >
        <Form.Check.Label className='d-flex align-items-center w-100'>
          <div className="d-flex align-items-center col-12 w-100 ltr">
            <div className="col-1 ms-3">
              <Form.Check.Input
                className='rounded rounded-3 font-22 mb-1'
                name={`checkbox-${item?.id}`}
                type='checkbox'
                // checked={checked}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="col-1 text-center">{item?.item?.generic}</div>
            <div className="col-2 text-center">
              <span
                className={`bg-primary bg-opacity-10 rounded rounded-3 border border-primary font-12 text-primary text-nowrap px-4 py-2 mx-2`}>
                {item?.item?.category?.name}
              </span>
            </div>
            <div className="col-4 text-center">{item?.item?.name}</div>
            <div className="col-1 text-center">{item?.itemCount}</div>
            <div className="col-1 text-center">{item?.frequency?.maxa?.value}</div>
            <div className="col-2 text-center">{item?.dosage?.faName}</div>
          </div>
        </Form.Check.Label>
      </Form.Check>
    </div>
  );
};


export default FavoriteMedicationItemRowItem
import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../../utils/date";
import React from "react";

type patientAttachmentSummaryProps = {
  item: any;
}

const PatientAttachmentSummaryRowItem = ({
  item
}: patientAttachmentSummaryProps) => {

  return (
    <>
      <div className='d-flex justify-content-start align-items-center'>
        <a href={item?.attachment?.url} className='d-flex align-items-center'>
          <div className='summary-thumbnail rounded my-2 mx-2'
               style={{backgroundImage: `url(${getCellValue(item?.attachment?.url)})`}}>
          </div>
        </a>
        <div className='me-3'>
          <div className=''>
            <span className="text-gray">تاریخ</span>
            <span className="me-3">{getCellValue(MtoJDateFormat(item?.updateDate))}</span>
          </div>
          <div className=''>
            <span className="text-gray">نام فایل</span>
            <span className="me-3">{getCellValue(item?.name)}</span>
          </div>
        </div>
      </div>
    </>
);
};

export default PatientAttachmentSummaryRowItem;

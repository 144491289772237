import React, {useEffect} from "react";
import {Form} from "react-bootstrap";
import {getCellValue} from "../../../../../../../../utils/utils";
import {useWindowSize} from "../../../../../../../../hook/useWindowSize";

type RecentParaclinicalPrescriptionRowItemProps = {
  item?: any;
  index?: number,
  checked?: any
  onChange?: any
}

const RecentParaclinicalPrescriptionRowItem = ({
 item,
 index,
 checked,
 onChange,
}: RecentParaclinicalPrescriptionRowItemProps) => {
  const [width] = useWindowSize();
  const handleCheckboxChange = (e: any) => {
    const { checked } = e.target;
    onChange(index, checked, item);
  };

  useEffect(() => {
    onChange(index, checked, item);
  }, [checked]);

  return (
    <div className="background-gray-hover border-bottom px-2">
      <Form.Check className='py-3'>
        <Form.Check.Label className='d-flex align-items-center w-100'>
          <div className={`d-flex align-items-center w-100 fw-bold ltr ${width > 900 && 'col-12'}`}>
            {width > 900 ?
              <>
                <div className="col-2 text-center ms-2">{getCellValue(item?.service?.loinc)}</div>
                <div className="col-2 text-center">{getCellValue(item?.service?.type?.name)}</div>
                <div className={`col-5 text-center ${item?.service?.enName && 'font-en'}`}>
                  {item?.service?.enName ? getCellValue(item?.service?.enName) : getCellValue(item?.service?.name)}
                </div>
                <div className="col-1 text-center">{getCellValue(item?.itemCount)}</div>
                <div className="col-2 text-center">{item?.emergency == true ? 'اورژانسی' : 'غیر اورژانسی'}</div>
              </> :
              <div className="text-start ms-2">
                <span className='text-green mx-1'>{getCellValue(item?.service?.loinc)}</span>
                <span className='text-gray fw-semibold mx-1'>{getCellValue(item?.service?.type?.name)}</span>
                <span className={`${item?.service?.enName && 'font-en'} mx-1`}>
                  {item?.service?.enName ? getCellValue(item?.service?.enName) : getCellValue(item?.service?.name)}
                </span>
                <span className='text-green2 fw-semibold mx-1'>{getCellValue(item?.itemCount)}</span>
              </div>
            }

          </div>
          <Form.Check.Input
            className='rounded rounded-3 font-22 mb-1'
            name={`checkbox-${item?.id}`}
            type='checkbox'
            checked={checked}
            onChange={handleCheckboxChange}
          />
        </Form.Check.Label>
      </Form.Check>
    </div>
  );
};


export default RecentParaclinicalPrescriptionRowItem
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import {general} from "../../../../../../store/action";
import {connect, useSelector} from "react-redux";
import EmptyBox from "../../../../general/emptyBox";
import {useGetPatientAttachments} from "../../../../../../hook/request/ehr/patientAttachment";
import PatientAttachmentSummaryRowItem from "./dataList/patientAttachmentSummary_rowItem";
import AddPatientAttachmentModal from "../../attachment/addPatientAttachmentModal";
import {TDataList} from "../../../../../../types/general";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type patientAttachmentSummaryProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const PatientAttachmentSummary = ({
 patientData,
 openTab,
 handleShowModal,
 visitEHRCheck = false,
}: patientAttachmentSummaryProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [attachmentsData, setAttachmentsData] = useState<TDataList>({
    data: [],
    count: 0
  });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: patientData ? patientData : '0',
    pageNumber: "0",
    perPage: "3",
    search: "",
  });

  // services
  const getAttachmentsRequest = useGetPatientAttachments(getQueryParams(filterValues), openTab === 'patientSummary' && filterValues?.patient !== '0');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      patient: patientData,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "3",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams, patientData]);

  useEffect(() => {
    getAttachmentsRequest?.data?.data?.result &&
    setAttachmentsData(getAttachmentsRequest?.data?.data.result)
  }, [getAttachmentsRequest.data])


  const _dataList = attachmentsData?.data && attachmentsData?.data?.length > 0 && attachmentsData?.data?.map((item: any, i: number) => {
    return(
      <div className={`py-3 ${(i + 1) !== attachmentsData?.data?.length ? 'border-bottom' : ''}`}>
        <PatientAttachmentSummaryRowItem key={i} item={item}/>
      </div>
    )
  });

  return (
    <>
      <div className="rounded">
        <section className=" d-flex flex-column align-items-center flex-md-row border-bottom">
          <div className="form-group ms-md-auto fw-bold mx-3 my-2">
            فایل های ضمیمه
          </div>
          {((visitEHRCheck && isProvider) || findAccessInAccessList('EHR_ATTACHMENTS_CREATE')) &&
            <a className='p-1 d-flex align-items-center btn btn-dark rounded rounded-3 mx-3 my-2'
              onClick={() => handleShowModal('addPatientAttachmentModal', {
                patientId: patientData,
                patientData: patientData
              })}>
              <span className="m-1">افزودن فایل</span>
            </a>
          }
        </section>
        <section className="mx-3 my-2">
          {
            attachmentsData?.data?.length === 0 ? <EmptyBox/> : _dataList
          }
        </section>
      </div>
      {openTab === 'patientSummary' && <AddPatientAttachmentModal/>}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientAttachmentSummary);

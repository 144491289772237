import React from 'react';
import DefClinicLogo from '../../../assets/images/image/def-logo.png';
import hamrahDoctorLogo from '../../../assets/images/svg/saa-iran-logo.svg';
import Image from "../general/image";

type UserInfoBoxProps = {
  type?: string;
}

const UserInfoBox = ({
  type
}: UserInfoBoxProps) => {
  return(
    <div className="text-center">
      <div className="d-flex justify-content-center align-items-center overflow-hidden">
        <Image src={hamrahDoctorLogo} className='h-100 object-fit-cover' defSrc={DefClinicLogo} alt="Clinic Logo"/>
      </div>
      <div className='d-flex flex-column justify-content-center mr-2 text-nowrap hide-collapsed'>
        <span className={`${type === 'light' ? 'text-gray' : 'text-gray3'} font-16 fw-medium mt-2`}>پـنل مـدیریت صاایران</span>
      </div>
    </div>
  )
}

export default UserInfoBox;

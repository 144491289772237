import * as yup from "yup";

const addProgramSchema = yup.object({
  name: yup
    .string()
    .required("این فیلد الزامیست."),
});


export {
  addProgramSchema,
};

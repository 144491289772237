import * as React from "react"
import { SVGProps } from "react"
const DashboardSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={29} height={29} stroke="white" />
    <rect
      x={3.33203}
      y={3.33301}
      width={10.5}
      height={10.5}
      rx={2}
      fill="#3F8DFD"
    />
    <rect
      opacity={0.3}
      x={16.168}
      y={3.33301}
      width={10.5}
      height={10.5}
      rx={2}
      fill="#97ABFF"
    />
    <rect
      opacity={0.3}
      x={16.168}
      y={16.167}
      width={10.5}
      height={10.5}
      rx={2}
      fill="#97ABFF"
    />
    <rect
      opacity={0.3}
      x={3.33203}
      y={16.167}
      width={10.5}
      height={10.5}
      rx={2}
      fill="#97ABFF"
    />
  </svg>
)
export default DashboardSquare

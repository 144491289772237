import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {AllergyReactionService} from "../../services/requests/allergyReaction";

const useGetAllergyReactions = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getAllergyReactions", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return AllergyReactionService.getAllergyReactionsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetAllergyReaction = () => {
  return useMutation({
    mutationFn: AllergyReactionService.getAllergyReaction,
  })
}

const useAddAllergyReaction = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: AllergyReactionService.addAllergyReaction,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.IMAGING_SERVICE.PATH);
        }
      })
    },
  })
}

const useDeleteAllergyReaction = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: AllergyReactionService.deleteAllergyReaction,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getAllergyReactions"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetAllergyReactions,
  useGetAllergyReaction,
  useAddAllergyReaction,
  useDeleteAllergyReaction
}

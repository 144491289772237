import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {VaccineService} from "../../services/requests/vaccine";

const useGetVaccines = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getVaccines", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return VaccineService.getVaccinesList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetVaccine = () => {
  return useMutation({
    mutationFn: VaccineService.getVaccine,
  })
}

const useAddVaccine = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: VaccineService.addVaccine,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.VACCINE.PATH);
        }
      })
    },
  })
}

const useDeleteVaccine = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: VaccineService.deleteVaccine,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getVaccines"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetVaccines,
  useGetVaccine,
  useAddVaccine,
  useDeleteVaccine
}

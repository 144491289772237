import * as yup from "yup";

const checkPatientNationalCode = yup.object({
  nationalCode: yup
    .string()
    .required("این فیلد الزامیست.")
    .length(10, "کد ملی نامعتبر است.")
});

const addPatientDetailsSchema = yup.object({
  firstName: yup
    .string()
    .required("این فیلد الزامیست."),
  lastName: yup
    .string()
    .required("این فیلد الزامیست."),
  gender: yup.object({
    id: yup
      .number()
      .required("این فیلد الزامیست."),
  }).required("این فیلد الزامیست."),
  birthDate: yup
    .date()
    .required("این فیلد الزامیست."),
  mobile: yup
    .string()
    .required("این فیلد الزامیست."),
});

const addDiagnosisSchema = yup.object({
  icd11: yup
    .string()
    .required("این فیلد الزامیست."),
  severity: yup
    .string()
    .required("این فیلد الزامیست."),
  status: yup
    .string()
    .required("این فیلد الزامیست."),
});

const addPrescriptionMedicationItem = yup.object().shape({
  itemCount: yup
    .number().min(1, "کمترین تعداد ۱ میباشد.")
    .required("این فیلد الزامیست.").typeError("این فیلد الزامیست."),
  frequency: yup.number().required("این فیلد الزامیست."),
  dosage: yup.number().required("این فیلد الزامیست.")
});

const addPrescriptionParaclinicalItem = yup.object({
  itemCount: yup
    .string()
    .required("این فیلد الزامیست."),
  emergency: yup
    .boolean()
    .required("این فیلد الزامیست."),
  date: yup
    .string()
    .required("این فیلد الزامیست."),
});


export {
  checkPatientNationalCode,
  addPatientDetailsSchema,
  addDiagnosisSchema,
  addPrescriptionMedicationItem,
  addPrescriptionParaclinicalItem,
};

import React from "react";
import { connect } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import { MtoJFullDateFormat } from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const DeviceTableRowItem = ({
  item,
  handleShowModal
}) => {
  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return(
    <tr>
      <td>{getCellValue(item?.deviceCode)}</td>
      <td>{getCellValue(item?.type?.name)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.manufactureDate))}</bdi>
      </td>
      <td>
        {
          item?.assignedCount ?
            <span className='px-3 py-1 rounded bg-light-green text-green2 text-nowrap'>
              دستگاه {getCellValue(item?.assignedCount)} بار تخصیص یافته است
            </span> :
            <span className='px-3 py-1 rounded bg-light-red text-red text-nowrap'>
            دستگاه تخصیص نیافته است
            </span>
        }
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {
            findAccessInAccessList('DEVICE_ASSIGNMENT_VIEW') &&
            <Tooltip alt={'لیست تخصیص های دستگاه'} className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.DEVICE_ASSIGNED_LIST.PATH, {id: item?.id}))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='eye'/>
              </span>
            </Tooltip>
          }
          {
            findAccessInAccessList('DEVICE_ASSIGNMENT_CREATE') &&
            <Tooltip alt={'تخصیص دستگاه'} className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.ASSIGN_DEVICE.PATH, {id: item?.id}))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='archive-down'/>
              </span>
            </Tooltip>
          }
          {
            findAccessInAccessList('DEVICE_VIEW') &&
            <Tooltip alt={'ویرایش'} className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.DEVICE_EDIT.PATH, {id: item?.id}))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='pen-square'/>
              </span>
            </Tooltip>
          }
          {
            findAccessInAccessList('DEVICE_DELETE') &&
            <Tooltip alt={'حذف'} className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deleteDeviceModal', item?.id)}
                className="d-flex text-red-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='trash'/>
              </span>
            </Tooltip>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(DeviceTableRowItem);

import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {ROUTES} from "../../../constants";
import {PatientVitalService} from "../../../services/requests/ehr/patientVital";

const useGetPatientVitals = (params: any ,modalName?: boolean) => {
  return useQuery({
    queryKey: ["getPatientVitals", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PatientVitalService.getPatientVitalsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetPatientVitalChartsData = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getPatientVitalChartsData", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PatientVitalService.getPatientVitalChartsData(params);
    },
    enabled: modalName ?? true
  });
}

const useGetPatientVital = () => {
  return useMutation({
    mutationFn: PatientVitalService.getPatientVital,
  })
}

const useAddPatientVital = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: PatientVitalService.addPatientVital,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientVitals"] })
          queryClient.invalidateQueries({ queryKey: ["getPatientVitalChartsData"] })
          onSuccess();
        }
      })
    },
  })
}

const useDeletePatientVital = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PatientVitalService.deletePatientVital,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientVitals"] })
          queryClient.invalidateQueries({ queryKey: ["getPatientVitalChartsData"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetPatientVitals,
  useGetPatientVital,
  useAddPatientVital,
  useDeletePatientVital,
  useGetPatientVitalChartsData
}

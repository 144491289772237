import React from "react";
import {getCellValue} from "../../../../../../utils/utils";
import {TGQuantityData} from "../../../../../../types/general";

type EcgSummaryProps = {
  heartRate?: TGQuantityData;
  pulseRate?: TGQuantityData;
  leadDuration?: number;
  HBPM: number;
}

const EcgSummary = ({
  heartRate,
  pulseRate,
  leadDuration,
  HBPM
}: EcgSummaryProps) => {

  return (
    <div className="d-flex justify-content-center align-items-center border-bottom pb-2">
      <div className="d-flex justify-content-center align-items-center col-lg-3 col-md-6 col-sm-6">
        <span className=''>
          تعداد ضربان قلب در دقیقه
        </span>
        <span className='d-flex bg-primary-subtle rounded-3 align-items-center m-1 py-1 px-2'>
          <span className='font-9 text-primary fw-semibold font-en'>(beats/min)</span>
          <span className='font-18 text-primary fw-bold me-1'>{ heartRate && heartRate?.magnitude > 0 ? heartRate?.magnitude?.toFixed(0) : '-'}</span>
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center col-lg-3 col-md-6 col-sm-6">
        <span className=''>
          زمان نمونه
        </span>
        <span className='d-flex bg-primary-subtle rounded-3 align-items-center m-1 py-1 px-2'>
          <span className='font-9 text-primary fw-light font-en'>(minutes)</span>
          <span className='font-18 text-primary fw-bold me-1'>{getCellValue(leadDuration)}</span>
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center col-lg-3 col-md-6 col-sm-6">
        <span>
          مدل نمونه‌گیری
        </span>
        <span className='d-flex align-items-center bg-primary-subtle rounded-3 m-1 px-2 py-1'>
          <span className='d-flex align-items-center font-14 text-primary font-en'>Standard</span>
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center col-lg-3 col-md-6 col-sm-6">
        <span className=''>
          حالت بدن در زمان برداشت
        </span>
        <span className='d-flex align-items-center m-1'>
          <span className='bg-primary-subtle rounded-3 text-primary me-1 px-2 py-1'>دراز کشیده</span>
        </span>
      </div>
    </div>
  );
};

export default EcgSummary;
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Modal from "../../general/modal";
import Icon from "../../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../store/selector/general";
import {general} from "../../../../store/action";
import {Dispatch} from "redux";
import {ROUTES} from "../../../../constants";
import {Collapse} from "react-bootstrap";
import {useChangeStatusVisit} from "../../../../hook/request/visit/visits";
import {VISIT_STATUS_ENUM} from "../../../../constants/constant/enum";

type EndVisitModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

const EndVisitModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: EndVisitModalProps) => {

  const { id } = useParams();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const collapseStateInitialState = {
    endVisit: true,
    continueVisit: false
  }
  const [collapseState, setCollapseState] = useState(collapseStateInitialState);
  const navigate = useNavigate();

  //service
  const changeStatusVisitRequest = useChangeStatusVisit({
    onSuccess: () => {
      handleSetShowModal();
      navigate(ROUTES.VISIT.PATH)
    }
  })

  useEffect(() => {
    if(
      isShowModalData &&
      modalName === 'endVisitModal'
    ){
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  const handleContinueVisit = () => {
    setCollapseState({
      endVisit: false,
      continueVisit: true
    });
  }

  const handleEndVisit = () => {
    changeStatusVisitRequest.mutate({
      id: id,
      status: VISIT_STATUS_ENUM.RESOLVED.value
    })
  }

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
    setCollapseState(collapseStateInitialState)
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-400 mw-100"
      bodyClassName="py-5 px-3 px-lg-5 pos-rel"
    >
      <Collapse in={collapseState?.endVisit}>
        <div>
          <div className="text-center">
            <div className="d-flex justify-content-center mb-4">
              <span className="info-item-bg d-flex justify-content-center align-items-center text-primary font-25 pt-4">
                <Icon name='exit-right'/>
              </span>
            </div>
            <h4 className="font-14 mb-2">کاربر گرامی</h4>
            <p className="text-black">
              آیا می‌خواهید ویزیت اتمام یابد؟
            </p>
          </div>
          <div className="d-flex justify-content-center mt-4 pt-2">
            <button
              onClick={() => handleContinueVisit()}
              className="btn btn-outline-gray py-2 mx-1"
            >
              خیر
            </button>
            <button
              onClick={() => handleEndVisit()}
              className="btn btn-primary d-flex flex-grow-1 justify-content-center align-items-center py-2 mx-1"
            >
              بله
            </button>
          </div>
        </div>
      </Collapse>
      <Collapse in={collapseState?.continueVisit}>
        <div>
          <div className="text-center">
            <div className="d-flex justify-content-center mb-4">
              <span className="warning-item-bg d-flex justify-content-center align-items-center text-yellow font-25 pt-4">
                <Icon name='info-circle'/>
              </span>
            </div>
            <h4 className="font-14 mb-2">کاربر گرامی</h4>
            <p className="text-black">
              ویزیت بیمار ۲۴ ساعت پس از ثبت نسخه به صورت خودکار بسته می‌شود.
            </p>
          </div>
          <div className="d-flex justify-content-center mt-4 pt-2">
            <button
              onClick={() => handleSetShowModal()}
              className="btn btn-primary d-flex flex-grow-1 justify-content-center align-items-center py-2 mx-1"
            >
              متوجه شدم
            </button>
          </div>
        </div>
      </Collapse>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EndVisitModal);

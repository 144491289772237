import { connect } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import { MtoJFullDateFormat } from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const OrganizationTableRowItem = ({
  item,
  handleShowModal
}) => {
  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return(
    <tr>
      <td>{getCellValue(item?.name)}</td>
      <td>{getCellValue(item?.telNo)}</td>
      <td>{getCellValue(item?.address)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {
            (findAccessInAccessList('HEALTH_ORGANIZATION_CREATE') || findAccessInAccessList('HEALTH_ORGANIZATION_EDIT')) &&
            <Tooltip alt={'مراکز متصل'} className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.ORGANIZATION_RELATION.PATH, { id: item?.id }))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='maximize-square' />
              </span>
            </Tooltip>
          }
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {
            findAccessInAccessList('HEALTH_ORGANIZATION_VIEW') &&
            <Tooltip alt={'ویرایش'} className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.ORGANIZATION_EDIT.PATH, { id: item?.id }))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='pen-square' />
              </span>
            </Tooltip>
          }
          {
            findAccessInAccessList('HEALTH_ORGANIZATION_DELETE') &&
            <Tooltip alt={'حذف'} className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deleteOrganizationModal', item?.id)}
                className="d-flex text-red-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='trash' />
              </span>
            </Tooltip>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(OrganizationTableRowItem);

import {useMutation} from "@tanstack/react-query";
import {VideoCallService} from "../../services/requests/videoCall";

const useConnectToMeeting = () => {
  return useMutation({
    mutationFn: VideoCallService.connectToMeeting,
  })
}

const useConnectToWaitingRoom = () => {
  return useMutation({
    mutationFn: VideoCallService.connectToWaitingRoom,
  })
}

const useGetWaitingRoomList = () => {
  return useMutation({
    mutationFn: VideoCallService.getWaitingRoomList,
  })
}

export {
  useConnectToMeeting,
  useConnectToWaitingRoom,
  useGetWaitingRoomList,
}

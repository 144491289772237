import {Dropdown, DropdownButton, Placeholder} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { localStorageClear, localStorageGet, localStorageSet } from "../../../utils/localStorage";
import Icon from "../general/icon";
import {useWindowSize} from "../../../hook/useWindowSize";
import {useEffect, useState} from "react";
import BoxLoader from "../general/boxLoader";
import {useDispatch, useSelector} from "react-redux";
import {setFullName, setIsProvider} from "../../../store/slice/userData";

const ProfileMenu = () => {

  const navigate = useNavigate();
  const [width] = useWindowSize()
  const dispatch = useDispatch();
  const fullName = useSelector((state: any) => state.general.userDataReducer.fullName); // Access the ID from the Redux store


  const doLogout = () => {
    let _siteSettingStorage = localStorageGet('SITE_SETTING');
    localStorageClear();
    localStorageSet('SITE_SETTING', _siteSettingStorage);
    dispatch(setIsProvider(null))
    dispatch(setFullName(''))
    navigate(ROUTES.LOGIN.PATH);
  }

  return(
    <DropdownButton
      className="profile-menu me-4"
      title={
        <div className={`${width > 900 ? ' d-flex align-items-center rounded-3 border border-primary bg-primary-subtle px-2 py-1' : ''}`}>
          <span className="d-flex font-27 text-primary">
            <Icon name="user-circle" />
          </span>
          {
            width > 900 && (
              <span className={`d-flex align-items-center text-start text-primary fw-semibold me-1`}>
                <span>اطلاعات کاربری</span>
                <span className='d-flex align-items-center font-18 text-primary me-3'>
                  <Icon name="chevron-down" />
                </span>
              </span>
            )
          }
        </div>
      }
    >
      {fullName === '' ?
        <Placeholder as="p" animation="wave" className='d-flex justify-content-center rounded-3'>
          <Placeholder xs={10} className='rounded-3'/>
        </Placeholder> :
        <div className={`d-flex justify-content-center border-bottom text-primary fw-semibold px-3 pb-2`}>
          {fullName}
        </div>
      }
      <Dropdown.Item as={Link} to={ROUTES?.ACCOUNT?.PATH}>اطلاعات کاربری</Dropdown.Item>
      <Dropdown.Item as={Link} to={ROUTES?.SETTING?.PATH}>تنظیمات بیمه</Dropdown.Item>
      <Dropdown.Item as={Link} to={ROUTES?.CHANGE_PASSWORD?.PATH}>تغییر کلمه عبور</Dropdown.Item>
      {/* <Dropdown.Item as="button">تاریخچۀ ورود به سیستم</Dropdown.Item> */}
      <Dropdown.Item as="button" onClick={doLogout}>خـروج از حـساب کـاربری</Dropdown.Item>
    </DropdownButton>
  )
}

export default ProfileMenu;

import Icon from "../../../general/icon";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";
import React from "react";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

type MenuTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const MenuTableRowItem = ({
 item,
 handleShowModal
}: MenuTableRowItemProps) => {
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return (
    <tr>
      <td>{item?.name}</td>
      <td>
        <bdi className="ltr">{(item?.url)}</bdi>
      </td>
      <td>
        <span className="item-txt hide-collapsed">{(item?.icon)}</span>
      </td>
      <td>{(item?.accessGroup?.name)}</td>
      <td>{(item?.accessProcess?.name)}</td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {
            findAccessInAccessList('BASIC_SYSTEM_MENU') &&
            <span
              onClick={() => handleShowModal('deleteMenuModal', item?.id)}
              className="d-flex text-red-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='trash'/>
            </span>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(MenuTableRowItem);

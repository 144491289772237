import { useMutation, useQuery } from "@tanstack/react-query";
import { AuthService } from "../../services"
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../constants";
import { localStorageSet } from "../../utils/localStorage";
import { handleResponse, queryClient } from "./setup";

const useGetCaptcha = () => {
  // staleTime: Infinity,
  // cacheTime: 0,
  return useQuery({
    queryKey: ["getCaptcha"],
    queryFn: AuthService.getCaptcha,
    // onSuccess: (data: any) => {
    //   handleResponse({ data })
    // },
  });
}

const useLogin = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void,
  onError: () => void
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  return useMutation({
    mutationFn: AuthService.login,
    onSuccess: (data, variables, context) => {
      handleResponse({
        data,
        onSuccess: () => {
          const _returnUrl = searchParams.get("returnUrl");
          localStorageSet('USER_DATA', data.data.result);
          onSuccess()
          _returnUrl ?
            navigate(_returnUrl) :
            navigate(ROUTES.DASHBOARD.PATH);
        },
        onError: () => {
          queryClient.invalidateQueries({ queryKey: ["getCaptcha"] });
          onError();
        }
      })
    },
  })
}

export {
  useGetCaptcha,
  useLogin
}

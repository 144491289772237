import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import {general} from "../../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../../store/selector/general";
import {
  useAddPatientBiometric,
} from "../../../../../../hook/request/ehr/patientBiometric";
import Modal from "../../../../general/modal";
import BoxLoader from "../../../../general/boxLoader";
import Icon from "../../../../general/icon";
import TextAreaField from "../../../../general/form/textAreaField";

type TAiReportFieldValue = {
  description: string;
}

type EcgAiReportModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  id: string,
  patientId: string,
  patientData?: any,
}

const EcgAiReportModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: EcgAiReportModalProps) => {
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    patientId: '',
    patientData: ''
  });

  const [fieldValue, setFieldValue] = useState<TAiReportFieldValue>({description: ''});
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TAiReportFieldValue>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });
  // services
  const addPatientBiometricRequest = useAddPatientBiometric({
    onSuccess: () => {
      handleSetShowModal();
    }
  });

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'ecgAiReportModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])


  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  const addAiReport: SubmitHandler<TAiReportFieldValue> = (data) => {
    const { description } = data,
      body = {
        id: modalInfo?.id ? modalInfo?.id : 0,
        patient: {
          id: modalInfo?.patientId
        },
        entrySource:{
          id:200
        },
        description
      };
    addPatientBiometricRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue({description: ''});
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          addPatientBiometricRequest?.isPending
        ) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              درصورتی که سیستم هوش مصنوعی همراه‌دکتر دچار خطا است، لطفا نظر خود را با ما درمیان بگذارید.
            </h4>
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(addAiReport)}>
          <div className="row">
            <div className="col-12 px-4">
              <div className="form-group mb-3">
                <TextAreaField
                  name="description"
                  rows={3}
                  placeholder="توضیحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  ثبت گزارش
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EcgAiReportModal);
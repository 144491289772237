import { useEffect, useMemo, useState } from "react";
import BoxLoader from "../../components/general/boxLoader";
import SelectField from "../../components/general/form/selectField";
import TextField from "../../components/general/form/textField";
import { useNavigate, useParams } from "react-router-dom";
import { TAddDeviceField } from "../../../types/device";
import { useAddDevice, useGetDevice } from "../../../hook/request/devices";
import { DEVICE_TYPE_OPTIONS } from "../../../constants/constant/enum";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDeviceSchema } from "../../../schema/device-schema";
import DatePickerField from "../../components/general/form/datePickerField";
import { ToFullDateTimeFormat } from "../../../utils/date";
import momentJ from 'moment-jalaali';
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

const AddDevice = () => {

  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const { id } = useParams();
  const [fieldValue, setFieldValue] = useState<TAddDeviceField>({
    deviceCode: "",
    deviceType: "",
    imei: "",
    serialCode: "",
    manufactureDate: null
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddDeviceField>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addDeviceSchema),
  });

  // services
  const getDeviceRequest = useGetDevice();
  const addDeviceRequest = useAddDevice();

  useEffect(() => {
    id && getDeviceRequest.mutate({id});
  }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getDeviceRequest?.data?.data?.result) {
      const { deviceCode, type, imei, serialCode, manufactureDate } = getDeviceRequest.data.data.result,
        _fieldValue = {
          deviceCode, imei, serialCode,
          deviceType: type?.id,
          manufactureDate: manufactureDate ? momentJ(manufactureDate) : null
        };
      setFieldValue(_fieldValue);
    }
  }, [getDeviceRequest.data])

  const addDeviceSubmit: SubmitHandler<TAddDeviceField> = (data) => {
    const { deviceType, imei, deviceCode, serialCode, manufactureDate } = data,
      body = {
        ...(id ? {id} : {}),
        imei, deviceCode, serialCode,
        manufactureDate: ToFullDateTimeFormat(manufactureDate),
        type: {
          id: deviceType,
          name: ""
        }
      };
    addDeviceRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getDeviceRequest?.isPending ||
          addDeviceRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(addDeviceSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات دستگاه</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="deviceCode">کد دستگاه</label>
                <TextField
                  name="deviceCode"
                  placeholder="کد دستگاه"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="deviceType">‌مدل دستگاه</label>
                <SelectField
                  options={DEVICE_TYPE_OPTIONS}
                  name="deviceType"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  // returnObjectValue={true}
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="imei">IMEI</label>
                <TextField
                  name="imei"
                  placeholder="IMEI"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="serialCode">سریال دستگاه</label>
                <TextField
                  name="serialCode"
                  placeholder="سریال دستگاه"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="manufactureDate">تاریخ تولید</label>
                <DatePickerField
                  name="manufactureDate"
                  placeholder="تاریخ تولید"
                  // value={fieldValue?.manufactureDate}
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                {
                  ((findAccessInAccessList('DEVICE_EDIT') && id) || (findAccessInAccessList('DEVICE_CREATE') && !id)) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    { id ? "ویرایش دستگاه" : "افزودن دستگاه" }
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDevice;
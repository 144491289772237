import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ACTION_TYPES } from '../../constants';
import { AuthService } from '../../services';

/////////// Login //////////////////////////////////////////
function* handleLogin(action) {
  try {
    const res = yield call(
      AuthService.login,
      action.payload
    );
    yield put({ type: ACTION_TYPES.AUTH.LOGIN.SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: ACTION_TYPES.AUTH.LOGIN.ERROR, payload: error });
  }
}

function* watchLogin() {
  yield takeEvery(
    ACTION_TYPES.AUTH.LOGIN.REQUESTING, 
    handleLogin
  );
}

/////////// Logout //////////////////////////////////////////
function* handleLogout(action) {
  try {
    const res = yield call(
      AuthService.logout,
      action.payload
    );
    yield put({ type: ACTION_TYPES.AUTH.LOGOUT.SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: ACTION_TYPES.AUTH.LOGOUT.ERROR, payload: error });
  }
}

function* watchLogout() {
  yield takeEvery(
    ACTION_TYPES.AUTH.LOGOUT.REQUESTING, 
    handleLogout
  );
}

/////////// export all ///////////////////////////////////////////////
export default function* authSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLogout)
  ])
}
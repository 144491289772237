import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ACTION_TYPES } from '../../constants';
import { OrganizationService } from '../../services';

/////////// GetClinicsList //////////////////////////////////////////
function* handleGetClinicsList(action) {
  try {
    const res = yield call(
      OrganizationService.getClinicsList,
      action.payload
    );
    yield put({ type: ACTION_TYPES.CLINIC.LIST.SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: ACTION_TYPES.CLINIC.LIST.ERROR, payload: error });
  }
}

function* watchGetClinicsList() {
  yield takeEvery(
    ACTION_TYPES.CLINIC.LIST.REQUESTING,
    handleGetClinicsList
  );
}

/////////// GetClinicsAllList //////////////////////////////////////////
function* handleGetClinicsAllList(action) {
  try {
    const res = yield call(
      OrganizationService.getClinicsAllList,
      action.payload
    );
    yield put({ type: ACTION_TYPES.CLINIC.ALL_LIST.SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: ACTION_TYPES.CLINIC.ALL_LIST.ERROR, payload: error });
  }
}

function* watchGetClinicsAllList() {
  yield takeEvery(
    ACTION_TYPES.CLINIC.ALL_LIST.REQUESTING,
    handleGetClinicsAllList
  );
}

/////////// GetClinic //////////////////////////////////////////
function* handleGetClinic(action) {
  try {
    const res = yield call(
      OrganizationService.getClinic,
      action.payload
    );
    yield put({ type: ACTION_TYPES.CLINIC.INFO.SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: ACTION_TYPES.CLINIC.INFO.ERROR, payload: error });
  }
}

function* watchGetClinic() {
  yield takeEvery(
    ACTION_TYPES.CLINIC.INFO.REQUESTING,
    handleGetClinic
  );
}

/////////// AddClinic //////////////////////////////////////////
function* handleAddClinic(action) {
  try {
    const res = yield call(
      OrganizationService.addClinic,
      action.payload
    );
    yield put({ type: ACTION_TYPES.CLINIC.ADD.SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: ACTION_TYPES.CLINIC.ADD.ERROR, payload: error });
  }
}

function* watchAddClinic() {
  yield takeEvery(
    ACTION_TYPES.CLINIC.ADD.REQUESTING,
    handleAddClinic
  );
}

/////////// editClinic //////////////////////////////////////////
function* handleEditClinic(action) {
  try {
    const res = yield call(
      OrganizationService.editClinic,
      action.payload
    );
    yield put({ type: ACTION_TYPES.CLINIC.EDIT.SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: ACTION_TYPES.CLINIC.EDIT.ERROR, payload: error });
  }
}

function* watchEditClinic() {
  yield takeEvery(
    ACTION_TYPES.CLINIC.EDIT.REQUESTING,
    handleEditClinic
  );
}

/////////// deleteClinic //////////////////////////////////////////
function* handleDeleteClinic(action) {
  try {
    const res = yield call(
      OrganizationService.deleteClinic,
      action.payload
    );
    yield put({ type: ACTION_TYPES.CLINIC.DELETE.SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: ACTION_TYPES.CLINIC.DELETE.ERROR, payload: error });
  }
}

function* watchDeleteClinic() {
  yield takeEvery(
    ACTION_TYPES.CLINIC.DELETE.REQUESTING,
    handleDeleteClinic
  );
}

/////////// GetDoctorExpertise //////////////////////////////////////////
function* handleGetDoctorExpertise(action) {
  try {
    const res = yield call(
      OrganizationService.getDoctorExpertise,
      action.payload
    );
    yield put({ type: ACTION_TYPES.CLINIC.DOCTOR_EXPERTISE.SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: ACTION_TYPES.CLINIC.DOCTOR_EXPERTISE.ERROR, payload: error });
  }
}

function* watchGetDoctorExpertise() {
  yield takeEvery(
    ACTION_TYPES.CLINIC.DOCTOR_EXPERTISE.REQUESTING,
    handleGetDoctorExpertise
  );
}

/////////// export all ///////////////////////////////////////////////
export default function* clinicSaga() {
  yield all([
    fork(watchGetClinicsList),
    fork(watchGetClinicsAllList),
    fork(watchGetClinic),
    fork(watchAddClinic),
    fork(watchEditClinic),
    fork(watchDeleteClinic),
    fork(watchGetDoctorExpertise),
  ])
}
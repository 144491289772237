import { combineReducers } from 'redux';
import general from './general';
import auth from './auth';
import clinic from "./clinic";

const mainReducers = combineReducers({
  general,
  auth,
  clinic,
})

export default mainReducers;
import React, {useState} from "react";
import {Accordion, Form, Popover} from "react-bootstrap";
import RecentParaclinicalPrescriptionRowItem from "./recentParaclinicalPrescription_rowItem";
import {useWindowSize} from "../../../../../../../../hook/useWindowSize";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {getCellValue} from "../../../../../../../../utils/utils";
import {monthDay, yearTitle} from "../../../../../../../../utils/date";

type RecentParaclinicalPrescriptionRowProps = {
  item?: any
  pushRecentParaclinicalPrescription: any
}

const RecentParaclinicalPrescriptionRow = ({
 item,
 pushRecentParaclinicalPrescription
}: RecentParaclinicalPrescriptionRowProps) => {
  const [width] = useWindowSize();
  const [checkboxes, setCheckboxes] = useState({
    header: false,
    body: item?.paraclinicalItems?.map(() => false),
  });

  // Function to handle changes in the header checkbox
  const handleHeaderCheckboxChange = (e: any) => {
    const isChecked = e.target.checked;
    setCheckboxes({
      header: isChecked,
      body: item?.paraclinicalItems?.map(() => isChecked),
    });
  };

  // Function to handle changes in body checkboxes
  const handleBodyCheckboxChange = (index: number, checked: boolean, item: any) => {
    setCheckboxes((prev) => {
      const updatedBody = [...prev.body];
      updatedBody[index] = checked;
      const allChecked = updatedBody.every(Boolean);
      return {
        header: allChecked,
        body: updatedBody,
      };
    });
    pushRecentParaclinicalPrescription(item, checked)
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className='font-14 fw-bold text-center text-black me-2'>
          <div
            className={`font-12 font-vazir fw-bold text-primary text-nowrap px-4 py-2 mx-2`}>
            تجویز در {getCellValue(monthDay(item?.issueDate)) + ' ' + getCellValue(yearTitle(item?.issueDate))}
          </div>
          <div
            className={`font-12 font-vazir fw-bold text-primary text-nowrap px-4 py-2 mx-2`}>
            {item?.paraclinicalItems?.length} خدمت پاراکلینیک
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Accordion.Item eventKey={item?.id} className='mb-3 rounded border'>
      <Accordion.Header className='d-flex flex-wrap'>
        <div className="me-auto" onClick={(e) => e.stopPropagation()}>
          <Form.Check>
            <Form.Check.Label className='d-flex align-items-center w-100'>
              <div className='d-flex fw-bold font-12 ms-2'>انتخاب همه</div>
              <Form.Check.Input
                className='rounded rounded-3 font-22 mb-1'
                name={`header-checkbox-${item?.id}`}
                type='checkbox'
                id={`inline-checkbox-${item?.id}`}
                checked={checkboxes.header}
                onChange={handleHeaderCheckboxChange}
              />
            </Form.Check.Label>
          </Form.Check>
        </div>
        {width > 900 &&
          <span className='font-14 fw-bold me-2 text-black'>
          <span
            className={`bg-primary bg-opacity-10 rounded rounded-3 border border-primary font-12 fw-bold text-primary text-nowrap px-4 py-2 mx-2`}>
            حاوی {item?.paraclinicalItems?.length} خدمت پاراکلینیک
          </span>
        </span>
        }
      </Accordion.Header>
      <Accordion.Body className='rounded-bottom'>
        <div className=" ">
          <div className="form-group">
            {
              item?.paraclinicalItems?.map((item: any, index: number) => {
                return (
                  <RecentParaclinicalPrescriptionRowItem
                    key={item.id}
                    item={item}
                    index={index}
                    checked={checkboxes.body[index]}
                    onChange={handleBodyCheckboxChange}
                  />
                )
              })
            }
          </div>
          {width < 900 &&
            <div className="d-flex justify-content-center">
              <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                <div
                  className="btn btn-light-blue rounded d-flex align-items-center fw-bold mt-3 px-2 p-2"
                >
                  توضیحات
                </div>
              </OverlayTrigger>
            </div>
          }
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};


export default RecentParaclinicalPrescriptionRow
import Image from "../../../components/general/image";
import {getAge, getCellValue} from "../../../../utils/utils";
import React, {useState} from "react";
import {TPatientFields} from "../../../../types/patient";
import {TCheckInsuranceFields} from "../../../../types/prescription";
import EmptyAvatar from "../../../../assets/images/svg/profile-empty-avatar.svg"
import {Collapse} from "react-bootstrap";

type PatientDetailsResponsiveProps = {
  patient: TPatientFields
  insurance?: TCheckInsuranceFields;
  isRequesting?: boolean;
}

const PatientDetailsResponsive = ({
  patient,
  insurance,
  isRequesting
}: PatientDetailsResponsiveProps) => {

  const [showDetails, setShowDetails] = useState<boolean>(false)

  return (
    <div className="align-items-center px-4 mb-4">
      <div className=' rounded mx-3'>
        <div className="d-flex justify-content-center">
          <div className='align-content-center align-items-center text-center mx-2 w-25'>
            <Image
              className='rounded mb-4'
              src={insurance?.avatar ? getCellValue(insurance?.avatar) : EmptyAvatar}
            />
          </div>
        </div>
        <div className='d-flex justify-content-center font-12 fw-semibold mb-2'>
          {getCellValue(patient?.firstName)} {getCellValue(patient?.lastName)}
        </div>
        <div className='d-flex justify-content-center font-10 fw-semibold mb-2'>
          <span>کد ملی:</span>
          <span className='me-1'>{getCellValue(patient.nationalCode)}</span>
        </div>
        <Collapse in={showDetails}>
          <div className='mt-2'>
            <div className='row row-cols-1 d-flex align-items-center'>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>سن:</span>
                <span className='me-1'>{patient.birthDate && getAge(getCellValue(patient.birthDate))}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>جنسیت:</span>
                <span className='me-1'>{getCellValue(patient?.gender?.name)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>نوع بیمه:</span>
                <span className='me-1'>{getCellValue(insurance?.issuerType?.name)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>شماره تماس:</span>
                <span className='me-1'>{getCellValue(patient?.mobile)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>بیماری خاص:</span>
                <span className='me-1'>{getCellValue(insurance?.specialAccount)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>نام سرپرست:</span>
                <span className='me-1'>{getCellValue(insurance?.responsibleFullName)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>نسبت با سرپرست:</span>
                <span className='me-1'>{getCellValue(insurance?.relationType)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>صندوق بیمه:</span>
                <span className='me-1'>{getCellValue(insurance?.insuranceBox)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>پزشک خانواده:</span>
                <span className='me-1'>{getCellValue(insurance?.familyPhysician)}</span>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="d-flex justify-content-center">
          <div
            className="btn btn-light-blue rounded d-flex align-items-center fw-bold mt-3 px-2 p-2"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'کمتر' : 'بیشتر'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientDetailsResponsive;
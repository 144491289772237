import * as React from "react"
import { SVGProps } from "react"
const AdmissionAnimated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="animated"
    id="freepik_stories-medical-prescription"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <style>
      {
        "svg#freepik_stories-medical-prescription:not(.animated) .animable {opacity: 0;}svg#freepik_stories-medical-prescription.animated #freepik--Document--inject-26 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideLeft,1.5s Infinite  linear floating;animation-delay: 0s,1s;}svg#freepik_stories-medical-prescription.animated #freepik--character-2--inject-26 {animation: 3.0s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideLeft;animation-delay: 0s;}svg#freepik_stories-medical-prescription.animated #freepik--character-1--inject-26 {animation: 3.0s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideUp;animation-delay: 0s;}            @keyframes slideLeft {                0% {                    opacity: 0;                    transform: translateX(-30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }                    @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }                    @keyframes slideUp {                0% {                    opacity: 0;                    transform: translateY(30px);                }                100% {                    opacity: 1;                    transform: inherit;                }            }"
      }
    </style>
    <g
      id="freepik--background-simple--inject-26"
      style={{
        transformOrigin: "255.072px 250.894px 0px",
      }}
      className="animable"
    >
      <path
        d="M446.33,145.29s-80.4-115.75-180.78-128C176.94,6.41,94.69,48.79,83.24,140.35,79.16,173,71.9,209.07,59,238.83,44.53,272.21,19.84,300,38.75,364.17s98.77,122.12,156.82,122,112.58-52.89,157.57-100.88S541.4,292.17,446.33,145.29Z"
        style={{
          fill: "#2353BA",
          transformOrigin: "255.072px 250.894px 0px",
        }}
        id="elftym8es02zl"
        className="animable"
      />
      <g id="eli5g5ypkts4">
        <path
          d="M446.33,145.29s-80.4-115.75-180.78-128C176.94,6.41,94.69,48.79,83.24,140.35,79.16,173,71.9,209.07,59,238.83,44.53,272.21,19.84,300,38.75,364.17s98.77,122.12,156.82,122,112.58-52.89,157.57-100.88S541.4,292.17,446.33,145.29Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.7,
            transformOrigin: "255.072px 250.894px 0px",
          }}
          className="animable"
          id="elexk532jwp85"
        />
      </g>
    </g>
    <g
      id="freepik--Window--inject-26"
      style={{
        transformOrigin: "126.415px 271.84px 0px",
      }}
      className="animable"
    >
      <g id="eljudncwk6hbi">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "126.415px 271.84px 0px",
          }}
          className="animable"
          id="elw2j1lg1cc0d"
        >
          <polygon
            points="123.88 177.63 48.81 177.63 49.57 163.14 124.64 163.14 123.88 177.63"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 170.385px 0px",
            }}
            id="el3lwq1kr7ym4"
            className="animable"
          />
          <polygon
            points="123.88 197.92 48.81 197.92 49.57 183.43 124.64 183.43 123.88 197.92"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 190.675px 0px",
            }}
            id="elwumfggcwb4"
            className="animable"
          />
          <polygon
            points="123.88 218.21 48.81 218.21 49.57 203.72 124.64 203.72 123.88 218.21"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 210.965px 0px",
            }}
            id="elkmrs9kehd2l"
            className="animable"
          />
          <polygon
            points="123.88 238.5 48.81 238.5 49.57 224.01 124.64 224.01 123.88 238.5"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 231.255px 0px",
            }}
            id="el4nk3ol0v6o7"
            className="animable"
          />
          <polygon
            points="123.88 258.8 48.81 258.8 49.57 244.3 124.64 244.3 123.88 258.8"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 251.55px 0px",
            }}
            id="el58rdy3l5rec"
            className="animable"
          />
          <polygon
            points="123.88 279.09 48.81 279.09 49.57 264.59 124.64 264.59 123.88 279.09"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 271.84px 0px",
            }}
            id="el6w4lcus7cay"
            className="animable"
          />
          <polygon
            points="123.88 299.38 48.81 299.38 49.57 284.88 124.64 284.88 123.88 299.38"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 292.13px 0px",
            }}
            id="elipvp8od28j"
            className="animable"
          />
          <polygon
            points="123.88 319.67 48.81 319.67 49.57 305.17 124.64 305.17 123.88 319.67"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 312.42px 0px",
            }}
            id="el0vmwseqrgnfp"
            className="animable"
          />
          <polygon
            points="123.88 339.96 48.81 339.96 49.57 325.46 124.64 325.46 123.88 339.96"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 332.71px 0px",
            }}
            id="elg0xmqtcgsh"
            className="animable"
          />
          <polygon
            points="123.88 360.25 48.81 360.25 49.57 345.75 124.64 345.75 123.88 360.25"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 353px 0px",
            }}
            id="elota06xqz1v"
            className="animable"
          />
          <polygon
            points="123.88 380.54 48.81 380.54 49.57 366.05 124.64 366.05 123.88 380.54"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "86.725px 373.295px 0px",
            }}
            id="el4erhgls0d4q"
            className="animable"
          />
          <polygon
            points="203.26 177.63 128.19 177.63 128.95 163.14 204.02 163.14 203.26 177.63"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 170.385px 0px",
            }}
            id="elmaz3w3k86e"
            className="animable"
          />
          <polygon
            points="203.26 197.92 128.19 197.92 128.95 183.43 204.02 183.43 203.26 197.92"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 190.675px 0px",
            }}
            id="elaa6fs3hzz1o"
            className="animable"
          />
          <polygon
            points="203.26 218.21 128.19 218.21 128.95 203.72 204.02 203.72 203.26 218.21"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 210.965px 0px",
            }}
            id="elyd9egi9qqs"
            className="animable"
          />
          <polygon
            points="203.26 238.5 128.19 238.5 128.95 224.01 204.02 224.01 203.26 238.5"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 231.255px 0px",
            }}
            id="el7k5975yzfga"
            className="animable"
          />
          <polygon
            points="203.26 258.8 128.19 258.8 128.95 244.3 204.02 244.3 203.26 258.8"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 251.55px 0px",
            }}
            id="ell5ngy6rbu3"
            className="animable"
          />
          <polygon
            points="203.26 279.09 128.19 279.09 128.95 264.59 204.02 264.59 203.26 279.09"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 271.84px 0px",
            }}
            id="elbt5fwmm6woq"
            className="animable"
          />
          <polygon
            points="203.26 299.38 128.19 299.38 128.95 284.88 204.02 284.88 203.26 299.38"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 292.13px 0px",
            }}
            id="elc998o1zynl"
            className="animable"
          />
          <polygon
            points="203.26 319.67 128.19 319.67 128.95 305.17 204.02 305.17 203.26 319.67"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 312.42px 0px",
            }}
            id="elrhxojj5ql5f"
            className="animable"
          />
          <polygon
            points="203.26 339.96 128.19 339.96 128.95 325.46 204.02 325.46 203.26 339.96"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 332.71px 0px",
            }}
            id="ell9k4ovdi88g"
            className="animable"
          />
          <polygon
            points="203.26 360.25 128.19 360.25 128.95 345.75 204.02 345.75 203.26 360.25"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 353px 0px",
            }}
            id="ely86tbo9dvf8"
            className="animable"
          />
          <polygon
            points="203.26 380.54 128.19 380.54 128.95 366.05 204.02 366.05 203.26 380.54"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "166.105px 373.295px 0px",
            }}
            id="elyvoo4cfyuf"
            className="animable"
          />
        </g>
      </g>
    </g>
    <g
      id="freepik--Diplomas--inject-26"
      style={{
        transformOrigin: "398.795px 263.995px 0px",
      }}
      className="animable"
    >
      <g id="el0wqj2i0qho5j">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "385.64px 229.285px 0px",
          }}
          className="animable"
          id="el21afb94wava"
        >
          <rect
            x={337.19}
            y={192.63}
            width={96.9}
            height={73.31}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "385.64px 229.285px 0px",
            }}
            id="elt6abs105u1p"
            className="animable"
          />
          <rect
            x={342.44}
            y={198.69}
            width={86.38}
            height={61.2}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "385.63px 229.29px 0px",
            }}
            id="el71m63fjem5"
            className="animable"
          />
          <line
            x1={370.61}
            y1={208.33}
            x2={400.66}
            y2={208.33}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "385.635px 208.33px 0px",
            }}
            id="elntao8kkzwya"
            className="animable"
          />
          <line
            x1={351.15}
            y1={217.21}
            x2={420.12}
            y2={217.21}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "385.635px 217.21px 0px",
            }}
            id="elujd135k22z"
            className="animable"
          />
          <line
            x1={366.52}
            y1={234.96}
            x2={404.75}
            y2={234.96}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "385.635px 234.96px 0px",
            }}
            id="elp8l9z5ukm9r"
            className="animable"
          />
          <line
            x1={351.83}
            y1={252.72}
            x2={366.86}
            y2={252.72}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "359.345px 252.72px 0px",
            }}
            id="elrbkyje05p5"
            className="animable"
          />
          <line
            x1={404.41}
            y1={252.72}
            x2={419.43}
            y2={252.72}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "411.92px 252.72px 0px",
            }}
            id="el8somfwwmjvq"
            className="animable"
          />
        </g>
      </g>
      <g id="elgorl0onbv3b">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "424.74px 308.38px 0px",
          }}
          className="animable"
          id="elcrixt5z4q4"
        >
          <rect
            x={389.08}
            y={281.4}
            width={71.32}
            height={53.96}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "424.74px 308.38px 0px",
            }}
            id="elj1lju3f69u"
            className="animable"
          />
          <rect
            x={392.95}
            y={285.86}
            width={63.58}
            height={45.05}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "424.74px 308.385px 0px",
            }}
            id="elmp5agoadtze"
            className="animable"
          />
          <line
            x1={413.69}
            y1={292.96}
            x2={435.8}
            y2={292.96}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "424.745px 292.96px 0px",
            }}
            id="elgl309nxdko"
            className="animable"
          />
          <line
            x1={399.36}
            y1={299.49}
            x2={450.13}
            y2={299.49}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "424.745px 299.49px 0px",
            }}
            id="elvv1mmtlryl"
            className="animable"
          />
          <line
            x1={410.67}
            y1={312.56}
            x2={438.82}
            y2={312.56}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "424.745px 312.56px 0px",
            }}
            id="elrfpjq09npy"
            className="animable"
          />
          <line
            x1={399.86}
            y1={325.63}
            x2={410.92}
            y2={325.63}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "405.39px 325.63px 0px",
            }}
            id="elkec17sure2"
            className="animable"
          />
          <line
            x1={438.56}
            y1={325.63}
            x2={449.62}
            y2={325.63}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              transformOrigin: "444.09px 325.63px 0px",
            }}
            id="ellvtocmecohh"
            className="animable"
          />
        </g>
      </g>
    </g>
    <g
      id="freepik--Document--inject-26"
      style={{
        transformOrigin: "273.1px 177.67px 0px",
      }}
      className="animable"
    >
      <path
        d="M377.78,319.36l-103.53-3.6q-6.4-.22-12.8,0L165,319.36l4.28-135.42q.24-7.69,0-15.4L165,40.76H377.78Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "271.39px 180.06px 0px",
        }}
        id="el75qifz1t71x"
        className="animable"
      />
      <rect
        x={168.42}
        y={35.98}
        width={212.78}
        height={278.6}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "274.81px 175.28px 0px",
        }}
        id="elduh6hncagx"
        className="animable"
      />
      <rect
        x={182.56}
        y={111.91}
        width={184.49}
        height={186.76}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "274.805px 205.29px 0px",
        }}
        id="elomcpwutlnlk"
        className="animable"
      />
      <path
        d="M184.29,84V49.85h10a9.68,9.68,0,0,1,5,1.14,6.78,6.78,0,0,1,2.83,3.31,13.65,13.65,0,0,1,.9,5.25,11,11,0,0,1-1.14,5.37,6.77,6.77,0,0,1-3.18,3,11.56,11.56,0,0,1-4.83.93h-3.37V84Zm6.24-19.56h2.36a5.91,5.91,0,0,0,2.64-.49,2.54,2.54,0,0,0,1.3-1.56,9.39,9.39,0,0,0,.36-2.89,13.69,13.69,0,0,0-.27-3,2.62,2.62,0,0,0-1.18-1.71,5.75,5.75,0,0,0-2.85-.55h-2.36Z"
        style={{
          fill: "#2353BA",
          transformOrigin: "193.66px 66.9203px 0px",
        }}
        id="eljuq5k2b54o"
        className="animable"
      />
      <path
        d="M191.88,100.47l6.87-18.12-6.66-16h5.82l4.42,10.58,3.88-10.58h5.4L205,83.53l7.08,16.94H206.3L201.45,89l-4.22,11.46Z"
        style={{
          fill: "#2353BA",
          transformOrigin: "201.98px 83.41px 0px",
        }}
        id="elbhn2t6jcwa"
        className="animable"
      />
      <line
        x1={287.57}
        y1={60.38}
        x2={366.44}
        y2={60.38}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "327.005px 60.38px 0px",
        }}
        id="elw32qgcnp12"
        className="animable"
      />
      <line
        x1={287.57}
        y1={77.4}
        x2={366.44}
        y2={77.4}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "327.005px 77.4px 0px",
        }}
        id="elh5rx6blxxr"
        className="animable"
      />
      <line
        x1={304.6}
        y1={70.03}
        x2={366.44}
        y2={70.03}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "335.52px 70.03px 0px",
        }}
        id="el9xqc7bzc3ye"
        className="animable"
      />
      <line
        x1={304.6}
        y1={85.35}
        x2={366.44}
        y2={85.35}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "335.52px 85.35px 0px",
        }}
        id="el2wi6hiip6dn"
        className="animable"
      />
      <line
        x1={188.84}
        y1={132.44}
        x2={250.69}
        y2={132.44}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "219.765px 132.44px 0px",
        }}
        id="elborf0kfacz"
        className="animable"
      />
      <line
        x1={188.84}
        y1={148.59}
        x2={250.69}
        y2={148.59}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "219.765px 148.59px 0px",
        }}
        id="elkk2fcmkbxp"
        className="animable"
      />
      <line
        x1={188.84}
        y1={140.79}
        x2={282.47}
        y2={140.79}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "235.655px 140.79px 0px",
        }}
        id="elcdesdatppa7"
        className="animable"
      />
      <line
        x1={188.84}
        y1={168.27}
        x2={250.69}
        y2={168.27}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "219.765px 168.27px 0px",
        }}
        id="el5arhha5po6r"
        className="animable"
      />
      <line
        x1={188.84}
        y1={184.42}
        x2={250.69}
        y2={184.42}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "219.765px 184.42px 0px",
        }}
        id="elb6qzmlel2lj"
        className="animable"
      />
      <line
        x1={188.84}
        y1={176.62}
        x2={282.47}
        y2={176.62}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "235.655px 176.62px 0px",
        }}
        id="elcqvyi1zqp9s"
        className="animable"
      />
      <line
        x1={187.52}
        y1={288.6}
        x2={246.15}
        y2={288.6}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "216.835px 288.6px 0px",
        }}
        id="elmgtv79sdymo"
        className="animable"
      />
      <line
        x1={298.7}
        y1={288.6}
        x2={357.33}
        y2={288.6}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "328.015px 288.6px 0px",
        }}
        id="elv0olcemizia"
        className="animable"
      />
      <path
        d="M195.79,224.23a21,21,0,1,0,29,6.51A21,21,0,0,0,195.79,224.23ZM215.94,256a16.59,16.59,0,1,1,5.13-22.9A16.59,16.59,0,0,1,215.94,256Z"
        style={{
          fill: "#2353BA",
          transformOrigin: "207.04px 241.964px 0px",
        }}
        id="elnzyqprqieb"
        className="animable"
      />
      <g id="el0x0vkk4l2d7">
        <rect
          x={196.24}
          y={240.54}
          width={21.17}
          height={3.41}
          style={{
            fill: "#2353BA",
            transformOrigin: "206.825px 242.245px 0px",
            transform: "rotate(-32.36deg)",
          }}
          className="animable"
          id="ely99s3l6j9k"
        />
      </g>
      <path
        d="M196.91,282s.29,6.66,9-5.22,7.54-11,5.22-4.34a58.76,58.76,0,0,1-7,13s10.44-16.52,10.44-11.89-9.86,24.35-7.83,22.61,8.41-10.43,8.41-10.43-.87,5.79,1.44,3.18,4.93-7.82,4.64-4.92-4.06,6.37-.29,2.9,6.67-7.83,5.8-4.64-3.19,4.35-.87,3.19,15.36-22.9,11.3-20.29-11.59,25.21-8.11,23.47,7.53-10.43,7.24-6.08.87,4.35,2,2.9"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "217.605px 280.601px 0px",
        }}
        id="eli0rskx8t7bi"
        className="animable"
      />
    </g>
    <g
      id="freepik--Table--inject-26"
      style={{
        transformOrigin: "254.82px 470.03px 0px",
      }}
      className="animable"
    >
      <line
        x1={483.09}
        y1={465.25}
        x2={26.55}
        y2={465.25}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "254.82px 465.25px 0px",
        }}
        id="elysmanes2qzn"
        className="animable"
      />
      <line
        x1={483.09}
        y1={474.81}
        x2={26.55}
        y2={474.81}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "254.82px 474.81px 0px",
        }}
        id="elr4w2jv5z9wl"
        className="animable"
      />
    </g>
    <g
      id="freepik--character-2--inject-26"
      style={{
        transformOrigin: "346.485px 373.555px 0px",
      }}
      className="animable"
    >
      <path
        d="M458.08,359.8h-96.8a6.35,6.35,0,0,0-6.29,5.44l-14.48,99H455.62l8.79-97.54A6.35,6.35,0,0,0,458.08,359.8Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "402.472px 412.02px 0px",
        }}
        id="elh3eupnwv0xd"
        className="animable"
      />
      <path
        d="M327,343.64s-26.19,13-35.18,16.59-11.2,5.2-12,7.4-35.78,57.17-39.77,62.17-12.6,15.79-11.4,23.18,10.8,13.8,20,13.6,15-2,16-4.6,26.39-48,26.39-48l8,50.57H401.19s8-69.16,8-79.36,2-22.19-2.2-24.79-21.59-8.59-34.39-12.79-16.79-5-19.78-5S327,343.64,327,343.64Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "319.101px 404.597px 0px",
        }}
        id="elq4atsjdsxib"
        className="animable"
      />
      <path
        d="M324.23,344s-3.4,6.4-3.6,11.6-1,14.79-4,20.59-5.2,9-3.6,8.39,10.79-10.79,11.79-13,2.6,2,2.6,2-3,8.59-3.4,9.79,12.59,17.79,12.59,17.79,11.2-8.79,13.8-10.79,1.8-3.6,2-6.2,1-6.39,2.39-8.59S360.21,382,363,386s5.6,5.4,5.2,2.4-1.8-8.8-2.8-19-.8-22.39-3.4-26.19-16.24-4.55-25.19-5.19A13.22,13.22,0,0,0,324.23,344Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "340.365px 369.549px 0px",
        }}
        id="elzhqiodilhea"
        className="animable"
      />
      <polyline
        points="283.85 378.05 291.05 414.01 281.75 390.1"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "286.4px 396.03px 0px",
        }}
        id="elnhk87vxj6hg"
        className="animable"
      />
      <path
        d="M325.23,353.64s2.8,15.59,5.4,27.58a123,123,0,0,0,6,20s13.2-22.19,17-33.78a133.83,133.83,0,0,0,5-19.59H325.23Z"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "341.93px 374.535px 0px",
        }}
        id="eltulisdzyu9"
        className="animable"
      />
      <path
        d="M339.82,359l-7.19,9,5,9.6-4.92,11.91a108.59,108.59,0,0,0,3.92,11.67s4.12-6.92,8.41-15l-3.61-8.33,4.4-10Z"
        style={{
          fill: "#2353BA",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "339.23px 380.09px 0px",
        }}
        id="eld8ojfstlkw"
        className="animable"
      />
      <path
        d="M325.23,347.84v5.8s1.93,10.76,4.07,21.27l10.12-10.68,10.67,11.82a84.2,84.2,0,0,0,3.53-8.62,133.83,133.83,0,0,0,5-19.59Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "341.925px 361.945px 0px",
        }}
        id="eloz6uh2bjac"
        className="animable"
      />
      <path
        d="M238.08,435.72s18.67-6.82,28.74,9.77"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "252.45px 439.911px 0px",
        }}
        id="el4azvlu2eru7"
        className="animable"
      />
      <path
        d="M267.15,440.37s-10.43,2.36-16,11.25,5.76,9.88,8.89,9.88,6.09-2.1,6.26-3.19,4.27-11.63,4.11-14.09A4,4,0,0,0,267.15,440.37Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "260.024px 450.935px 0px",
        }}
        id="elwtydh7chxdf"
        className="animable"
      />
      <path
        d="M262.35,444.05s-5.77,5-5.38,7,8.95,9.7,10.6,8.59a2.54,2.54,0,0,0,1.11-3.42C268.21,455.47,262.35,444.05,262.35,444.05Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "262.955px 451.899px 0px",
        }}
        id="elqv7xbidt27"
        className="animable"
      />
      <path
        d="M257,451s-3.39,3.61-2.28,5.26,8.86,5.86,10.87,4.59.71-2.57.71-2.57Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "260.605px 456.043px 0px",
        }}
        id="elx7c175dzyf"
        className="animable"
      />
      <polygon
        points="276.86 443.86 269.92 442.66 271.55 452.65 278.64 452.87 276.86 443.86"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "274.28px 447.765px 0px",
        }}
        id="elmthvkkwfdvm"
        className="animable"
      />
      <path
        d="M264.37,428.15l8.13,28.49,3.95,3.59v-5.07l-8.6-28a1.8,1.8,0,0,0-2.32-1.18h0A1.81,1.81,0,0,0,264.37,428.15Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "270.38px 443.054px 0px",
        }}
        id="elfnpx9qe7lkr"
        className="animable"
      />
      <path
        d="M267.15,440.37s-4.8,2.81-4.32,4.94,5.66,12.09,7.53,12.22,3-1,2.73-3.16-2-10-3.17-11.71S268.62,440.27,267.15,440.37Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "267.963px 448.95px 0px",
        }}
        id="elkv01zv4s3rm"
        className="animable"
      />
      <path
        d="M276.86,443.86s5.11.64,6,2.2-2.68,7.78-3.82,9.2-3.18,1.36-3.24-2.24A54.93,54.93,0,0,1,276.86,443.86Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "279.4px 449.992px 0px",
        }}
        id="elwntjia0rar"
        className="animable"
      />
      <path
        d="M249.22,455.89h56.07a5.92,5.92,0,0,1,5.91,5.91h0a5,5,0,0,1-5,5H250a4.6,4.6,0,0,1-4.6-4.6V459.7A3.81,3.81,0,0,1,249.22,455.89Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "278.3px 461.345px 0px",
        }}
        id="elixfnkoquocr"
        className="animable"
      />
      <path
        d="M349,443s-14.59-1.6-18.19-2.8-9.4-2.2-11.4-.6-11.79,13-12,14.39-2.2,6-1.2,7.2a7.25,7.25,0,0,0,3,1.8s13,3.6,14.39,3.8,14.79-.4,14.79-.4l7-2.6,9.79-.2s.2,3.2.4-4.6S349,443,349,443Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "330.758px 452.801px 0px",
        }}
        id="elqpsdtd6rt6c"
        className="animable"
      />
      <path
        d="M309.24,463s.2-4.6,1.2-6.6,12.39-12.19,12.39-12.19"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "316.035px 453.605px 0px",
        }}
        id="elpl9e2p09oe8"
        className="animable"
      />
      <path
        d="M313,464.18s2-5,2.8-5.4,10.19-6.4,10.19-6.4"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "319.495px 458.28px 0px",
        }}
        id="elpf2lb9ducha"
        className="animable"
      />
      <path
        d="M319.83,465.58s.4-2,1.4-2.4,9.4-4.2,9.4-4.2"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "325.23px 462.28px 0px",
        }}
        id="el9c4h5n0k64w"
        className="animable"
      />
      <polyline
        points="328.83 466.58 332.63 465.58 332.23 463.78 328.23 464.78"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "330.43px 465.18px 0px",
        }}
        id="elfld13s9m3g"
        className="animable"
      />
      <path
        d="M394.79,378.62s-10.59,22.19-10,30S388,429.4,388,429.4s-16.79,6.59-22.59,9S349,443,349,443l5.39,21.19-.79,2.2h58.16s4.4-.8,5.2-1.8,1.6-1.4,2-4.6,3-31.18,2.4-39-8.8-48.37-9.8-52.57-2.79-7-4.59-8-9.6-1.79-9.6-1.79"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "385.225px 412.51px 0px",
        }}
        id="elaip4h2njmju"
        className="animable"
      />
      <path
        d="M389.4,429.8s15.39.8,23,5.59"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "400.9px 432.595px 0px",
        }}
        id="elt75lvi3slfc"
        className="animable"
      />
      <path
        d="M392.19,427.8s10.8-.6,16.6,1.2"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "400.49px 428.352px 0px",
        }}
        id="el1h62pe7lt1p"
        className="animable"
      />
      <path
        d="M389.2,378s-5.4,12.6-5.6,17.4S383,403,383,403"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "386.1px 390.5px 0px",
        }}
        id="elj0s4nhvfjso"
        className="animable"
      />
      <path
        d="M364.61,344.64S376.8,362,367,410.41"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "367.711px 377.525px 0px",
        }}
        id="elo588yuvrouc"
        className="animable"
      />
      <path
        d="M372.8,411.81a6,6,0,1,0-6,6A6,6,0,0,0,372.8,411.81Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "366.8px 411.81px 0px",
        }}
        id="ellj6geoaynvc"
        className="animable"
      />
      <path
        d="M323,346.44s-6.19,4-7.19,20.19"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "319.405px 356.535px 0px",
        }}
        id="elw7e949vo4jc"
        className="animable"
      />
      <path
        d="M324.41,391.16c-1.51,10.92-3.57,22.65-4,25.22-1,5.5-7.53,7.42-11.73,5.62"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "316.545px 406.908px 0px",
        }}
        id="eltvfa6lnofe"
        className="animable"
      />
      <path
        d="M308.75,391.88c-1.37-6.12-2.21-11.43-1.91-13.86,1.4-11.19,9-11.39,9-11.39s9,1.2,9.59,10.8a79.93,79.93,0,0,1-1,13.73"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "316.108px 379.255px 0px",
        }}
        id="elx2tfb67gmsr"
        className="animable"
      />
      <path
        d="M325.63,421.2s-4.2,2.4-7,.8c-1.89-1.08-7-17.36-9.88-30.12"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "317.19px 407.215px 0px",
        }}
        id="el24g3n9x64f8"
        className="animable"
      />
      <g id="elu7kq5fttpre">
        <rect
          x={324.44}
          y={419.24}
          width={4.94}
          height={3.19}
          rx={1.57}
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "326.91px 420.835px 0px",
            transform: "rotate(-23.32deg)",
          }}
          className="animable"
          id="el6q283esqqhr"
        />
      </g>
      <g id="ela3f62mgb5k">
        <rect
          x={305.1}
          y={419.43}
          width={4.94}
          height={3.19}
          rx={1.57}
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "307.57px 421.025px 0px",
            transform: "rotate(30.99deg)",
          }}
          className="animable"
          id="elaibfe9ulgy"
        />
      </g>
      <path
        d="M315.84,298.07s-3.2,7.59-2.6,12.39,7,10,7,10,2.2-21.39,2.2-22.19S318.43,295.67,315.84,298.07Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "317.803px 308.664px 0px",
        }}
        id="elisin9t5985"
        className="animable"
      />
      <line
        x1={313.54}
        y1={325.26}
        x2={317.79}
        y2={322.29}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "315.665px 323.775px 0px",
        }}
        id="elzhcuns0t9h"
        className="animable"
      />
      <path
        d="M318.23,299.47s-.8,21.19-.4,25.18,2,16.79,3.4,20.79,6,16.79,9.6,18.19,10.79,2.4,13.19.8,11.19-7.59,13.59-13.39a39.31,39.31,0,0,0,2.8-10.59,14.73,14.73,0,0,0,7.6-10.6c1.2-7.6-2-9.79-2-9.79l.2-13.2s-2.2-9.19-8-14.19-12.59-5.4-19.59-1.6S318.23,299.47,318.23,299.47Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "342.999px 326.889px 0px",
        }}
        id="elehw8i3qince"
        className="animable"
      />
      <path
        d="M355.81,298.27s.2-5.2-4.79-3.4-6.2,5.2-13.6,8.2-17.19-.4-20.58-2.4-5.8-4.8-1.2-10,15.39-10.39,27-10.39,20.59,9.59,24.39,13.59,4.4,9,3,12.59-4,13.6-4,13.6-1.6-.2-2.2,2.19-.4,5-3,5.2-2.4-6.79-2.4-6.79-6.39-2-7.19-6.6S355.81,302.67,355.81,298.27Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "341.909px 303.867px 0px",
        }}
        id="ellyjrjgi55f"
        className="animable"
      />
      <path
        d="M332,328.52l-18.49-3.26,1.07,8.29a2.42,2.42,0,0,0,2,2.08l11.24,1.77a2.41,2.41,0,0,0,2.75-1.92Z"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "322.755px 331.346px 0px",
        }}
        id="elkh0w6st1z8"
        className="animable"
      />
      <path
        d="M335.37,328.87l21.44-.62-2.1,7.76a2.56,2.56,0,0,1-2.54,1.72l-13,.18a2.81,2.81,0,0,1-2.94-2.21Z"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "346.09px 333.083px 0px",
        }}
        id="el3mn5399zyjb"
        className="animable"
      />
      <line
        x1={332.03}
        y1={328.52}
        x2={335.37}
        y2={328.87}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "333.7px 328.695px 0px",
        }}
        id="elapn9m5k2wbk"
        className="animable"
      />
      <line
        x1={356.81}
        y1={328.25}
        x2={363.81}
        y2={322.25}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "360.31px 325.25px 0px",
        }}
        id="el3ehmy2zh9bn"
        className="animable"
      />
      <path
        d="M338.22,326.45s6-4.59,11.6-1.8"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "344.02px 325.1px 0px",
        }}
        id="elby2muratpv"
        className="animable"
      />
      <path
        d="M329.23,325.45s-4.6-5-9.8-1.6"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          transformOrigin: "324.33px 324.058px 0px",
        }}
        id="elqp3wfz7ag8l"
        className="animable"
      />
      <path
        d="M330.43,342.84s-1,4.2,0,5a8.83,8.83,0,0,0,5.59,1"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "333.003px 345.89px 0px",
        }}
        id="elfaggy5j2zla"
        className="animable"
      />
      <path
        d="M327.43,351s4,3,7.8,3.2,8.19-2.6,8.19-2.6"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "335.425px 352.605px 0px",
        }}
        id="elvfa7zwp4dze"
        className="animable"
      />
      <path
        d="M342.22,330.65s2.4,2.8,6.4-.2"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "345.42px 331.144px 0px",
        }}
        id="elkcn1knr96d"
        className="animable"
      />
      <path
        d="M322.53,329.42s2,3.1,6.36.67"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "325.71px 330.22px 0px",
        }}
        id="ell4frbxk50un"
        className="animable"
      />
    </g>
    <g
      id="freepik--character-1--inject-26"
      style={{
        transformOrigin: "138.698px 372.128px 0px",
      }}
      className="animable"
    >
      <path
        d="M198.5,298.25s1.27,19.05.31,20.64L196,323.65s1.27,13.66-1.27,15.24a16.73,16.73,0,0,1-6.35,1.91s-2.54,10.16-3.5,11.43-6.67,5.4-8.57,6-7.94,1-22.23-7.62-19.69-11.43-19.37-16.19,1-10.16,1-10.16,1.59-22.87,4.45-29.85,39.06-10.8,47-8.89S198.5,298.25,198.5,298.25Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "166.933px 321.768px 0px",
        }}
        id="el9dwmr7b2fs"
        className="animable"
      />
      <path
        d="M161.66,329.68l2.23,1.59s-1.91,7.62-1,11.12"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "162.775px 336.035px 0px",
        }}
        id="elb1kj5bkxax6"
        className="animable"
      />
      <path
        d="M193.41,323.3c-.32,1.46-1.09,2.52-1.71,2.38s-.87-1.42-.54-2.88,1.09-2.52,1.71-2.38S193.74,321.85,193.41,323.3Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "192.285px 323.05px 0px",
        }}
        id="el8x5hfio5003"
        className="animable"
      />
      <path
        d="M190.17,317.47s3.46-3.46,6.35-.19"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "193.345px 316.672px 0px",
        }}
        id="eldfiwyt3hx5s"
        className="animable"
      />
      <path
        d="M185.8,295.71s4.12,7.62,3.81,9.21-13.34,5.4-13.34,5.4-1.27,7.62-3.18,8.57l-1.9.95a33.26,33.26,0,0,1-3.81-6.67c-2.22-4.76-5.4-6-8.89-1.27s-.64,15.88,1.27,16.83l1.9.95s-1.59,1.59-7.62,2.55-10.48,2.22-14.92.95-10.8-13.34-13-28.9,4.76-28.89,14-33,30.17-.32,31.75.63,12.39-4.12,18.74-.95,6,6.67,7.62,8.89,9.84-.63,10.48,7.31-2.54,12.7-6.67,13.33S185.8,295.71,185.8,295.71Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "167.252px 301.561px 0px",
        }}
        id="el5sgu9pywin6"
        className="animable"
      />
      <path
        d="M110.61,345.56S80.18,361.79,74.38,365s-6.67,6.09-7,9,0,100.85,0,100.85H231.75S206.82,400,206,391.64s-2.6-23.19-4.92-24.93S166,350.48,166,350.48s-7.83-8.4-26.37-8.4S110.61,345.56,110.61,345.56Z"
        style={{
          fill: "#2353BA",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "149.492px 408.465px 0px",
        }}
        id="el6fj434um1yt"
        className="animable"
      />
      <path
        d="M129.45,405.19c.86.34,1.73.65,2.6.94"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "130.75px 405.66px 0px",
        }}
        id="elnq3rdyyk41o"
        className="animable"
      />
      <path
        d="M97,378.31s12,16.74,28.39,25"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "111.195px 390.81px 0px",
        }}
        id="el8n6qta6pwas"
        className="animable"
      />
      <path
        d="M107.42,397.72s6.66,6.67,20.57,10.44"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "117.705px 402.94px 0px",
        }}
        id="elgm2oj0seeh"
        className="animable"
      />
      <path
        d="M190,373.7c-4.63,5.26-11.15,13.67-13.31,21.41"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "183.345px 384.405px 0px",
        }}
        id="elfkiuutomc7"
        className="animable"
      />
      <path
        d="M194.94,368.45s-.92.89-2.36,2.42"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "193.76px 369.66px 0px",
        }}
        id="elb5bzfwi3qxj"
        className="animable"
      />
      <path
        d="M178.42,385.84s-4.35,8.4-5.21,13.62"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "175.815px 392.65px 0px",
        }}
        id="eli8xpnimp24"
        className="animable"
      />
      <path
        d="M108.87,347s16.81-.58,32.75-.29S168,351.93,168,351.93s-9.57-11.59-13.91-14.2-17.68-7-28.69-3.48A31.94,31.94,0,0,0,108.87,347Z"
        style={{
          fill: "#2353BA",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "138.435px 342.434px 0px",
        }}
        id="el788x0yhfixa"
        className="animable"
      />
      <path
        d="M61.07,474.74,45.75,416a4.9,4.9,0,0,1,4.8-5.9H172.44a4.92,4.92,0,0,1,4.8,3.86l13.53,60.84"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "118.208px 442.45px 0px",
        }}
        id="eln8j81f68jk"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
)
export default AdmissionAnimated

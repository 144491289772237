import { ACTION_TYPES } from "../../constants";

const handleShowModal = (modalName, payload) => ({
  type: ACTION_TYPES.GENERAL.MODAL.SHOW,
  modalName, payload
})
const handleHideModal = () => ({
  type: ACTION_TYPES.GENERAL.MODAL.HIDE
})
const handleResetModal = () => ({
  type: ACTION_TYPES.GENERAL.MODAL.RESET
})


export const general = {
  handleShowModal,
  handleHideModal,
  handleResetModal,
};

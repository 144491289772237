import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {PrescriptionParaclinicalServiceService} from "../../services/requests/paraclinical";
import {OrganizationService} from "../../services";
import {SystemReportService} from "../../services/requests/report";

const useGetInsuranceStabilityPercentage = () => {
  return useMutation({
    mutationFn: SystemReportService.getInsuranceStabilityPercentage,
  })
}

const useGetInsuranceResponseTimePercentage = () => {
  return useMutation({
    mutationFn: SystemReportService.getInsuranceResponseTimePercentage,
  })
}

const useGetDashboardStatistics = () => {
  return useMutation({
    mutationFn: SystemReportService.getDashboardStatistics,
  })
}

const useGetDashboardProviderStatistics = () => {
  return useMutation({
    mutationFn: SystemReportService.getDashboardProviderStatistics,
  })
}


const useGetTotalVisitCountChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getTotalVisitCountChart,
  })
}

const useGetVisitStatusChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getVisitStatusChart,
  })
}

const useGetVisitIssuerChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getVisitIssuerChart,
  })
}

const useGetVisitIssuerDailyChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getVisitIssuerDailyChart,
  })
}

const useGetVisitGenderChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getVisitGenderChart,
  })
}

const useGetVisitAgeChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getVisitAgeChart,
  })
}

const useGetPrescriptionIssuerDailyChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getPrescriptionIssuerDailyChart,
  })
}

const useGetVisitDailyChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getVisitDailyChart,
  })
}


const useGetTotalPatientCountChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getTotalPatientCountChart,
  })
}

const useGetPatientGenderChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getPatientGenderChart,
  })
}

const useGetPatientAgeChart = () => {
  return useMutation({
    mutationFn: SystemReportService.getPatientAgeChart,
  })
}

export {
  useGetInsuranceStabilityPercentage,
  useGetInsuranceResponseTimePercentage,
  useGetDashboardStatistics,
  useGetDashboardProviderStatistics,
  useGetTotalVisitCountChart,
  useGetVisitStatusChart,
  useGetVisitIssuerChart,
  useGetVisitIssuerDailyChart,
  useGetVisitGenderChart,
  useGetVisitAgeChart,
  useGetPrescriptionIssuerDailyChart,
  useGetVisitDailyChart,
  useGetTotalPatientCountChart,
  useGetPatientGenderChart,
  useGetPatientAgeChart
}

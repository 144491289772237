import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import Icon from "../../../../components/general/icon";
import SearchBox from "../../../../components/general/searchBox";
import Pagination from "../../../../components/general/table/pagination";
import {general} from "../../../../../store/action";
import {connect, useSelector} from "react-redux";
import PatientBiometricCardsBox from "../../../../components/patients/ehr/biometric/dataList/patientBiometricCardsBox";
import PatientBiometricsTable from "../../../../components/patients/ehr/biometric/dataList/patientBiometricsTable";
import AddPatientBiometricModal from "../../../../components/patients/ehr/biometric/addPatientBiometricModal";
import DeletePatientBiometricModal from "../../../../components/patients/ehr/biometric/deletePatientBiometricModal";
import {useGetPatientBiometrics} from "../../../../../hook/request/ehr/patientBiometric";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";



const dataListTitles = [
  "تاریخ و ساعت معاینه",
  "وزن (Kg)",
  "قد (Cm)",
  "شاخص توده بدنی (Kg/m)",
  "دور بازو زیر بغل (Cm)",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type biometricsListProps = {
  patientData?: any;
  openTab: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const BiometricsList = ({
  patientData,
  openTab,
  handleShowModal,
  visitEHRCheck = false,
}: biometricsListProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [biometricsData, setBiometricsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getBiometricsRequest = useGetPatientBiometrics(getQueryParams(filterValues), openTab === 'biometricsList');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getBiometricsRequest?.data?.data?.result &&
    setBiometricsData(getBiometricsRequest?.data?.data.result)
  }, [getBiometricsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const _filterValues = {
      ...filterValues,
      [name]: value,
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    const _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <>
      <section className="filter-box d-flex flex-column flex-md-row mb-4">
        <div className="form-group ms-md-auto">
          <SearchBox
            name="search"
            value={filterValues?.search}
            onChange={handleFilterChange}
            placeholder="جستجوی بیومتریک"
          />
        </div>
        {((visitEHRCheck && isProvider) || findAccessInAccessList('EHR_BIOMETRIC_CREATE')) &&
          <div
            className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <span onClick={() => handleShowModal('addPatientBiometricModal', {patientId: id, patientData: patientData})}
                    className="btn btn-outline-primary rounded d-flex align-items-center fw-bold px-4">
              <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
              ثبت بیومتریک
            </span>
          </div>
        }
      </section>

      <section className="table-cont table-responsive">
        {
          width < 900 ?
            <PatientBiometricCardsBox
              dataList={biometricsData}
              dataListTitles={dataListTitles}
              isRequesting={getBiometricsRequest.isPending}
              readonly={!visitEHRCheck}
            /> :
            <PatientBiometricsTable
              dataList={biometricsData}
              dataListTitles={dataListTitles}
              isRequesting={getBiometricsRequest.isPending}
              readonly={!visitEHRCheck}
            />
        }
        <Pagination
          paginationData={{...filterValues, ...biometricsData}}
          doChangePage={doChangePage}
        />
      </section>
      {openTab === 'biometricsList' && <AddPatientBiometricModal/>}
      <DeletePatientBiometricModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(BiometricsList);

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../general/icon';
import { localStorageClear, localStorageGet, localStorageSet } from '../../../utils/localStorage';
import { ROUTES } from '../../../constants';
import UserInfoBox from './userInfoBox';
import { useWindowSize } from '../../../hook/useWindowSize';
import SidebarItem from './sidebarItem';
import { useGetMenus } from '../../../hook/request/menus';
import BoxLoader from '../general/boxLoader';

type TSidebarProps = {
  handleShowDrawer: any;
  setShowDrawer: any;
  showDrawer:any;
}

const Sidebar = ({
  handleShowDrawer,
  setShowDrawer,
  showDrawer
}: TSidebarProps) => {

  const navigate = useNavigate();
  const [width] = useWindowSize();
  const [menusData, setMenusData] = useState([]);

  // services
  const getMenusRequest = useGetMenus();

  useEffect(() => {
    getMenusRequest.mutate()
  }, []);

  useEffect(() => {
    if(getMenusRequest?.data?.data?.result) {
      const _menuData = getMenusRequest?.data?.data?.result,
        _userAccessStorage = localStorageGet('USER_ACCESS');
      setMenusData(_menuData);
      localStorageSet('USER_ACCESS', {
        ..._userAccessStorage,
        menu: _menuData
      });
    }
  }, [getMenusRequest.data])

  const doLogout = () => {
    let _siteSettingStorage = localStorageGet('SITE_SETTING');
    localStorageClear();
    localStorageSet('SITE_SETTING', _siteSettingStorage);
    navigate(ROUTES.LOGIN.PATH);
  }

  useEffect(() => {
    if(!!width && width < 1450) {
      setShowDrawer(false)
      window.addEventListener("click", handleCloseDraweer);
    } else {
      window.removeEventListener("click", handleCloseDraweer);
    }
  }, [width]);

  const handleCloseDraweer = (e: any) => {
    if (
      document.getElementsByClassName("sidebar-content")[0] &&
      !document.getElementsByClassName("sidebar-content")[0]?.contains(e.target) &&
      !document.getElementsByClassName("sidebar-toggle-btn")[0]?.contains(e.target)
    ) {
      setShowDrawer(false);
    }
  }

  const _menuList = menusData
    .sort((a: any, b: any) => a?.viewOrder - b?.viewOrder)
    .map((item, i) => {
    return <SidebarItem item={item} showDrawer={showDrawer} key={i} />
  })

  // let _menuList2 = MainRoutes.map((item, i) => {
  //     if(item.isShowSideBar && hasRoutePermission(item))
  //       return <SidebarItem2 item={item} showDrawer={showDrawer} key={i} />
  //     return false;
  //   }).filter(item => item);

  return(
    <>
      <aside className="sidebar">
        <div className='sidebar-content pos-rel h-100'>
          <div className="d-flex justify-content-center p-4 position-relative">
            <UserInfoBox/>
            {/*<span*/}
            {/*  onClick={handleShowDrawer}*/}
            {/*  className='close-sidebar-btn font-30 cur-pointer me-auto hide-collapsed '*/}
            {/*>*/}
            {/*  <Icon name='close-square'/>*/}
            {/*</span>*/}
            <div className="floating-button-container">
              <button
                onClick={handleShowDrawer}
                className="btn-arrow-right btn btn-white rounded-3 px-1 d-flex align-items-center justify-content-center size-30 font-25"
              >
                <Icon name='arrow-right'/>
              </button>
            </div>
          </div>
          <nav className="sidebar-menu scrollbar-custom-sidebar ltr d-flex flex-column h-100 px-4 pb-4">
            {/* <SidebarItem item={{ title: ROUTES.PROFILE.TITLE, path: ROUTES.PROFILE.PATH, icon: ROUTES.PROFILE.ICON }} showDrawer={showDrawer} /> */}
            <div className="divider opacity-25 mx-3 my-2"></div>
            <div className="rtl pb-5 mb-3">
              {getMenusRequest?.isPending ? <BoxLoader className='mt-4' /> : _menuList}
              {/* {_menuList2} */}
            </div>
            {/* <div className="mt-auto pt-3 rtl">
              <div className="item-lst text-nowrap">
                {
                  showDrawer ?
                    <span onClick={doLogout} className="item-lnk cur-pointer mb-5">
                      <Icon name="logout" />
                      <span className="item-txt hide-collapsed">خروج از حساب کاربری</span>
                    </span>:
                    <Tooltip alt="خروج از حساب کاربری" placement='left'>
                      <span onClick={doLogout} className="item-lnk cur-pointer">
                        <Icon name="logout" />
                        <span className="item-txt hide-collapsed">خروج از حساب کاربری</span>
                      </span>
                    </Tooltip>
                }
              </div>
            </div> */}
          </nav>
        </div>
      </aside>
      {/* <ConfirmModal />
      <AdvancedReportDrawer>
        <AdvancedReportSample />
      </AdvancedReportDrawer> */}
    </>
  )
}

export default Sidebar;
import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "../general/modal";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector,
} from "../../../store/selector/general";
import { general } from "../../../store/action";
import { Dispatch } from "redux";
import PasswordField from "../general/form/passwordField";

import Image from "../general/image";

// icons
import warningDanger from "../../../assets/images/svg/warningDanger.svg";
import Icon from "../general/icon";
import { useGetProviderInsurer } from "../../../hook/request/setting";
import TextField from "../general/form/textField";
import { insurerSchema } from "../../../schema/settingSave-insurer-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import BoxLoader from "../general/boxLoader";
import AlertBox from "../general/alert";
interface IModalInfo {
  username: string;
  password: string;
}

interface ModalInfoProps {
  id: number;
  name: string;
  active: boolean;
  handleSuccess: any;
}
// کامپوننت مربوط مودال اتصال بیمه که پزشک می تواند با
// اطلاعات که وارد می کند ارتباط خود را به بیمه مورد نظر متصل کند
// Parent:SettingInsurance
// در این کامپوننت لیست بیمه ها رندر می شود
// و در صورت انتخاب هر بیمه با توجه با فعال یا غیر فعال بودن ان نمایش داده می شود

const AddSettingInsuranceModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: any) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>();
  const fieldValueInitialState = {
    username: "",
    password: "",
  }
  const [fieldValue, setFieldValue] = React.useState<IModalInfo>(fieldValueInitialState);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IModalInfo>({
    defaultValues: React.useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(insurerSchema),
  });

  React.useEffect(() => {
    if (modalName === "showAddInsurer" && isShowModalData) {
      setShowModal(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalName, modalData]);

  // services
  const providerInsurer = useGetProviderInsurer({
    onSuccess: () => {
      handleSetShowModal();
      modalInfo?.handleSuccess(modalInfo?.id)
    },
  });

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  const handleSubmitInsurer: SubmitHandler<IModalInfo> = (data) => {
    const { username, password } = data,
      body = {
        username: username,
        password: password,
        issuerType: {
          id: modalInfo?.id,
          name: modalInfo?.name,
        },
        active: modalInfo?.active,
      };
    providerInsurer.mutate(body);
  };

  const handleSetShowModal = () => {
    setShowModal(false);
    handleHideModal();
    setFieldValue(fieldValueInitialState)
  };

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showModal}
      noCloseBtn={true}
      className="w-1000  mw-100"
      bodyClassName="py-3 px-3 px-lg-5 pos-rel"
    >
      {/* loading */}
      { providerInsurer?.isPending && <BoxLoader type="cover" /> }
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className="d-flex mb-4 fs-3 px-2">
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              اتصال بیمه {modalInfo?.name}
            </h4>
            <span className="font-10 font-weight-700 text-gray2">
              در این بخش میتوانید حساب کاربری بیمه های تامین اجتماعی و سلامت را ثبت کنید ستون و سطرآنچنان که لازم است.
            </span>
            {/* {modalInfo?.firstName && */}
            {/* <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {/* {modalInfo?.firstName + ' ' + modalInfo?.lastName} */}
            {/* لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. */}
            {/* </span> */}
            {/* } */}
          </div>
          <span
            className="text-red-hover align-content-center mt-2"
            onClick={() => handleSetShowModal()}
          >
            <Icon name="close-square" />
          </span>
        </div>
        <form onSubmit={handleSubmit(handleSubmitInsurer)}>
          <div className="row">
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="password">
                  نام کاربری
                </label>
                <TextField
                  name="username"
                  placeholder="نام کاربری"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="password">
                  کلمه عبور
                </label>
                <PasswordField
                  name="password"
                  placeholder="کلمه عبور"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-between">
              <div className="col-9 col-md-6 col-sm-10 ps-4">
                <AlertBox type={'warning'} icon={'info-circle'} text={'در صورت فعال بودن ورود دو مرحله ای ، آن را غیر فعال نمایید.'} className={'border-orange py-1'} py={'py-2'}/>
              </div>
              <div className="d-flex form-group mb-0 mr-auto mb-auto pb-1">
                <button
                  type="submit"
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  ذخیره
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};


// get states
const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

// call actions
const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) =>
    dispatch(general.handleShowModal(data, body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSettingInsuranceModal);

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {Accordion} from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {useGetExamination} from "../../../hook/request/visit/examination";
import {TExaminationDetail} from "../../../types/visit";
import {getAge, getCellValue} from "../../../utils/utils";
import {BloodPressureScatterChart} from "../../components/general/chartOptions";
import SummaryCards from "../../components/visits/visitDetail/examinations/summaryCards";
import BoxLoader from "../../components/general/boxLoader";
import ECG from "../../components/visits/visitDetail/examinations/ecg/ECG";
import {general} from "../../../store/action";
import Image from "../../components/general/image";
import EmptyAvatar from "../../../assets/images/svg/profile-empty-avatar.svg";
import ExaminationInfo from "../../components/examinations/examinationInfo";
import {useWindowSize} from "../../../hook/useWindowSize";
import ExaminationInfoResponsive from "../../components/examinations/examinationInfoResponsive";



const ExaminationDetail = () => {

  let { id } = useParams();
  const [width] = useWindowSize();
  const [examinationData, setExaminationData] = useState<TExaminationDetail>();

  // services
  const getExamination = useGetExamination();

  useEffect(() => {
    id && getExamination.mutate({id})
  }, [id]);

  useEffect(() => {
    if(!getExamination.isPending && getExamination?.data?.data?.result){
      const {
        bpDiastolic, bpSystolic, bpPosition, ecgLeads, endDateDate,
        etco2, fico2, heartRate, id, pulseRate, respiratoryRate, spo2,
        startDate, temperature1, temperature2, visit, examiner, device, patient
      } = getExamination?.data?.data?.result;
      setExaminationData({
        bpDiastolic, bpSystolic, bpPosition, ecgLeads, endDateDate,
        etco2, fico2, heartRate, id, pulseRate, respiratoryRate, spo2,
        startDate, temperature1, temperature2, visit, examiner, device, patient
      })
    }
  }, [getExamination.data?.data?.result])



  return (
    <>
      <div className="card-box">
        {
          width > 900 ?
            <ExaminationInfo
              device={examinationData?.device}
              patient={examinationData?.patient}
              examiner={examinationData?.examiner}
              leadCount={examinationData?.ecgLeads?.length}
              provider={examinationData?.visit?.provider}
              healthUnit={examinationData?.visit?.healthUnit}
              chiefComplaint={examinationData?.visit?.chiefComplaint}
              startDate={examinationData?.startDate}
            />
            :
            <ExaminationInfoResponsive
              device={examinationData?.device}
              patient={examinationData?.patient}
              examiner={examinationData?.examiner}
              leadCount={examinationData?.ecgLeads?.length}
              provider={examinationData?.visit?.provider}
              healthUnit={examinationData?.visit?.healthUnit}
              chiefComplaint={examinationData?.visit?.chiefComplaint}
              startDate={examinationData?.startDate}
            />
        }
        {getExamination.isPending && <BoxLoader type="cover"/>}
        <Accordion className='visits-accordion rounded mb-4' alwaysOpen>
          <Accordion.Item eventKey='ecg' className='mb-3 rounded border'>
            <ECG
              id={examinationData?.id}
              ecgLeads={examinationData?.ecgLeads}
              startDate={examinationData?.startDate}
              heartRate={examinationData?.heartRate}
              pulseRate={examinationData?.pulseRate}
              healthUnit={examinationData?.visit?.healthUnit?.name}
            />
          </Accordion.Item>
          <Accordion.Item eventKey='spo2&bp' className='mb-3 rounded border'>
            <Accordion.Header className='d-flex flex-wrap'>
               <span className='font-14 me-4 text-gray font-en'>
                NIBP: &ensp;
                 <span className="text-black">
                   {getCellValue(examinationData?.bpDiastolic?.magnitude.toFixed(0))}/{getCellValue(examinationData?.bpSystolic?.magnitude.toFixed(0))} mmhg
                 </span>
              </span>
              <span className='font-14 me-2 text-gray font-en'>
                SpO2: &ensp;
                <span className="text-black">
                 {getCellValue(examinationData?.spo2?.magnitude.toFixed(1))}
                </span>
              </span>
              <div className="flex-fill"></div>
              <span className='font-14 fw-bold me-2 text-black'>
                بررسی اطلاعات اکسیژن‌خون و فشارخون
              </span>
            </Accordion.Header>
            <Accordion.Body className='rounded-bottom bg-primary-10'>
              <div className='card-box'>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={BloodPressureScatterChart({
                    data: [examinationData?.bpDiastolic, examinationData?.bpSystolic]
                  })}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='co2' className='mb-3 rounded border'>
            <SummaryCards
              bpDiastolic={examinationData?.bpDiastolic}
              bpSystolic={examinationData?.bpSystolic}
              etco2={examinationData?.etco2}
              fico2={examinationData?.fico2}
              respiratoryRate={examinationData?.respiratoryRate}
              temperature1={examinationData?.temperature1}
              temperature2={examinationData?.temperature2}
            />
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ExaminationDetail);
import * as yup from "yup";

const addDoctorSchema = yup.object({
  firstName: yup
    .string()
    .required("این فیلد الزامیست."),
  lastName: yup
    .string()
    .required("این فیلد الزامیست."),
  specialty: yup
    .string()
    .required("این فیلد الزامیست."),
  medId: yup
    .string()
    .required("این فیلد الزامیست."),
  mobile: yup
    .string()
    .required("این فیلد الزامیست."),
  nationalCode: yup
    .string()
    .required("این فیلد الزامیست."),
  password: yup
    .string()
    .required("این فیلد الزامیست."),
});

const editDoctorSchema = yup.object({
  firstName: yup
    .string()
    .required("این فیلد الزامیست."),
  lastName: yup
    .string()
    .required("این فیلد الزامیست."),
  specialty: yup
    .string()
    .required("این فیلد الزامیست."),
  medId: yup
    .string()
    .required("این فیلد الزامیست."),
  mobile: yup
    .string()
    .required("این فیلد الزامیست."),
  nationalCode: yup
    .string()
    .required("این فیلد الزامیست."),
});

export {
  addDoctorSchema,
  editDoctorSchema
};

import React from "react";
import Icon from "./icon";
import { DebounceInput } from "react-debounce-input";

type SearchBoxProps = {
  name?: string;
  value?: string;
  onChange: (e: any) => void;
  placeholder?: string;
  timeout?: number;
  autoFocus?:  boolean;
}

const SearchBox = ({ 
  name,
  value,
  onChange,
  placeholder,
  timeout,
  autoFocus
}: SearchBoxProps) => {
  return(
    <div className="search-box inp-box w-100">
      <span className="inp-icon text-gray right-mode font-20 me-1"><Icon name="search" /></span>
      <DebounceInput
        className="form-control font-12"
        style={{paddingRight: "44px"}}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        // minLength={2}
        debounceTimeout={timeout || 500}
        autoFocus={autoFocus}
      />
    </div>
  )
}

export default SearchBox;
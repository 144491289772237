import {useMutation, useQuery} from "@tanstack/react-query";
import {generatePath, useNavigate} from "react-router-dom";
import {VisitService} from "../../../services/requests/visit/visit";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {ROUTES} from "../../../constants";
import {VISIT_MODE} from "../../../constants/constant/enum";

const useGetVisits = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getVisits", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return VisitService.getVisitsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetVisit = () => {
  return useMutation({
    mutationFn: VisitService.getVisit,
  })
}

const useAddVisit = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: VisitService.addVisit,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, { id: data?.data?.id }))
          onSuccess();
        }
      })
    },
  })
}

const useChangeStatusVisit = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: VisitService.changeStatusVisit,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getVisits"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const useCheckInsurance = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: VisitService.checkInsurance,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          // Noti("success", data?.data?.msg)
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const useVisitEHRCheck = () => {
  return useMutation({
    mutationFn: VisitService.visitEHRCheck,
    onSuccess: (data: any) => {
      handleResponse({
        data,
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const useMedicalHistory = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: VisitService.medicalHistory,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const useVisitAdmission = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: VisitService.visitAdmission,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, { id: data?.data?.result?.id }), {state: {mode: VISIT_MODE.EDIT.value}})
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const useUrgentRequest = () => {
  return useMutation({
    mutationFn: VisitService.urgentRequest,
  })
}

const useUrgentApprove = () => {
  return useMutation({
    mutationFn: VisitService.urgentApprove,
  })
}

const useUrgentAccept = ({
  onSuccess
}: {
  onSuccess: (data: any) => void
}) => {
  return useMutation({
    mutationFn: VisitService.urgentAccept,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          onSuccess(data);
        }
      })
    },
  })
}

const useVisitCallRequest = () => {
  return useMutation({
    mutationFn: VisitService.visitCallRequest,
  })
}

const useVisitCallApprove = () => {
  return useMutation({
    mutationFn: VisitService.visitCallApprove,
  })
}

const useVisitCallAccept = ({
  onSuccess
}: {
  onSuccess: (data: any) => void
}) => {
  return useMutation({
    mutationFn: VisitService.visitCallAccept,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          onSuccess(data);
        }
      })
    },
  })
}

export {
  useGetVisits,
  useGetVisit,
  useAddVisit,
  useChangeStatusVisit,
  useCheckInsurance,
  useVisitEHRCheck,
  useMedicalHistory,
  useVisitAdmission,
  useUrgentRequest,
  useUrgentApprove,
  useUrgentAccept,
  useVisitCallRequest,
  useVisitCallApprove,
  useVisitCallAccept
}

import { createSelector } from 'reselect';

const generalSelector = (state) => state.general;

// error
export const errorDataSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.errorReducer.data,
);

// modal
export const modalDataSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.modalReducer.data,
);
export const modalNameSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.modalReducer.modalName,
);
export const isShowModalDataSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.modalReducer.show,
);
export const isHideModalDataSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.modalReducer.hide,
);

// socket is connected
export const setSocketIsConnectedSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setSocketIsConnectedReducer.data,
);
// socket call data
export const setSocketCallDataSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setSocketCallDataReducer.data,
);
// socket call status
export const setSocketCallStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setSocketCallStatusReducer.data,
);

// getUserInfo
export const getUserInfoSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.getUserInfoReducer.data,
);
export const isSuccessGetUserInfoSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.getUserInfoReducer.success,
);
export const isRequestingGetUserInfoSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.getUserInfoReducer.requesting,
);
export const isErrorGetUserInfoSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.getUserInfoReducer.error,
);

// getUserStatus
export const getUserStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.getUserStatusReducer.data,
);
export const isSuccessGetUserStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.getUserStatusReducer.success,
);
export const isRequestingGetUserStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.getUserStatusReducer.requesting,
);
export const isErrorGetUserStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.getUserStatusReducer.error,
);

// setOnlineStatus
export const setOnlineStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setOnlineStatusReducer.data,
);
export const isSuccessSetOnlineStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setOnlineStatusReducer.success,
);
export const isRequestingSetOnlineStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setOnlineStatusReducer.requesting,
);
export const isErrorSetOnlineStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setOnlineStatusReducer.error,
);

// setOfflineStatus
export const setOfflineStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setOfflineStatusReducer.data,
);
export const isSuccessSetOfflineStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setOfflineStatusReducer.success,
);
export const isRequestingSetOfflineStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setOfflineStatusReducer.requesting,
);
export const isErrorSetOfflineStatusSelector = createSelector(
  [generalSelector],
  (generalStatus) => generalStatus.setOfflineStatusReducer.error,
);
import React from "react";
import {connect, useSelector} from "react-redux";
import {general} from "../../../../../../store/action";
import Icon from "../../../../general/icon";
import {getCellValue} from "../../../../../../utils/utils";
import {Dispatch} from "redux";
import {MtoJDateFormat} from "../../../../../../utils/date";
import Tooltip from "../../../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type PatientAllergyCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any,
  readonly: boolean
}

const PatientAllergyCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal,
  readonly
}: PatientAllergyCardsBoxItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="allergy">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.allergy?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="severity">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.severity?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="reactions">{dataListTitles[2]}</label>
        <span className="mr-2">
          {item?.reactions?.map((reaction:any, i:number) => {
            return getCellValue(reaction?.name) + ((i +1) != item?.reactions?.length ? ', ' : '')
          })}
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="startDate">{dataListTitles[3]}</label>
        <span
          className="mr-2">{getCellValue(MtoJDateFormat(item?.startDate))}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="endDate">{dataListTitles[4]}</label>
        <span
          className="mr-2">{item?.endDate ? getCellValue(MtoJDateFormat(item?.endDate)) : '-'}</span>
      </div>
      {(!readonly && isProvider) || findAccessInAccessList('EHR_ALLERGY_DELETE') &&
        <div className="d-flex align-items-center justify-content-between my-3">
          <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[6]}</label>
          <div className="d-flex align-items-center justify-content-end my-3">
            <div className="d-flex align-items-center">
              <Tooltip alt={'حذف'} placement='bottom' className="tooltip-pre-line">
                <span
                  onClick={() => handleShowModal('deletePatientAllergyModal', item?.id)}
                  className="d-flex text-red-hover mx-2 font-20 cur-pointer"
                >
                  <Icon name='trash'/>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientAllergyCardsBoxItem);
import { axiosRequest } from '../api';
import { API_PATH } from '../../constants';

export const OrganizationService = {
  getOrganizationsList(params){ return axiosRequest('GET', API_PATH.ORGANIZATION.LIST(params)) },
  getOrganization(data){ return axiosRequest('POST', API_PATH.ORGANIZATION.INFO, data) },
  addOrganization(data){ return axiosRequest('POST', API_PATH.ORGANIZATION.ADD, data) },
  deleteOrganization(data){ return axiosRequest('POST', API_PATH.ORGANIZATION.DELETE, data) },
  getRelatedOrganizationsList(params){ return axiosRequest('GET', API_PATH.ORGANIZATION.LIST_RELATED(params)) },
  addRelatedOrganization(data){ return axiosRequest('POST', API_PATH.ORGANIZATION.ADD_RELATED, data) },
}
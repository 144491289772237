import { useEffect, useMemo, useState } from "react";
import { ROUTES } from "../../../constants";
import BoxLoader from "../../components/general/boxLoader";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserAccess, useAddUserAccess, useGetUser } from "../../../hook/request/users";
import { TUserAccessFields } from "../../../types/user";
import { SubmitHandler, useForm } from "react-hook-form";
import Icon from "../../components/general/icon";
import UserAccessLevelBox from "../../components/users/userAccessLevelBox";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

const UserAccess = () => {

  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const { id, accountId } = useParams();
  const [fieldValue, setFieldValue] = useState<TUserAccessFields>({
    firstName: '',
    lastName: '',
    access: {}
  });
  const {
    handleSubmit,
    reset,
  } = useForm<TUserAccessFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });
  const [allUserAccess, setAllUserAccess] = useState([]);

  // services
  const getUserRequest = useGetUser();
  const getUserAccessRequest = useGetUserAccess();
  const addUserAccessRequest = useAddUserAccess();

  useEffect(() => {
    if(id){
      getUserRequest.mutate({id});
      getUserAccessRequest.mutate({id: accountId});
    }
  }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getUserRequest?.data?.data?.result) {
      const { firstName, lastName } = getUserRequest.data.data.result,
        _fieldValue = {
          firstName, lastName
        };
      setFieldValue({
        ...fieldValue,
        ..._fieldValue
      });
    }
  }, [getUserRequest.data])

  useEffect(() => {
    if (getUserAccessRequest?.data?.data?.result) {
      const _userAccess = getUserAccessRequest.data.data.result;
      let _access = {};
      setAllUserAccess(_userAccess);
      for(const acc of _userAccess){
        for(const innerAcc of acc?.accessProcess){
          if(innerAcc?.checked) {
            _access = {
              ..._access,
              [`access_${innerAcc?.id}`]: innerAcc.checked
            }
          }
        }
      }
      setFieldValue({ 
        ...fieldValue,
        access: _access
      });
    }
  }, [getUserAccessRequest.data])

  const UserAccessSubmit: SubmitHandler<TUserAccessFields> = (data) => {
    const { access }: any = fieldValue;
    const _selectedAccess = [];
    for(const acc in access){
      access?.[acc] && _selectedAccess.push(acc.split("_")[1])
    }
    const body = {
      accountId,
      accessProcessIds: _selectedAccess
    }
    addUserAccessRequest.mutate(body);
  }

  const _allUserAccess = allUserAccess.map((item: any) => {
    return (
      <UserAccessLevelBox 
        item={item}
        setFieldValue={setFieldValue}
        fieldValue={fieldValue}
      />
    )
  })

  return (
    <div className="card-box mb-3">
      {
        (
          getUserAccessRequest?.isPending ||
          addUserAccessRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(UserAccessSubmit)}>
          <div className="d-flex align-items-center">
            <button
              onClick={() => navigate(-1)}
              type='button'
              className="btn-arrow-right btn btn-dark px-1 d-flex align-items-center justify-content-center size-47 font-25 shadow"
            >
              <Icon name='arrow-right' />
            </button>
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold me-3">
              سطح دسترسی
              {
                getUserRequest?.isPending ? 
                  <BoxLoader type="text-loader" className="text-loader mx-2" /> :
                  <span className="d-inline-flex fw-semibold text-primary mx-1">{fieldValue?.firstName} {fieldValue?.lastName}</span>
              }
            </h4>
          </div>
          <div className="bg-gray rounded px-5 py-1 mt-4">
            {_allUserAccess}
          </div>

          <div className="col-12 d-flex justify-content-end mt-4">
            <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
              <button
                type='button'
                onClick={() => navigate(ROUTES?.USER?.PATH)}
                className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
              >
                بازگشت
              </button>
              {
                findAccessInAccessList('OPERATOR_CHANGE_ACCESS') &&
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  ثبت تغییرات
                </button>
              }
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

export default UserAccess;
import { ACTION_TYPES } from "../../../constants";

const initialState = {
  requesting: false,
  success: false,
  error: false,
  data: null,
};

const getClinicsListReducer = (state = initialState, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case ACTION_TYPES.CLINIC.LIST.SUCCESS: {
      return {
        success: true,
        requesting: false,
        error: false,
        data: payload,
      }
    }
    case ACTION_TYPES.CLINIC.LIST.UPDATE: {
      return {
        success: true,
        requesting: false,
        error: false,
        data: {
          ...state?.data,
          data: state?.data?.data?.filter(item => item?.id !== payload),
        }
      }
    }
    case ACTION_TYPES.CLINIC.LIST.REQUESTING:
      return { success: false, requesting: true, error: false };
    case ACTION_TYPES.CLINIC.LIST.ERROR:
      return { success: false, requesting: false, error: true, data: payload };
    default:
      return state
  }
}

export default getClinicsListReducer
import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {AppointmentService} from "../../services/requests/appointment";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {TGIdNameData} from "../../types/general";

const useGetAppointments = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getAppointments", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return AppointmentService.getAppointmentsList(params);
    },
    enabled: modalName ?? true
  });
}


const useGetAppointment = () => {
  return useMutation({
    mutationFn: AppointmentService.getAppointment,
  })
}

const useAddAppointment = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: AppointmentService.addAppointment,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.APPOINTMENT.PATH);
        }
      })
    },
  })
}

const useChangeStatusAppointment = ({
  onSuccess
}: {
  onSuccess: (status: TGIdNameData) => void
}) => {
  return useMutation({
    mutationFn: AppointmentService.changeStatusAppointment,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getAppointments"] })
          queryClient.invalidateQueries({ queryKey: ["getVisits"] })
          onSuccess(data?.data?.result?.status);
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetAppointments,
  useGetAppointment,
  useAddAppointment,
  useChangeStatusAppointment
}

import * as React from "react"
import { SVGProps } from "react"
const Analytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={47}
    height={40}
    viewBox="0 0 47 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      opacity={0.3}
      x={25.5117}
      y={6.66602}
      width={5.69659}
      height={26.6667}
      rx={2}
      fill="#2E57FF"
    />
    <rect
      x={16.0195}
      y={15}
      width={5.69659}
      height={18.3333}
      rx={2}
      fill="#2E57FF"
    />
    <rect
      x={35.0078}
      y={18.334}
      width={5.69659}
      height={15}
      rx={2}
      fill="#2E57FF"
    />
    <rect
      x={6.52344}
      y={21.666}
      width={5.69659}
      height={11.6667}
      rx={2}
      fill="#2E57FF"
    />
  </svg>
)
export default Analytics

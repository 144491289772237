import * as yup from "yup";

const addUserSchema = yup.object({
  firstName: yup
    .string()
    .required("این فیلد الزامیست."),
  lastName: yup
    .string()
    .required("این فیلد الزامیست."),
  mobile: yup
    .string()
    .required("این فیلد الزامیست."),
  nationalCode: yup
    .string()
    .required("این فیلد الزامیست."),
  password: yup
    .string()
    .required("این فیلد الزامیست."),
});

const editUserSchema = yup.object({
  firstName: yup
    .string()
    .required("این فیلد الزامیست."),
  lastName: yup
    .string()
    .required("این فیلد الزامیست."),
  mobile: yup
    .string()
    .required("این فیلد الزامیست."),
  nationalCode: yup
    .string()
    .required("این فیلد الزامیست."),
});

export {
  addUserSchema,
  editUserSchema,
};

import React from "react";
import {connect} from "react-redux";
import {general} from "../../../../../../store/action";
import {getCellValue} from "../../../../../../utils/utils";
import {Dispatch} from "redux";
import {MtoJFullDateFormat} from "../../../../../../utils/date";
import Icon from "../../../../general/icon";

type PatientVisitCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const PatientVisitCardsBoxItem = ({
 item,
 dataListTitles,
 handleShowModal
}: PatientVisitCardsBoxItemProps) => {

  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-yellow1 text-yellow'
      case 200:
        return 'bg-yellow1 text-yellow'
      case 300:
        return 'bg-light-green text-green2'
      case 400:
        return 'bg-primary text-white'
      default:
        return 'bg-light-red text-red'
    }
  }

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="provider">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.provider?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(MtoJFullDateFormat(item?.startDateTime))}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="startDateTime">{dataListTitles[2]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(item?.visitType?.name)}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="visitType">{dataListTitles[3]}</label>
        <span
          className="mr-2">
          <span className={`px-3 py-1 rounded text-nowrap ${determineBgColor()} `}>
            {getCellValue(item?.status?.name)}
          </span>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[4]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            <span
              onClick={() => handleShowModal('showPatientVisitModal', {visitId: item?.id, patientId: item?.patient?.id})}
              className="d-flex text-hover mx-2 font-20 cur-pointer"
            >
            <Icon name='eye'/>
          </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientVisitCardsBoxItem);
import {Nav, Tab} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Icon from "../../../../components/general/icon";
import PatientBiometricsList from "./patientBiometricsList";
import PatientBiometricsCharts from "./patientBiometricsCharts";

type biometricsProps = {
  patientData?: any;
  initialOpenTab?: string;
  visitEHRCheck?: boolean;
}

const PatientBiometrics = ({
 patientData,
 initialOpenTab,
 visitEHRCheck = false,
}: biometricsProps) => {

  const [openTab, setOpenTab] = useState('')

  useEffect(() => {
    setOpenTab(initialOpenTab === 'biometrics' ? 'biometricsCharts' : '')
  }, [initialOpenTab]);

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        <Tab.Container
          id="biometrics-list-chart-tabs"
          defaultActiveKey="biometricsCharts"
          onSelect={(eventKey) => setOpenTab(eventKey || '')}
        >
          <Tab.Content>
            <Tab.Pane eventKey="biometricsList"><PatientBiometricsList patientData={patientData} openTab={openTab} visitEHRCheck={visitEHRCheck}/></Tab.Pane>
            <Tab.Pane eventKey="biometricsCharts">{openTab === 'biometricsCharts' && <PatientBiometricsCharts patientData={patientData} initialOpenTab={openTab} visitEHRCheck={visitEHRCheck}/>}</Tab.Pane>
          </Tab.Content>
          <Nav variant="pills" className='mt-5' justify>
            <div className='flex-fill'></div>
            <div className='d-flex rounded bg-primary-subtle vital-list-chart-nav'>
              <Nav.Item>
                <Nav.Link eventKey="biometricsList" className='font-20 d-flex'><Icon name='document'/></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="biometricsCharts" className='font-20 d-flex'><Icon name='graph'/></Nav.Link>
              </Nav.Item>
            </div>
          </Nav>
        </Tab.Container>
      </div>

    </>
  )
}

export default PatientBiometrics;
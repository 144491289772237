import React, {useState} from "react";
import {general} from "../../../../../../store/action";
import {connect, useSelector} from "react-redux";
import {Nav, Tab} from "react-bootstrap";
import Icon from "../../../../general/icon";
import AddPatientVitalModal from "../../vital/addPatientVitalModal";
import VitalChartsSummary from "./dataList/vitalChartsSummary";
import VitalsListSummary from "./dataList/vitalListSummary";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type patientVitalSummaryProps = {
  patientData?: any;
  initialOpenTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const PatientVitalSummary = ({
 patientData,
 initialOpenTab,
 handleShowModal,
 visitEHRCheck = false,
}: patientVitalSummaryProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [openTab, setOpenTab] = useState('vitalsCharts')

  return (
    <>
      <Tab.Container
        id="vitals-list-chart-tabs"
        defaultActiveKey="vitalsCharts"
        onSelect={(eventKey) => setOpenTab(eventKey || '')}
      >
        <div className="rounded">
          <section className=" d-flex flex-column align-items-center flex-md-row border-bottom">
            <div className="form-group ms-md-auto fw-bold mx-3 my-2">
              شاخص های حیاتی
            </div>
            <Nav variant="pills" justify>
              <div className={`d-flex bg-primary-subtle vital-list-chart-nav my-2 ${!visitEHRCheck ? 'mx-3' : ''}`}>
                <Nav.Item>
                  <Nav.Link eventKey="vitalsList" className='font-20 d-flex'><Icon name='document'/></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vitalsCharts" className='font-20 d-flex'><Icon name='graph'/></Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
            {((visitEHRCheck && isProvider) || findAccessInAccessList('EHR_VITAL_CREATE')) &&
              <a className='p-1 d-flex align-items-center btn btn-dark rounded rounded-3 text-nowrap mx-3 my-2'
                onClick={() => handleShowModal('addPatientVitalModal', {
                  patientId: patientData,
                  patientData: patientData
                })}>
                <span className="m-1">افزودن شاخص</span>
              </a>
            }
          </section>
          <section className="mt-2">
            <Tab.Content>
              <Tab.Pane eventKey="vitalsList">
                <VitalsListSummary patientData={patientData} openTab={openTab}/>
              </Tab.Pane>
              <Tab.Pane eventKey="vitalsCharts">
                {openTab === 'vitalsCharts' && <VitalChartsSummary patientData={patientData}/>}
              </Tab.Pane>
            </Tab.Content>
          </section>
        </div>
      </Tab.Container>
      {initialOpenTab === 'patientSummary' && <AddPatientVitalModal/>}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientVitalSummary);

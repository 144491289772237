import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {DraftPrescriptionMedicationItemService} from "../../../services/requests/visit/draftPrescriptionMedicationItem";

const useGetDraftPrescriptionMedicationItemList = (params: any, modalName: boolean) => {
  return useQuery({
    queryKey: ["getDraftPrescriptionMedicationItemList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return DraftPrescriptionMedicationItemService.getDraftPrescriptionMedicationItemList(params);
    },
    enabled: modalName ?? true,
  });
}

const useGetDraftPrescriptionMedicationItem = () => {
  return useMutation({
    mutationFn: DraftPrescriptionMedicationItemService.getDraftPrescriptionMedicationItem,
  })
}

const useAddDraftPrescriptionMedicationItem = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: DraftPrescriptionMedicationItemService.addDraftPrescriptionMedicationItem,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getDraftPrescriptionMedicationItemList"] })
          onSuccess();
        }
      })
    },
  })
}

const useDeleteDraftPrescriptionMedicationItem = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: DraftPrescriptionMedicationItemService.deleteDraftPrescriptionMedicationItem,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getDraftPrescriptionMedicationItemList"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetDraftPrescriptionMedicationItemList,
  useGetDraftPrescriptionMedicationItem,
  useAddDraftPrescriptionMedicationItem,
  useDeleteDraftPrescriptionMedicationItem
}

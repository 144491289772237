import {useEffect, useMemo, useState} from "react";
import { connect } from "react-redux";
import {Dispatch} from "redux";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import Modal from "../general/modal";
import BoxLoader from "../general/boxLoader";
import Icon from "../general/icon";
import {useCloseSchedule} from "../../../hook/request/schedule";
import DatePickerField from "../general/form/datePickerField";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {closeScheduleSchema} from "../../../schema/scheduleSchema";
import {mFormat} from "../../../utils/date";
import {TCloseScheduleFields} from "../../../types/schedule";

type CloseScheduleModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

const CloseScheduleModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: CloseScheduleModalProps) => {

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  const [fieldValue, setFieldValue] = useState<TCloseScheduleFields>({
    toDate: '',
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TCloseScheduleFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(closeScheduleSchema),
  });

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if(
      isShowModalData &&
      modalName === 'closeScheduleModal'
    ){
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  // services
  const closeScheduleRequest = useCloseSchedule({
    onSuccess: () => {
      handleSetShowModal();
    }
  });

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  const closeScheduleSubmit: SubmitHandler<TCloseScheduleFields> = (data) => {
    console.log(data)
    const {toDate} = data,
      body = {
        id: modalInfo,
        toDate: mFormat(toDate, 'YYYY-MM-DD'),
      };
    closeScheduleRequest.mutate(body);
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-400 mw-100"
      bodyClassName="py-5 px-3 px-lg-5 pos-rel"
    >
      {closeScheduleRequest?.isPending && <BoxLoader type="cover"/>}
      <form onSubmit={handleSubmit(closeScheduleSubmit)}>
        <div className="text-center">
          <div className="d-flex justify-content-center mb-4">
          <span className="delete-item-bg d-flex justify-content-center align-items-center text-red font-25 pt-4">
            <Icon name='close-square'/>
          </span>
          </div>
          <h4 className="font-14 mb-2">کاربر گرامی</h4>
          <p className="text-black">
            لطفا تاریخ اتمام برنامه را مشخص نمایید
          </p>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 px-3 w-100">
            <div className="form-group">
              <DatePickerField
                name="toDate"
                placeholder="تاریخ پایان برنامه"
                control={control}
                error={errors}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4 pt-2">
            <button
              type='button'
              onClick={() => handleSetShowModal()}
              className="btn btn-outline-gray flex-grow-1 py-2 mx-1"
            >
              انصراف
            </button>
            <button
              type={"submit"}
              className="btn btn-danger d-flex align-items-center py-2 mx-1"
            >
              تایید تاریخ اتمام
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseScheduleModal);

import React, {useEffect, useState} from "react";
import {TAddMedicalHistoryFields} from "../../../../../../types/visit";
import BoxLoader from "../../../../general/boxLoader";
import Icon from "../../../../general/icon";
import {useGetPatientEHRVisit} from "../../../../../../hook/request/ehr/patientSummary";
import {Accordion} from "react-bootstrap";
import Tooltip from "../../../../general/tooltip";
import EmptyRow from "../../../../general/table/emptyRow";

type MedicalHistoryProps = {
  visitData?: string;
  openTab?: string;
}

const VisitMedicalHistory = ({
 visitData,
 openTab,
}: MedicalHistoryProps) => {

  const [medicalHistoryData, setMedicalHistoryData] = useState<TAddMedicalHistoryFields>();

  // services
  const getMedicalHistoryRequest = useGetPatientEHRVisit();

  useEffect(() => {
    visitData && getMedicalHistoryRequest.mutate({id: visitData})
  }, [visitData]);

  useEffect(() => {
    if (getMedicalHistoryRequest?.data?.data?.result) {
      const {
          chiefComplaint, clinicalFindings, labResultNote, visitGoalNote, examinationNote
        } = getMedicalHistoryRequest.data.data.result,
        _fieldValue = {
          ...medicalHistoryData,
          chiefComplaint, clinicalFindings, labResultNote, visitGoalNote, examinationNote
        };
      setMedicalHistoryData(_fieldValue)
    }
  }, [getMedicalHistoryRequest.data])

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        {getMedicalHistoryRequest.isPending && <BoxLoader type="cover"/>}
        <Accordion className='visits-accordion rounded mb-4' alwaysOpen>
          <Accordion.Item eventKey='chiefComplaint' className='mb-3 rounded border'>
            <Accordion.Header className='d-flex flex-wrap'>
              <div className="flex-fill"></div>
              <span className='font-14 fw-bold me-2 text-black'>  شکایت اصلی بیمار
                </span>
              <Tooltip alt={'توضیح مختصر از علائم و مشکلات اصلی بیمار'} className="tooltip-pre-line">
                <div className='d-flex align-items-center'>
                  <Icon name='info'/>
                </div>
              </Tooltip>
            </Accordion.Header>
            <Accordion.Body className='rounded-bottom'>
              <div className=" ">
                <div className="form-group">
                  {medicalHistoryData?.chiefComplaint ? medicalHistoryData?.chiefComplaint : <EmptyRow colSpan={12}/>}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='clinicalFindings' className='mb-3 rounded border'>
            <Accordion.Header className='d-flex flex-wrap'>
              <div className="flex-fill"></div>
              <span className='font-14 fw-bold me-2 text-black'>  یافته های بالینی
                </span>
              <Tooltip alt={'خلاصه نتایج معاینات فیزیکی بیمار'} className="tooltip-pre-line">
                <div className='d-flex align-items-center'>
                  <Icon name='info'/>
                </div>
              </Tooltip>
            </Accordion.Header>
            <Accordion.Body className='rounded-bottom'>
              <div className=" ">
                <div className="form-group">
                  {medicalHistoryData?.clinicalFindings ? medicalHistoryData?.clinicalFindings : <EmptyRow colSpan={12}/> }
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='labResultNote' className='mb-3 rounded border'>
            <Accordion.Header className='d-flex flex-wrap'>
              <div className="flex-fill"></div>
              <span className='font-14 fw-bold me-2 text-black'>  نتایج تست های آزمایشگاهی
                </span>
              <Tooltip alt={'خلاصه نتایج آزمایشات و تست‌های بیمار'} className="tooltip-pre-line">
                <div className='d-flex align-items-center'>
                  <Icon name='info'/>
                </div>
              </Tooltip>
            </Accordion.Header>
            <Accordion.Body className='rounded-bottom'>
              <div className=" ">
                <div className="form-group">
                  {medicalHistoryData?.labResultNote ? medicalHistoryData?.labResultNote : <EmptyRow colSpan={12}/>}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='visitGoalNote' className='mb-3 rounded border'>
            <Accordion.Header className='d-flex flex-wrap'>
              <div className="flex-fill"></div>
              <span className='font-14 fw-bold me-2 text-black'>  هدف
                </span>
              <Tooltip alt={'کوتاه از اهداف و برنامه‌های این ویزیت'} className="tooltip-pre-line">
                <div className='d-flex align-items-center'>
                  <Icon name='info'/>
                </div>
              </Tooltip>
            </Accordion.Header>
            <Accordion.Body className='rounded-bottom'>
              <div className=" ">
                <div className="form-group">
                  {medicalHistoryData?.visitGoalNote ? medicalHistoryData?.visitGoalNote : <EmptyRow colSpan={12}/>}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='examinationNote' className='mb-3 rounded border'>
            <Accordion.Header className='d-flex flex-wrap'>
              <div className="flex-fill"></div>
              <span className='font-14 fw-bold me-2 text-black'>  یادداشت‌های پزشک
                </span>
              <Tooltip alt={'خلاصه‌ای از مشاهدات و یادداشت‌های پزشک'} className="tooltip-pre-line">
                <div className='d-flex align-items-center'>
                  <Icon name='info'/>
                </div>
              </Tooltip>
            </Accordion.Header>
            <Accordion.Body className='rounded-bottom'>
              <div className=" ">
                <div className="form-group">
                  {medicalHistoryData?.examinationNote ? medicalHistoryData?.examinationNote : <EmptyRow colSpan={12}/>}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default VisitMedicalHistory;
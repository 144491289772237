import React, {useEffect, useMemo, useState} from "react";
import {connect, useSelector} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {general} from "../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {
  useAddPatientVital,
  useGetPatientVital,
} from "../../../../../hook/request/ehr/patientVital";
import Modal from "../../../general/modal";
import BoxLoader from "../../../general/boxLoader";
import Icon from "../../../general/icon";
import DatePickerField from "../../../general/form/datePickerField";
import TextAreaField from "../../../general/form/textAreaField";
import {useSearchParams} from "react-router-dom";
import {addPatientVitalSchema, atLeastOneFieldRequiredVitalSchema} from "../../../../../schema/patient-schema";
import {TAddPatientVitalFields} from "../../../../../types/patient";
import NumberField from "../../../general/form/numberField";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

type AddPatientVitalModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type ModalInfoProps = {
  id: string,
  patientId: string,
  patientData?: any,
}


const AddPatientVitalModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPatientVitalModalProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    patientId: '',
    patientData: ''
  });

  const [searchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const fieldValueInitialState = {
    observationDateTime: null,
    spo2: null,
    pulseRate: null,
    respiratoryRate: null,
    temperature: '',
    bpSystolic: null,
    bpDiastolic: null,
    bpPosition: '',
    description: '',
  };
  const [fieldValue, setFieldValue] = useState<TAddPatientVitalFields>(fieldValueInitialState);
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TAddPatientVitalFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addPatientVitalSchema),
  });
  const [atLeastOneFieldRequiredError, setAtLeastOneFieldRequiredError] = useState({
    hasError: false,
    message: "حداقل یکی از فیلدهای بالا اجباریست."
  });

  // services
  const addPatientVitalRequest = useAddPatientVital({
    onSuccess: () => {
      handleSetShowModal();
    }
  });
  const getPatientVitalRequest = useGetPatientVital();

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addPatientVitalModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData?.id
      }
      handleGetPatientVital(data)
    }
  }, [modalData, modalName])

  const handleGetPatientVital = (data: any) => {
    data.id && getPatientVitalRequest.mutate(data);
  }

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);


  useEffect(() => {
    if (getPatientVitalRequest?.data?.data?.result) {
      const {
          imei, updateDate
        } = getPatientVitalRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          imei,
          updateDate
        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getPatientVitalRequest.data])

  const addPatientVital: SubmitHandler<TAddPatientVitalFields> = async (data) => {
    try {
      await atLeastOneFieldRequiredVitalSchema.validate(data, { abortEarly: false });
      setAtLeastOneFieldRequiredError({
        ...atLeastOneFieldRequiredError,
        hasError: false
      })
      const {observationDateTime, spo2, pulseRate, respiratoryRate, temperature, bpSystolic, bpDiastolic, description } = data,
        body = {
          id: modalInfo?.id ? modalInfo?.id : 0,
          patient: {
            id: modalInfo?.patientId
          },
          entrySource:{
            id: 200
          },
          observationDateTime,
          spo2: !!spo2 ? {magnitude: spo2} : null,
          pulseRate: !!pulseRate ? {magnitude: pulseRate} : null,
          respiratoryRate: !!respiratoryRate ? {magnitude: respiratoryRate} : null,
          temperature: !!temperature ? {magnitude: temperature} : null,
          bpSystolic: !!bpSystolic ? {magnitude: bpSystolic} : null,
          bpDiastolic: !!bpDiastolic ? {magnitude: bpDiastolic} : null,
          description,
        };
      addPatientVitalRequest.mutate(body);
    } catch (errors) {
      setAtLeastOneFieldRequiredError({
        ...atLeastOneFieldRequiredError,
        hasError: true
      })
    }
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue(fieldValueInitialState);
    setAtLeastOneFieldRequiredError({
      ...atLeastOneFieldRequiredError,
      hasError: false
    })
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          getPatientVitalRequest?.isPending || /*getVitalsRequest?.isPending*/
          addPatientVitalRequest?.isPending
        ) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {modalInfo?.id ? "ویرایش شاخص حیاتی" : "ثبت شاخص حیاتی"}
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.firstName + ' ' + modalInfo?.patientData?.lastName}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(addPatientVital)}>
          <div className="row">
            <div className="col-12 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="observationDateTime">تاریخ و ساعت معاینه</label>
                <DatePickerField
                  name="observationDateTime"
                  placeholder="تاریخ معاینه"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className={`${atLeastOneFieldRequiredError?.hasError ? "border-red" : "border"} col rounded p-3 mt-4 mx-4`}>
              <div className="row">
                <div className="col-12 col-md-6 px-4">
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="bpSystolic">فشار خون بالا (mmHg)</label>
                    <NumberField
                      name="bpSystolic"
                      placeholder="فشار خون بالا"
                      control={control}
                      error={errors}
                      allowLeadingZeroes
                      isNumericString
                      maxLength={3}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="bpDiastolic">فشار خون پایین (mmHg)</label>
                    <NumberField
                      name="bpDiastolic"
                      placeholder="فشار خون پایین"
                      control={control}
                      error={errors}
                      isNumericString
                      maxLength={3}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="pulseRate">ضربان قلب (beats/min)</label>
                    <NumberField
                      name="pulseRate"
                      placeholder="ضربان قلب"
                      control={control}
                      error={errors}
                      isNumericString
                      maxLength={3}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="temperature">درجه حرارت (DEG C)</label>
                    <NumberField
                      name="temperature"
                      placeholder="درجه حرارت"
                      control={control}
                      error={errors}
                      isNumericString
                      maxLength={3}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="respiratoryRate">نرخ تنفس (breaths/min)</label>
                    <NumberField
                      name="respiratoryRate"
                      placeholder="نرخ تنفس"
                      control={control}
                      error={errors}
                      isNumericString
                      maxLength={3}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="spo2">اکسیژن اشباع خون (%)</label>
                    <NumberField
                      name="spo2"
                      placeholder="اکسیژن اشباع خون"
                      control={control}
                      error={errors}
                      isNumericString
                      maxLength={3}
                    />
                  </div>
                </div>
              </div>
            </div>
            {
              atLeastOneFieldRequiredError?.hasError &&
              <p className="text-danger font-10 mt-2 mx-4 mb-1">{atLeastOneFieldRequiredError?.message}</p>
            }
            <div className="col-12 px-4 mt-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  rows={2}
                  placeholder="توضیحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                {(isProvider || findAccessInAccessList('EHR_VITAL_CREATE')) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    {modalInfo?.id ? "ویرایش شاخص حیاتی" : "ثبت شاخص حیاتی"}
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientVitalModal);
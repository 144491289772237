import { combineReducers } from 'redux';
import errorReducer from './error';
import modalReducer from './modal';
import videoCallReducer from "../videoCall/videoCall";
import userDataReducer from "./userData";
import accessProcessReducer from "./accessProcess";

const general = combineReducers({
  errorReducer,
  modalReducer,
  videoCallReducer,
  userDataReducer,
  accessProcessReducer,
});

export default general;

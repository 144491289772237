import { ACTION_TYPES } from "../../../constants";

const initialState = {
  data: null,
};

const errorReducer = (state = initialState, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case ACTION_TYPES.ERRORS.HAPPENED.SHOW: {
      return { data: payload }
    }
    case ACTION_TYPES.ERRORS.HAPPENED.HIDE: {
      return { data: null }
    }
    default:
      return state
  }
}

export default errorReducer
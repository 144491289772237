import { Card } from "react-bootstrap";
import React from "react";
import Icon from "../../../../components/general/icon";
import Image from "../../../../components/general/image";
import { truncate } from "../../../../../utils/validation";
import {useSelector} from "react-redux";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

// کامپونتت مربوط به هر سابقه برای هر بخش  که اطلاعات هر سابقه بیماری را رندر می کند
// Parent:PatientSummary

interface IPatientSummaryHistory {
  title: string;
  count: string;
  description: string;
  date: string;
  status: string;
  titleName: string;
  onClick: () => void;
  onClickShowList?: () => void;
  icon?: any;
  visitEHRCheck?: boolean;
  viewPermission: boolean;
  createPermission: boolean;
}

const CardData = {
  surgery: {
    description: 'جراحی',
    date: 'تاریخ',
    status: 'وضعیت'
  },
  misuse: {
    description: 'سابقه',
    date: 'تاریخ',
    status: 'وضعیت'
  },
  cigarettes: {
    description: 'میزان مصرف',
    date: 'تاریخ شروع',
    status: 'وضعیت'
  },
  Family: {
    description: 'نسبت',
    date: 'نوع بیماری',
    status: '-'
  }
}

type CardKey = keyof typeof CardData;

const PatientSummaryHistoryCard: React.FC<IPatientSummaryHistory> = ({
  title,
  count,
  description,
  date,
  status,
  titleName,
  onClick,
  onClickShowList,
  icon,
  visitEHRCheck = false,
  viewPermission,
  createPermission
}) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const isValidTitleName = (name: string): name is CardKey => {
    return name in CardData;
  };
  
  return (
    <Card className="w-100 h-100 border-none bg-gray  my-xl-3 my-xxl-0">
      <Card.Body className="w-100 px-0 py-2">
        <div className="border-bottom w-100 px-3">
          <div className="d-flex flex-column flex-xxl-row justify-content-xxl-between mb-2">
            <h4 className="d-flex align-items-center title-dot font-12 font-weight-semibold">
              {title}
            </h4>
            <div className="d-flex gap-2 align-items-center justify-content-end">
              {
                viewPermission &&
                <button
                  className="btn btn-dark rounded-3 font-10 px-3 font-weight-semibold button-sizing"
                  onClick={onClickShowList}
                >
                  تاریخچه
                </button>
              }
              {((visitEHRCheck && isProvider) || createPermission) &&
                <div className='btn btn-dark rounded-3 p-1'>
                  <div
                    className="font-20 d-flex align-items-center cur-pointer"
                    onClick={onClick}
                  >
                    <Icon name="add-square"/>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between px-3 py-4">
          <div className="font-en d-flex align-items-center">
            <span
              style={{ fontWeight: 900, fontSize: "24px" }}
              className="text-primry-light"
            >
              {titleName}
            </span>

            <span
              style={{ fontWeight: 900, fontSize: "32px" }}
              className="text-primary"
            >
              {titleName === 'cigarettes' ? description[0] : count}
            </span>
          </div>
          <div>
            <Image src={icon} alt={"adsf"} />
          </div>
        </div>

        <div className="px-2 mb-4">
          <div className="d-flex gap-3 mb-3">
            <h4 className="d-flex align-items-center text-gray title-dot-primary font-12 font-weight-medium">
              {isValidTitleName(titleName) ? CardData[titleName].description : ' - '}
            </h4>
            <span className="font-en font-12 font-weight-medium">
              {truncate(description, 35)}
            </span>
          </div>

          <div className="d-flex justify-content-between">
            <div className="d-flex gap-3">
              <h4 className="d-flex align-items-center text-gray  title-dot-primary font-12 font-weight-medium">
                {isValidTitleName(titleName) ? CardData[titleName].date : ' - '}
              </h4>
              <span className={`font-12 font-weight-medium ${date === 'نوع بیماری' ? 'font-en' : ''}`}>{date}</span>
            </div>
            {/*{*/}
            {/*  titleName !== 'Family' &&*/}
            {/*  <div className="d-flex align-items-center gap-3">*/}
            {/*    <h4 className="d-flex align-items-center text-gray title-dot-primary font-12 font-weight-medium">*/}
            {/*      {isValidTitleName(titleName) ? CardData[titleName].status : ' - '}*/}
            {/*    </h4>*/}
            {/*    <span className="font-12 text-gray3 font-weight-medium">*/}
            {/*      {status}*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*}*/}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
export default PatientSummaryHistoryCard;

import * as React from "react"
import { SVGProps } from "react"
const DoctorCountAnimated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="animated"
    id="freepik_stories-online-doctor"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 500 500"
    {...props}
    width={250}
    height={250}
  >
    <style>
      {
        "svg#freepik_stories-online-doctor:not(.animated) .animable {opacity: 0;}svg#freepik_stories-online-doctor.animated #freepik--Bottle--inject-72 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}svg#freepik_stories-online-doctor.animated #freepik--Pills--inject-72 {animation: 3s Infinite  linear floating;animation-delay: 0s;}svg#freepik_stories-online-doctor.animated #freepik--Ampoule--inject-72 {animation: 6s Infinite  linear floating;animation-delay: 0s;}svg#freepik_stories-online-doctor.animated #freepik--Device--inject-72 {animation: 6s Infinite  linear shake;animation-delay: 0s;}            @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }                    @keyframes shake {                10%, 90% {                    transform: translate3d(-1px, 0, 0);                  }                  20%, 80% {                    transform: translate3d(2px, 0, 0);                  }                  30%, 50%, 70% {                    transform: translate3d(-4px, 0, 0);                  }                  40%, 60% {                    transform: translate3d(4px, 0, 0);                  }            }"
      }
    </style>
    <g
      id="freepik--background-simple--inject-72"
      style={{
        transformOrigin: "222.984px 245.192px 0px",
      }}
      className="animable"
    >
      <path
        d="M62.72,205.62s-13.83,61,26.14,114.47S198,394.7,253.67,413.3s107.5-2.11,126.7-48.86S360,292.17,350.91,230.88,348.64,152,307.82,105.11s-124-46.33-179.89,1.17S62.72,205.62,62.72,205.62Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "222.984px 245.192px 0px",
        }}
        id="el3ier6j13v2h"
        className="animable"
      />
      <g id="el4wjgoggsljn">
        <path
          d="M62.72,205.62s-13.83,61,26.14,114.47S198,394.7,253.67,413.3s107.5-2.11,126.7-48.86S360,292.17,350.91,230.88,348.64,152,307.82,105.11s-124-46.33-179.89,1.17S62.72,205.62,62.72,205.62Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.7,
            transformOrigin: "222.984px 245.192px 0px",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Plants--inject-72"
      style={{
        transformOrigin: "230.28px 259.75px 0px",
      }}
      className="animable"
    >
      <path
        d="M387.51,124.54s1-5.18-4.83-13.86S373,96.94,373,96.94s-6,11.22-.64,19.8a130.15,130.15,0,0,0,13.34,17.14Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "378.854px 115.41px 0px",
        }}
        id="el27f74lpc1i1"
        className="animable"
      />
      <path
        d="M370.64,154.87s1-5.18-4.83-13.86-9.7-13.74-9.7-13.74-6,11.22-.63,19.79a129.82,129.82,0,0,0,13.34,17.15Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "361.976px 145.74px 0px",
        }}
        id="elsw4r1ft9z9q"
        className="animable"
      />
      <path
        d="M385.32,133.29s1.56-5,10.81-9.94,15-7.53,15-7.53,0,12.7-8.78,17.75a131,131,0,0,1-19.86,8.81Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "396.81px 129.1px 0px",
        }}
        id="el1hdxif9snr4"
        className="animable"
      />
      <path
        d="M365.12,169.05s2.08-4.86,11.79-8.75,15.74-5.91,15.74-5.91-1.37,12.63-10.6,16.73a129.52,129.52,0,0,1-20.67,6.67Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "377.015px 166.09px 0px",
        }}
        id="el07w6tp8opyf2"
        className="animable"
      />
      <path
        d="M359.36,211.22s1-5.19,9.7-11,14.15-9.08,14.15-9.08,1.3,12.64-6.86,18.58a129.21,129.21,0,0,1-18.81,10.86Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "370.413px 205.86px 0px",
        }}
        id="elm8md6yu1br"
        className="animable"
      />
      <path
        d="M357.25,221.37s1.74-5-2.8-14.41-7.65-15-7.65-15-7.5,10.25-3.45,19.5a129.53,129.53,0,0,0,10.77,18.86Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "349.864px 211.14px 0px",
        }}
        id="el5nb1d6ww23y"
        className="animable"
      />
      <path
        d="M344.2,250.14s1-5.18,9.69-11S368.05,230,368.05,230s1.3,12.63-6.86,18.58a130.27,130.27,0,0,1-18.81,10.86Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "355.253px 244.72px 0px",
        }}
        id="el32eoqe37teo"
        className="animable"
      />
      <path
        d="M343.7,278.44s-.33-5.27,6.59-13.12S361.68,253,361.68,253s4.46,11.89-1.93,19.71a129.58,129.58,0,0,1-15.45,15.27Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "353.446px 270.49px 0px",
        }}
        id="elzu0thbhdpeo"
        className="animable"
      />
      <path
        d="M388.42,123.34a150.35,150.35,0,0,1-5.13,15.72c-5.77,14.27-14.81,22.43-20.3,35.26s-.62,31.7-6.19,48.47-14.13,25-14.46,36.88S346,304.58,346,304.58"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "365.37px 213.96px 0px",
        }}
        id="el6eo9r03dc3x"
        className="animable"
      />
      <line
        x1={384.58}
        y1={136.72}
        x2={393.58}
        y2={132.25}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "389.08px 134.485px 0px",
        }}
        id="el7t46ht4rmpf"
        className="animable"
      />
      <line
        x1={386.29}
        y1={129.88}
        x2={384.16}
        y2={123.42}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "385.225px 126.65px 0px",
        }}
        id="elqmmakbcm4pc"
        className="animable"
      />
      <path
        d="M363.54,173.79s7.52-3.63,15.21-7.23"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "371.145px 170.175px 0px",
        }}
        id="el4237b8h9i7k"
        className="animable"
      />
      <path
        d="M368.82,164.21s-3.37-11.77-8.17-20.07"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "364.735px 154.175px 0px",
        }}
        id="elzivisfc8ltc"
        className="animable"
      />
      <path
        d="M358.18,218.65a82.48,82.48,0,0,1,17.67-16"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "367.015px 210.65px 0px",
        }}
        id="el116q84c5j22"
        className="animable"
      />
      <path
        d="M355.48,225.68A93,93,0,0,0,351,210.82c-2.72-6.38-3.12-7.22-3.12-7.22"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "351.68px 214.64px 0px",
        }}
        id="elvbina0e33dh"
        className="animable"
      />
      <path
        d="M342.34,259.67a87.88,87.88,0,0,1,8.23-10.77,78,78,0,0,1,9.6-8.52"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "351.255px 250.025px 0px",
        }}
        id="elqqv2wzig9me"
        className="animable"
      />
      <path
        d="M344.3,287.93a22.66,22.66,0,0,1,4.21-11.1c3.76-4.75,8.21-11.05,10-13.54"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "351.405px 275.61px 0px",
        }}
        id="ellvow1pzefo"
        className="animable"
      />
      <path
        d="M326.43,184.54s.07-3.37,4.88-8,7.89-7.23,7.89-7.23,2.19,7.78-2.29,12.39a82.43,82.43,0,0,1-10.61,8.86Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "333.018px 179.935px 0px",
        }}
        id="el7rmex1zcpj"
        className="animable"
      />
      <path
        d="M332.8,205.68s.08-3.36,4.88-8,7.9-7.23,7.9-7.23,2.18,7.78-2.29,12.4a82.62,82.62,0,0,1-10.62,8.85Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "339.391px 201.075px 0px",
        }}
        id="elvvyocujkpon"
        className="animable"
      />
      <path
        d="M326.6,190.27s-.28-3.35-5.37-7.65-8.33-6.72-8.33-6.72-1.7,7.91,3.06,12.23a82.93,82.93,0,0,0,11.15,8.17Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "319.857px 186.1px 0px",
        }}
        id="el3h4g0fm2l2i"
        className="animable"
      />
      <path
        d="M334.32,215.24s-.64-3.3-6.15-7-9-5.81-9-5.81-.85,8,4.33,11.84a83.07,83.07,0,0,0,11.95,7Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "327.284px 211.85px 0px",
        }}
        id="eleze99fcwlof"
        className="animable"
      />
      <path
        d="M332.18,242.24s.07-3.36-4.53-8.17-7.57-7.56-7.57-7.56-2.52,7.68,1.74,12.48a82.68,82.68,0,0,0,10.23,9.3Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "325.76px 237.4px 0px",
        }}
        id="elzpvv34q4nj"
        className="animable"
      />
      <path
        d="M332.12,248.84s-.41-3.33,3.7-8.58S342.6,232,342.6,232s3.27,7.39-.5,12.59a82.7,82.7,0,0,1-9.25,10.27Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "338.012px 243.43px 0px",
        }}
        id="elke2wa83jpwf"
        className="animable"
      />
      <path
        d="M336.1,276.86s1-3.21,6.93-6.27,9.61-4.71,9.61-4.71-.09,8.08-5.68,11.24a82.56,82.56,0,0,1-12.69,5.51Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "343.455px 274.255px 0px",
        }}
        id="el803xoi7zpr8"
        className="animable"
      />
      <path
        d="M336.34,268.5s.07-3.36-4.54-8.17-7.57-7.56-7.57-7.56-2.52,7.68,1.75,12.48a82.68,82.68,0,0,0,10.23,9.3Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "329.916px 263.66px 0px",
        }}
        id="elezs5l6larrr"
        className="animable"
      />
      <path
        d="M332.81,286.16s.92-3.23-2.31-9.05-5.41-9.23-5.41-9.23-4.39,6.79-1.47,12.52A82.63,82.63,0,0,0,331.15,292Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "327.774px 279.94px 0px",
        }}
        id="elzbale1zia2"
        className="animable"
      />
      <path
        d="M326,183.66a96,96,0,0,0,1.06,10.47c1.66,9.65,6.17,16,7.85,24.68s-3.91,19.79-2.72,31,5.39,17.47,4,24.89-8.34,27.43-8.34,27.43"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "331.234px 242.895px 0px",
        }}
        id="elo37k9y5khwd"
        className="animable"
      />
      <line
        x1={326.6}
        y1={192.5}
        x2={321.61}
        y2={188.5}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "324.105px 190.5px 0px",
        }}
        id="el4zxdhcxvsix"
        className="animable"
      />
      <line
        x1={326.46}
        y1={188.02}
        x2={328.66}
        y2={184.29}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "327.56px 186.155px 0px",
        }}
        id="elqi3ss772f3"
        className="animable"
      />
      <path
        d="M334.65,218.4s-4.17-3.27-8.47-6.55"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "330.415px 215.125px 0px",
        }}
        id="el67mkki8mqt7"
        className="animable"
      />
      <path
        d="M332.67,211.73a68,68,0,0,1,7.8-11.38"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "336.57px 206.04px 0px",
        }}
        id="elko5lj26zjzl"
        className="animable"
      />
      <path
        d="M331.91,247a52.35,52.35,0,0,0-8.82-12.34"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "327.5px 240.83px 0px",
        }}
        id="elzg2gzejmbb"
        className="animable"
      />
      <path
        d="M332.64,251.76a59.4,59.4,0,0,1,4.78-8.63c2.56-3.6,2.92-4.07,2.92-4.07"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "336.49px 245.41px 0px",
        }}
        id="elhw4s1lxvqvn"
        className="animable"
      />
      <path
        d="M334.85,280.25a49.58,49.58,0,0,1,7.19-5,31.59,31.59,0,0,0,4.54-2.62"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "340.715px 276.44px 0px",
        }}
        id="elwx1orboi9o"
        className="animable"
      />
      <path
        d="M336.2,274.68a56,56,0,0,0-3.65-7.82,49.62,49.62,0,0,0-4.82-6.59"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "331.965px 267.475px 0px",
        }}
        id="el4ej5zqxal1s"
        className="animable"
      />
      <path
        d="M331.15,292a14.34,14.34,0,0,0-1.11-7.47c-1.69-3.46-3.6-8-4.39-9.77"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "328.451px 283.38px 0px",
        }}
        id="elrl807i73js"
        className="animable"
      />
      <path
        d="M84.82,254.88s-1.58-4.57-10.18-8.74-14-6.41-14-6.41.44,11.6,8.58,15.93a118.42,118.42,0,0,0,18.41,7.43Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "74.135px 251.41px 0px",
        }}
        id="eltwgdf51u3t7"
        className="animable"
      />
      <path
        d="M85.41,286.58s-1.58-4.56-10.18-8.74-14-6.41-14-6.41.44,11.6,8.58,15.94a119,119,0,0,0,18.41,7.42Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "74.725px 283.11px 0px",
        }}
        id="elzq2lzvsebck"
        className="animable"
      />
      <path
        d="M87.11,262.79s-1.09-4.71,4-12.82,8.43-12.85,8.43-12.85,5.79,10.06,1.2,18.06a118.22,118.22,0,0,1-11.65,16.07Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "94.7607px 254.185px 0px",
        }}
        id="elhttquiczqwl"
        className="animable"
      />
      <path
        d="M87.55,300.32s-.59-4.8,5.3-12.33,9.73-11.89,9.73-11.89,4.71,10.61-.7,18.08a119.21,119.21,0,0,1-13.27,14.76Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "96.007px 292.52px 0px",
        }}
        id="elz07omohm72"
        className="animable"
      />
      <path
        d="M102.35,336.28s-1.58-4.56,2.6-13.16,7-13.67,7-13.67,6.83,9.39,3.11,17.83a118.58,118.58,0,0,1-9.88,17.21Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "109.113px 326.97px 0px",
        }}
        id="el41c8ff8crx9"
        className="animable"
      />
      <path
        d="M105.35,345.27s-.92-4.74-8.84-10.1-12.91-8.32-12.91-8.32-1.22,11.54,6.23,17a118.74,118.74,0,0,0,17.16,10Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "95.2579px 340.35px 0px",
        }}
        id="elui9by0x2ok9"
        className="animable"
      />
      <path
        d="M108.23,374s-1.58-4.57,2.6-13.17,7-13.67,7-13.67,6.83,9.39,3.11,17.83a119,119,0,0,1-9.89,17.22Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "114.993px 364.685px 0px",
        }}
        id="eln2a1z1puzsg"
        className="animable"
      />
      <path
        d="M120.83,396.58s-2.69-4-.82-13.39,3.33-15,3.33-15,9,7.36,7.51,16.46a118.54,118.54,0,0,1-5.2,19.16Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "125.183px 386px 0px",
        }}
        id="elm3sw5a1gk4f"
        className="animable"
      />
      <path
        d="M85,253.51a136.55,136.55,0,0,1,3.16,14.77c2,13.92-1.42,24.52.14,37.18s14,25.33,17.35,41.14.32,26.26,5.49,35.78,23.49,33.82,23.49,33.82"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "109.815px 334.855px 0px",
        }}
        id="elkhfp2l9ci8"
        className="animable"
      />
      <line
        x1={88.09}
        y1={265.84}
        x2={93.15}
        y2={258.18}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "90.62px 262.01px 0px",
        }}
        id="elgemwmdk5ex"
        className="animable"
      />
      <line
        x1={86.31}
        y1={259.65}
        x2={81.65}
        y2={255.52}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "83.98px 257.585px 0px",
        }}
        id="el11y99spi51y"
        className="animable"
      />
      <path
        d="M88.48,304.79s4.27-6.32,8.7-12.69"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "92.83px 298.445px 0px",
        }}
        id="elymg17yepb6d"
        className="animable"
      />
      <path
        d="M88.25,294.79S80.19,287,72.59,282.68"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "80.42px 288.735px 0px",
        }}
        id="ely21vkmj45l9"
        className="animable"
      />
      <path
        d="M104.83,342.69a75.25,75.25,0,0,1,6.62-20.75"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "108.14px 332.315px 0px",
        }}
        id="elp0ljabw4laj"
        className="animable"
      />
      <path
        d="M105.92,349.48a85.32,85.32,0,0,0-10.34-9.68c-5.08-3.8-5.78-4.28-5.78-4.28"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "97.86px 342.5px 0px",
        }}
        id="elu19thjylqx"
        className="animable"
      />
      <path
        d="M111.13,382.38a81.9,81.9,0,0,1,1.56-12.29A71.44,71.44,0,0,1,116.37,359"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "113.75px 370.69px 0px",
        }}
        id="elhcc1m13scte"
        className="animable"
      />
      <path
        d="M125.65,403.81a20.74,20.74,0,0,1-1.77-10.7c.8-5.48,1.42-12.5,1.7-15.29"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "124.713px 390.815px 0px",
        }}
        id="elhbeik7fhwx4"
        className="animable"
      />
      <path
        d="M64.08,330.31s-1.49-2.69.2-8.54,2.92-9.33,2.92-9.33,5.3,5.15,3.88,10.84a75.74,75.74,0,0,1-4.33,11.87Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "67.3975px 323.795px 0px",
        }}
        id="el21qtqqetwq3"
        className="animable"
      />
      <path
        d="M78.82,344.09s-1.48-2.69.2-8.54,2.92-9.33,2.92-9.33,5.3,5.15,3.88,10.84a75.09,75.09,0,0,1-4.32,11.87Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "82.1397px 337.575px 0px",
        }}
        id="el19m35u1xe1j"
        className="animable"
      />
      <path
        d="M66.85,334.76s-1.76-2.52-7.76-3.58-9.66-1.49-9.66-1.49,2.29,7,8,8.26A75.12,75.12,0,0,0,70,339.29Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "59.715px 334.49px 0px",
        }}
        id="el8wm3m9buow"
        className="animable"
      />
      <path
        d="M84.41,351s-2-2.32-8.09-2.74-9.77-.46-9.77-.46,3,6.74,8.85,7.37a76.16,76.16,0,0,0,12.63,0Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "77.29px 351.616px 0px",
        }}
        id="elab6zx53m9m"
        className="animable"
      />
      <path
        d="M95.11,373.27s-1.49-2.69-7.33-4.38-9.46-2.5-9.46-2.5,1.54,7.23,7.11,9.06a75.17,75.17,0,0,0,12.35,2.66Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "88.05px 372.25px 0px",
        }}
        id="eldwt3cq5r54a"
        className="animable"
      />
      <path
        d="M98.09,378.51s-1.85-2.45-1-8.47,1.57-9.66,1.57-9.66,6,4.34,5.38,10.19a75,75,0,0,1-2.6,12.36Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "100.476px 371.655px 0px",
        }}
        id="el6lgp5j26k2h"
        className="animable"
      />
      <path
        d="M114.09,398.82s-.66-3,2.6-8.13,5.43-8.14,5.43-8.14,3.64,6.44.67,11.5a74.86,74.86,0,0,1-7.5,10.17Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "118.996px 393.385px 0px",
        }}
        id="el7q8gg1xv1js"
        className="animable"
      />
      <path
        d="M110.44,392.11s-1.48-2.69-7.33-4.37-9.45-2.51-9.45-2.51,1.53,7.23,7.11,9.07A75.56,75.56,0,0,0,113.12,397Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "103.39px 391.115px 0px",
        }}
        id="elbe82snam77"
        className="animable"
      />
      <path
        d="M115.76,407.69s-.75-3-6-6.09-8.51-4.82-8.51-4.82-.35,7.38,4.58,10.57a75.4,75.4,0,0,0,11.27,5.7Z"
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "109.172px 404.915px 0px",
        }}
        id="elwucxv6vvbml"
        className="animable"
      />
      <path
        d="M63.36,329.81A89,89,0,0,0,69,337.59c5.74,6.87,12.2,9.78,17.53,15.9s6,17.44,12.06,25.73,12.29,11.33,14.58,17.84,6,25.5,6,25.5"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "91.265px 376.185px 0px",
        }}
        id="elao20u72qju"
        className="animable"
      />
      <line
        x1={67.87}
        y1={336.53}
        x2={62.09}
        y2={335.65}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "64.98px 336.09px 0px",
        }}
        id="elize7jnqdm"
        className="animable"
      />
      <line
        x1={65.7}
        y1={333.04}
        x2={65.73}
        y2={329.09}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "65.715px 331.065px 0px",
        }}
        id="el1bm88kezlzvh"
        className="animable"
      />
      <path
        d="M86.12,353.3s-4.8-.67-9.7-1.29"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "81.27px 352.655px 0px",
        }}
        id="elgf4ja9nr54h"
        className="animable"
      />
      <path
        d="M81.5,348.93a61.43,61.43,0,0,1,.94-12.56"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "81.958px 342.65px 0px",
        }}
        id="elcbrttsh84qs"
        className="animable"
      />
      <path
        d="M97.09,377.17a48.13,48.13,0,0,0-12.64-5.71"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "90.77px 374.315px 0px",
        }}
        id="eluqwjxy20q7"
        className="animable"
      />
      <path
        d="M99.84,380.58a53.67,53.67,0,0,1-.18-9c.37-4,.44-4.55.44-4.55"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "99.8251px 373.805px 0px",
        }}
        id="el9cp6xuo9ppa"
        className="animable"
      />
      <path
        d="M114.66,402.07A45.42,45.42,0,0,1,118,394.8a30.39,30.39,0,0,0,2.39-4.16"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "117.525px 396.355px 0px",
        }}
        id="el53rqp6j0dlv"
        className="animable"
      />
      <path
        d="M113.17,397.06a51.3,51.3,0,0,0-6.47-4.5,46,46,0,0,0-6.84-3"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "106.515px 393.31px 0px",
        }}
        id="elqvop166r9b7"
        className="animable"
      />
      <path
        d="M117.12,413.05a13.19,13.19,0,0,0-4.31-5.4c-2.92-1.95-6.51-4.65-8-5.7"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "110.965px 407.5px 0px",
        }}
        id="elq1yvg75875c"
        className="animable"
      />
    </g>
    <g
      id="freepik--Bottle--inject-72"
      style={{
        transformOrigin: "315.035px 333.695px 0px",
      }}
      className="animable"
    >
      <rect
        x={275.32}
        y={270.6}
        width={79.43}
        height={160.89}
        rx={4.51}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "315.035px 351.045px 0px",
        }}
        id="elaq2e2mcpoxd"
        className="animable"
      />
      <rect
        x={277.46}
        y={291.71}
        width={75.16}
        height={135.94}
        rx={4.51}
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "315.04px 359.68px 0px",
        }}
        id="elje9xcw5e8u9"
        className="animable"
      />
      <g id="el08qkzr9paf7">
        <rect
          x={277.46}
          y={291.71}
          width={75.16}
          height={135.94}
          rx={4.51}
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.5,
            transformOrigin: "315.04px 359.68px 0px",
          }}
          className="animable"
        />
      </g>
      <rect
        x={296.15}
        y={235.9}
        width={37.79}
        height={34.7}
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "315.045px 253.25px 0px",
        }}
        id="el4kf5d02ma0b"
        className="animable"
      />
      <line
        x1={299.3}
        y1={239.33}
        x2={299.3}
        y2={266.86}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "299.3px 253.095px 0px",
        }}
        id="el0oid030e8a19"
        className="animable"
      />
      <line
        x1={305.14}
        y1={239.33}
        x2={305.14}
        y2={266.86}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "305.14px 253.095px 0px",
        }}
        id="el0t66c14kuzdp"
        className="animable"
      />
      <line
        x1={313.48}
        y1={248.09}
        x2={313.48}
        y2={266.86}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "313.48px 257.475px 0px",
        }}
        id="eldlr3m0cpdjo"
        className="animable"
      />
      <line
        x1={313.48}
        y1={239.33}
        x2={313.48}
        y2={243.92}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "313.48px 241.625px 0px",
        }}
        id="elnvaus2wjaxo"
        className="animable"
      />
      <rect
        x={279.86}
        y={304.74}
        width={70.36}
        height={77.65}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "315.04px 343.565px 0px",
        }}
        id="elx2tywx74s"
        className="animable"
      />
      <circle
        cx={314.57}
        cy={345.57}
        r={26.06}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "314.57px 345.57px 0px",
        }}
        id="elzobke7h9x8o"
        className="animable"
      />
      <rect
        x={309.8}
        y={327.64}
        width={9.54}
        height={35.86}
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "314.57px 345.57px 0px",
        }}
        id="el9x6t0u8ai1n"
        className="animable"
      />
      <g id="el0yplx8hhmo2">
        <rect
          x={309.8}
          y={327.64}
          width={9.54}
          height={35.86}
          style={{
            fill: "rgb(102, 132, 196)",
            transformOrigin: "314.57px 345.57px 0px",
            transform: "rotate(90deg)",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Pills--inject-72"
      style={{
        transformOrigin: "369.195px 394.865px 0px",
      }}
      className="animable"
    >
      <rect
        x={309.06}
        y={357.9}
        width={120.27}
        height={73.93}
        rx={4.75}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "369.195px 394.865px 0px",
        }}
        id="el7fcwlbiii7e"
        className="animable"
      />
      <path
        d="M320.57,390h0a4.44,4.44,0,0,1-4.44-4.44v-12a4.44,4.44,0,0,1,4.44-4.45h0a4.45,4.45,0,0,1,4.45,4.45v12A4.44,4.44,0,0,1,320.57,390Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "320.575px 379.555px 0px",
        }}
        id="el3e1lms68wy9"
        className="animable"
      />
      <line
        x1={323.58}
        y1={373.15}
        x2={323.58}
        y2={380.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "323.58px 376.87px 0px",
        }}
        id="ely0eeo6n059o"
        className="animable"
      />
      <line
        x1={323.58}
        y1={382.51}
        x2={323.58}
        y2={386.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "323.58px 384.55px 0px",
        }}
        id="elo1hbj8qwak"
        className="animable"
      />
      <path
        d="M340,390h0a4.44,4.44,0,0,1-4.44-4.44v-12a4.44,4.44,0,0,1,4.44-4.45h0a4.44,4.44,0,0,1,4.44,4.45v12A4.44,4.44,0,0,1,340,390Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "340px 379.555px 0px",
        }}
        id="elb6r2jwekwlk"
        className="animable"
      />
      <line
        x1={343.02}
        y1={373.15}
        x2={343.02}
        y2={380.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "343.02px 376.87px 0px",
        }}
        id="elgzj21xk8ij8"
        className="animable"
      />
      <line
        x1={343.02}
        y1={382.51}
        x2={343.02}
        y2={386.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "343.02px 384.55px 0px",
        }}
        id="elyv57rji44ft"
        className="animable"
      />
      <path
        d="M359.47,390h0a4.44,4.44,0,0,1-4.44-4.44v-12a4.44,4.44,0,0,1,4.44-4.45h0a4.44,4.44,0,0,1,4.44,4.45v12A4.44,4.44,0,0,1,359.47,390Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "359.47px 379.555px 0px",
        }}
        id="elohucmnjbld"
        className="animable"
      />
      <line
        x1={362.47}
        y1={373.15}
        x2={362.47}
        y2={380.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "362.47px 376.87px 0px",
        }}
        id="el1vxaduue7gw"
        className="animable"
      />
      <line
        x1={362.47}
        y1={382.51}
        x2={362.47}
        y2={386.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "362.47px 384.55px 0px",
        }}
        id="elg4jjifv4ifh"
        className="animable"
      />
      <path
        d="M378.92,390h0a4.44,4.44,0,0,1-4.44-4.44v-12a4.44,4.44,0,0,1,4.44-4.45h0a4.44,4.44,0,0,1,4.44,4.45v12A4.44,4.44,0,0,1,378.92,390Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "378.92px 379.555px 0px",
        }}
        id="elwpfrxk2cy7h"
        className="animable"
      />
      <line
        x1={381.92}
        y1={373.15}
        x2={381.92}
        y2={380.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "381.92px 376.87px 0px",
        }}
        id="eln4yhrcy9vqe"
        className="animable"
      />
      <line
        x1={381.92}
        y1={382.51}
        x2={381.92}
        y2={386.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "381.92px 384.55px 0px",
        }}
        id="el1s8g84fivp2"
        className="animable"
      />
      <path
        d="M398.37,390h0a4.44,4.44,0,0,1-4.45-4.44v-12a4.45,4.45,0,0,1,4.45-4.45h0a4.44,4.44,0,0,1,4.44,4.45v12A4.44,4.44,0,0,1,398.37,390Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "398.365px 379.555px 0px",
        }}
        id="el8dmidhq1wcb"
        className="animable"
      />
      <line
        x1={401.37}
        y1={373.15}
        x2={401.37}
        y2={380.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "401.37px 376.87px 0px",
        }}
        id="elzg22o78ooo"
        className="animable"
      />
      <line
        x1={401.37}
        y1={382.51}
        x2={401.37}
        y2={386.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "401.37px 384.55px 0px",
        }}
        id="elc8yrlgr68y"
        className="animable"
      />
      <path
        d="M417.81,390h0a4.44,4.44,0,0,1-4.44-4.44v-12a4.44,4.44,0,0,1,4.44-4.45h0a4.44,4.44,0,0,1,4.44,4.45v12A4.44,4.44,0,0,1,417.81,390Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "417.81px 379.555px 0px",
        }}
        id="elav9a95sskos"
        className="animable"
      />
      <line
        x1={420.81}
        y1={373.15}
        x2={420.81}
        y2={380.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "420.81px 376.87px 0px",
        }}
        id="el30g6hlfpmh3"
        className="animable"
      />
      <line
        x1={420.81}
        y1={382.51}
        x2={420.81}
        y2={386.59}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "420.81px 384.55px 0px",
        }}
        id="el00xdrcmwmrmbk"
        className="animable"
      />
      <path
        d="M320.57,421.88h0a4.44,4.44,0,0,1-4.44-4.44v-12a4.44,4.44,0,0,1,4.44-4.44h0a4.44,4.44,0,0,1,4.45,4.44v12A4.44,4.44,0,0,1,320.57,421.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "320.575px 411.44px 0px",
        }}
        id="el2pcmnltxqah"
        className="animable"
      />
      <line
        x1={323.58}
        y1={405.08}
        x2={323.58}
        y2={412.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "323.58px 408.8px 0px",
        }}
        id="el31xlj59bgot"
        className="animable"
      />
      <line
        x1={323.58}
        y1={414.44}
        x2={323.58}
        y2={418.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "323.58px 416.48px 0px",
        }}
        id="elr2b8s5ymthn"
        className="animable"
      />
      <path
        d="M340,421.88h0a4.44,4.44,0,0,1-4.44-4.44v-12A4.44,4.44,0,0,1,340,401h0a4.43,4.43,0,0,1,4.44,4.44v12A4.43,4.43,0,0,1,340,421.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "340px 411.44px 0px",
        }}
        id="elh8jxreqjxm6"
        className="animable"
      />
      <line
        x1={343.02}
        y1={405.08}
        x2={343.02}
        y2={412.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "343.02px 408.8px 0px",
        }}
        id="elro6r7tn242d"
        className="animable"
      />
      <line
        x1={343.02}
        y1={414.44}
        x2={343.02}
        y2={418.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "343.02px 416.48px 0px",
        }}
        id="elvt08bjr2yro"
        className="animable"
      />
      <path
        d="M359.47,421.88h0a4.44,4.44,0,0,1-4.44-4.44v-12a4.44,4.44,0,0,1,4.44-4.44h0a4.44,4.44,0,0,1,4.44,4.44v12A4.44,4.44,0,0,1,359.47,421.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "359.47px 411.44px 0px",
        }}
        id="elawaa7txlrz"
        className="animable"
      />
      <line
        x1={362.47}
        y1={405.08}
        x2={362.47}
        y2={412.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "362.47px 408.8px 0px",
        }}
        id="elmkc9dbcyr6q"
        className="animable"
      />
      <line
        x1={362.47}
        y1={414.44}
        x2={362.47}
        y2={418.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "362.47px 416.48px 0px",
        }}
        id="elav5asj21fak"
        className="animable"
      />
      <path
        d="M378.92,421.88h0a4.43,4.43,0,0,1-4.44-4.44v-12a4.43,4.43,0,0,1,4.44-4.44h0a4.44,4.44,0,0,1,4.44,4.44v12A4.44,4.44,0,0,1,378.92,421.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "378.92px 411.44px 0px",
        }}
        id="elexgyu0ij35e"
        className="animable"
      />
      <line
        x1={381.92}
        y1={405.08}
        x2={381.92}
        y2={412.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "381.92px 408.8px 0px",
        }}
        id="elygl2sv0a7n9"
        className="animable"
      />
      <line
        x1={381.92}
        y1={414.44}
        x2={381.92}
        y2={418.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "381.92px 416.48px 0px",
        }}
        id="eljpz6dwc5csq"
        className="animable"
      />
      <path
        d="M398.37,421.88h0a4.44,4.44,0,0,1-4.45-4.44v-12a4.44,4.44,0,0,1,4.45-4.44h0a4.44,4.44,0,0,1,4.44,4.44v12A4.44,4.44,0,0,1,398.37,421.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "398.365px 411.44px 0px",
        }}
        id="eloy0zoiua13"
        className="animable"
      />
      <line
        x1={401.37}
        y1={405.08}
        x2={401.37}
        y2={412.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "401.37px 408.8px 0px",
        }}
        id="el5wk8g29nr4i"
        className="animable"
      />
      <line
        x1={401.37}
        y1={414.44}
        x2={401.37}
        y2={418.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "401.37px 416.48px 0px",
        }}
        id="eln3kquo6g0mc"
        className="animable"
      />
      <path
        d="M417.81,421.88h0a4.44,4.44,0,0,1-4.44-4.44v-12a4.44,4.44,0,0,1,4.44-4.44h0a4.43,4.43,0,0,1,4.44,4.44v12A4.43,4.43,0,0,1,417.81,421.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "417.81px 411.44px 0px",
        }}
        id="elsh12u75mnvf"
        className="animable"
      />
      <line
        x1={420.81}
        y1={405.08}
        x2={420.81}
        y2={412.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "420.81px 408.8px 0px",
        }}
        id="elybz7cbvcdu"
        className="animable"
      />
      <line
        x1={420.81}
        y1={414.44}
        x2={420.81}
        y2={418.52}
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "420.81px 416.48px 0px",
        }}
        id="eliy9mx6rki9"
        className="animable"
      />
      <line
        x1={330.41}
        y1={361.74}
        x2={330.41}
        y2={364.24}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "330.41px 362.99px 0px",
        }}
        id="elxl8baz3s63"
        className="animable"
      />
      <line
        x1={330.41}
        y1={368.88}
        x2={330.41}
        y2={422.23}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          strokeDasharray: "4.63891px, 4.63891px",
          transformOrigin: "330.41px 395.555px 0px",
        }}
        id="el8coyf40me7n"
        className="animable"
      />
      <line
        x1={330.41}
        y1={424.55}
        x2={330.41}
        y2={427.05}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "330.41px 425.8px 0px",
        }}
        id="el4a463w4l48b"
        className="animable"
      />
      <line
        x1={349.86}
        y1={361.74}
        x2={349.86}
        y2={364.24}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "349.86px 362.99px 0px",
        }}
        id="el6gfystiwc7"
        className="animable"
      />
      <line
        x1={349.86}
        y1={368.88}
        x2={349.86}
        y2={422.23}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          strokeDasharray: "4.63891px, 4.63891px",
          transformOrigin: "349.86px 395.555px 0px",
        }}
        id="ele6z3qx8fy3j"
        className="animable"
      />
      <line
        x1={349.86}
        y1={424.55}
        x2={349.86}
        y2={427.05}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "349.86px 425.8px 0px",
        }}
        id="el42qtf4bv5yn"
        className="animable"
      />
      <line
        x1={369.31}
        y1={361.74}
        x2={369.31}
        y2={364.24}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "369.31px 362.99px 0px",
        }}
        id="el8g80whk4dy3"
        className="animable"
      />
      <line
        x1={369.31}
        y1={368.88}
        x2={369.31}
        y2={422.23}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          strokeDasharray: "4.63891px, 4.63891px",
          transformOrigin: "369.31px 395.555px 0px",
        }}
        id="elja5t8jx1j5"
        className="animable"
      />
      <line
        x1={369.31}
        y1={424.55}
        x2={369.31}
        y2={427.05}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "369.31px 425.8px 0px",
        }}
        id="eldaptocu1nvf"
        className="animable"
      />
      <line
        x1={388.75}
        y1={361.74}
        x2={388.75}
        y2={364.24}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "388.75px 362.99px 0px",
        }}
        id="el0lddctn4mami"
        className="animable"
      />
      <line
        x1={388.75}
        y1={368.88}
        x2={388.75}
        y2={422.23}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          strokeDasharray: "4.63891px, 4.63891px",
          transformOrigin: "388.75px 395.555px 0px",
        }}
        id="el2fudgef0jj2"
        className="animable"
      />
      <line
        x1={388.75}
        y1={424.55}
        x2={388.75}
        y2={427.05}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "388.75px 425.8px 0px",
        }}
        id="ell7abf4ojcza"
        className="animable"
      />
      <line
        x1={408.2}
        y1={361.74}
        x2={408.2}
        y2={364.24}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "408.2px 362.99px 0px",
        }}
        id="elbemggpzlqg5"
        className="animable"
      />
      <line
        x1={408.2}
        y1={368.88}
        x2={408.2}
        y2={422.23}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          strokeDasharray: "4.63891px, 4.63891px",
          transformOrigin: "408.2px 395.555px 0px",
        }}
        id="el7ghywwdv5qp"
        className="animable"
      />
      <line
        x1={408.2}
        y1={424.55}
        x2={408.2}
        y2={427.05}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          transformOrigin: "408.2px 425.8px 0px",
        }}
        id="el1v47m28mtmp"
        className="animable"
      />
      <line
        x1={312.16}
        y1={394.87}
        x2={425.73}
        y2={394.87}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "0.5px",
          strokeDasharray: 5,
          transformOrigin: "368.945px 394.87px 0px",
        }}
        id="elsyezkdbl4tq"
        className="animable"
      />
    </g>
    <g
      id="freepik--Ampoule--inject-72"
      style={{
        transformOrigin: "407.515px 384.685px 0px",
      }}
      className="animable"
    >
      <path
        d="M420.74,368.13V351.8H394.33v16.33L388,376.8a7.19,7.19,0,0,0-1.37,4.22v50.47h41.77V381a7.19,7.19,0,0,0-1.37-4.22Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "407.515px 391.645px 0px",
        }}
        id="elaf2ccsj02p"
        className="animable"
      />
      <rect
        x={394.44}
        y={344.31}
        width={26.31}
        height={2.83}
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "407.595px 345.725px 0px",
        }}
        id="elxf9fhm8ymvo"
        className="animable"
      />
      <rect
        x={390.49}
        y={337.88}
        width={34.08}
        height={7.25}
        style={{
          fill: "rgb(102, 132, 196)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "407.53px 341.505px 0px",
        }}
        id="el7j9il41mt5q"
        className="animable"
      />
      <rect
        x={390.49}
        y={347}
        width={34.08}
        height={5.76}
        rx={1.27}
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "407.53px 349.88px 0px",
        }}
        id="el0xa2utaqcnc"
        className="animable"
      />
      <rect
        x={386.64}
        y={384.11}
        width={27}
        height={33.62}
        style={{
          fill: "rgb(102, 132, 196)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "400.14px 400.92px 0px",
        }}
        id="elfe4fq31yg8"
        className="animable"
      />
    </g>
    <g
      id="freepik--Device--inject-72"
      style={{
        transformOrigin: "187.96px 273.51px 0px",
      }}
      className="animable animator-active"
    >
      <rect
        x={107.08}
        y={115.68}
        width={161.75}
        height={315.66}
        rx={14.52}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "187.955px 273.51px 0px",
        }}
        id="elge1jjiuvjj"
        className="animable"
      />
      <g
        style={{
          clipPath: "url(&quot",
          transformOrigin: "156.745px 271.885px 0px",
        }}
        id="el9s18zm8yh8r"
        className="animable"
      >
        <g id="eld13rh8pjwu9">
          <path
            d="M121,115.68a13.94,13.94,0,0,0-13.94,13.94V417.4a13.9,13.9,0,0,0,5,10.69l94.37-312.41Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.2,
              transformOrigin: "156.745px 271.885px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M193.46,422.21a5.5,5.5,0,1,0-5.5,5.5A5.5,5.5,0,0,0,193.46,422.21Z"
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "187.96px 422.21px 0px",
          }}
          id="el9mbsiwr1rcf"
          className="animable"
        />
      </g>
      <rect
        x={107.08}
        y={115.68}
        width={161.75}
        height={315.66}
        rx={14.52}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "187.955px 273.51px 0px",
        }}
        id="el17lfg3hb5bj"
        className="animable"
      />
      <rect
        x={114.28}
        y={137.12}
        width={147.37}
        height={275.49}
        style={{
          fill: "rgb(102, 132, 196)",
          transformOrigin: "187.965px 274.865px 0px",
        }}
        id="elbg5cgkvvq5"
        className="animable"
      />
      <g
        style={{
          clipPath: "url(&quot",
          transformOrigin: "187.96px 277.945px 0px",
        }}
        id="elwga4ekqsexc"
        className="animable"
      >
        <polygon
          points="234 273.06 210.64 251.95 187.96 252.34 165.28 251.95 141.92 273.06 86.68 309.44 86.68 416.78 185.04 416.78 190.88 416.78 289.24 416.78 289.24 309.44 234 273.06"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "187.96px 334.365px 0px",
          }}
          id="elefxhlwb9ajf"
          className="animable"
        />
        <path
          d="M186.07,352.47,176,366.75s12-5.44,23.56-10.15,26.09-19.57,29.35-25,8-13.41,8-13.41L222.39,307H238s-.37-17-5.08-30.81"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "207px 321.47px 0px",
          }}
          id="elyo4cliwlfi"
          className="animable"
        />
        <path
          d="M181.47,358.71c-1.69-.71-3.4-1.42-5.11-2.11-11.59-4.71-26.09-19.57-29.35-25s-8-13.41-8-13.41L153.54,307H138s.37-17,5.08-30.81"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "159.735px 317.45px 0px",
          }}
          id="eli2x5a0fj1h"
          className="animable"
        />
        <path
          d="M163.27,253.55s-4.51,71.61,22.8,98.92c0,0,28.81-19.78,28.81-98.06Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "188.936px 303.01px 0px",
          }}
          id="ellqjxv1gqeia"
          className="animable"
        />
        <g
          style={{
            clipPath: "url(&quot",
            transformOrigin: "190.155px 227.53px 0px",
          }}
          id="el5a5jfursx6g"
          className="animable"
        >
          <path
            d="M178.52,172.06h23.23a25.11,25.11,0,0,1,25.11,25.11v52.11A33.71,33.71,0,0,1,193.16,283h-6a33.71,33.71,0,0,1-33.71-33.71V197.17a25.11,25.11,0,0,1,25.11-25.11Z"
            style={{
              fill: "rgb(102, 132, 196)",
              transformOrigin: "190.155px 227.53px 0px",
            }}
            id="elyahg7hjco7"
            className="animable"
          />
          <g id="el7rg2zsguyo4">
            <path
              d="M178.52,172.06h23.23a25.11,25.11,0,0,1,25.11,25.11v52.11A33.71,33.71,0,0,1,193.16,283h-6a33.71,33.71,0,0,1-33.71-33.71V197.17a25.11,25.11,0,0,1,25.11-25.11Z"
              style={{
                fill: "rgb(255, 255, 255)",
                opacity: 0.5,
                transformOrigin: "190.155px 227.53px 0px",
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M163.27,253.55s-4.51,71.61,22.8,98.92c0,0,28.81-19.78,28.81-98.06Z"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "188.936px 303.01px 0px",
          }}
          id="elvpk7p16tiag"
          className="animable"
        />
        <path
          d="M174.76,247.45s-2.5,31.25,12.59,43.17c0,0,15.92-8.63,15.92-42.79Z"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "188.938px 269.035px 0px",
          }}
          id="elbobnu8fzhlq"
          className="animable"
        />
        <polyline
          points="169.72 300.43 187.35 290.62 207.14 304.3"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "188.43px 297.46px 0px",
          }}
          id="elpok4gao1zel"
          className="animable"
        />
        <path
          d="M192.09,306.45l3.44-9.89-8.18-5.94-10.75,5.94,5.59,9-8.82,26.49c3.18,7.92,7.32,15,12.7,20.39,0,0,7.66-5.26,15.08-21.09Z"
          style={{
            fill: "rgb(102, 132, 196)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "187.26px 321.53px 0px",
          }}
          id="elmcxg7p3t1un"
          className="animable"
        />
        <g id="elxgxi67c7cy">
          <circle
            cx={225.01}
            cy={346.26}
            r={14.37}
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "1.01px",
              transformOrigin: "225.01px 346.26px 0px",
              transform: "rotate(-9.26deg)",
            }}
            className="animable"
          />
        </g>
        <g id="elxk9lehlgzo">
          <circle
            cx={225.01}
            cy={346.26}
            r={10.33}
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "1.01px",
              transformOrigin: "225.01px 346.26px 0px",
              transform: "rotate(-67.65deg)",
            }}
            className="animable"
          />
        </g>
        <path
          d="M225,348.46a2.2,2.2,0,0,1-2.2-2.2V261.83a2.2,2.2,0,0,1,4.4,0v84.43A2.19,2.19,0,0,1,225,348.46Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "225px 304.047px 0px",
          }}
          id="elgejzh0sb5sw"
          className="animable"
        />
        <path
          d="M155.57,379.08l-.21,0c-2-.34-21.19-19-23-24s-1.3-13-1.28-13.3a1.1,1.1,0,0,1,2.2.14c0,.07-.45,7.85,1.15,12.44,1.47,4.19,18.55,20.92,21.25,22.55,1.5-.47,7-5.4,9-10.45a1.1,1.1,0,1,1,2.05.81C164.65,372.62,158.1,379.08,155.57,379.08Zm.31-2.15Zm-.27-.05Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "148.903px 359.914px 0px",
          }}
          id="el6vqfs5n3yzs"
          className="animable"
        />
        <path
          d="M138.52,379.08c-2.54,0-9.08-6.46-11.2-11.87a1.1,1.1,0,0,1,2.05-.81c2,5.05,7.49,10,9,10.45,2.7-1.63,19.78-18.36,21.25-22.55,1.6-4.59,1.16-12.37,1.15-12.44a1.1,1.1,0,1,1,2.2-.14c0,.34.48,8.3-1.28,13.3s-20.91,23.7-23,24Zm-.31-2.15Zm.27-.05Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "145.136px 359.884px 0px",
          }}
          id="elwn6nh0e3oj"
          className="animable"
        />
        <path
          d="M147.31,304.45a2.21,2.21,0,0,1-2.2-2.2V265.42a2.2,2.2,0,0,1,4.4,0v36.83A2.2,2.2,0,0,1,147.31,304.45Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "147.31px 283.837px 0px",
          }}
          id="elit7gujk659"
          className="animable"
        />
        <path
          d="M161.68,346.22a2.21,2.21,0,0,1-2.2-2.2v-26.5a12.62,12.62,0,1,0-25.24,0V344a2.2,2.2,0,0,1-4.4,0v-26.5a17,17,0,0,1,34,0V344A2.21,2.21,0,0,1,161.68,346.22Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "146.84px 323.36px 0px",
          }}
          id="el7fjcbc6w1k"
          className="animable"
        />
        <g id="elbx6fdt76jr7">
          <rect
            x={124.7}
            y={362.43}
            width={5.59}
            height={7.77}
            rx={2.56}
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "1.01px",
              transformOrigin: "127.495px 366.315px 0px",
              transform: "rotate(-37.84deg)",
            }}
            className="animable"
          />
        </g>
        <g id="elvb71uci4j7j">
          <rect
            x={163.44}
            y={362.43}
            width={5.59}
            height={7.77}
            rx={2.56}
            style={{
              fill: "rgb(255, 255, 255)",
              stroke: "rgb(38, 50, 56)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "1.01px",
              transformOrigin: "166.235px 366.315px 0px",
              transform: "rotate(-142.16deg)",
            }}
            className="animable"
          />
        </g>
        <rect
          x={145.36}
          y={207.27}
          width={11.37}
          height={19.81}
          rx={5.22}
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "151.045px 217.175px 0px",
          }}
          id="el3m9sx42l6h7"
          className="animable"
        />
        <rect
          x={222.2}
          y={207.27}
          width={11.37}
          height={19.81}
          rx={5.22}
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "227.885px 217.175px 0px",
          }}
          id="el63io9nghem5"
          className="animable"
        />
        <path
          d="M178.52,157.64h23.23a25.11,25.11,0,0,1,25.11,25.11v52.11a33.71,33.71,0,0,1-33.71,33.71h-6a33.71,33.71,0,0,1-33.71-33.71V182.75A25.11,25.11,0,0,1,178.52,157.64Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "190.15px 213.105px 0px",
          }}
          id="elkr9e7wfzkk"
          className="animable"
        />
        <rect
          x={168.03}
          y={201.49}
          width={15.36}
          height={4.12}
          rx={1.89}
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "175.71px 203.55px 0px",
          }}
          id="elfagqmdxtd4l"
          className="animable"
        />
        <rect
          x={198.38}
          y={201.49}
          width={15.36}
          height={4.12}
          rx={1.89}
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "206.06px 203.55px 0px",
          }}
          id="elh6b27590536"
          className="animable"
        />
        <path
          d="M180,214.05c0,2.38-1.09,4.3-2.44,4.3s-2.43-1.92-2.43-4.3,1.09-4.31,2.43-4.31S180,211.67,180,214.05Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "177.565px 214.045px 0px",
          }}
          id="el4frknxaua6a"
          className="animable"
        />
        <path
          d="M207.38,214.05c0,2.38-1.09,4.3-2.44,4.3s-2.44-1.92-2.44-4.3,1.09-4.31,2.44-4.31S207.38,211.67,207.38,214.05Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "204.94px 214.045px 0px",
          }}
          id="elmncdri6jks"
          className="animable"
        />
        <path
          d="M203.27,157.64H177a23.59,23.59,0,0,0-23.59,23.59v55.43a31.91,31.91,0,0,0,31.91,31.91H195a31.9,31.9,0,0,0,31.91-31.91V181.23A23.59,23.59,0,0,0,203.27,157.64Zm18,71.21s0,6.74-6.74,13.49c0,0-7.12-9.37-10.12-10.12-1.94-.48-9.16-.34-14.06-.18-4.89-.16-12.12-.3-14,.18-3,.75-10.12,10.12-10.12,10.12-6.74-6.75-6.74-13.49-6.74-13.49V198.12L163,193.5a10.41,10.41,0,0,0,.49-12.14,9.94,9.94,0,0,1,1.41-12.57l.07-.06a9.94,9.94,0,0,1,12.25-1.24l5.24,4.11a13,13,0,0,0,16,0l5.22-4.11A10,10,0,0,1,216,168.73l.06.06a10.05,10.05,0,0,1,1.32,12.71,10.38,10.38,0,0,0,.59,12l3.63,4.62Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "190.16px 213.105px 0px",
          }}
          id="el3i5uy6tmzte"
          className="animable"
        />
        <path
          d="M195.76,227.57s4.5-.6,4.5,2-2.63,4.49-7.87,2.62"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "196.325px 230.227px 0px",
          }}
          id="el9s5cf3to81s"
          className="animable"
        />
        <path
          d="M195.38,206.36V228.1a4.5,4.5,0,0,1-4.49,4.5h-4c-2.86.2-3.86-.5-4.2-1.71a3,3,0,0,1,.38-2.39l0,0c0-1.12,4.78-1.12,4.78-1.12"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "188.986px 219.497px 0px",
          }}
          id="elh6pobgs1jg4"
          className="animable"
        />
        <path
          d="M190.67,245a23.85,23.85,0,0,1-11.91-3.11,1.65,1.65,0,0,1,1.76-2.79A21.17,21.17,0,0,0,201,239a1.65,1.65,0,1,1,1.55,2.91A25,25,0,0,1,190.67,245Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "190.757px 241.886px 0px",
          }}
          id="eltrkwfzgcxxi"
          className="animable"
        />
        <line
          x1={202.71}
          y1={220.55}
          x2={209.97}
          y2={220.55}
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "206.34px 220.55px 0px",
          }}
          id="el03rzhbdm0mls"
          className="animable"
        />
        <line
          x1={173.87}
          y1={220.55}
          x2={181.13}
          y2={220.55}
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
            transformOrigin: "177.5px 220.55px 0px",
          }}
          id="elresaylkyvzk"
          className="animable"
        />
        <circle
          cx={145.11}
          cy={393.26}
          r={12.98}
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.724166px",
            transformOrigin: "145.11px 393.26px 0px",
          }}
          id="elgkgpode4ycn"
          className="animable"
        />
        <path
          d="M145.09,396.47h0a2.62,2.62,0,0,1-2.62-2.62v-8.3a2.62,2.62,0,0,1,2.62-2.62h0a2.62,2.62,0,0,1,2.62,2.62v8.3A2.62,2.62,0,0,1,145.09,396.47Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "145.09px 389.7px 0px",
          }}
          id="elxkr0lm5o2er"
          className="animable"
        />
        <path
          d="M145.11,398.36a4.64,4.64,0,0,1-4.63-4.63v-3.9a.48.48,0,0,1,.48-.48.47.47,0,0,1,.48.48v3.9a3.68,3.68,0,0,0,7.35,0v-3.9a.47.47,0,0,1,.48-.48.48.48,0,0,1,.48.48v3.9A4.64,4.64,0,0,1,145.11,398.36Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "145.115px 393.855px 0px",
          }}
          id="elawp44jbc30b"
          className="animable"
        />
        <path
          d="M145.11,403.34a.47.47,0,0,1-.47-.48v-5a.48.48,0,1,1,1,0v5A.47.47,0,0,1,145.11,403.34Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "145.14px 400.352px 0px",
          }}
          id="elkdlyks8pyqq"
          className="animable"
        />
        <path
          d="M148.31,403.6h-6.39a.49.49,0,0,1-.48-.48.48.48,0,0,1,.48-.48h6.39a.48.48,0,0,1,.48.48A.49.49,0,0,1,148.31,403.6Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "145.115px 403.12px 0px",
          }}
          id="eltx9jwa39w2"
          className="animable"
        />
        <circle
          cx={231.06}
          cy={393.26}
          r={12.98}
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.724166px",
            transformOrigin: "231.06px 393.26px 0px",
          }}
          id="elygxlj9pt1pp"
          className="animable"
        />
        <path
          d="M239.07,389.46l-3.79,2.19v-1.8a1.55,1.55,0,0,0-1.55-1.54h-8.56a1.54,1.54,0,0,0-1.55,1.54v6.9a1.54,1.54,0,0,0,1.55,1.55h8.56a1.55,1.55,0,0,0,1.55-1.55V395l3.79,2.19Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "231.345px 393.305px 0px",
          }}
          id="el0nizf9f0lqe"
          className="animable"
        />
        <circle
          cx={188.09}
          cy={393.26}
          r={12.98}
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.724166px",
            transformOrigin: "188.09px 393.26px 0px",
          }}
          id="elyeijqj27t9h"
          className="animable"
        />
        <path
          d="M194.66,390.52H181.52a2.48,2.48,0,0,0-2.44,2.93l.38,2.06a.62.62,0,0,0,.6.5h4a.62.62,0,0,0,.62-.62v-2.23h6.9v2.23a.62.62,0,0,0,.62.62h4a.62.62,0,0,0,.61-.5l.38-2.06A2.48,2.48,0,0,0,194.66,390.52Z"
          style={{
            fill: "rgb(102, 132, 196)",
            transformOrigin: "188.135px 393.264px 0px",
          }}
          id="el1awolps162o"
          className="animable"
        />
        <rect
          x={243.63}
          y={139.98}
          width={12.22}
          height={6.7}
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.821067px",
            transformOrigin: "249.74px 143.33px 0px",
          }}
          id="eldyvfimk8p1w"
          className="animable"
        />
        <rect
          x={243.63}
          y={139.98}
          width={9.53}
          height={6.7}
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.725073px",
            transformOrigin: "248.395px 143.33px 0px",
          }}
          id="eljint937guwe"
          className="animable"
        />
        <rect
          x={255.82}
          y={141.85}
          width={1.72}
          height={2.95}
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.725073px",
            transformOrigin: "256.68px 143.325px 0px",
          }}
          id="elvcw0963hpn"
          className="animable"
        />
        <rect
          x={116.81}
          y={142.05}
          width={2.24}
          height={3.84}
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.883086px",
            transformOrigin: "117.93px 143.97px 0px",
          }}
          id="elkjc8l5utke"
          className="animable"
        />
        <rect
          x={120.33}
          y={140.71}
          width={2.24}
          height={5.19}
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.883086px",
            transformOrigin: "121.45px 143.305px 0px",
          }}
          id="elbrldb3bkv9"
          className="animable"
        />
        <rect
          x={123.85}
          y={139.11}
          width={2.24}
          height={6.78}
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.883086px",
            transformOrigin: "124.97px 142.5px 0px",
          }}
          id="el4inutxgzhfs"
          className="animable"
        />
      </g>
      <rect
        x={114.28}
        y={137.12}
        width={147.37}
        height={275.49}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "187.965px 274.865px 0px",
        }}
        id="eledl0oer2ot"
        className="animable"
      />
    </g>
    <g
      id="freepik--Floor--inject-72"
      style={{
        transformOrigin: "257.125px 431.34px 0px",
      }}
      className="animable"
    >
      <line
        x1={80.32}
        y1={431.34}
        x2={454.43}
        y2={431.34}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "267.375px 431.34px 0px",
        }}
        id="elyy2dwrsn5ts"
        className="animable"
      />
      <line
        x1={59.82}
        y1={431.34}
        x2={69.71}
        y2={431.34}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.01px",
          transformOrigin: "64.765px 431.34px 0px",
        }}
        id="el2qo2pn5x3hu"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
    <defs>
      <clipPath id="freepik--clip-path--inject-72">
        <rect
          x={107.08}
          y={115.68}
          width={161.75}
          height={315.66}
          rx={14.52}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-2--inject-72">
        <rect
          x={114.28}
          y={137.12}
          width={147.37}
          height={275.49}
          style={{
            fill: "#6684C4",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-3--inject-72">
        <path
          d="M163.27,253.55s-4.51,71.61,22.8,98.92c0,0,28.81-19.78,28.81-98.06Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.01px",
          }}
        />
      </clipPath>
    </defs>
  </svg>
)
export default DoctorCountAnimated

import React from "react";
import BoxLoader from "../../../general/boxLoader";
import EmptyBox from "../../../general/emptyBox";
import ProgramCardsBoxItem from "./programCardsBox_Item";

type ProgramsCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const ProgramCardsBox = ({
 dataList,
 dataListTitles,
 isRequesting
}: ProgramsCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <ProgramCardsBoxItem item={item} dataListTitles={dataListTitles} key={i}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default ProgramCardsBox;
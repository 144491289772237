import { getCellValue } from "../../../../../utils/utils";
import { MtoJFullDateFormat } from "../../../../../utils/date";

const LoginHistoryTableRowItem = ({
  item,
}) => {
  return(
    <tr>
      <td>{getCellValue(item?.account?.name)}</td>
      <td>{getCellValue(item?.mobile)}</td>
      <td>{getCellValue(item?.requesterIp)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.dateTime))}</bdi>
      </td>
      <td>{getCellValue(item?.type?.name)}</td>
    </tr>
  )
}

export default LoginHistoryTableRowItem;

import { TUserAccessFields } from "../../../types/user";

type UserAccessLevelBoxItemProps = {
  data: any;
  fieldValue: any;
  setFieldValue: React.Dispatch<React.SetStateAction<TUserAccessFields>>;
}

const UserAccessLevelBoxItem = ({
  data,
  fieldValue,
  setFieldValue
}: UserAccessLevelBoxItemProps) => {

  const handleAccessChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFieldValue({ 
      ...fieldValue,
      access: {
        ...fieldValue.access,
        [name]: checked
      }
    });
  }

  return (
    <div className="d-flex justify-content-between my-3 px-4">
      <label className="custom-control-label w-100 me-2" htmlFor={`access_${data?.id}`}>{data?.name}</label>
      <input
        type="checkbox"
        name={`access_${data?.id}`}
        checked={fieldValue?.access?.[`access_${data?.id}`]}
        onChange={handleAccessChange}
        className="custom-control-input"
        id={`access_${data?.id}`}
      />
    </div>
  );
};

export default UserAccessLevelBoxItem;

import {getCellValue} from "../../../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../../../utils/date";
import {general} from "../../../../../../store/action";
import {connect} from "react-redux";
import React from "react";
import Icon from "../../../../general/icon";

type PatientVisitTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const PatientVisitTableRowItem = ({
 item,
 handleShowModal
}: PatientVisitTableRowItemProps) => {

  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-yellow1 text-yellow'
      case 200:
        return 'bg-yellow1 text-yellow'
      case 300:
        return 'bg-light-green text-green2'
      case 400:
        return 'bg-primary text-white'
      default:
        return 'bg-light-red text-red'
    }
  }

  return (
    <tr>
      <td>{getCellValue(item?.provider?.name)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.startDateTime))}</bdi>
      </td>
      <td>{getCellValue(item?.visitType?.name)}</td>
      <td>
        <span className={`px-3 py-1 rounded text-nowrap ${determineBgColor()} `}>
          {getCellValue(item?.status?.name)}
        </span>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <span
            onClick={() => handleShowModal('showPatientVisitModal', {visitId: item?.id, patientId: item?.patient?.id})}
            className="d-flex text-hover mx-2 font-20 cur-pointer"
          >
            <Icon name='eye'/>
          </span>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientVisitTableRowItem);

import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {general} from "../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import Modal from "../../../general/modal";
import BoxLoader from "../../../general/boxLoader";
import Icon from "../../../general/icon";
import SelectField, {AsyncSelectField} from "../../../general/form/selectField";
import TextAreaField from "../../../general/form/textAreaField";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import {
  DIAGNOSIS_SEVERITY,
  DIAGNOSIS_STATUS
} from "../../../../../constants/constant/enum";
import {useGetIcd11List} from "../../../../../hook/request/visit/icd11";
import {useAddDiagnosis, useGetDiagnosis} from "../../../../../hook/request/visit/diagnosis";
import {TAddDiagnosisFields} from "../../../../../types/visit";
import {addDiagnosisSchema} from "../../../../../schema/visit-schema";
import {asyncSelectLoadOptions} from "../../../../../utils/asyncSelectLoadOptions";


type AddDiagnosisModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type ModalInfoProps = {
  id: string,
  visitId: string,
  patientData?: any,
}


const AddDiagnosisModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddDiagnosisModalProps) => {
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    visitId: '',
    patientData: '',
  });

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();

  const fieldValueInitialState = {
    icd11: "",
    severity: "",
    status: "",
    description: ""
  }
  const [fieldValue, setFieldValue] = useState<TAddDiagnosisFields>(fieldValueInitialState);
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TAddDiagnosisFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addDiagnosisSchema),
  });
  const [icd11FilterValues, setIcd11FilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [icd11List, setIcd11List] = useState([]);
  const callbackRef = useRef<any>(null);

  // services
  const getIcd11ListRequest = useGetIcd11List(getQueryParams(icd11FilterValues), (modalName === 'addDiagnosisModal'));
  const addDiagnosisRequest = useAddDiagnosis({
    onSuccess: () => {
      handleSetShowModal();
    }
  });
  const getDiagnosisRequest = useGetDiagnosis();

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addDiagnosisModal'
    ) {
      setShowState(true);
      console.log(modalData)
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData.id
      }
      handleGetDiagnosis(data)
    }
  }, [modalData, modalName])

  const handleGetDiagnosis = (data: any) => {
    data.id && getDiagnosisRequest.mutate(data);
  }

  useEffect(() => {
    let _icd11FilterValues = {};
    _icd11FilterValues = {
      ...icd11FilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setIcd11FilterValues(_icd11FilterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getIcd11ListRequest?.data?.data?.result) {
      const { data } = getIcd11ListRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id }
        ))
      setIcd11List(_data);
    }
  }, [getIcd11ListRequest.data])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);


  useEffect(() => {
    if (getDiagnosisRequest?.data?.data?.result) {
      const {
          diagnosis, severity, status, note
        } = getDiagnosisRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          icd11: diagnosis?.id,
          severity: severity?.id,
          status: status?.id,
          description: note
        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getDiagnosisRequest.data])

  const changeRequestFilters = (inputValue: any, callback: any) => {
    callbackRef.current = callback;
    setIcd11FilterValues({
      ...icd11FilterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(callbackRef.current) {
      diagnosisLoadOptions(icd11FilterValues.search, callbackRef.current);
    }
  }, [icd11FilterValues?.search]);

  const diagnosisLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, icd11FilterValues, setIcd11FilterValues, getIcd11ListRequest);
  };

  const addDiagnosis: SubmitHandler<TAddDiagnosisFields> = (data) => {
    const { icd11, severity, status, description } = data;
    const body = {
      id: modalInfo?.id ? modalInfo?.id : 0,
      visit: {
        id: modalInfo?.visitId
      },
      diagnosis: {
        id: icd11,
      },
      severity: {
        id: severity,
      },
      status: {
        id: status,
      },
      note: description
    };
    console.log(data)
    addDiagnosisRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue(fieldValueInitialState)
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          getDiagnosisRequest?.isPending ||
          getIcd11ListRequest?.isLoading ||
          addDiagnosisRequest?.isPending
        ) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {modalInfo?.id ? "فرم ویرایش تشخیص بیماری" : "فرم ثبت تشخیص بیماری"}
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(addDiagnosis)}>
          <div className="row">
            <div className="col-12 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="icd11"> تشخیص
                  <span className="font-10 text-gray me-1">(icd11)</span>
                </label>
                <AsyncSelectField
                  options={icd11List}
                  name="icd11"
                  placeholder='انتخاب تشخیص مورد نظر کنید'
                  className="font-en"
                  // returnObjectValue={true}
                  control={control}
                  error={errors}
                  asyncLoadOptions={changeRequestFilters}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="severity">میزان شدت</label>
                <SelectField
                  options={DIAGNOSIS_SEVERITY}
                  name="severity"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  // returnObjectValue={true}
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="status">وضعیت تشخیص</label>
                <SelectField
                  options={DIAGNOSIS_STATUS}
                  name="status"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  // returnObjectValue={true}
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  rows={2}
                  placeholder="توضیحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  {modalInfo?.id ? "ویرایش تشخیص" : "ثبت تشخیص"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDiagnosisModal);
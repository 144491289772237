import { useLocation, useParams } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import PatientVaccinesList from "./vaccine/patientVaccinesList";
import React, { useEffect, useState } from "react";
import { useGetPatient } from "../../../../hook/request/patients";
import { TPatientFields } from "../../../../types/patient";
import BoxLoader from "../../../components/general/boxLoader";
import PatientVitals from "./vital";
import PatientConditionsList from "./condition/patientConditionsList";
import PatientBiometrics from "./biometric";
import PatientProgramsList from "./program/patientProgramsList";
import PatientAllergiesList from "./allergy/patientAllergiesList";
import PatientAppointmentsList from "./appointment/patientAppointmentsList";
import PatientAttachmentsList from "./attachment/patientAttachmentsList";
import PatientSummary from "./patientSummary/patientSummary";
import PatientVisitsCardsList from "./visit/patientVisitsCardsList";
import PatientDetails from "./patientDetails";
import { TCheckInsuranceFields } from "../../../../types/prescription";
import {useCheckInsurance, useVisitEHRCheck} from "../../../../hook/request/visit/visits";
import PatientPrescriptionCardsList from "./prescription/patientPrescriptionCardsList";
import {useWindowSize} from "../../../../hook/useWindowSize";
import PatientDetailsResponsive from "./patientDetailsResponsive";
import AlertBox from "../../../components/general/alert";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";
import {useSelector} from "react-redux";

const Ehr = () => {
  const { id } = useParams();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider); // Access the ID from the Redux store
  const [width] = useWindowSize();
  const [openTab, setOpenTab] = useState("patientSummary");
  const [patientFieldValue, setPatientFieldValue] = useState<TPatientFields>({
    firstName: "",
    lastName: "",
    fullName: "",
    gender: {
      id: 0,
      name: "",
    },
    birthDate: "",
    nationalCode: "",
    passportNo: "",
    mobile: "",
  });
  const [insuranceFieldValue, setInsuranceFieldValue] =
    useState<TCheckInsuranceFields>({
      infoMessage: {
        info: [],
        error: [],
        warning: [],
      },
      snackMessage: {
        info: [],
        error: [],
        warning: [],
      },
      issuerType: {
        id: 0,
        name: "",
      },
      covered: false,
    });
  const [visitEHRCheck, setVisitEHRCheck] = useState<boolean>(false);
  const location = useLocation();

  //<editor-fold desc="Constants">
  const tabsList = [
    ...(isProvider || findAccessInAccessList('EHR_SUMMARY') ?
      [
        {
          key: "patientSummary",
          name: "خلاصه‌بیمار",
          component: (
            <PatientSummary
              openTab={openTab}
              setOpenTab={setOpenTab}
              patientId={id}
              visitEHRCheck={visitEHRCheck}
            />
          ),
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_VITAL_VIEW') ?
      [
        {
          key: "vitals",
          name: "شاخص‌های‌حیاتی",
          component: (
            <PatientVitals
              patientData={patientFieldValue}
              initialOpenTab={openTab}
              visitEHRCheck={visitEHRCheck}
            />
          ),
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_BIOMETRIC_VIEW') ?
      [
        {
          key: "biometrics",
          name: "بایومتریک",
          component: (
            <PatientBiometrics
              patientData={patientFieldValue}
              initialOpenTab={openTab}
              visitEHRCheck={visitEHRCheck}
            />
          ),
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_PRESCRIPTIONS') ?
      [
        {
          key: "prescriptions",
          name: "نسخه‌ها",
          component: <PatientPrescriptionCardsList openTab={openTab}/>,
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_VISIT') ?
      [
        {
          key: "visits",
          name: "ویزیت‌ها",
          component: <PatientVisitsCardsList openTab={openTab}/>,
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_ALLERGY_VIEW') ?
      [
        {
          key: "allergies",
          name: "حساسیت‌ها",
          component: (
            <PatientAllergiesList
              patientData={patientFieldValue}
              openTab={openTab}
              visitEHRCheck={visitEHRCheck}
            />
          ),
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_MEDICAL_CONDITIONS_VIEW') ?
      [
        {
          key: "conditions",
          name: "شرایط‌‌‌پزشکی",
          component: (
            <PatientConditionsList
              patientData={patientFieldValue}
              openTab={openTab}
              visitEHRCheck={visitEHRCheck}
            />
          ),
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_VACCINATIONS_VIEW') ?
      [
        {
          key: "immunizations",
          name: "واکسیناسیون‌ها",
          component: (
            <PatientVaccinesList
              patientData={patientFieldValue}
              openTab={openTab}
              visitEHRCheck={visitEHRCheck}
            />
          ),
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_ATTACHMENTS_VIEW') ?
      [
        {
          key: "attachments",
          name: "ضمائم",
          component: (
            <PatientAttachmentsList
              patientData={patientFieldValue}
              openTab={openTab}
              visitEHRCheck={visitEHRCheck}
            />
          ),
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_PROGRAM_VIEW') ?
      [
        {
          key: "programs",
          name: "برنامه‌ها",
          component: (
            <PatientProgramsList
              patientData={patientFieldValue}
              openTab={openTab}
              visitEHRCheck={visitEHRCheck}
            />
          ),
        }
      ] : []),
    ...(isProvider || findAccessInAccessList('EHR_APPOINTMENT') ?
      [
        {
          key: "appointments",
          name: "وقت‌های‌ملاقات",
          component: (
            <PatientAppointmentsList
              patientData={patientFieldValue}
              openTab={openTab}
            />
          ),
        }
      ] : []),
  ];
  //</editor-fold>

  // services
  const getPatientRequest = useGetPatient();
  const getVisitEhrCheck = useVisitEHRCheck();
  const checkInsuranceRequest = useCheckInsurance({
    onSuccess() {},
  });

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      tabsList.some((item: any) => item.key === hash) && setOpenTab(hash);
    }
  }, []);

  useEffect(() => {
    id && getPatientRequest.mutate({ id });
    id && checkInsuranceRequest.mutate({ id });
    id && getVisitEhrCheck.mutate({ id });
  }, [id]);

  useEffect(() => {
    if (getPatientRequest?.data?.data?.result) {
      const {
          id,
          firstName,
          lastName,
          fullName,
          gender,
          birthDate,
          maritalStatus,
          national,
          nationalCode,
          passportNo,
          mobile,
        } = getPatientRequest.data.data.result,
        _fieldValue = {
          ...patientFieldValue,
          id,
          firstName,
          lastName,
          fullName,
          gender,
          birthDate,
          maritalStatus,
          national,
          nationalCode,
          passportNo,
          mobile,
        };
      setPatientFieldValue(_fieldValue);
    }
  }, [getPatientRequest.data]);

  useEffect(() => {
    if (checkInsuranceRequest?.data?.data?.result) {
      const {
          avatar,
          insuranceBox,
          relationType,
          responsibleFullName,
          familyPhysician,
          specialAccount,
          infoMessage,
          snackMessage,
          issuerType,
          covered,
        } = checkInsuranceRequest.data.data.result,
        _fieldValue = {
          ...checkInsuranceRequest,
          avatar,
          insuranceBox,
          relationType,
          responsibleFullName,
          familyPhysician,
          specialAccount,
          infoMessage,
          snackMessage,
          issuerType,
          covered,
        };
      setInsuranceFieldValue(_fieldValue);
    }
  }, [checkInsuranceRequest.data]);

  useEffect(() => {
    if (getVisitEhrCheck?.data?.data?.result) {
      const { result } = getVisitEhrCheck?.data?.data
      setVisitEHRCheck(result);
    }
  }, [getVisitEhrCheck.data]);

  const renderTabsNav = tabsList.map((item: any, key: number) => {
    return (
      <Nav.Item key={key}>
        <Nav.Link href={`#${item.key}`} eventKey={item.key}>
          {item.name}
        </Nav.Link>
      </Nav.Item>
    );
  });

  const renderTabs = tabsList.map((item: any, key: number) => {
    return <Tab.Pane key={key} eventKey={item?.key}>{item?.component}</Tab.Pane>;
  });

  return (
    <Tab.Container
      id="patient_ehr_tabs"
      activeKey={openTab}
      onSelect={(eventKey) => setOpenTab(eventKey || "")}
    >
      {(getPatientRequest.isPending || checkInsuranceRequest.isPending) && (
        <BoxLoader type="cover" />
      )}
      <div className="card-box">
        <div className="rounded bg-primary bg-opacity-5 pt-4 mx-4">
          {width > 900 ?
            (isProvider || findAccessInAccessList('PATIENT_VIEW')) &&
            <PatientDetails
              patient={patientFieldValue}
              insurance={insuranceFieldValue}
              isRequesting={
                checkInsuranceRequest.isPending && getPatientRequest.isPending
              }
            /> :
            (isProvider || findAccessInAccessList('PATIENT_VIEW')) &&
            <PatientDetailsResponsive
              patient={patientFieldValue}
              insurance={insuranceFieldValue}
              isRequesting={
                checkInsuranceRequest.isPending && getPatientRequest.isPending
              }
            />
          }
          <Nav variant={"pills"} justify className='d-flex overflow-x-auto custom-scrollbar'>
            <div className="d-flex w-responsive w-sm-auto ehr-nav rounded bg-white shadow font-12 mb-4 mx-md-4">
              {renderTabsNav}
            </div>
          </Nav>
        </div>
        <div className="d-flex flex-wrap visit-tab-content w-100">
          {!visitEHRCheck &&
            <AlertBox type={"warning"} icon='info-circle' text='برای افزودن اطلاعات به پرونده سلامت بیمار، باید ویزیت در حال انجام با این بیمار داشته باشید.' className='w-100 mx-4 mt-3'/>
          }
          <Tab.Content>{renderTabs}</Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

export default Ehr;

import {useParams} from "react-router-dom";
import {Nav, Tab} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Icon from "../../../../components/general/icon";
import PatientVitalsList from "./patientVitalsList";
import PatientVitalsCharts from "./patientVitalsCharts";

type vitalsProps = {
  patientData?: any;
  initialOpenTab?: string
  visitEHRCheck?: boolean;
}

const PatientVitals =({
  patientData,
  initialOpenTab,
  visitEHRCheck = false,
}: vitalsProps) => {
  const { id } = useParams();
  const [openTab, setOpenTab] = useState('')

  useEffect(() => {
    setOpenTab(initialOpenTab === 'vitals' ? 'vitalsCharts' : '')
  }, [initialOpenTab]);

  return (
    <>
      <div className="card-box shadow-none rounded-0">
          <Tab.Container
            id="vitals-list-chart-tabs"
            defaultActiveKey="vitalsCharts"
            onSelect={(eventKey) => setOpenTab(eventKey || '')}
          >
            <Tab.Content>
              <Tab.Pane eventKey="vitalsList"><PatientVitalsList patientData={patientData} openTab={openTab} visitEHRCheck={visitEHRCheck}/></Tab.Pane>
              <Tab.Pane eventKey="vitalsCharts">{openTab === 'vitalsCharts' && <PatientVitalsCharts patientData={patientData} initialOpenTab={initialOpenTab} visitEHRCheck={visitEHRCheck}/>}</Tab.Pane>
            </Tab.Content>
            <Nav variant="pills" className='mt-5' justify>
              <div className='flex-fill'></div>
              <div className='d-flex rounded bg-primary-subtle vital-list-chart-nav'>
                <Nav.Item>
                  <Nav.Link eventKey="vitalsList" className='font-20 d-flex'><Icon name='document'/></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vitalsCharts" className='font-20 d-flex'><Icon name='graph'/></Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </Tab.Container>
      </div>

    </>
  )
}

export default PatientVitals;
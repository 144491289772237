import React from "react";
import {connect, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {general} from "../../../../store/action";
import Icon from "../../general/icon";
import {getCellValue} from "../../../../utils/utils";
import {Dispatch} from "redux";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

type AppointmentCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const AppointmentCardsBoxItem = ({
 item,
 dataListTitles,
 handleShowModal
}: AppointmentCardsBoxItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const navigate = useNavigate();
  const statusCodeColorArr = ['', 'text-gray', 'text-green2', 'text-red'];

  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-gray3'
      case 400:
        return 'bg-yellow1 text-yellow'
      default:
        return 'bg-light-red text-red'
    }
  }

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="patient">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.patient?.firstName)} {getCellValue(item?.patient?.lastName)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.healthUnit?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="provider">{dataListTitles[2]}</label>
        <span className="font-en mr-2">{getCellValue(item?.provider?.firstName)} {getCellValue(item?.provider?.lastName)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="fromDateTime">{dataListTitles[3]}</label>
        <span className="mr-2">
          <bdi>{getCellValue(MtoJFullDateFormat(item?.fromDateTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="visitType">{dataListTitles[4]}</label>
        <span className="mr-2">{getCellValue(item?.visitType?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="status">{dataListTitles[5]}</label>
        <span className={`px-3 py-1 rounded text-nowrap ${determineBgColor()}`}>
          {getCellValue(item?.status?.name)}
          </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[6]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            {(isProvider || findAccessInAccessList('APPOINTMENT_VIEW')) &&
              <Tooltip alt={'جزئیات'} placement='bottom' className="tooltip-pre-line">
                <span
                  onClick={() => handleShowModal('changeStatusAppointmentModal', {id: item?.id})}
                  className="d-flex text-hover mr-2 font-20 cur-pointer"
                >
                  <Icon name='eye'/>
                </span>
              </Tooltip>
            }
          </div>
        </div>
      </div>

    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AppointmentCardsBoxItem);
import React from "react";
import BoxLoader from "../../../../general/boxLoader";
import EmptyBox from "../../../../general/emptyBox";
import PatientVaccineCardsBoxItem from "./patientVaccineCardsBox_Item";

type PatientVaccinesCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean;
  readonly: boolean;
}

const PatientVaccineCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting,
  readonly = false
}: PatientVaccinesCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <PatientVaccineCardsBoxItem item={item} dataListTitles={dataListTitles} key={i} readonly={readonly}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default PatientVaccineCardsBox;
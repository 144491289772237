import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './reducer';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducer,
  process.env.NODE_ENV !== 'production' ? 
    compose(applyMiddleware(sagaMiddleware),  window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f) :
    applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);
import React, {useEffect, useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ComputerAnalytics from "../../../../assets/images/svgComponents/icon/computerAnalytics";
import {Carousel} from "react-bootstrap";
import {MultipleGauge, pieChartOptions} from "../../general/dashboardChartOptions";
import {
  useGetPatientAgeChart,
  useGetPatientGenderChart,
} from "../../../../hook/request/systemReport";
import Analytics from "../../../../assets/images/svgComponents/icon/analytics";
import EmptyChart from "../../general/emptyChart";

const PatientChartsCarousel = () => {

  const [genderSum, setGenderSum] = useState<number>();
  const [genderData, setGenderData] = useState([]);
  const [genderLabels, setGenderLabels] = useState([]);

  const [ageData, setAgeData] = useState([]);
  const [ageLabels, setAgeLabels] = useState([]);

  //services
  const getPatientGenderChart = useGetPatientGenderChart();
  const getPatientAgeChart = useGetPatientAgeChart();

  useEffect(() => {
    getPatientGenderChart.mutate({})
  }, []);

  useEffect(() => {
    if(!getPatientGenderChart.isPending && getPatientGenderChart?.data?.data){
      const { result } = getPatientGenderChart?.data?.data;
      let _genderData = [],
        _genderLabel = [];
      if(result){
        _genderData = result
          .map((item:any) => (item?.values[0]));
        _genderLabel = result
          .map((item:any) => (`${item?.label}`));
      }
      const totalSum = _genderData.reduce((acc:any , curr: any) => acc + curr, 0);
      setGenderSum(totalSum);
      setGenderData(_genderData)
      setGenderLabels(_genderLabel)
    }
  }, [getPatientGenderChart.data])

  useEffect(() => {
    getPatientAgeChart.mutate({})
  }, []);

  useEffect(() => {
    if(!getPatientAgeChart.isPending && getPatientAgeChart?.data?.data){
      const { result } = getPatientAgeChart?.data?.data;
      let _geData = [],
        _geLabel = [];
      if(result){
        _geData = result
          .map((item:any) => (item?.values[0]));
        _geLabel = result
          .map((item:any) => (`${item?.label}`));
      }
      setAgeData(_geData)
      setAgeLabels(_geLabel)
    }
  }, [getPatientAgeChart.data])

  return (
    <div className="dashboard-card-box h-100">
      <Carousel className='dashboard-carousel-indicators carousel-container remove-carousel-arrows h-100'>
        <Carousel.Item>
          <div className="flex-wrap align-items-center m-2">
        <span className="d-flex align-items-center text-white font-25">
          <span className="d-flex align-items-center ms-2">
           <Analytics/>
          </span>
          <span className="font-15 fw-bold text-black">
            نمودار‌ آماری بیماران
          </span>
          <span className="font-12 me-auto border border-primary bg-primary-10 rounded-3 text-primary py-1 px-3">
            جنسیت
          </span>
        </span>
            {
              genderData.every(item => item === 0) ?
                <EmptyChart/> :
              <HighchartsReact
                highcharts={Highcharts}
                options={MultipleGauge({
                  data: genderData,
                  labels: genderLabels,
                  max: genderSum
                })}
              />
            }
          </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="flex-wrap align-items-center m-2">
          <span className="d-flex align-items-center text-white font-25">
            <span className="d-flex align-items-center ms-2">
             <ComputerAnalytics/>
            </span>
            <span className="font-15 fw-bold text-black">
              نمودار‌ آماری بیماران
            </span>
            <span className="font-12 me-auto border border-primary bg-primary-10 rounded-3 text-primary py-1 px-3">
              بازه سنی
            </span>
          </span>
          {
            ageData.every(item => item === 0) ?
              <EmptyChart/> :
            <HighchartsReact
              highcharts={Highcharts}
              options={pieChartOptions({
                chartType: 'pie',
                data: ageData,
                dataNames:ageLabels,
                labels: ageLabels,
                chartTitle: 'گزارش ویزیت‌های انجام شده',
              })}
            />
          }
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default PatientChartsCarousel;
import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "../../../../../components/general/modal";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector,
} from "../../../../../../store/selector/general";
import { general } from "../../../../../../store/action";
import { Dispatch } from "redux";
import Icon from "../../../../../components/general/icon";
import { useWindowSize } from "../../../../../../hook/useWindowSize";
import PatientFamilyHistoryTable from "./dataList/patientFamilyHistoryTable";
import { useMySearchParams } from "../../../../../../hook/useMySearchParams";
import { useGetListFamilyHistory } from "../../../../../../hook/request/ehr/familyHistory";
import PatientFamilyHistoryCardsBox from "./dataList/patientFamilyHistoryCardsBox";
import Pagination from "../../../../../components/general/table/pagination";

// کامپوننت مربوط مودال اتصال بیمه  که پزشک می تواند با
// اطلاعات که وارد می کند  ارتباط خود را به بیمه مورد نظر متصل کند
// Parent:SettingInsurance
// در این کامپوننت لیست بیمه ها رندر می شود
// و در صورت انتخاب هر بیمه با توجه با فعال یا غیر فعال بودن ان نمایش داده می شود

const dataListTitles = [
  "بیماری",
  "نسبت خانوادگی",
  "تاریخ ثبت",
  "منجر به فوت شده است",
  "توضیحات",
];

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  patient?: number;
}

type ShowPatientFamilyHistoryModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  patientId: number;
  name: string;
  active: boolean;
}

const ShowPatientFamilyHistoryModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: ShowPatientFamilyHistoryModalProps) => {
  const [width] = useWindowSize();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>();
  const [familyHistoryList,setFamilyHistoryList] = useState([])
  const { getQueryParams } = useMySearchParams();
  const filterValuesInitialState = {
    pageNumber: "0",
    perPage: "10",
  }
  const [filterValues, setFilterValues] = useState<TFilterValues>(filterValuesInitialState);

  // services
  const requestGetFamilyHistory = useGetListFamilyHistory(getQueryParams(filterValues), modalName === 'showListFamily');

  useEffect(() => {
    showState && requestGetFamilyHistory.refetch();
  }, [filterValues])

  useEffect(() => {
    if (modalName === "showListFamily" && { isShowModalData }) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalName, modalData]);

  useEffect(() => {
    setFilterValues({...filterValuesInitialState, patient: modalInfo?.patientId})
  }, [modalInfo]);

  useEffect(()=>{
    if(requestGetFamilyHistory?.data?.data?.result){
      setFamilyHistoryList(requestGetFamilyHistory?.data?.data?.result)
    }
  },[requestGetFamilyHistory?.data])

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  };

  const doChangePage = (param: any) => {
    let _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1300  mw-100"
      bodyClassName="py-3 px-3 px-lg-5 pos-rel"
    >
      {/* loading */}
      {/* {providerInsurer?.isPending && <BoxLoader type="cover" />} */}
      <div className="form-box w-1300 mw-100 mx-auto py-3">
        <div className="d-flex mb-4 fs-3 px-2">
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {/* اتصال {modalInfo?.name} */}
              لیست سوابق  خانوادگی
            </h4>
          </div>
          <span
            className="text-red-hover align-content-center mt-2"
            onClick={() => handleSetShowModal()}
          >
            <Icon name="close-square" />
          </span>
        </div>
        <form>
          <div className="row">
            <div className="col-12 px-4">
              <section
                className="table-cont table-responsive"
                style={{ overflow: "visible" }}
              >
                {width < 900 ? (
                  <PatientFamilyHistoryCardsBox
                    dataList={familyHistoryList}
                    dataListTitles={dataListTitles}
                    isRequesting={false}
                  />
                ) : (
                  <PatientFamilyHistoryTable
                    dataList={familyHistoryList}
                    dataListTitles={dataListTitles}
                    isRequesting={false}
                  />
                )}
                <Pagination
                  paginationData={{...filterValues, ...familyHistoryList}}
                  doChangePage={doChangePage}
                />
              </section>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

// get states
const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

// call actions
const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) =>
    dispatch(general.handleShowModal(data, body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowPatientFamilyHistoryModal);

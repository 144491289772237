import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import SearchBox from "../../../../components/general/searchBox";
import Pagination from "../../../../components/general/table/pagination";
import PatientAppointmentCardsBox from "../../../../components/patients/ehr/appointment/dataList/patientAppointmentCardsBox";
import PatientAppointmentsTable from "../../../../components/patients/ehr/appointment/dataList/patientAppointmentsTable";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";
import {useGetAppointments} from "../../../../../hook/request/appointments";
import ChangeStatusAppointmentModal from "../../../../components/appointments/changeStatusAppointmentModal";


const dataListTitles = [
  "نام مرکز",
  "نام پزشک",
  "تاریخ و ساعت حضور",
  "نوع نوبت",
  "وضعیت",
  "جزئیات",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type appointmentsListProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
}

const AppointmentsList = ({
  patientData,
  openTab,
  handleShowModal
}: appointmentsListProps) => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getAppointmentsRequest = useGetAppointments(getQueryParams(filterValues), openTab === 'appointments');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getAppointmentsRequest?.data?.data?.result &&
    setAppointmentsData(getAppointmentsRequest?.data?.data.result)
  }, [getAppointmentsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const _filterValues = {
      ...filterValues,
      [name]: value,
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    const _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی وقت ملاقات"
            />
          </div>
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <PatientAppointmentCardsBox
                dataList={appointmentsData}
                dataListTitles={dataListTitles}
                isRequesting={getAppointmentsRequest.isPending}
              /> :
              <PatientAppointmentsTable
                dataList={appointmentsData}
                dataListTitles={dataListTitles}
                isRequesting={getAppointmentsRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...appointmentsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <ChangeStatusAppointmentModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AppointmentsList);

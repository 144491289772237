import * as yup from "yup";

const addConditionSchema = yup.object({
  name: yup
    .string()
    .required("این فیلد الزامیست."),
  maxa: yup
    .string()
    .required("این فیلد الزامیست."),
});


export {
  addConditionSchema,
};

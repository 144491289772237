import {useLocation, useParams} from "react-router-dom";
import {Nav, Tab} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import {useGetPatient} from "../../../../hook/request/patients";
import {TPatientFields} from "../../../../types/patient";
import BoxLoader from "../../../components/general/boxLoader";
import MedicalHistory from "./medicalHistory/medicalHistory";
import VisitDetailFooter from "./visitDetailFooter";
import PatientDetails from "../../patients/ehr/patientDetails";
import Prescription from "./prescription/prescription";
import {useCheckInsurance, useGetVisit} from "../../../../hook/request/visit/visits";
import {TVisitFields} from "../../../../types/visit";
import {general} from "../../../../store/action";
import {connect, useSelector} from "react-redux";
import AddVisitPrescriptionModal from "../../../components/visits/visitDetail/endVisit/addVisitPrescriptionModal";
import {TCheckInsuranceFields, TPrescriptionFields} from "../../../../types/prescription";
import PatientSummary from "../../patients/ehr/patientSummary/patientSummary";
import Examinations from "./examinations/examinations";
import EndVisitModal from "../../../components/visits/visitDetail/endVisitModal";
import {useWindowSize} from "../../../../hook/useWindowSize";
import PatientDetailsResponsive from "../../patients/ehr/patientDetailsResponsive";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

type visitDetailProps = {
  handleShowModal: any;
}

const VisitDetail = ({
  handleShowModal
}: visitDetailProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const { id } = useParams();
  const [width] = useWindowSize();
  const location = useLocation();
  const [openTab, setOpenTab] = useState('prescription')
  const [insuranceFieldValue, setInsuranceFieldValue] = useState<TCheckInsuranceFields>({
    infoMessage: {
      info: [],
      error: [],
      warning: [],
    },
    snackMessage: {
      info: [],
      error: [],
      warning: [],
    },
    issuerType: {
      id: 0,
      name: ''
    },
    covered: false,
  });
  const [patientFieldValue, setPatientFieldValue] = useState<TPatientFields>({
    id: 0,
    firstName: '',
    lastName: '',
    fullName: '',
    gender: {
      id: 0,
      name: ''
    },
    birthDate: '',
    nationalCode: '',
    passportNo: '',
    mobile: '',
  });
  const fieldValueInitialState = {
    id: id ? id : '',
    
    patient: {
      id: '',
    },
    provider: {
      id: '',
      name: ''
    },
    healthUnit: {
      id: '',
      name: ''
    },
    service: {
      id: '',
      name: ''
    },
    visitType: {
      id: '',
      name: ''
    },
    status: {
      id: '',
      name: ''
    },
    appointment: {
      id: ''
    },
    startDateTime: null,
    endDateTime: null,
    chiefComplaint: '',
    clinicalFindings: '',
    labResultNote: '',
    visitGoalNote: '',
    examinationNote: '',
  }
  const [fieldValue, setFieldValue] = useState<TVisitFields>(fieldValueInitialState)
  const [prescriptionFieldValue, setPrescriptionFieldValue] = useState<TPrescriptionFields>({});
  const [medicalHistoryFieldValue, setMedicalHistoryFieldValue] = useState([]);
  const [draftPrescriptionField, setDraftPrescriptionField] = useState();
  const [medicationItemsCheckCodes, setMedicationItemsCheckCodes] = useState<string[]>([]);
  const [paraclinicalItemsCheckCodes, setParaclinicalItemsCheckCodes] = useState<string[]>([]);

  // services
  const getVisitRequest = useGetVisit();
  const getPatientRequest = useGetPatient();
  const checkInsuranceRequest = useCheckInsurance({
    onSuccess (){}
  })


  useEffect(() => {
    var data = {
      id: id
    }
    handleGetVisit(data)
  }, [id])

  const handleGetVisit = (data: any) => {
    getVisitRequest.mutate(data);
  }

  useEffect(() => {
    if (getVisitRequest?.data?.data?.result) {
      const {
          patient, provider, healthUnit, service, visitType, status, appointment, startDateTime, endDateTime,
          chiefComplaint, clinicalFindings, labResultNote, visitGoalNote, examinationNote
        } = getVisitRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          patient, provider, healthUnit, service, visitType, status, appointment, startDateTime, endDateTime,
          chiefComplaint, clinicalFindings, labResultNote, visitGoalNote, examinationNote
        };
      setFieldValue(_fieldValue);
    }
  }, [getVisitRequest.data])

  useEffect(() => {
    fieldValue?.patient?.id && getPatientRequest.mutate({id: fieldValue?.patient?.id});
    fieldValue?.patient?.id && checkInsuranceRequest.mutate({id: fieldValue?.patient?.id});
  }, [fieldValue?.patient?.id])


  useEffect(() => {
    if(!getVisitRequest.isPending) {
      if (getPatientRequest?.data?.data?.result) {
      const {
          id, firstName, lastName, fullName, gender, birthDate, maritalStatus,
          national, nationalCode, passportNo, mobile
        } = getPatientRequest.data.data.result,
        _fieldValue = {
          ...patientFieldValue,
          id, firstName, lastName, fullName, gender, birthDate, maritalStatus,
          national, nationalCode, passportNo, mobile
        };
      setPatientFieldValue(_fieldValue);
      }
    }
  }, [getPatientRequest.data])

  useEffect(() => {
    if(!getVisitRequest.isPending) {
      if (checkInsuranceRequest?.data?.data?.result) {
        const {
            avatar, insuranceBox, relationType, responsibleFullName, familyPhysician, specialAccount,
            infoMessage, snackMessage, issuerType, covered
          } = checkInsuranceRequest.data.data.result,
          _fieldValue = {
            ...insuranceFieldValue,
            avatar, insuranceBox, relationType, responsibleFullName, familyPhysician, specialAccount,
            infoMessage, snackMessage, issuerType, covered
          };
        setInsuranceFieldValue(_fieldValue);
      }
    }
  }, [checkInsuranceRequest.data])

  useEffect(() => {
    const otherItems = (prescriptionFieldValue?.medicationItems || []).map(item => item.checkCode)
      .filter((checkCode): checkCode is string => checkCode !== undefined && checkCode !== null && checkCode !== '');
    setMedicationItemsCheckCodes(otherItems)
  }, [prescriptionFieldValue.medicationItems]);

  useEffect(() => {
    const otherItems = (prescriptionFieldValue?.paraclinicalItems || []).map(item => item.checkCode)
      .filter((checkCode): checkCode is string => checkCode !== undefined && checkCode !== null && checkCode !== '');
    setParaclinicalItemsCheckCodes(otherItems)
  }, [prescriptionFieldValue.paraclinicalItems]);

  const handlePrescriptionModalSubmit = (id: string, insurerData: any, draftDescription: string) => {
    setPrescriptionFieldValue({...prescriptionFieldValue, id: id, insurerStatus: insurerData, draftDescription})
  }

  const submitEndVisit = () => {
    handleShowModal('addVisitPrescriptionModal',
      {
        visitId: fieldValue?.id,
        prescriptionFieldValue: prescriptionFieldValue,
        medicalHistoryFieldValue: medicalHistoryFieldValue,
        draftDescription: draftPrescriptionField,
        handlePrescriptionModalSubmit: handlePrescriptionModalSubmit,
      })
  }

  const queueRef = useRef(Promise.resolve());
  const updatePrescriptionFields = async (item: any, name: string) => {
    // Queue the state update
    await queueRef.current;
    // Create a new promise for the next operation
    const newPromise = new Promise<void>((resolve) => {
      if (name === 'medication') {
        setPrescriptionFieldValue(prev => {
          const newState = {...prev, medicationItems: item};
          resolve();
          return newState;
        });
      } else if (name === 'paraclinical') {
        setPrescriptionFieldValue(prev => {
          const newState = {...prev, paraclinicalItems: item};
          resolve();
          return newState;
        });
      } else if (name === 'medicalHistory') {
        setMedicalHistoryFieldValue(item);
        resolve();
      } else {
        setDraftPrescriptionField(item);
        resolve();
      }
    });
    // Update the queue to include this promise
    queueRef.current = newPromise;
  };

  return (
    <>
      <Tab.Container
        id="visit-detail-tabs"
        defaultActiveKey="prescription"
        onSelect={(eventKey) => setOpenTab(eventKey || '')}
      >
        {(getVisitRequest.isPending || getPatientRequest.isPending || checkInsuranceRequest.isPending) && <BoxLoader type="cover"/>}

        <div className='card-box'>
          <div className="rounded bg-primary bg-opacity-5 pt-4 mx-4">
            {width > 900 ?
              <PatientDetails
                patient={patientFieldValue}
                insurance={insuranceFieldValue}
                isRequesting={checkInsuranceRequest.isPending && getPatientRequest.isPending}
              /> :
              <PatientDetailsResponsive
                patient={patientFieldValue}
                insurance={insuranceFieldValue}
                isRequesting={checkInsuranceRequest.isPending && getPatientRequest.isPending}
              />
            }

            <Nav variant={'pills'} justify className='d-flex overflow-x-auto custom-scrollbar'>
              <div className="d-flex w-responsive w-sm-auto ehr-nav rounded bg-white shadow font-12 mb-4 mx-md-4">
                <Nav.Item>
                  <Nav.Link eventKey="prescription">
                    تجویز‌‌‌نسخه
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="medicalHistory">
                    شرح‌‌‌حال‌‌‌بیمار
                  </Nav.Link>
                </Nav.Item>
                {(isProvider || findAccessInAccessList('EHR_SUMMARY')) &&
                  <Nav.Item>
                    <Nav.Link eventKey="patientSummary">
                      پرونده‌سلامت‌‌‌بیمار
                    </Nav.Link>
                  </Nav.Item>
                }
                {(isProvider || findAccessInAccessList('EXAMINATION_VIEW')) &&
                  <Nav.Item>
                    <Nav.Link eventKey="examinations">
                      معاینات
                    </Nav.Link>
                  </Nav.Item>
                }
              </div>
            </Nav>
          </div>
          <div className='d-flex flex-wrap visit-tab-content w-100'>
            <Tab.Content>
              <Tab.Pane eventKey="prescription">
                <Prescription visitData={fieldValue}
                              isRequesting={getVisitRequest.isPending}
                              checkInsurance={insuranceFieldValue}
                              updatePrescriptionFields={updatePrescriptionFields}
                              prescriptionData={prescriptionFieldValue}
                              medicationItemsCheckCodes={medicationItemsCheckCodes}
                              paraclinicalItemsCheckCodes={paraclinicalItemsCheckCodes}
                              handlePrescriptionModalSubmit={handlePrescriptionModalSubmit}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="medicalHistory">
                <MedicalHistory visitData={fieldValue}
                                isRequesting={getVisitRequest.isPending}
                                openTab={openTab}
                                updatePrescriptionFields={updatePrescriptionFields}
                />
              </Tab.Pane>
              {(isProvider || findAccessInAccessList('EHR_VITAL_VIEW')) &&
                <Tab.Pane eventKey="patientSummary">
                  {
                    getPatientRequest.isPending ? <BoxLoader type='cover'/> :
                      <PatientSummary
                        openTab={openTab}
                        patientId={`${patientFieldValue?.id ? patientFieldValue?.id : 0}`}
                        visitEHRCheck={location?.state?.mode === 'edit'}
                      />
                  }
                </Tab.Pane>
              }
              {(isProvider || findAccessInAccessList('EXAMINATION_VIEW')) &&
                <Tab.Pane eventKey="examinations">
                  <Examinations openTab={openTab}/>
                </Tab.Pane>
              }
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
      <VisitDetailFooter submitEndVisit={submitEndVisit} visitData={fieldValue}/>
      <AddVisitPrescriptionModal/>
      <EndVisitModal/>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VisitDetail);
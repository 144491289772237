import TableLoader from "../../../../../general/table/tableLoader";
import EmptyRow from "../../../../../general/table/emptyRow";
import React from "react";
import {Accordion} from "react-bootstrap";
import VisitParaclinicalPrescriptionCardsItem from "./visitParaclinicalPrescriptionCards_Item";
type VisitParaclinicalPrescriptionCardsProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const VisitParaclinicalPrescriptionCards = ({
  dataList,
  dataListTitles,
  isRequesting
}: VisitParaclinicalPrescriptionCardsProps) => {

  const _dataList = dataList && dataList.length > 0 && dataList.map((item: any, i: number) => {
    return <VisitParaclinicalPrescriptionCardsItem item={item} key={i} index={i}/>
  });

  return (
    isRequesting ?
      <TableLoader colSpan={dataListTitles.length}/> :
      _dataList ? <Accordion className='prescription-items-accordion rounded mx-1'> {_dataList} </Accordion> :
        <EmptyRow colSpan={dataListTitles.length} content={''}/>
  )
}

export default VisitParaclinicalPrescriptionCards;

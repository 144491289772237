import React from "react";
import FavoriteParaclinicalItemRowItemResponsive from "./favoriteParaclinicalItemRow_ItemResponsive";


type FavoriteParaclinicalItemRowResponsiveProps = {
  item?: any
  pushFavoriteParaclinicalItem: any
}


const FavoriteParaclinicalItemRowResponsive = ({
 item,
 pushFavoriteParaclinicalItem
}: FavoriteParaclinicalItemRowResponsiveProps) => {

  // Function to handle changes in body checkboxes
  const handleBodyCheckboxChange = (checked: boolean, item: any) => {
    pushFavoriteParaclinicalItem(item, checked)
  };

  return (
    <div className="form-group">
      <FavoriteParaclinicalItemRowItemResponsive
        key={item.id}
        item={item}
        onChange={handleBodyCheckboxChange}
      />
    </div>
  );
};


export default FavoriteParaclinicalItemRowResponsive
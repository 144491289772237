import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {ROUTES} from "../../../constants";
import {PatientBiometricService} from "../../../services/requests/ehr/patientBiometric";

const useGetPatientBiometrics = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getPatientBiometrics", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PatientBiometricService.getPatientBiometricsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetPatientBiometricChartsData = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getPatientBiometricChartsData", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PatientBiometricService.getPatientBiometricChartsData(params);
    },
    enabled: modalName ?? true
  });
}

const useGetPatientBiometric = () => {
  return useMutation({
    mutationFn: PatientBiometricService.getPatientBiometric,
  })
}

const useAddPatientBiometric = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: PatientBiometricService.addPatientBiometric,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientBiometrics"] })
          queryClient.invalidateQueries({ queryKey: ["getPatientBiometricChartsData"] })
          onSuccess();
        }
      })
    },
  })
}

const useDeletePatientBiometric = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PatientBiometricService.deletePatientBiometric,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientBiometrics"] })
          queryClient.invalidateQueries({ queryKey: ["getPatientBiometricChartsData"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetPatientBiometrics,
  useGetPatientBiometric,
  useAddPatientBiometric,
  useDeletePatientBiometric,
  useGetPatientBiometricChartsData
}

import TableLoader from "../../../../../../components/general/table/tableLoader";
import EmptyRow from "../../../../../../components/general/table/emptyRow";
import React from "react";
import PatientListFamilyTableRowItem  from "./patientFamilyHistoryTable_rowItems";

type PatientAppointmentsTableProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const PatientFamilyHistoryTable = ({
  dataList,
  dataListTitles,
  isRequesting
}: PatientAppointmentsTableProps) => {
  const _dataListTitles = dataListTitles.map((item, i) => <td key={i} className='text-black font-weight-semibold font-12'>{item}</td>),
    _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
      return <PatientListFamilyTableRowItem item={item} key={i}/>
    });
  return (
    <table className='border-rounded font-en' >
      <thead>
      <tr>
        {_dataListTitles}
      </tr>
      </thead>
      <tbody className="bg-gray">
      {isRequesting ?
        <TableLoader colSpan={dataListTitles.length}/> :
        _dataList ? _dataList : <EmptyRow colSpan={dataListTitles.length} content={''}/>
      }
      </tbody>
    </table>
  )
}

export default PatientFamilyHistoryTable;

import ImageUploaderBoxItem from "./imageUploaderBox_item";
import ImageUploaderBoxFile from "./imageUploaderBox_file";

type ImageUploaderBoxProps = {
  setFieldValue: any;
  fieldValue: any;
  name: string;
  type?: "full";
  error?: any;
}

const ImageUploaderBox = ({
  setFieldValue,
  fieldValue,
  name,
  type,
  error
}: ImageUploaderBoxProps) => {

  const showError = error?.hasError && error?.dataError?.[name];

  return (
    <>
      <div
        className={`image-uploader-box h-110 d-flex flex-wrap ${type === "full" ? "w-100" : "w-110"} ${showError ? 'invalid-box' : ''}`}
      >
        {
          fieldValue?.[name]?.imageUrl ?
            <ImageUploaderBoxItem name={name} setFieldValue={setFieldValue} fieldValue={fieldValue} /> :
            <ImageUploaderBoxFile name={name} setFieldValue={setFieldValue} fieldValue={fieldValue} />
        }
      </div>
      { showError && <p className="text-red font-10 mt-1">{error?.dataError?.[name]}</p> }
    </>
  );
};

export default ImageUploaderBox;

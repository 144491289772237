import React, {useEffect, useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ComputerAnalytics from "../../../../assets/images/svgComponents/icon/computerAnalytics";
import {chartOptions} from "../../general/chartOptions";
import {useGetVisitDailyChart} from "../../../../hook/request/systemReport";
import {MtoJDateFormat} from "../../../../utils/date";

const DailyVisitCountChart = () => {

  const [data, setData] = useState([]);
  const [labels, setLabels] = useState<string[]>([]);
  const getVisitDailyChart = useGetVisitDailyChart();

  useEffect(() => {
    getVisitDailyChart.mutate({})
  }, []);

  useEffect(() => {
    if(!getVisitDailyChart.isPending && getVisitDailyChart?.data?.data){
      const { result } = getVisitDailyChart?.data?.data;
      let _totalVisitCountData = [],
        _totalVisitCountLabel: string[] = [];
      if(result){
        _totalVisitCountData = result
          .map((item:any) => (item?.values[0]));
        _totalVisitCountLabel = result
          .map((item:any) => (`${MtoJDateFormat(item?.label)}`));
      }

      setData(_totalVisitCountData)
      setLabels(_totalVisitCountLabel)
    }
  }, [getVisitDailyChart.data])

  return (
    <div className="dashboard-card-box h-100">
      <div className="flex-wrap align-items-center m-2">
            <span className="d-flex align-items-center text-white font-25">
              <span className="d-flex align-items-center ms-2">
               <ComputerAnalytics/>
              </span>
              <span className="font-15 fw-bold text-black">
                گزارش ویزیت‌های انجام شده
              </span>
            </span>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions({
            chartType: 'areaspline',
            data: [data],
            dataNames: ['ویزیت'],
            labels: labels,
            chartTitle: 'گزارش ویزیت‌های انجام شده',
            showLegend: false,
            displayXLabels: false
          })}
        />
      </div>
    </div>
  )
}

export default DailyVisitCountChart;
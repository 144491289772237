export const ROUTES = {
  // Auth routes
  LOGIN: {
    TITLE: 'login',
    PATH: '/auth/login'
  },
  // Others routes
  HOME: {
    TITLE: 'خانه',
    PATH: '/'
  },
  // ACCOUNT
  ACCOUNT: {
    TITLE: 'اطلاعات کاربری',
    PATH: '/account',
  },
  PROFILE: {
    TITLE: 'پروفایل',
    PATH: '/account/profile',
  },
  CHANGE_PASSWORD: {
    TITLE: 'تغییر کلمه عبور',
    PATH: '/account/change-password',
  },
  LOGIN_HISTORY: {
    TITLE: 'تاریخچه ورود به سیستم',
    PATH: '/account/login-history',
  },
  // VIDEO_CALL
  VIDEO_CALL: {
    TITLE: 'تماس تصویری',
    PATH: '/videoCall',
    ICON: 'phone'
  },
  // ORGANIZATION
  ORGANIZATION: {
    TITLE: 'مراکز درمانی',
    PATH: '/organization',
    ICON: 'hospital'
  },
  ORGANIZATION_ADD: {
    TITLE: 'مرکز درمانی جدید',
    PATH: '/organization/new',
  },
  ORGANIZATION_EDIT: {
    TITLE: 'ویرایش مرکز درمانی',
    PATH: '/organization/:id',
  },
  ORGANIZATION_RELATION: {
    TITLE: 'ارتباط مرکز درمانی',
    PATH: '/organization/relation/:id',
  },
  // DOCTOR
  DOCTOR: {
    TITLE: 'پــــــزشــکـان',
    PATH: '/doctor',
    ICON: 'heart-pulse'
  },
  DOCTOR_ADD: {
    TITLE: 'پــــــزشــک جدید',
    PATH: '/doctor/new',
  },
  DOCTOR_EDIT: {
    TITLE: 'ویرایش پــــــزشــک',
    PATH: '/doctor/:id',
  },
  // DEVICE
  DEVICE: {
    TITLE: 'دســـتـگاه‌هـا',
    PATH: '/device',
    ICON: 'gadget'
  },
  DEVICE_ADD: {
    TITLE: 'افزودن دســـتـگاه‌',
    PATH: '/device/new',
  },
  DEVICE_EDIT: {
    TITLE: 'ویرایش دســـتـگاه‌',
    PATH: '/device/:id',
  },
  ASSIGN_DEVICE: {
    TITLE: 'تخصیص دستگاه',
    PATH: '/device/assign/:id',
  },
  DEVICE_ASSIGNED_LIST: {
    TITLE: 'لیست تخصیص های دســـتـگاه‌',
    PATH: '/device/assign-list/:id',
  },
  // PENDING_DEVICE
  PENDING_DEVICE: {
    TITLE: 'دستگاه های در صف تایید',
    PATH: '/pending-device',
    ICON: 'revote'
  },
  // SCHEDULE
  SCHEDULE: {
    TITLE: 'برنامه زمان‌بندی',
    PATH: '/schedule',
    ICON: 'time'
  },
  SCHEDULE_ADD: {
    TITLE: 'برنامه زمان‌بندی جدید',
    PATH: '/schedule/new',
  },
  SCHEDULE_EDIT: {
    TITLE: 'ویرایش برنامه زمان‌بندی',
    PATH: '/schedule/:id',
  },
  SCHEDULE_ADD_ITEM: {
    TITLE: 'جزییات برنامه زمان‌بندی',
    PATH: '/schedule/item/:id',
  },
  // VACATION
  VACATION: {
    TITLE: 'مرخصی ها',
    PATH: '/vacation',
    ICON: 'notebook'
  },
  VACATION_ADD: {
    TITLE: 'مرخصی جدید',
    PATH: '/vacation/new',
  },
  VACATION_EDIT: {
    TITLE: 'ویرایش مرخصی',
    PATH: '/vacation/:id',
  },
  // PATIENT
  PATIENT: {
    TITLE: 'بــــــیمـــاران',
    PATH: '/patient',
    ICON: 'users-group'
  },
  PATIENT_EHR: {
    TITLE: 'پرونده سلامت بیمار',
    PATH: '/patient/ehr/:id',
  },
  // VISIT
  VISIT: {
    TITLE: 'ویــــزیـت ها',
    PATH: '/visit',
    ICON: 'docs'
  },
  VISIT_DETAIL: {
    TITLE: 'ویــــزیـت بیمار',
    PATH: '/visit/:id',
  },
  EXAMINATION: {
    TITLE: 'نمونه‌ها',
    PATH: '/examination',
    ICON: 'docs'
  },
  EXAMINATION_DETAIL: {
    TITLE: 'نمونه بیمار',
    PATH: '/examination/:id',
    ICON: 'docs'
  },
  // APPOINTMENT
  APPOINTMENT: {
    TITLE: 'نـــــوبــت ها',
    PATH: '/appointment',
    ICON: 'doc-add'
  },
  // BASIC_INFORMATION
  BASIC_INFORMATION: {
    TITLE: 'اطلاعات پایه',
    PATH: '/setting',
    ICON: 'medical-kit'
  },
  // MEDICATION
  MEDICATION: {
    TITLE: 'دارو ها',
    PATH: '/setting/prescription',
    ICON: 'hospital'
  },
  MEDICATION_ADD: {
    TITLE: 'دارو جدید',
    PATH: '/setting/prescription/new',
    ICON: 'user-octagon'
  },
  MEDICATION_EDIT: {
    TITLE: 'ویرایش دارو',
    PATH: '/setting/prescription/:id',
    ICON: 'user-octagon'
  },
  // MEDICATION_CATEGORY
  MEDICATION_CATEGORY: {
    TITLE: 'دسته بندی دارو ها',
    PATH: '/setting/category',
    ICON: 'basket'
  },
  MEDICATION_CATEGORY_ADD: {
    TITLE: 'دسته بندی جدید',
    PATH: '/setting/category/new',
    ICON: 'user-octagon'
  },
  MEDICATION_CATEGORY_EDIT: {
    TITLE: 'ویرایش دسته بندی',
    PATH: '/setting/category/:id',
    ICON: 'user-octagon'
  },
  // VACCINE
  VACCINE: {
    TITLE: 'واکسن ها',
    PATH: '/setting/vaccine',
    ICON: 'basket'
  },
  VACCINE_ADD: {
    TITLE: 'واکسن جدید',
    PATH: '/setting/vaccine/new',
    ICON: 'user-octagon'
  },
  VACCINE_EDIT: {
    TITLE: 'ویرایش واکسن',
    PATH: '/setting/vaccine/:id',
    ICON: 'user-octagon'
  },
  // CONDITION
  CONDITION: {
    TITLE: 'شرایط پزشکی',
    PATH: '/setting/condition',
    ICON: 'basket'
  },
  CONDITION_ADD: {
    TITLE: 'پــــــزشــک جدید',
    PATH: '/setting/condition/new',
    ICON: 'user-octagon'
  },
  CONDITION_EDIT: {
    TITLE: 'ویرایش پــــــزشــک',
    PATH: '/setting/condition/:id',
    ICON: 'user-octagon'
  },
  // PROGRAM
  PROGRAM: {
    TITLE: 'برنامه های آموزش درمانی',
    PATH: '/setting/program',
    ICON: 'basket'
  },
  PROGRAM_ADD: {
    TITLE: 'برنامه جدید',
    PATH: '/setting/program/new',
    ICON: 'user-octagon'
  },
  PROGRAM_EDIT: {
    TITLE: 'ویرایش برنامه',
    PATH: '/setting/program/:id',
    ICON: 'user-octagon'
  },
  // IMAGING_SERVICE
  IMAGING_SERVICE: {
    TITLE: 'خدمات تصویر برداری',
    PATH: '/setting/imagingService',
    ICON: 'basket'
  },
  IMAGING_SERVICE_ADD: {
    TITLE: 'خدمت تصویر برداری جدید',
    PATH: '/setting/imagingService/new',
    ICON: 'user-octagon'
  },
  IMAGING_SERVICE_EDIT: {
    TITLE: 'ویرایش خدمت تصویر برداری',
    PATH: '/setting/imagingService/:id',
    ICON: 'user-octagon'
  },
  // LABORATORY_SERVICE
  LABORATORY_SERVICE: {
    TITLE: 'خدمات آزمایشگاهی',
    PATH: '/setting/laboratoryService',
    ICON: 'basket'
  },
  LABORATORY_SERVICE_ADD: {
    TITLE: 'خدمت آزمایشگاهی جدید',
    PATH: '/setting/laboratoryService/new',
    ICON: 'user-octagon'
  },
  LABORATORY_SERVICE_EDIT: {
    TITLE: 'ویرایش خدمت آزمایشگاهی',
    PATH: '/setting/laboratoryService/:id',
    ICON: 'user-octagon'
  },
  // LABORATORY_SERVICE_CATEGORY
  LABORATORY_SERVICE_CATEGORY: {
    TITLE: 'دسته بندی خدمات آزمایشگاهی',
    PATH: '/setting/laboratoryServiceCategory',
    ICON: 'basket'
  },
  LABORATORY_SERVICE_CATEGORY_ADD: {
    TITLE: 'دسته بندی خدمت آزمایشگاهی جدید',
    PATH: '/setting/laboratoryServiceCategory/new',
    ICON: 'user-octagon'
  },
  LABORATORY_SERVICE_CATEGORY_EDIT: {
    TITLE: 'ویرایش دسته بندی خدمت آزمایشگاهی',
    PATH: '/setting/laboratoryServiceCategory/:id',
    ICON: 'user-octagon'
  },
  // MENU
  MENU: {
    TITLE: 'منو ها',
    PATH: '/setting/menu',
    ICON: 'basket'
  },
  MENU_ADD: {
    TITLE: 'منو جدید',
    PATH: '/setting/menu/new',
    ICON: 'user-octagon'
  },
  MENU_EDIT: {
    TITLE: 'ویرایش منو',
    PATH: '/setting/menu/:id',
    ICON: 'user-octagon'
  },
  // USER
  USER: {
    TITLE: 'کــــاربـــــران',
    PATH: '/user',
    ICON: 'user-plus'
  },
  USER_ADD: {
    TITLE: 'کاربر جدید',
    PATH: '/user/new',
    ICON: 'user-octagon'
  },
  USER_EDIT: {
    TITLE: 'ویرایش کاربر',
    PATH: '/user/:id',
    ICON: 'user-octagon'
  },
  USER_ACCESS: {
    TITLE: 'دسترسی کاربر',
    PATH: '/user/access/:id/:accountId',
    ICON: 'user-octagon'
  },
  // SETTING
  SETTING:{
    TITLE:"تنظیمات",
    PATH:'/setting-issuer',
    ICON:''
  },
  DASHBOARD:{
    TITLE:"داشبورد",
    PATH:'/dashboard',
    ICON:'home'
  },
  // 404
  NOT_FOUND: {
    TITLE: '404',
    PATH: '/404'
  },
};
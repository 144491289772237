import {getCellValue} from "../../../../../../../utils/utils";
import {general} from "../../../../../../../store/action";
import {connect} from "react-redux";
import React from "react";


type VisitMedicationPrescriptionTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const VisitMedicationPrescriptionTableRowItem = ({
  item,
}: VisitMedicationPrescriptionTableRowItemProps) => {

  return (
    <tr>
      <td>{getCellValue(item?.brandName)}</td>
      <td>{getCellValue(item?.dosageName)}</td>
      <td>{getCellValue(item?.frequencyName)}</td>
      <td>{getCellValue(item?.itemCount)}</td>
      <td>{getCellValue(item?.item?.dose)}</td>
      <td className='text-start'>
        <bdi>
          <span className='text-primary font-en me-2'>{getCellValue(item?.item?.id)}</span>
          <span className='font-en'>{getCellValue(item?.item?.molecule)}</span>
        </bdi>
      </td>
      <td>{getCellValue(item?.item?.shape?.name)}</td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VisitMedicationPrescriptionTableRowItem);

import React, {useState} from "react";
import {Nav, Tab} from "react-bootstrap";
import PatientMUACChart from "../../../biometric/dataCharts/patientMUACChart";
import PatientBMIChart from "../../../biometric/dataCharts/patientBMIChart";
import PatientHeightChart from "../../../biometric/dataCharts/patientHeightChart";
import PatientWeightChart from "../../../biometric/dataCharts/patientWeightChart";

type biometricChartsSummaryProps = {
  patientData?: any;
  openTab?: string;
}

const BiometricChartsSummary = ({
 patientData,
 openTab,
}: biometricChartsSummaryProps) => {

  const [openBiometricTab, setOpenBiometricTab] = useState('weight')

  const biometricCharts = [
    {
      label: 'muac',
      title: 'MUAC',
      children: <PatientMUACChart patientId={patientData} displayX={false} displayY={false} displayDataLabels={true} showLegend={false} showExportButton={false} openTab={openBiometricTab}/>
    },
    {
      label: 'bmi',
      title: 'BMI',
      children: <PatientBMIChart patientId={patientData} displayX={false} displayY={false} displayDataLabels={true} showLegend={false} showExportButton={false} openTab={openBiometricTab}/>
    },
    {
      label: 'height',
      title: 'Height',
      children: <PatientHeightChart patientId={patientData} displayX={false} displayY={false} displayDataLabels={true} showLegend={false} showExportButton={false} openTab={openBiometricTab}/>

    },
    {
      label: 'weight',
      title: 'Weight',
      children: <PatientWeightChart patientId={patientData} displayX={false} displayY={false} displayDataLabels={true} showLegend={false} showExportButton={false} openTab={openBiometricTab}/>
    },
  ]

  const renderCharts = (data: any, openTab: string) => data.map((item:any, key: number) => {
    return (
      <Tab.Pane key={key} eventKey={item?.label}>
        {openTab == item?.label &&
          <div className='card-box-gray mx-1 my-2 py-0 pt-1'>
            {item?.children}
          </div>}
      </Tab.Pane>
    )
  })

  const renderTabs = (data: any) => data.map((item:any, key: number) => {
    return (
      <Nav.Item key={key}>
        <Nav.Link className='rounded-top-0' eventKey={item?.label}>
          <span className='fw-bold'>{item?.title}</span>
        </Nav.Link>
      </Nav.Item>
    )
  })

  return (
    <Tab.Container
      id="biometrics-chart-tabs"
      defaultActiveKey="weight"
      onSelect={(eventKey) => setOpenBiometricTab(eventKey || '')}
    >
      <Tab.Content>
        {renderCharts(biometricCharts, openBiometricTab)}
      </Tab.Content>
      <Nav variant="pills" className='mt-1' justify>
        <div className='d-flex rounded-bottom bg-gray3 ehr-nav top-not-round w-100'>
          {renderTabs(biometricCharts)}
        </div>
      </Nav>
    </Tab.Container>
  );
};

export default BiometricChartsSummary;

import * as yup from "yup";

const loginSchema = yup.object({
  username: yup
    .string()
    .required("این فیلد الزامیست."),
  password: yup
    .string()
    .required("این فیلد الزامیست."),
  captchaValue: yup
    .string()
    .required("این فیلد الزامیست."),
  // nameValue: yup
  //   .string()
  //   .min(3, "حداقل 3 کاراکتر")
  //   .max(10, "حداکثر 10 کاراکتر")
  //   .required("این فیلد الزامیست."),
  // emailValue: yup
  //   .string()
  //   .trim()
  //   .email("فرمت ایمیل نادرست است")
  //   .required("این فیلد الزامیست."),
  // dropdownValue: yup.string().required("این فیلد الزامیست."),
  // textAreaValue: yup.string().required("این فیلد الزامیست."),
  // autoCompleteMultiValue: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       title: yup.string(),
  //       value: yup.string()
  //     })
  //   )
  //   .min(1, "این فیلد الزامیست."),
  // autoCompleteSingleValue: yup
  //   .object()
  //   .shape({
  //     title: yup.string(),
  //     value: yup.string()
  //   })
  //   .typeError("لطفا یک گزینه را انتخاب کنبد")
  //   .required("این فیلد الزامیست."),
  // chipsValue: yup.array().min(1, "این فیلد الزامیست."),
  // dateTimeValue: yup.string().typeError("لطفا یک تاریخ وارد کنبد").required("این فیلد الزامیست."),
  // switchValue: yup.boolean().required("این فیلد الزامیست."),
  // checkboxValue: yup.boolean().required("این فیلد الزامیست."),
  // ageValue: yup
  //   .number()
  //   .when("checkboxValue", {
  //     is: true,
  //     then: yup
  //       .number()
  //       .typeError("لطفا یک عدد وارد کنبد")
  //       .positive("سن باید مثبت باشد")
  //       .integer("سن اعشاری نباید باشد")
  //       .lessThan(25, "حداکثر سن 25 سال است")
  //       .moreThan(10, "حداقل سن 10 سال است")
  //       .required("این فیلد الزامیست."),
  //     otherwise: yup
  //       .number()
  //       .notRequired()
  //   })
  //   ,
});

export {
  loginSchema
};

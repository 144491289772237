import React from "react";
import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../../utils/date";

type PatientSurgeryListTableRowItemProps = {
  item: any;
};

const PatientSurgeryListTableRowItem = ({
  item,
}: PatientSurgeryListTableRowItemProps) => {

  return (
    <tr>
      <td>
        <div >{getCellValue(item?.surgery.faName)}</div>
      </td>
      <td>
        <div>{getCellValue(MtoJDateFormat(item?.surgeryDate))}</div>
      </td>
      <td>
        <div className="word-spacing-10">
          {getCellValue(MtoJDateFormat(item?.updateDate))}
        </div>
      </td>
      <td>
        <div>{getCellValue(item?.surgery.description)}</div>
      </td>
    </tr>
  );
};

export default PatientSurgeryListTableRowItem;

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {
  isHideModalDataSelector,
  isShowModalDataSelector, modalDataSelector,
  modalNameSelector
} from "../../../../../../../store/selector/general";
import {general} from "../../../../../../../store/action";
import Modal from "../../../../../general/modal";
import BoxLoader from "../../../../../general/boxLoader";
import Icon from "../../../../../general/icon";
import {useMySearchParams} from "../../../../../../../hook/useMySearchParams";
import {Accordion, Form} from "react-bootstrap";
import RecentParaclinicalPrescriptionRow from "./dataList/recentParaclinicalPrescriptionRow";
import {useGetRecentPrescriptionList} from "../../../../../../../hook/request/visit/prescription";
import EmptyBox from "../../../../../general/emptyBox";
import {useWindowSize} from "../../../../../../../hook/useWindowSize";


type RecentParaclinicalPrescriptionModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  draftMedication?: boolean;
  patient?: number | null;
  provider?: number | null;
}

type ModalInfoProps = {
  id: string;
  visitId: string;
  patientData?: any;
  index?: number;
  item?: any;
  handleParaclinicalModalSubmit: any;
  fieldValue: any;
}


const RecentParaclinicalPrescriptionModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: RecentParaclinicalPrescriptionModalProps) => {
  const [width] = useWindowSize();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    visitId: '',
    patientData: '',
    handleParaclinicalModalSubmit: null,
    fieldValue: null
  });

  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "20",
    draftMedication: false,
    patient: null
  });
  const [fieldValue, setFieldValue] = useState([]);
  const [recentParaclinicalPrescriptionList, setRecentParaclinicalPrescriptionList] = useState([]);

  // services
  const getRecentParaclinicalPrescriptionListRequest = useGetRecentPrescriptionList(
    getQueryParams(filterValues),modalName == 'recentParaclinicalPrescriptionModal');

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'recentParaclinicalPrescriptionModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "20",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    showState && getRecentParaclinicalPrescriptionListRequest.refetch();
  }, [filterValues])

  useEffect(() => {
    if (getRecentParaclinicalPrescriptionListRequest?.data?.data?.result) {
      const { data } = getRecentParaclinicalPrescriptionListRequest.data.data.result
      setRecentParaclinicalPrescriptionList(data);
    }
  }, [getRecentParaclinicalPrescriptionListRequest.data])


  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue([])
    handleHideModal();
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target;
    if(checked) {
      const _filterValues = {
        ...filterValues,
        patient: modalInfo?.patientData?.id,
      }
      setFilterValues(_filterValues)
    } else {
      const _filterValues = {
        ...filterValues,
        patient: null
      }
      setFilterValues(_filterValues)
    }
  }

  const addDraftPrescription = () => {
    handleSetShowModal();
    modalInfo.handleParaclinicalModalSubmit(fieldValue, -1)
  }

  const pushRecentParaclinicalPrescription = (item: any, checked: boolean) => {
    setFieldValue((prev: any) => {
      if (checked) {
        // Add the item if it is not already in the list
        if (!prev.find((i: any) => i.id === item.id)) {
          return [...prev, item];
        }
      } else {
        // Remove the item if it exists in the list
        return prev.filter((i: any) => i.id !== item.id);
      }
      return prev;
    })
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel overflow-hidden"
      fullscreen={width <= 900 ? true : 'false'}
    >
      {getRecentParaclinicalPrescriptionListRequest?.isLoading && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              لیست سوابق تجویز پاراکلینیک
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <div className="col-12 px-2">
          <div className="d-flex justify-content-between font-12 fw-bold mb-4 ms-md-auto">
            <div className='d-flex align-items-center'>
              نمایش {recentParaclinicalPrescriptionList.length} تجویز اخیر برای کل بیماران
            </div>
            <div>
              <Form.Check className='mx-3'>
                <Form.Check.Label className='d-flex align-items-center w-100'>
                  فقط نمایش سابقۀ بیمار فعلی
                  <Form.Check.Input
                    className='rounded rounded-3 font-22 mb-1 me-2'
                    // name={`checkbox-${item?.id}`}
                    type='checkbox'
                    // checked={checked}
                    onChange={handleFilterChange}
                  />
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
        </div>
        {/*{isRequesting && <BoxLoader/>}*/}
        <div className="overflow-auto custom-scrollbar" style={{maxHeight: '70vh'}}>
          <Accordion className='visits-accordion rounded mb-4 mx-2' alwaysOpen style={{ maxHeight: '100%', overflowY: 'auto' }}>
            {
              recentParaclinicalPrescriptionList.length > 0 ?
                recentParaclinicalPrescriptionList.map((item: any, key: number) => {
                  return (
                    <RecentParaclinicalPrescriptionRow
                      key={key}
                      item={item}
                      pushRecentParaclinicalPrescription={pushRecentParaclinicalPrescription}
                    />
                  )
                }) : <EmptyBox/>
            }
          </Accordion>
        </div>
        <div className="col-12 d-flex justify-content-end ">
          <div className="d-flex w-100 align-items-stretch form-group px-2">
            <button
              type='submit'
              className="btn btn-primary rounded d-flex w-100 justify-content-center align-items-center"
              onClick={addDraftPrescription}
            >
              {"افزودن به نسخه"}
            </button>
          </div>
        </div>
      </div>

    </Modal>
);
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentParaclinicalPrescriptionModal);
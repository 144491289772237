import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {PrescriptionService} from "../../../services/requests/visit/prescription";

const useGetPrescriptionList = (params: any, modalName: boolean) => {
  return useQuery({
    queryKey: ["getPrescriptionList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PrescriptionService.getPrescriptionList(params);
    },
    enabled: modalName ?? true,
  });
}

const useGetRecentPrescriptionList = (params: any, modalName: boolean) => {
  return useQuery({
    queryKey: ["getRecentPrescriptionList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PrescriptionService.getRecentPrescriptionList(params);
    },
    enabled: modalName ?? true,
  });
}

const useGetPrescription = () => {
  return useMutation({
    mutationFn: PrescriptionService.getPrescription,
  })
}

const useAddPrescription = ({
  onSuccess
}: {
  onSuccess: (result: any) => void
}) => {
  return useMutation({
    mutationFn: PrescriptionService.addPrescription,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPrescriptionList"] })
          onSuccess(data?.data?.result);
        }
      })
    },
  })
}

const useDeletePrescription = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PrescriptionService.deletePrescription,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPrescriptionList"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const usePrescriptionItemIhioCheck = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PrescriptionService.prescriptionItemIhioCheck,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          // Noti("success", data?.data?.msg)
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const usePrescriptionInsurerSave = () => {
  return useMutation({
    mutationFn: PrescriptionService.prescriptionInsurerSave,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          data?.data?.result?.errorMessage ?
            Noti("error", data?.data?.result?.errorMessage) :
            Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getVisits"] })
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetPrescriptionList,
  useGetRecentPrescriptionList,
  useGetPrescription,
  useAddPrescription,
  useDeletePrescription,
  usePrescriptionItemIhioCheck,
  usePrescriptionInsurerSave
}

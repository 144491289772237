import * as React from "react"
import { SVGProps } from "react"
import {PROVIDER_VISIT_STATUS_ENUM} from "../../../../constants/constant/enum";

type OnlineForMyVisitsStatusIconProps = SVGProps<SVGSVGElement> & {
  status?: number;
};

const OnlineStatusIcon = ({ status = 100, ...props }: OnlineForMyVisitsStatusIconProps) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 3.25C5.04086 3.25 3.25 5.04086 3.25 7.25V7.91667C3.25 10.1258 5.04086 11.9167 7.25 11.9167H18.75C20.9591 11.9167 22.75 10.1258 22.75 7.91667V7.25C22.75 5.04086 20.9591 3.25 18.75 3.25H7.25ZM9.75 7.58333C9.75 8.77995 8.77995 9.75 7.58334 9.75C6.38672 9.75 5.41667 8.77995 5.41667 7.58333C5.41667 6.38671 6.38672 5.41666 7.58334 5.41666C8.77995 5.41666 9.75 6.38671 9.75 7.58333Z"
      style={{transition: "fill 0.8s ease"}}
      fill={
        status === PROVIDER_VISIT_STATUS_ENUM.OFFLINE.value ? "#FF5656" :
        status === PROVIDER_VISIT_STATUS_ENUM.ONLINE_FOR_MY_VISIT.value ? "#F6A60A" :
        status === PROVIDER_VISIT_STATUS_ENUM.ONLINE.value ? "#09CB7A" :
          "#D8DAE0"
    }
    />
    <path
      opacity={0.3}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 14.0835C5.04086 14.0835 3.25 15.8744 3.25 18.0835V18.7502C3.25 20.9593 5.04086 22.7502 7.25 22.7502H18.75C20.9591 22.7502 22.75 20.9593 22.75 18.7502V18.0835C22.75 15.8744 20.9591 14.0835 18.75 14.0835H7.25ZM20.5833 18.4168C20.5833 19.6134 19.6133 20.5835 18.4167 20.5835C17.22 20.5835 16.25 19.6134 16.25 18.4168C16.25 17.2202 17.22 16.2502 18.4167 16.2502C19.6133 16.2502 20.5833 17.2202 20.5833 18.4168Z"
      fill="#7E8299"
    />
  </svg>
)
export default OnlineStatusIcon

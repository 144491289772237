import * as yup from "yup";

const accountPasswordSchema = yup.object({
  newPassword: yup
    .string()
    .required("این فیلد الزامیست."),
  repeatNewPassword: yup
    .string()
    .required("این فیلد الزامیست.")
    .oneOf([yup.ref('newPassword'),], 'تکرار کلمه عبور یکسان نمی باشد'),
});

export {
  accountPasswordSchema
};

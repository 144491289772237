import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Nav, Tab} from "react-bootstrap";
import {useMySearchParams} from "../../../../../../../hook/useMySearchParams";
import {useGetPatientVitals} from "../../../../../../../hook/request/ehr/patientVital";
import PatientPulseRateChart from "../../../vital/dataCharts/patientPulseRateChart";
import PatientRespiratoryRateChart from "../../../vital/dataCharts/patientRespiratoryRateChart";
import PatientTemperatureChart from "../../../vital/dataCharts/patientTemperatureChart";
import PatientSPO2Chart from "../../../vital/dataCharts/patientSPO2Chart";
import PatientBloodPressureChart from "../../../vital/dataCharts/patientBloodPressureChart";
import {TDataList} from "../../../../../../../types/general";

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type vitalChartsSummaryProps = {
  patientData?: any;
  openTab?: string;
}

const VitalChartsSummary = ({
  patientData,
  openTab,
}: vitalChartsSummaryProps) => {

  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams } = useMySearchParams();
  const [vitalsData, setVitalsData] = useState<TDataList>({
    data: [],
    count: 0
  });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "3",
    search: "",
  });
  const [openVitalTab, setOpenVitalTab] = useState('bloodPressure')

  const vitalCharts = [
    {
      label: 'pulseRate',
      title: 'Pulse',
      children: <PatientPulseRateChart patientId={patientData} displayX={false} displayY={false} displayDataLabels={true} showLegend={false} showExportButton={false} openTab={openVitalTab}/>
    },
    {
      label: 'respiratoryRate',
      title: 'R.rate',
      children: <PatientRespiratoryRateChart patientId={patientData} displayX={false} displayY={false} displayDataLabels={true} showLegend={false} showExportButton={false} openTab={openVitalTab}/>

    },
    {
      label: 'temperature',
      title: 'Temp',
      children: <PatientTemperatureChart patientId={patientData} displayX={false} displayY={false} displayDataLabels={true} showLegend={false} showExportButton={false} openTab={openVitalTab}/>
    },
    {
      label: 'spo2',
      title: 'SpO2',
      children: <PatientSPO2Chart patientId={patientData} displayX={false} displayY={false} displayDataLabels={true} showLegend={false} showExportButton={false} openTab={openVitalTab}/>
    },
    {
      label: 'bloodPressure',
      title: 'BP',
      children: <PatientBloodPressureChart patientId={patientData} displayX={false} displayY={false} displayDataLabels={true} showLegend={false} showExportButton={false} openTab={openVitalTab}/>
    }
  ]

  // services
  const getVitalsRequest = useGetPatientVitals(getQueryParams(filterValues), openTab === 'patientSummary');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "3",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getVitalsRequest?.data?.data?.result &&
    setVitalsData(getVitalsRequest?.data?.data.result)
  }, [getVitalsRequest.data])

  const renderCharts = (data: any, openTab: string) => data.map((item:any, key: number) => {
    return (
      <Tab.Pane key={key} eventKey={item?.label}>
        {openTab == item?.label &&
          <div className='card-box-gray mx-1 my-2 py-0 pt-1'>
            {item?.children}
          </div>}
      </Tab.Pane>
    )
  })

  const renderTabs = (data: any) => data.map((item:any, key: number) => {
    return (
      <Nav.Item key={key}>
        <Nav.Link className='rounded-top-0' eventKey={item?.label}>
          <span className='fw-bold'>{item?.title}</span>
        </Nav.Link>
      </Nav.Item>
    )
  })

  return (
    <Tab.Container
      id="vitals-chart-tabs"
      defaultActiveKey="bloodPressure"
      onSelect={(eventKey) => setOpenVitalTab(eventKey || '')}
    >
      <Tab.Content>
        {renderCharts(vitalCharts, openVitalTab)}
      </Tab.Content>
      <Nav variant="pills" className='mt-1' justify>
        <div className='d-flex rounded-bottom bg-gray3 ehr-nav top-not-round w-100'>
          {renderTabs(vitalCharts)}
        </div>
      </Nav>
    </Tab.Container>
  );
};

export default VitalChartsSummary;

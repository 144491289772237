import React from "react";
import {connect} from "react-redux";
import {Accordion} from "react-bootstrap";
import {general} from "../../../../../store/action";
import {TGQuantityData} from "../../../../../types/general";
import Thermometers from "../../../../../assets/images/svgComponents/thermometers";
import ECG from "../../../../../assets/images/svgComponents/ecg";


type SummaryCardsProps = {
  bpDiastolic?: TGQuantityData,
  bpSystolic?: TGQuantityData,
  etco2?: TGQuantityData,
  fico2?: TGQuantityData,
  respiratoryRate?: TGQuantityData,
  temperature1?: TGQuantityData,
  temperature2?: TGQuantityData,
}

const SummaryCards = ({
 bpDiastolic,
 bpSystolic,
 etco2,
 fico2,
 respiratoryRate,
 temperature1,
 temperature2,
}: SummaryCardsProps) => {

  return (
    <>
      <Accordion.Header className='d-flex flex-wrap'>
        <span className='font-14 me-4 text-gray font-en'>
          ETCO2: &ensp;
          <span className="text-black">
            {(etco2?.magnitude)?.toFixed(1)} {etco2?.unit}
          </span>
        </span>
        <span className='font-14 me-2 text-gray font-en'>
          FICO2: &ensp;
          <span className="text-black">
           {(fico2?.magnitude)?.toFixed(1)} {fico2?.unit}
          </span>
        </span>
        <span className='font-14 me-2 text-gray font-en'>
          RR: &ensp;
          <span className="text-black">
            {(respiratoryRate?.magnitude)?.toFixed(1)} {respiratoryRate?.unit}
          </span>
        </span>
        <div className="flex-fill"></div>
        <span className='font-14 fw-bold me-2 text-black'>
          اطلاعات مربوط به علائم حیاتی و گاز‌های تنفسی
        </span>
      </Accordion.Header>
      <Accordion.Body className='rounded-bottom bg-primary-10'>
        <div className="col-12 row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className='title-dot mb-2'>
              ETCO2
            </div>
            <div className='card-box d-flex'>
              <div>
                <div className="text-gray font-12">
                  تبادل گاز دی اکسیدکربن در سیستم تنفس
                </div>
                <div>
                  <span className='d-flex m-1 font-en'>
                    <span className='h1 text-primary fw-bold'>{(etco2?.magnitude)?.toFixed(1)}</span>
                    <div className='d-flex align-items-end'>
                      <span className='bg-primary-subtle font-10 text-primary p-1 mb-2 me-2'>{etco2?.unit}</span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center me-auto">
                <ECG/>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className='title-dot mb-2'>
            FICO2
            </div>
            <div className='card-box d-flex'>
              <div>
                <div className="text-gray font-12">
                  میزان اکسیدکربن در سیستم تنفس
                </div>
                <div>
                  <span className='d-flex m-1 font-en'>
                    <span className='h1 text-primary fw-bold'>{(fico2?.magnitude)?.toFixed(1)}</span>
                    <div className='d-flex align-items-end'>
                      <span className='bg-primary-subtle font-10 text-primary p-1 mb-2 me-2'>{fico2?.unit}</span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center me-auto">
                <ECG/>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className='title-dot mb-2'>
            RR
            </div>
            <div className='card-box d-flex'>
              <div>
                <div className="text-gray font-12">
                  نرخ تنفس و تعداد نفس‌های فرد در یک دقیقه
                </div>
                <div>
                  <span className='d-flex m-1 font-en'>
                    <span className='h1 text-primary fw-bold'>{(respiratoryRate?.magnitude)?.toFixed(1)}</span>
                    <div className='d-flex align-items-end'>
                      <span
                        className='bg-primary-subtle font-10 text-primary p-1 mb-2 me-2'>{respiratoryRate?.unit}</span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center me-auto">
                <ECG/>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className='title-dot mb-2'>
              BP
            </div>
            <div className='card-box d-flex'>
              <div>
                <div className="text-gray font-12">
                  میزان فشار خون
                </div>
                <div>
                  <span className='d-flex m-1 font-en'>
                    <span
                      className='h1 text-primary fw-bold'>{(bpDiastolic?.magnitude)?.toFixed(0)}/{(bpSystolic?.magnitude)?.toFixed(0)}</span>
                    <div className='d-flex align-items-end'>
                      <span
                        className='bg-primary-subtle font-10 text-primary p-1 mb-2 me-2'>({bpDiastolic?.unit})</span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center me-auto">
                <ECG/>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className='title-dot mb-2'>
              Temp1
            </div>
            <div className='card-box d-flex'>
              <div>
                <div className="text-gray font-12">
                  میزان دمای طبیعی بدن
                </div>
                <div>
                  <span className='d-flex m-1 font-en'>
                    <span className='h1 text-primary fw-bold'>{(temperature1?.magnitude)?.toFixed(1)}</span>
                    <div className='d-flex align-items-end'>
                      <span className='bg-primary-subtle font-10 text-primary p-1 mb-2 me-2'>({temperature1?.unit})</span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center me-auto">
                <Thermometers/>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className='title-dot mb-2'>
              Temp2
            </div>
            <div className='card-box d-flex'>
              <div>
                <div className="text-gray font-12">
                  میزان دمای طبیعی بدن
                </div>
                <div>
                  <span className='d-flex m-1 font-en'>
                    <span className='h1 text-primary fw-bold'>{(temperature2?.magnitude)?.toFixed(1)}</span>
                    <div className='d-flex align-items-end'>
                      <span className='bg-primary-subtle font-10 text-primary p-1 mb-2 me-2'>({temperature2?.unit})</span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center me-auto">
                <Thermometers/>
              </div>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(SummaryCards);
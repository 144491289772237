import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../../../hook/useMySearchParams";
import {useGetPatientVitals} from "../../../../../../../hook/request/ehr/patientVital";
import PatientVitalCardsBox from "../../../vital/dataList/patientVitalCardsBox";
import PatientVitalsTable from "../../../vital/dataList/patientVitalsTable";

const dataListTitles = [
  "تاریخ و ساعت معاینه",
  "درجه حرارت (DEG C)",
  "فشار خون (mmHg)",
  "ضربان قلب (beats/min)",
  "نرخ تنفس (breaths/min)",
  "اکسیژن اشباع خون (%)",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type vitalsListProps = {
  patientData?: any;
  openTab: string;
}

const VitalsListSummary = ({
 patientData,
 openTab,
}: vitalsListProps) => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [vitalsData, setVitalsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: patientData,
    pageNumber: "0",
    perPage: "3",
    search: "",
  });

  // services
  const getVitalsRequest = useGetPatientVitals(getQueryParams(filterValues), openTab === 'vitalsList' && filterValues?.patient !== '0');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      patient: patientData,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "3",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams, patientData]);

  useEffect(() => {
    getVitalsRequest?.data?.data?.result &&
    setVitalsData(getVitalsRequest?.data?.data.result)
  }, [getVitalsRequest.data])


  return (
    <section className="table-cont table-responsive">
      {
        width < 900 ?
          <PatientVitalCardsBox
            dataList={vitalsData}
            dataListTitles={dataListTitles}
            isRequesting={getVitalsRequest.isPending}
            readonly={true}
          /> :
          <PatientVitalsTable
            dataList={vitalsData}
            dataListTitles={dataListTitles}
            isRequesting={getVitalsRequest.isPending}
            readonly={true}
          />
      }
    </section>
  );
};

export default VitalsListSummary;

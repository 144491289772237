import { axiosRequest } from '../api';
import { API_PATH } from '../../constants';

export const DoctorService = {
  getDoctorsList(params){ return axiosRequest('GET', API_PATH.DOCTOR.LIST(params)) },
  getDoctor(data){ return axiosRequest('POST', API_PATH.DOCTOR.INFO, data) },
  getMyProviderInfo(data){ return axiosRequest('POST', API_PATH.DOCTOR.MY_INFO, data) },
  getDoctorStatistics(data){ return axiosRequest('POST', API_PATH.DOCTOR.STATISTICS, data) },
  addDoctor(data){ return axiosRequest('POST', API_PATH.DOCTOR.ADD, data) },
  updateDoctorProfile(data){ return axiosRequest('POST', API_PATH.DOCTOR.UPDATE_PROFILE, data) },
  deleteDoctor(data){ return axiosRequest('POST', API_PATH.DOCTOR.DELETE, data) },
  getSpecialitiesList(params){ return axiosRequest('GET', API_PATH.DOCTOR.SPECIALITIES_LIST(params)) },
  doctorVisitStatusChange(data){ return axiosRequest('POST', API_PATH.DOCTOR.VISIT_STATUS_CHANGE, data) },
  getDoctorVisitStatus(data){ return axiosRequest('POST', API_PATH.DOCTOR.VISIT_STATUS_GET, data) },
}
import {useWindowSize} from "../../../hook/useWindowSize";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import React, {useEffect, useMemo, useState} from "react";
import SearchBox from "../../components/general/searchBox";
import Pagination from "../../components/general/table/pagination";
import {general} from "../../../store/action";
import {connect} from "react-redux";
import ExaminationsCardsBox from "../../components/examinations/dataList/examinationCardsBox";
import ExaminationsTable from "../../components/examinations/dataList/examinationsTable";
import {useGetExaminationList} from "../../../hook/request/visit/examination";


const dataListTitles = [
  "نام بیمار",
  "کد دستگاه (صدرا A15 Plus)",
  "تاریخ برداشت",
  "زمان برداشت",
  "نوع برداشت",
  "عملیات",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}



const ExaminationsList = () => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [examinationsData, setExaminationsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getExaminationsRequest = useGetExaminationList(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
      status: searchParams.get("status") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getExaminationsRequest?.data?.data?.result &&
    setExaminationsData(getExaminationsRequest?.data?.data.result)
  }, [getExaminationsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی بیمار"
            />
          </div>
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <ExaminationsCardsBox
                dataList={examinationsData}
                dataListTitles={dataListTitles}
                isRequesting={getExaminationsRequest.isPending}
              /> :
              <ExaminationsTable
                dataList={examinationsData}
                dataListTitles={dataListTitles}
                isRequesting={getExaminationsRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...examinationsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ExaminationsList);
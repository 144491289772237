import React, {useEffect, useMemo, useRef, useState} from "react";
import BoxLoader from "../../components/general/boxLoader";
import {AsyncSelectField} from "../../components/general/form/selectField";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { TAssignDeviceField } from "../../../types/device";
import {
  useAssignDevice,
  useGetDevice,
  useGetAssignableAccountsList,
} from "../../../hook/request/devices";
import { useGetOrganizations } from "../../../hook/request/organizations";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { assignDeviceSchema } from "../../../schema/device-schema";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import {asyncSelectLoadOptions} from "../../../utils/asyncSelectLoadOptions";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const AssignDevice = () => {

  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  let { id } = useParams();
  const isEditMode = id;
  const [organizationsList, setOrganizationsList] = useState([]);
  const [assignableAccountsList, setAssignableAccountsList] = useState([]);
  const { getQueryParams } = useMySearchParams();
  const [searchParams] = useSearchParams();
  const [organizationFilterValues, setOrganizationFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [assignableAccountsFilterValues, setAssignableAccountsFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  const [fieldValue, setFieldValue] = useState<TAssignDeviceField>({
    isAssigned: 0,
    device: "",
    healthUnit: "",
    account: "",
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(assignDeviceSchema),
  });
  const [fetch, setFetch] = useState<boolean>(true)


  const organizationCallbackRef = useRef<any>(null);
  const assignCallbackRef = useRef<any>(null);


  // services
  const getDeviceRequest = useGetDevice();
  const getOrganizationsRequest = useGetOrganizations(getQueryParams(organizationFilterValues), fetch);
  const getAssignableAccountsListRequest = useGetAssignableAccountsList(getQueryParams(assignableAccountsFilterValues), fetch);
  const assignDeviceRequest = useAssignDevice();

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    id && getDeviceRequest.mutate({id});
  }, [id])

  useEffect(() => {
    let _OrganizationFilterValues = {};
    _OrganizationFilterValues = {
      ...organizationFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setOrganizationFilterValues(_OrganizationFilterValues)
    setFetch(false)
  }, [searchParams]);

  useEffect(() => {
    let _AssignableAccountsFilterValues = {};
    _AssignableAccountsFilterValues = {
      ...organizationFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setAssignableAccountsFilterValues(_AssignableAccountsFilterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getOrganizationsRequest?.data?.data?.result) {
      const { data } = getOrganizationsRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item.name, value: item?.id }
        ))
      setOrganizationsList(_data);
    }
  }, [getOrganizationsRequest.data])

  useEffect(() => {
    if (getAssignableAccountsListRequest?.data?.data?.result) {
      const { data } = getAssignableAccountsListRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: `${item?.firstName} ${item?.lastName}`, value: item?.id }
        ))
      setAssignableAccountsList(_data);
    }
  }, [getAssignableAccountsListRequest.data])

  useEffect(() => {
    if (getDeviceRequest?.data?.data?.result) {
      if(getDeviceRequest.data.data.result?.assigned) {
        const { id, device, healthUnit, account } = getDeviceRequest.data.data.result?.assigned,
          _fieldValue = {
            isAssigned: id,
            device: device?.name,
            healthUnit: healthUnit?.id,
            account: account?.id
          };
        setFieldValue(_fieldValue);
      } else {
        const { type, deviceCode } = getDeviceRequest.data.data.result,
          _fieldValue = {
            isAssigned: 0,
            device: `${deviceCode} (${type?.name})`,
          };
        setFieldValue(_fieldValue)
      }
    }
  }, [getDeviceRequest.data])

  const changeOrganizationRequestFilters = (inputValue: any, callback: any) => {
    organizationCallbackRef.current = callback;
    setOrganizationFilterValues({
      ...organizationFilterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(organizationCallbackRef.current) {
      organizationsLoadOptions(organizationFilterValues.search, organizationCallbackRef.current);
    }
  }, [organizationFilterValues?.search]);

  const organizationsLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, organizationFilterValues, setOrganizationFilterValues, getOrganizationsRequest);
  };

  const changeAssignRequestFilters = (inputValue: any, callback: any) => {
    assignCallbackRef.current = callback;
    setAssignableAccountsFilterValues({
      ...assignableAccountsFilterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(assignCallbackRef.current) {
      assignableAccountsLoadOptions(assignableAccountsFilterValues.search, assignCallbackRef.current);
    }
  }, [assignableAccountsFilterValues?.search]);

  const assignableAccountsLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, assignableAccountsFilterValues, setAssignableAccountsFilterValues, getAssignableAccountsListRequest,
      (item) => ({ label: `${item?.firstName} ${item?.lastName}`, value: item?.id })
    );
  };

  const assignDeviceSubmit: SubmitHandler<TAssignDeviceField> = (data) => {
    const { healthUnit, account } = data,
      body = {
        device: {
          id: id,
          name: ""
        },
        healthUnit: {
          id: healthUnit,
          name: ""
        },
        account: {
          id: account,
          name: ""
        }
      };
    assignDeviceRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getAssignableAccountsListRequest?.isLoading ||
          getOrganizationsRequest?.isLoading ||
          getDeviceRequest?.isPending ||
          assignDeviceRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(assignDeviceSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">
                تخصیص دستگاه با کد
                <span className="fw-semibold font-en text-primary mx-2">{fieldValue?.device}</span>
              </h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="healthUnit">انتخاب مرکز</label>
                <AsyncSelectField
                  options={organizationsList}
                  name="healthUnit"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                  asyncLoadOptions={changeOrganizationRequestFilters}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="account">‌انتخاب اپراتور</label>
                <AsyncSelectField
                  options={assignableAccountsList}
                  name="account"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                  asyncLoadOptions={changeAssignRequestFilters}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                {
                  ((findAccessInAccessList('DEVICE_ASSIGNMENT_EDIT') && id) || (findAccessInAccessList('DEVICE_ASSIGNMENT_CREATE') && !id)) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    { isEditMode ? "اعمال تغییرات" : "تخصیص دستگاه" }
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssignDevice;
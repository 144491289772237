import React, {useMemo} from "react";
import { useEffect, useState } from "react";
import {connect, useSelector} from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../../../general/modal";
import Icon from "../../../../general/icon";
import SelectField from "../../../../general/form/selectField";
import DatePickerField from "../../../../general/form/datePickerField";
import TextField from "../../../../general/form/textField";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../../store/selector/general";
import {Dispatch} from "redux";
import {general} from "../../../../../../store/action";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import {useAddCigarette, useGetListCigaretteAll} from "../../../../../../hook/request/ehr/cigarette";
import {patientCigaretteSchema} from "../../../../../../schema/patient-schema";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

// کامپوننت مربوط مودال اتصال بیمه که پزشک می تواند با
// Parent:PatientSummary
// اطلاعات که وارد می کندمیزان مصرف سیگار اثبت کند

type IModalInfo = {
  frequency: string;
  startDate?: any;
  endDate?: any;
  description?: string;
}

type ModalInfoProps = {
  id: number;
  name: string;
  patientId: number;
  handleGetPatientSummary: any;
}

type AddPatientCigarettesModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}


const AddPatientCigarettesModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPatientCigarettesModalProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [showState, setShowState] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>();
  const { getQueryParams } = useMySearchParams();
  const [cigarettesList, setCigarettesList] = React.useState([]);
  const fieldValueInitialState = {
    frequency: "",
    startDate: null,
    endDate: null,
    description: "",
  }
  const [fieldValue, setFieldValue] = useState<IModalInfo>(fieldValueInitialState);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IModalInfo>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    // @ts-ignore
    resolver: yupResolver(patientCigaretteSchema),
  });

  // services
  const addCigarette = useAddCigarette({
    onSuccess: () => {
      handleSetShowModal();
      modalInfo?.handleGetPatientSummary({id: modalInfo?.patientId})
    },
  });
  const requestGetListCigarettes = useGetListCigaretteAll(getQueryParams({}), modalName === 'showAddCigarettes');

  useEffect(() => {
    if (requestGetListCigarettes?.data?.data.result) {
      const data = requestGetListCigarettes?.data?.data.result;
      const _data = data.map((item: any) => {
        return { label: item.name, value: item.id };
      });

      setCigarettesList(_data);
    }
  }, [requestGetListCigarettes?.data?.data.result]);

  useEffect(() => {
    if (modalName === "showAddCigarettes" && { isShowModalData }) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalName, modalData]);

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  const handleSubmitCigarettes: SubmitHandler<IModalInfo> = (data) => {
    const { frequency, description, startDate, endDate } = data,
      body = {
      id: 0,
      patient: {
        id: modalInfo?.patientId,
      },
      frequency: {
        id: frequency,
      },
      startDate, endDate, description
    };
    addCigarette.mutate(body);
  };

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
    setFieldValue(fieldValueInitialState)
  };

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000  mw-100"
      bodyClassName="py-3 px-3 px-lg-5 pos-rel"
    >
      {/* loading */}
      {/* {providerInsurer?.isPending && <BoxLoader type="cover" />} */}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className="d-flex mb-4 fs-3 px-2">
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {/* اتصال {modalInfo?.name} */}
              فرم ثبت سوابق مصرف سیگار
            </h4>
          </div>
          <span
            className="text-red-hover align-content-center mt-2"
            onClick={() => handleSetShowModal()}
          >
            <Icon name="close-square" />
          </span>
        </div>
        <form onSubmit={handleSubmit(handleSubmitCigarettes)}>
          <div className="row">
            <div className="col-12 col-md-6 px-3">
              <div className="form-group mb-3">
                <SelectField
                  name="frequency"
                  placeholder="میزان مصرف سیگار"
                  control={control}
                  error={errors}
                  options={cigarettesList}
                />
              </div>
            </div>
            <div className="col-12 col-md-3 px-3">
              <div className="form-group mb-3">
                <DatePickerField
                  name="startDate"
                  placeholder="زمان شروع مصرف"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-3 px-3">
              <div className="form-group mb-3">
                <DatePickerField
                  name="endDate"
                  placeholder="زمان پایان مصرف(اختیاری)"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 px-3">
              <div className="form-group mb-3">
                <TextField
                  name="description"
                  placeholder="ثبت توضیحات (اختیاری)"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-3 d-flex justify-content-end">
              <div className="d-flex form-group mb-0 mr-auto mt-auto pb-1">
                {
                  (isProvider || findAccessInAccessList('EHR_SUBSTANCE_CIGARETTE_CREATE')) &&
                  <button
                    type="submit"
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-5 py-2"
                  >
                    افزودن
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

// get states
const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

// call actions
const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) =>
    dispatch(general.handleShowModal(data, body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPatientCigarettesModal);

import {getCellValue} from "../../../../utils/utils";
import {MtoJDateFormat, MtoJHHMMFormat} from "../../../../utils/date";
import Icon from "../../general/icon";
import {general} from "../../../../store/action";
import {connect} from "react-redux";
import React from "react";
import Tooltip from "../../general/tooltip";
import {generatePath, useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../constants";
import {PRESCRIPTION_STATUS_ENUM} from "../../../../constants/constant/enum";
import {usePrescriptionInsurerSave} from "../../../../hook/request/visit/prescription";

type ExaminationTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const ExaminationTableRowItem = ({
 item,
 handleShowModal
}: ExaminationTableRowItemProps) => {

  const navigate = useNavigate();

  const prescriptionInsurerSaveRequest = usePrescriptionInsurerSave()

  const handleResendPrescriptionToInsurer = () => {
    prescriptionInsurerSaveRequest.mutate({id: item?.insurerStatus?.prescriptionId})
  }

  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-yellow1 text-yellow'
      case 300:
        return 'bg-light-green text-green2'
      case 400:
        return 'bg-primary-subtle text-primary'
      default:
        return 'bg-light-red text-red'
    }
  }

  const determinePrescriptionBgColor = () => {
    switch (item?.insurerStatus?.status?.id) {
      case PRESCRIPTION_STATUS_ENUM.ADD_OK.value:
        return 'bg-light-green text-green2'
      case PRESCRIPTION_STATUS_ENUM.ADD_ERROR.value:
        return 'bg-light-red text-red'
      case PRESCRIPTION_STATUS_ENUM.EDIT_OK.value:
        return 'bg-light-green text-green2'
      case PRESCRIPTION_STATUS_ENUM.EDIT_ERROR.value:
        return 'bg-light-red text-red'
    }
  }

  return (
    <tr>
      <td>{getCellValue(item?.patient?.firstName)} {getCellValue(item?.patient?.lastName)}</td>
      <td>{getCellValue(item?.device?.name)}</td>
      <td>{getCellValue(MtoJDateFormat(item?.startTime))}</td>
      <td>{getCellValue(MtoJHHMMFormat(item?.startTime))}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(item?.type?.name)}</bdi>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip alt={"مشاهده نمونه"} className="tooltip-pre-line">
            <span
              onClick={() =>
                navigate(generatePath(ROUTES.EXAMINATION_DETAIL.PATH, {id: item?.id}))
              }
              className={`d-flex mx-2 font-20 cur-pointer text-hover`}
            >
              <Icon name='eye'/>
            </span>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ExaminationTableRowItem);

import React, {useEffect, useMemo, useState} from "react";
import {connect, useSelector} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import Form from 'react-bootstrap/Form';
import Modal from "../general/modal";
import BoxLoader from "../general/boxLoader";
import Icon from "../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import {TAddVisitFields} from "../../../types/visit";
import {useVisitAdmission} from "../../../hook/request/visit/visits";
import TextField from "../general/form/textField";
import {useAddPatient, usePatientAdmission} from "../../../hook/request/patients";
import {TPatientFields} from "../../../types/patient";
import {Collapse} from "react-bootstrap";
import SelectField from "../general/form/selectField";
import {
  GENDER_ENUM,
  ISSUER_TYPE_ENUM,
  MARITAL_STATUS
} from "../../../constants/constant/enum";
import DatePickerField from "../general/form/datePickerField";
import Image from "../general/image";
import {getAge, getCellValue, toEnDigit} from "../../../utils/utils";
import EmptyAvatar from "../../../assets/images/svg/profile-empty-avatar.svg"
import AlertBox from "../general/alert";
import EmptyForm from "../../../assets/images/svgComponents/EmptyForm";
import Tooltip from "../general/tooltip";
import {yupResolver} from "@hookform/resolvers/yup";
import {addPatientDetailsSchema, checkPatientNationalCode} from "../../../schema/visit-schema";
import NumberField from "../general/form/numberField";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";



type AddVisitModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  id: string,
  patientId: string,
  patientData?: any,
  nationalCode?: number
}


const AddVisitModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddVisitModalProps) => {
  const [showState, setShowState] = useState(false);
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    patientId: '',
    patientData: '',
  });
  const collapseStateInitialState = {
    patientAdmission: true,
    patientDetail: false
  }
  const [collapseState, setCollapseState] = useState(collapseStateInitialState)
  const fieldValueInitialState = {
    nationalCode: "",
    referenceNo: "",
  }
  const [fieldValue, setFieldValue] = useState<TAddVisitFields>(fieldValueInitialState);
  const [visitCode, setVisitCode] = useState('nationalCode')
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TAddVisitFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(checkPatientNationalCode),
  });
  const [patientFieldValue, setPatientFieldValue] = useState<TPatientFields>()
  const {
    control: patientControl,
    handleSubmit: handlePatientSubmit,
    reset: resetPatient,
    formState: { errors: patientErrors },
  } = useForm<TPatientFields>({
    defaultValues: useMemo(() => patientFieldValue, [patientFieldValue]),
    resolver: yupResolver(addPatientDetailsSchema),
  });

  // services
  const patientAdmissionRequest = usePatientAdmission();
  const visitAdmissionRequest = useVisitAdmission();
  const addPatientRequest = useAddPatient();

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addVisitModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      setFieldValue({...fieldValue, nationalCode: modalData?.nationalCode})
      handleResetModal();
      var data = {
        id: modalData?.id
      }
    }
  }, [modalData, modalName])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    resetPatient(patientFieldValue);
  }, [patientFieldValue]);

  useEffect(() => {
    if(patientAdmissionRequest?.data?.data?.result){
      setPatientFieldValue(patientAdmissionRequest?.data?.data.result.patient)
    }
  }, [patientAdmissionRequest.data])

  useEffect(() => {
    if(addPatientRequest?.data?.data?.result){
      setPatientFieldValue(addPatientRequest?.data?.data.result)
    }
  }, [addPatientRequest.data])

  useEffect(() => {
    if (patientFieldValue && patientFieldValue?.id !== 0) {
      visitAdmissionRequest.mutate({id: patientFieldValue?.id})
    }
    else if (patientFieldValue && patientFieldValue?.id === 0){
      setCollapseState({patientAdmission: false, patientDetail: true})
    }
  }, [patientFieldValue]);

  const patientAdmission: SubmitHandler<TAddVisitFields> = (data) => {
    const { nationalCode, referenceNo } = data,
      body = {
        id: modalInfo?.id ? modalInfo?.id : 0,
        name: nationalCode ? toEnDigit(nationalCode) : '',
        referenceNo: referenceNo? toEnDigit(referenceNo) : ''
      };
    setFieldValue({
      nationalCode: nationalCode ? toEnDigit(nationalCode) : '',
      referenceNo: referenceNo ? toEnDigit(referenceNo) : ''
    })
    patientAdmissionRequest.mutate(body);
  }

  const visitAdmission: SubmitHandler<TPatientFields> = (data) => {
    console.log(data)
    const { firstName, lastName, gender, mobile, birthDate} = data,
      body = {
        nationalCode: fieldValue?.nationalCode,
        firstName, lastName, birthDate, gender,
        mobile: mobile ? toEnDigit(mobile) : ''
      };
    addPatientRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue(fieldValueInitialState)
    setVisitCode('nationalCode')
    setCollapseState(collapseStateInitialState);
    handleHideModal();
  }

  const visitCodeChange = (e:any) => {
    setVisitCode(e.target.id)
    setFieldValue(fieldValueInitialState)
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {patientAdmissionRequest?.isPending && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              فرم پذیرش بیمار
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.firstName + ' ' + modalInfo?.patientData?.lastName}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <Collapse in={collapseState?.patientAdmission}>
          <form onSubmit={handleSubmit(patientAdmission)}>
            <div className="row">
              <div className="col-12 col-md-6 px-4">
                <div className="form-group">
                  <Form.Check
                    inline
                    reverse
                    id='nationalCode'
                    label="پذیرش مستقیم"
                    type={"radio"}
                    name="visitCode"
                    className='font-10 fw-bold'
                    checked={visitCode == 'nationalCode'}
                    onChange={visitCodeChange}
                  />
                  <Form.Check
                    inline
                    reverse
                    id='referenceNo'
                    label="پذیرش از مسیر ارجاع (کد ارجاع بیمه سلامت)"
                    type={"radio"}
                    checked={visitCode == 'referenceNo'}
                    name="visitCode"
                    className='font-10 fw-bold'
                    onChange={visitCodeChange}
                  />
                  {
                    visitCode == "nationalCode" ?
                      <NumberField
                        className='font-en'
                        name="nationalCode"
                        placeholder="کد ملی"
                        control={control}
                        error={errors}
                        allowLeadingZeroes
                        isNumericString
                        maxLength={10}
                      />
                      :
                      <TextField
                        name="referenceNo"
                        placeholder="کد ارجاع"
                        control={control}
                        error={errors}
                      />
                  }
                </div>
              </div>
              <div className="col-12 col-md-6 px-4 d-flex justify-content-end">
                <div className="d-flex form-group mr-auto mt-auto">
                  {
                    (isProvider || findAccessInAccessList('VISIT_VIEW')) &&
                    <button
                      type='submit'
                      className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                    >
                      بررسی و پذیرش
                    </button>
                  }
                </div>
              </div>
            </div>
          </form>
        </Collapse>
        <Collapse in={collapseState?.patientDetail}>
          <form onSubmit={handlePatientSubmit(visitAdmission)}>
            <div className="row">
              <div className="row col-12 col-md-4 rounded border border-4 px-4 me-4 mb-2"
                   style={{backgroundColor: '#FDFDFD'}}>
                <div className='d-flex justify-content-center mt-4'>
                  <div className='align-content-center align-items-center text-center mx-2'>
                    <Image
                      className='rounded rounded-5 mb-2 w-25'
                      src={EmptyAvatar}
                    />
                    <div className='rounded-3 border border-2 bg-white fw-medium font-12 text-nowrap mb-2 px-2 py-1'>
                      {getCellValue(patientFieldValue?.firstName)} {getCellValue(patientFieldValue?.lastName)}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="w-100 mx-1 mb-1">
                    <div
                      className='d-flex justify-content-center border border-2 rounded-3 bg-white font-10 text-nowrap py-2'>
                      <span>کد ملی:</span>
                      <span className='me-1 fw-semibold'>{getCellValue(patientFieldValue?.nationalCode)}</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="w-50 mx-1">
                    <div
                      className='d-flex justify-content-center border border-2 rounded-3 bh-white font-10 text-nowrap py-2'>
                      <span>جنسیت:</span>
                      <span className='me-1 fw-semibold'>{getCellValue(patientFieldValue?.gender?.name)}</span>
                    </div>
                  </div>
                  <div className="w-50 mx-1 mb-1">
                    <div
                      className='d-flex justify-content-center border border-2 rounded-3 bg-white font-10 text-nowrap py-2'>
                      <span>سن:</span>
                      <span className='me-1 fw-semibold'>{getCellValue(getAge(patientFieldValue?.birthDate))} سال</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="w-100 mx-1 mb-1">
                    <div
                      className='d-flex justify-content-center border border-2 rounded-3 bg-white font-10 text-nowrap py-2'>
                      <span>شماره موبایل:</span>
                      <span className='me-1 fw-semibold'>{getCellValue(patientFieldValue?.mobile)}</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 form-group">
                  <div className="w-100">
                    <button
                      type='button'
                      className="btn btn-light-blue justify-content-center align-items-center w-100 rounded-3 text-primary text-center px-4 py-1 mx-1"
                      onClick={() => setCollapseState({patientAdmission: true, patientDetail: false})}
                    >
                      ویرایش کد ملی
                    </button>
                  </div>
                </div>
              </div>
              <div className="row col-12 col-md-8 px-4">
                <div className={`col-12 ${patientFieldValue?.firstName ? 'd-none' : ''}`}>
                  <AlertBox
                    type='warning'
                    icon='info-circle'
                    text={'برای استحقاق سنجی بیماران تحت پوشش بیمه سلامت، تکمیل اطلاعات حساب کاربری بیمه سلامت شما الزامی است ؛ در غیر این صورت بیمار فاقد پوشش بيمه اي فعال است و تمام هزينه ها براي ايشان به صورت آزاد محاسبه خواهد شد . برای ادامه فرآیند، ابتدا اطلاعات زیر را کامل کنید.'}
                    className='border-2 border-orange mb-4'
                  />
                </div>
                <div className={`col-12 col-md-4 ${patientFieldValue?.firstName ? 'd-none' : ''}`}>
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="firstName">نام</label>
                    <TextField
                      name="firstName"
                      placeholder="نام"
                      control={patientControl}
                      error={patientErrors}
                    />
                  </div>
                </div>
                <div className={`col-12 col-md-4 ${patientFieldValue?.firstName ? 'd-none' : ''}`}>
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="lastName">نام خانوادگی</label>
                    <TextField
                      name="lastName"
                      placeholder="نام خانوادگی"
                      control={patientControl}
                      error={patientErrors}
                    />
                  </div>
                </div>
                <div className={`col-12 col-md-4 ${patientFieldValue?.firstName ? 'd-none' : ''}`}>
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="gender">جنسیت</label>
                    <SelectField
                      options={GENDER_ENUM}
                      name="gender"
                      placeholder='انتخاب کنید'
                      className="font-en"
                      control={patientControl}
                      error={patientErrors}
                      returnIdNameData={true}
                    />
                  </div>
                </div>
                <div className={`col-12 col-md-4 ${patientFieldValue?.firstName ? 'd-none' : ''}`}>
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="maritalStatus">وضعیت تاهل</label>
                    <SelectField
                      options={MARITAL_STATUS}
                      name="maritalStatus"
                      placeholder='انتخاب کنید'
                      className="font-en"
                      control={patientControl}
                      error={patientErrors}
                    />
                  </div>
                </div>
                <div className={`${patientFieldValue?.firstName ? 'col-12 col-md-6' : 'col-12 col-md-4'}`}>
                  <div className="form-group mb-3">
                    <label className="d-flex inp-lbl me-2 mb-2" htmlFor="mobile">
                      <Tooltip alt={'از این شماره موبایل برای فرستادن لینک نسخه به بیمار استفاده می شود'} className="tooltip-pre-line">
                      <span className='d-flex align-items-center text-yellow font-18 ms-2'>
                        <Icon name='info-circle'/>
                      </span>
                      </Tooltip>
                      شماره تلفن
                    </label>
                    <NumberField
                      name="mobile"
                      placeholder="شماره تلفن"
                      control={patientControl}
                      error={patientErrors}
                      allowLeadingZeroes
                      isNumericString
                      maxLength={11}
                    />
                  </div>
                </div>
                <div className={`${patientFieldValue?.firstName ? 'col-12 col-md-6' : 'col-12 col-md-4'}`}>
                  <div className="form-group mb-2">
                    <label className="inp-lbl me-2 mb-2" htmlFor="birthDate">تاریخ تولد</label>
                    <DatePickerField
                      name="birthDate"
                      placeholder="تاریخ تولد"
                      control={patientControl}
                      error={patientErrors}
                    />
                  </div>
                </div>
                <div className={`col-12 col-md-4 ${patientFieldValue?.firstName ? 'd-none' : ''}`}>
                  <div className="form-group mb-3">
                    <label className="inp-lbl me-2 mb-2" htmlFor="issuerType">نوع بیمه</label>
                    <SelectField
                      options={ISSUER_TYPE_ENUM}
                      name="issuerType"
                      placeholder='انتخاب کنید'
                      className="font-en"
                      // returnObjectValue={true}
                      control={patientControl}
                      error={patientErrors}
                    />
                  </div>
                </div>
                <div className={`${patientFieldValue?.firstName ? 'd-flex justify-content-center' : 'd-none'}`}>
                  <EmptyForm/>
                </div>
                <div className="col-12 px-4 d-flex justify-content-end">
                  <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
                    <button
                      type='button'
                      className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                      onClick={() => handleSetShowModal()}
                    >
                      انصراف
                    </button>
                    {
                      (isProvider || findAccessInAccessList('VISIT_VIEW')) &&
                      <button
                        type='submit'
                        className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                      >
                        شروع ویزیت
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Collapse>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVisitModal);
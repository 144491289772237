import React from "react";
import {connect, useSelector} from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import { MtoJFullDateFormat } from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {
  useFindAccessInAccessList,
  HDAccessProcessConstant,
} from "../../../../constants/constant/accessProcess";
const UserTableRowItem = ({
  item,
  handleShowModal
}) => {
  const navigate = useNavigate();

  const {findAccessInAccessList} = useFindAccessInAccessList();

  return(
    <tr>
      <td>{getCellValue(item?.firstName)} {getCellValue(item?.lastName)}</td>
      <td>{getCellValue(item?.nationalCode)}</td>
      <td>{getCellValue(item?.mobile)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
      </td>
      <td>
        {item?.banned ?
          <div>
            <span className='bg-light-red text-red px-3 py-1 rounded text-nowrap'>
              غیر فعال
            </span>
          </div>
          :
          <div>
            <span className='px-3 py-1 text-green2 rounded text-nowrap' style={{backgroundColor: '#E6FAF2'}}>
              فعال
            </span>
          </div>
        }
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {
            findAccessInAccessList('OPERATOR_VIEW_ACCESS') &&
            <Tooltip alt={'سطح دسترسی'} className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.USER_ACCESS.PATH, {id: item?.id, accountId: item?.accountId}))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='move'/>
              </span>
            </Tooltip>
          }
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {
            findAccessInAccessList('OPERATOR_CHANGE_BLOCK') &&
            <Tooltip alt={item?.banned ? 'فعال سازی حساب کاربری' : 'غیر فعال سازی حساب کاربری'} className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('blockingUserModal', {accountId: item?.accountId, banned: item?.banned, queryKey: "getUsers"})}
                className={`d-flex mx-2 font-20 cur-pointer ${item?.banned ? 'text-hover' : 'text-red-hover'}`}
              >
                <Icon name={item?.banned ? 'check-square' : 'close-square'}/>
              </span>
            </Tooltip>
          }
          {
            findAccessInAccessList('OPERATOR_CHANGE_PASSWORD') &&
            <Tooltip alt={'تغییر کلمه عبور'} className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('changeAccountPasswordModal', {firstName: item?.firstName, lastName: item?.lastName, accountId: item?.accountId})}
                className="d-flex text-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='key'/>
              </span>
            </Tooltip>
          }
          {
            findAccessInAccessList('OPERATOR_VIEW') &&
            <Tooltip alt={'ویرایش'} className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.USER_EDIT.PATH, {id: item?.id}))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='pen-square'/>
              </span>
            </Tooltip>
          }

          {
            findAccessInAccessList('OPERATOR_DELETE') &&
            <Tooltip alt={'حذف'} className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deleteUserModal', item?.id)}
                className="d-flex text-red-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='trash'/>
              </span>
            </Tooltip>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(UserTableRowItem);

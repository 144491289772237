import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../../utils/date";

type patientVaccineSummaryProps = {
  item: any;
}

const PatientVaccineSummary_rowItem = ({
  item
}: patientVaccineSummaryProps) => {

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div>
          <span className="text-gray">نام واکسن</span>
          <span className="me-3">{getCellValue(item?.vaccine?.name)}</span>
        </div>
        <div>
          <span className="text-gray">تاریخ واکسیناسیون</span>
          <span className="me-3">{getCellValue(MtoJDateFormat(item?.vaccinationDateTime))}</span>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <div>
          <span className="text-gray">تولید کننده</span>
          <span className="me-3">{getCellValue(item?.manufacturer)}</span>
        </div>
        <div>
          <span className="text-gray">تاریخ انقضا</span>
          <span className="me-3">{getCellValue(MtoJDateFormat(item?.expirationDate))}</span>
        </div>
      </div>
    </>
  );
};

export default PatientVaccineSummary_rowItem;

import React, { useEffect, useState } from 'react';
import defImage from "../../../assets/images/image/def-image.png";

type ImageProps = {
  src: string;
  defSrc?: string;
  className?: string;
  alt?: string;
}

const Image = ({
  src,
  defSrc,
  className,
  alt
}: ImageProps) => {
  const [error, setError] = useState(false);
  const [imgSrc, setImgSrc] = useState<any>(src);

  useEffect(() => {
    setImgSrc(src || defSrc || defImage);
  }, [src, defSrc])
  
  const onError = () => {
    if (!error) {
      setImgSrc(defSrc || defImage);
      setError(true)
    }
  }

  return <img src={imgSrc} className={className} alt={alt} onError={onError} />
}

export default Image;
import * as React from "react"
import { SVGProps } from "react"
const Chart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={23}
    height={23}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5763_7083)">
      <path
        d="M15.2078 11.7696L13.5322 14.8018L13.074 13.5126C12.9438 13.1442 12.5979 12.9016 12.2115 12.9016H10.801L9.50274 8.51275C9.26914 7.7356 8.20898 7.60083 7.7957 8.37349L6.05723 11.9627H4.67812C4.17051 11.9627 3.76172 12.3715 3.76172 12.8792C3.76172 13.3868 4.17051 13.7956 4.67812 13.7956H6.63223C6.98262 13.7956 7.30606 13.5934 7.45879 13.279L8.41563 11.3024L9.2377 14.0786C9.35449 14.4694 9.70938 14.7344 10.1182 14.7344H11.5646L12.4855 17.3309C12.6967 17.9374 13.6311 18.2698 14.1521 17.4702L16.5555 13.1307H18.3254C18.833 13.1307 19.2418 12.7219 19.2418 12.2143C19.2418 11.7067 18.833 11.2979 18.3254 11.2979H16.0164C15.675 11.2934 15.3695 11.4776 15.2078 11.7696Z"
        fill="#3699FF"
      />
      <path
        d="M21.5875 3.28828H17.9758V1.41055C17.9758 0.90293 17.567 0.494141 17.0594 0.494141C16.5518 0.494141 16.143 0.90293 16.143 1.41055V3.28828H6.85313V1.41055C6.85313 0.90293 6.44434 0.494141 5.93672 0.494141C5.4291 0.494141 5.02031 0.90293 5.02031 1.41055V3.28828H1.40859C0.900977 3.28828 0.492188 3.69707 0.492188 4.20469V21.5895C0.492188 22.0971 0.900977 22.5059 1.40859 22.5059H21.5875C22.0951 22.5059 22.5039 22.0971 22.5039 21.5895V4.20469C22.5039 3.69707 22.0951 3.28828 21.5875 3.28828ZM20.6711 20.673H2.325V5.12109H5.01582V6.54063C5.01582 7.04824 5.42461 7.45703 5.93223 7.45703C6.43984 7.45703 6.84863 7.04824 6.84863 6.54063V5.12109H16.1385V6.54063C16.1385 7.04824 16.5473 7.45703 17.0549 7.45703C17.5625 7.45703 17.9713 7.04824 17.9713 6.54063V5.12109H20.6621V20.673H20.6711Z"
        fill="#97ABFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_5763_7083">
        <rect width={23} height={23} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default Chart

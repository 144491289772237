import BoxLoader from "../../general/boxLoader";
import EmptyBox from "../../general/emptyBox";
import React from "react";
import DeviceAssignedCardsBoxItem from "./deviceAssignedCardsBox_Item";

type DeviceAssignedCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const DeviceAssignedCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting
}: DeviceAssignedCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <DeviceAssignedCardsBoxItem item={item} dataListTitles={dataListTitles} key={i}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default DeviceAssignedCardsBox;
import * as React from "react"
import { SVGProps } from "react"
const MedicationItemsAnimated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="animated"
    id="freepik_stories-medicine"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 500 500"
    {...props}
  >
    <style>
      {
        "svg#freepik_stories-medicine:not(.animated) .animable {opacity: 0;}svg#freepik_stories-medicine.animated #freepik--Floor--inject-8 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) fadeIn;animation-delay: 0s;}svg#freepik_stories-medicine.animated #freepik--Blister--inject-8 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}svg#freepik_stories-medicine.animated #freepik--pillls-bottle-1--inject-8 {animation: 3s Infinite  linear floating;animation-delay: 0s;}svg#freepik_stories-medicine.animated #freepik--pillls-bottle-2--inject-8 {animation: 1.5s Infinite  linear wind;animation-delay: 0s;}svg#freepik_stories-medicine.animated #freepik--Character--inject-8 {animation: 2.3s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideUp;animation-delay: 0s;}            @keyframes fadeIn {                0% {                    opacity: 0;                }                100% {                    opacity: 1;                }            }                    @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }                    @keyframes wind {                0% {                    transform: rotate( 0deg );                }                25% {                    transform: rotate( 1deg );                }                75% {                    transform: rotate( -1deg );                }            }                    @keyframes slideUp {                0% {                    opacity: 0;                    transform: translateY(30px);                }                100% {                    opacity: 1;                    transform: inherit;                }            }"
      }
    </style>
    <g
      id="freepik--Floor--inject-8"
      style={{
        transformOrigin: "250px 345.6px 0px",
      }}
      className="animable"
    >
      <ellipse
        id="freepik--floor--inject-8"
        cx={250}
        cy={345.6}
        rx={223.87}
        ry={137}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "250px 345.6px 0px",
        }}
        className="animable"
      />
    </g>
    <g
      id="freepik--Shadows--inject-8"
      style={{
        transformOrigin: "249.278px 362.028px 0px",
      }}
      className="animable"
    >
      <path
        id="freepik--Shadow--inject-8"
        d="M433.07,365.35l7.86-4.54a1.32,1.32,0,0,0,0-2.5l-81-46.75a4.79,4.79,0,0,0-4.33,0l-7.86,4.54a1.32,1.32,0,0,0,0,2.5l81,46.75A4.79,4.79,0,0,0,433.07,365.35Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "394.335px 338.455px 0px",
        }}
        className="animable"
      />
      <ellipse
        id="freepik--shadow--inject-8"
        cx={154.69}
        cy={362.02}
        rx={56.87}
        ry={32.83}
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "154.69px 362.02px 0px",
        }}
        className="animable"
      />
      <ellipse
        id="freepik--shadow--inject-8"
        cx={86.05}
        cy={371.67}
        rx={29.32}
        ry={16.93}
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "86.05px 371.67px 0px",
        }}
        className="animable"
      />
      <ellipse
        id="freepik--shadow--inject-8"
        cx={274.03}
        cy={325.25}
        rx={75.84}
        ry={43.78}
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "274.03px 325.25px 0px",
        }}
        className="animable"
      />
      <ellipse
        id="freepik--shadow--inject-8"
        cx={345.11}
        cy={393.61}
        rx={41.09}
        ry={23.72}
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "345.11px 393.61px 0px",
        }}
        className="animable"
      />
      <path
        id="freepik--shadow--inject-8"
        d="M241.8,416.09c-8-4.63-21-4.63-29.06,0-3.17,1.84-5.07,4.12-5.73,6.49-7.67,4.65-7.56,12,.34,16.56s21,4.63,29.05,0c3.18-1.83,5.07-4.11,5.74-6.49C249.8,428,249.7,420.66,241.8,416.09Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "224.573px 427.602px 0px",
        }}
        className="animable"
      />
      <path
        id="freepik--shadow--inject-8"
        d="M304.51,426.73l-7.18-4.14-9.89-5.71a15.11,15.11,0,0,0-13.69,0c-3.77,2.18-3.75,5.72,0,7.9l9.91,5.7,7.17,4.15a15.11,15.11,0,0,0,13.69,0C308.32,432.44,308.3,428.9,304.51,426.73Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "289.146px 425.755px 0px",
        }}
        className="animable"
      />
      <path
        id="freepik--shadow--inject-8"
        d="M393.73,400.06l7.19-4.14,9.89-5.72a15.18,15.18,0,0,1,13.69,0c3.77,2.19,3.75,5.73,0,7.91l-9.91,5.7L407.39,408a15.11,15.11,0,0,1-13.69,0C389.93,405.77,389.94,402.23,393.73,400.06Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "409.1px 399.104px 0px",
        }}
        className="animable"
      />
    </g>
    <g
      id="freepik--Clouds--inject-8"
      style={{
        transformOrigin: "391.93px 82.4658px 0px",
      }}
      className="animable"
    >
      <g
        id="freepik--clouds--inject-8"
        style={{
          transformOrigin: "391.93px 82.4658px 0px",
        }}
        className="animable"
      >
        <path
          d="M349,83.75l6.55,3.78a8.46,8.46,0,0,1-.05-.87V84.77c0-3.38,2.37-4.75,5.3-3.06a10.28,10.28,0,0,1,3.15,3c.48-4.24,3.81-5.83,7.86-3.49A17.51,17.51,0,0,1,379.76,95v2.84a7.46,7.46,0,0,1-.67,3.29l5.45,3.14a6.47,6.47,0,0,1,2.93,5.09c0,1.87-1.31,2.63-2.93,1.69L349,90.53a6.48,6.48,0,0,1-2.94-5.08C346.09,83.57,347.41,82.81,349,83.75Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "366.765px 95.7959px 0px",
          }}
          id="elendh17y6d3i"
          className="animable"
        />
        <path
          d="M392.34,66.35,396,67.74v-8c0-6.31,5-7.82,10.5-4.67h0c5.46,3.16,9.89,10.84,9.89,17.15V73.4a5.34,5.34,0,0,1,4.6.71h0a15.76,15.76,0,0,1,7.12,12.34V87l5,2.91A10.37,10.37,0,0,1,437.8,98c0,3-2.1,4.2-4.69,2.7L392.34,77.18a10.39,10.39,0,0,1-4.69-8.13C387.65,66.06,389.75,64.85,392.34,66.35Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "412.725px 77.4121px 0px",
          }}
          id="elddi6r0j6p5r"
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Plants--inject-8"
      style={{
        transformOrigin: "250.908px 327.852px 0px",
      }}
      className="animable"
    >
      <g
        id="freepik--plants--inject-8"
        style={{
          transformOrigin: "455.951px 321.159px 0px",
        }}
        className="animable"
      >
        <path
          d="M438.54,345c-3-16.45,1.76-33.46,7.31-42.95s15.31-17.26,23.27-15.78c7.42,1.38,8,10.76.34,18.15s-21.21,21.54-24.9,31.2c-2.46,6.43-3.86,10.63-3.86,10.63Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "456.281px 316.169px 0px",
          }}
          id="elo7n74eft3pk"
          className="animable"
        />
        <g id="eltihw670wt0c">
          <path
            d="M438.54,345c-3-16.45,1.76-33.46,7.31-42.95s15.31-17.26,23.27-15.78c7.42,1.38,8,10.76.34,18.15s-21.21,21.54-24.9,31.2c-2.46,6.43-3.86,10.63-3.86,10.63Z"
            style={{
              opacity: 0.15,
              transformOrigin: "456.281px 316.169px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M439.34,341.3a.49.49,0,0,0,.38-.45c1.11-23,16.52-43.77,26.74-49.37a.48.48,0,0,0-.46-.84c-10.42,5.7-26.12,26.84-27.25,50.16a.49.49,0,0,0,.46.51Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "452.715px 315.953px 0px",
          }}
          id="el3brn6tzrq69"
          className="animable"
        />
        <path
          d="M438.86,356.23c.51-3.83,5-8.27,11.51-14.35,7.23-6.72,17-10.76,18.74-15.42,2.05-5.6-3-9.86-11.14-7.62s-21,15.62-20.81,32.92Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "453.362px 337.231px 0px",
          }}
          id="elkfjin43409o"
          className="animable"
        />
        <path
          d="M437.56,354.89a.48.48,0,0,0,.37-.38c4-20.13,17.66-29,24.29-30.85a.48.48,0,0,0-.26-.93c-7.34,2.06-20.85,11-25,31.59a.49.49,0,0,0,.38.57A.45.45,0,0,0,437.56,354.89Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "449.762px 338.808px 0px",
          }}
          id="elzl5w8nf3wjb"
          className="animable"
        />
      </g>
      <g
        id="freepik--plants--inject-8"
        style={{
          transformOrigin: "53.9779px 330.138px 0px",
        }}
        className="animable"
      >
        <path
          d="M72.28,334.06s-1.64-14.07-8.67-25S46.93,291.39,38,290.7s-16.2,7.6-6.58,13.24S58.07,315.33,66,336.52Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "49.5729px 313.59px 0px",
          }}
          id="el2dmz56ndxvi"
          className="animable"
        />
        <g id="elg8l9ihnyefe">
          <path
            d="M72.28,334.06s-1.64-14.07-8.67-25S46.93,291.39,38,290.7s-16.2,7.6-6.58,13.24S58.07,315.33,66,336.52Z"
            style={{
              opacity: 0.15,
              transformOrigin: "49.5729px 313.59px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M69.64,330.41l.09,0a.48.48,0,0,0,.27-.63c-9.54-23.3-27.61-33-35.83-34.86a.48.48,0,1,0-.21.93c8,1.83,25.76,11.36,35.15,34.29A.47.47,0,0,0,69.64,330.41Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "51.7831px 312.657px 0px",
          }}
          id="elow134u4sga"
          className="animable"
        />
        <path
          d="M58.36,363A10.07,10.07,0,0,0,68,369.61a6.23,6.23,0,0,0,4.36-1.53c2.16-2.08,1.82-5.57,2.89-8.37,1.44-3.77,5.3-6.29,5.79-10.46a6.36,6.36,0,0,0-1.36-4.79,18.09,18.09,0,0,0-1.88-1.73c-.73-.65-1.54-1.2-2.3-1.8a7.65,7.65,0,0,1-2.54-3c-.71-1.73-.32-3.68-.53-5.53A8.54,8.54,0,0,0,65.17,325a39.36,39.36,0,0,0-5.64-.46c-2.8,0-5.73,1.09-8.52.49s-5.27-1.76-8.1-2a9.91,9.91,0,0,0-8.23,2.69,5.25,5.25,0,0,0-1.51,4.7c.47,1.83,2.19,3,3.8,4s3.37,2.05,4,3.82c1.34,3.57-2.52,7.63-1,11.14a5.94,5.94,0,0,0,3.6,3c1.25.45,2.56.67,3.85,1a17.68,17.68,0,0,0,1.89.17,8.59,8.59,0,0,1,5.29,1.47C57,356.81,57.21,360.21,58.36,363Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "57.0883px 346.277px 0px",
          }}
          id="el8k2z35qsmju"
          className="animable"
        />
        <path
          d="M65.42,340.37a.46.46,0,0,0-.15-.16c-9.19-9.6-21.84-12.92-26-13.22a.44.44,0,0,0-.46.4.43.43,0,0,0,.4.45c3.93.28,15.65,3.33,24.56,12.06a23.4,23.4,0,0,0-18.23,4.4.42.42,0,0,0,0,.6.43.43,0,0,0,.37.15.45.45,0,0,0,.23-.11,22.64,22.64,0,0,1,18.72-4,35.59,35.59,0,0,1,9.56,20.73.43.43,0,0,0,.44.37h0a.43.43,0,0,0,.38-.47A36.39,36.39,0,0,0,65.42,340.37Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "57.0258px 344.515px 0px",
          }}
          id="el50qspytfii5"
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Blister--inject-8"
      style={{
        transformOrigin: "395.32px 255.96px 0px",
      }}
      className="animable"
    >
      <path
        d="M439.22,354V204.85a15.34,15.34,0,0,0-6.93-12L361.62,152c-3.83-2.21-6.94-.42-6.94,4V305.23a15.33,15.33,0,0,0,6.94,12L432.29,358C436.12,360.25,439.22,358.46,439.22,354Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "396.95px 255.012px 0px",
        }}
        id="elnn2borzvnu"
        className="animable"
      />
      <path
        d="M436,355.92V206.73a15.3,15.3,0,0,0-6.93-12l-70.67-40.81c-3.83-2.21-6.94-.42-6.94,4V307.11a15.33,15.33,0,0,0,6.94,12L429,359.92C432.87,362.13,436,360.34,436,355.92Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "393.73px 256.92px 0px",
        }}
        id="elc0mtarkwwva"
        className="animable"
      />
      <path
        d="M429,194.73l-63.73-36.8h0c18.93,12.23,57,34.25,61.76,38s7.22,6.2,7.22,18.3S436,347.7,436,347.7v-141A15.3,15.3,0,0,0,429,194.73Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "400.635px 252.815px 0px",
        }}
        id="elv2wqdw66ypo"
        className="animable"
      />
      <path
        d="M391.26,180.06a1.91,1.91,0,0,1,.84,1.52V330.29c0,.57-.37.81-.84.55a1.93,1.93,0,0,1-.83-1.52V180.61C390.43,180,390.8,179.8,391.26,180.06Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "391.265px 255.447px 0px",
        }}
        id="elzniwcy739do"
        className="animable"
      />
      <g id="elw4jj03tqpd">
        <path
          d="M384,304.14h0a8.21,8.21,0,0,0-.12-1.41c0-.11-.07-.46-.09-.53a15.3,15.3,0,0,0-6.73-9.57l-12.14-7-.11-.05a8.66,8.66,0,0,0-13.33,7.3,8.22,8.22,0,0,0,.13,1.41,5.24,5.24,0,0,0,.1.53,15.29,15.29,0,0,0,6.7,9.57l12.15,7,.1.05a8.66,8.66,0,0,0,13.34-7.3Z"
          style={{
            fill: "rgb(250, 250, 250)",
            opacity: 0.7,
            mixBlendMode: "hard-light",
            transformOrigin: "367.74px 298.513px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M357.93,289.12c0,4.15,3.11,9.3,6.94,11.51l12.14,7c3.83,2.21,6.94.64,6.94-3.51s-3.11-9.3-6.94-11.51l-12.14-7C361,283.41,357.93,285,357.93,289.12Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "370.94px 296.625px 0px",
        }}
        id="elviynegxuwmr"
        className="animable"
      />
      <path
        d="M359.74,284.22a8.66,8.66,0,0,0-8.31,8.65,8.22,8.22,0,0,0,.13,1.41,5.24,5.24,0,0,0,.1.53,15.67,15.67,0,0,0,5.34,8.63c-3.35-4.64-5.24-7.6-4.43-12.2A8.78,8.78,0,0,1,359.74,284.22Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "355.585px 293.83px 0px",
        }}
        id="elpuozk15n3n"
        className="animable"
      />
      <path
        d="M365.51,305.85a3.59,3.59,0,0,1-1.48-3.29,11.2,11.2,0,0,1,5.07-8.78,3.57,3.57,0,0,1,3.59-.36l6.52,3.78A7.18,7.18,0,1,1,372,309.64Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "373.403px 301.853px 0px",
        }}
        id="eltdcew0mtc9"
        className="animable"
      />
      <path
        d="M356.52,300.65a7.19,7.19,0,0,1,7.19-12.45l9,5.22a3.57,3.57,0,0,0-3.59.36,11.2,11.2,0,0,0-5.07,8.78,3.59,3.59,0,0,0,1.48,3.29Z"
        style={{
          fill: "rgb(146, 227, 169)",
          transformOrigin: "362.88px 296.578px 0px",
        }}
        id="elsizpfuzylvc"
        className="animable"
      />
      <g id="eloc1sdgitfrc">
        <path
          d="M358.14,290.11a2.75,2.75,0,0,0,1,3.75l10.86,6.3a10.35,10.35,0,0,0,1.49-5c0-.18,0-.34,0-.5l-9.58-5.56A2.74,2.74,0,0,0,358.14,290.11Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "364.631px 294.441px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M365.51,305.85a3.59,3.59,0,0,1-1.48-3.29,11.2,11.2,0,0,1,5.07-8.78,3.57,3.57,0,0,1,3.59-.36l6.52,3.78A7.18,7.18,0,1,1,372,309.64Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "373.403px 301.853px 0px",
        }}
        id="elgp2y6u3ncev"
        className="animable"
      />
      <g id="elgi4xdqp95v">
        <path
          d="M369.1,293.78a11.2,11.2,0,0,0-5.07,8.78,3.59,3.59,0,0,0,1.48,3.29l-.51-.3-.39-.22a3.57,3.57,0,0,1-1.48-3.29,11.2,11.2,0,0,1,5.07-8.78,3.57,3.57,0,0,1,3.59-.36l.9.52A3.57,3.57,0,0,0,369.1,293.78Z"
          style={{
            opacity: 0.1,
            transformOrigin: "367.9px 299.213px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M382.75,302.66h0a7.17,7.17,0,0,1-10.73,7l-6.51-3.79a3.59,3.59,0,0,1-1.48-3.29,8.45,8.45,0,0,1,.31-2.19l10.89,6.3C378.81,308.73,382.71,305,382.75,302.66Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "373.403px 305.511px 0px",
        }}
        id="el3jhlteiaurs"
        className="animable"
      />
      <path
        d="M380.27,302.93a2.74,2.74,0,0,1-3.75,1l-11-6.37a10.42,10.42,0,0,1,3.59-3.79,4.64,4.64,0,0,1,.44-.23l9.73,5.63A2.75,2.75,0,0,1,380.27,302.93Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "373.082px 298.92px 0px",
        }}
        id="el4lovub6w81"
        className="animable"
      />
      <g id="el7wbd35eyen3">
        <path
          d="M354.4,290.08c.08-.11.17-.21.26-.32l.19-.22c.07-.08.15-.15.23-.23l.24-.24.14-.11a6.86,6.86,0,0,1,1.23-.84l0,0,.49-.24h0c-2,1.22-3.29,6.45.29,8.52,1.22.71,3.05,1.8,6.84,4a8.45,8.45,0,0,0-.31,2.19,3.59,3.59,0,0,0,1.48,3.29l-9-5.2a7.2,7.2,0,0,1-2.63-9.82c.12-.19.24-.38.37-.56Z"
          style={{
            opacity: 0.1,
            transformOrigin: "359.185px 296.88px 0px",
          }}
          className="animable"
        />
      </g>
      <g id="elair59nvmukh">
        <path
          d="M429.46,330.18h0a9.36,9.36,0,0,0-.11-1.41c0-.11-.08-.46-.1-.53a15.28,15.28,0,0,0-6.72-9.57l-12.15-7-.11-.05a8.66,8.66,0,0,0-13.33,7.3,8.22,8.22,0,0,0,.13,1.41c0,.1.07.37.1.53a15.27,15.27,0,0,0,6.7,9.57l12.15,7,.1,0a8.66,8.66,0,0,0,13.34-7.3Z"
          style={{
            fill: "rgb(250, 250, 250)",
            opacity: 0.7,
            mixBlendMode: "hard-light",
            transformOrigin: "413.2px 324.528px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M403.45,315.16c0,4.15,3.1,9.31,6.93,11.52l12.15,7c3.82,2.21,6.93.64,6.93-3.51s-3.11-9.3-6.93-11.51l-12.15-7C406.55,309.45,403.45,311,403.45,315.16Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "416.455px 322.668px 0px",
        }}
        id="elmc7mnzhvsz"
        className="animable"
      />
      <path
        d="M405.25,310.26a8.67,8.67,0,0,0-8.31,8.65,8.22,8.22,0,0,0,.13,1.41c0,.1.07.37.1.53a15.67,15.67,0,0,0,5.34,8.63c-3.34-4.64-5.24-7.6-4.43-12.19A8.8,8.8,0,0,1,405.25,310.26Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "401.095px 319.87px 0px",
        }}
        id="eledefy211b1"
        className="animable"
      />
      <path
        d="M411,331.89a3.58,3.58,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.57,3.57,0,0,1,3.59-.36l6.52,3.78a7.18,7.18,0,1,1-7.19,12.44Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "418.896px 327.89px 0px",
        }}
        id="eldwnl8h79rpm"
        className="animable"
      />
      <path
        d="M402,326.69a7.18,7.18,0,0,1,7.19-12.44l9,5.21a3.57,3.57,0,0,0-3.59.36,11.22,11.22,0,0,0-5.07,8.79,3.58,3.58,0,0,0,1.48,3.28Z"
        style={{
          fill: "rgb(146, 227, 169)",
          transformOrigin: "408.3px 322.587px 0px",
        }}
        id="elc95l27b8zs7"
        className="animable"
      />
      <g id="el97zfljtr8g">
        <path
          d="M403.65,316.15a2.74,2.74,0,0,0,1,3.75l10.86,6.31a10.42,10.42,0,0,0,1.49-5c0-.17,0-.34,0-.5l-9.58-5.56A2.74,2.74,0,0,0,403.65,316.15Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "410.14px 320.49px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M411,331.89a3.58,3.58,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.57,3.57,0,0,1,3.59-.36l6.52,3.78a7.18,7.18,0,1,1-7.19,12.44Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "418.896px 327.89px 0px",
        }}
        id="elcss422vklkv"
        className="animable"
      />
      <g id="elqi7kwglv19j">
        <path
          d="M414.61,319.82a11.22,11.22,0,0,0-5.07,8.79,3.58,3.58,0,0,0,1.48,3.28l-.51-.29-.39-.23a3.56,3.56,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.57,3.57,0,0,1,3.59-.36l.9.52A3.57,3.57,0,0,0,414.61,319.82Z"
          style={{
            opacity: 0.1,
            transformOrigin: "413.41px 325.253px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M428.26,328.7h0a7.17,7.17,0,0,1-10.73,7L411,331.89a3.58,3.58,0,0,1-1.48-3.28,8.52,8.52,0,0,1,.31-2.2c3.81,2.19,8.46,4.89,10.89,6.3C424.32,334.78,428.22,331.05,428.26,328.7Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "418.903px 331.541px 0px",
        }}
        id="el81q0e3jtuaa"
        className="animable"
      />
      <path
        d="M425.78,329a2.74,2.74,0,0,1-3.75,1l-11-6.37a10.42,10.42,0,0,1,3.59-3.79c.15-.09.3-.16.44-.23l9.73,5.63A2.76,2.76,0,0,1,425.78,329Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "418.59px 324.99px 0px",
        }}
        id="elesj1qnrt0xs"
        className="animable"
      />
      <g id="elp6xdckksa1k">
        <path
          d="M399.91,316.13a4,4,0,0,1,.26-.32,2,2,0,0,1,.19-.22l.23-.24a2.73,2.73,0,0,1,.25-.23A.69.69,0,0,1,401,315a6.91,6.91,0,0,1,1.23-.85l0,0c.15-.08.31-.16.48-.23h0c-2,1.21-3.29,6.45.29,8.51,1.22.71,3.06,1.8,6.84,4a8.52,8.52,0,0,0-.31,2.2,3.58,3.58,0,0,0,1.48,3.28l-9-5.2a7.19,7.19,0,0,1-2.62-9.82,6.65,6.65,0,0,1,.36-.56Z"
          style={{
            opacity: 0.1,
            transformOrigin: "404.717px 322.915px 0px",
          }}
          className="animable"
        />
      </g>
      <g id="elvf14741evh">
        <path
          d="M384,266.6h0a8.33,8.33,0,0,0-.12-1.42c0-.1-.07-.46-.09-.52a15.29,15.29,0,0,0-6.73-9.58l-12.14-7-.11,0a8.66,8.66,0,0,0-13.33,7.3,8.22,8.22,0,0,0,.13,1.41,4.38,4.38,0,0,0,.1.53,15.31,15.31,0,0,0,6.7,9.58l12.15,7,.1,0A8.66,8.66,0,0,0,384,266.6Z"
          style={{
            fill: "rgb(250, 250, 250)",
            opacity: 0.7,
            mixBlendMode: "hard-light",
            transformOrigin: "367.74px 260.993px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M357.93,251.58c0,4.14,3.11,9.3,6.94,11.51l12.14,7c3.83,2.21,6.94.64,6.94-3.5s-3.11-9.3-6.94-11.52l-12.14-7C361,245.86,357.93,247.43,357.93,251.58Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "370.94px 259.08px 0px",
        }}
        id="elkn6z08m8j6h"
        className="animable"
      />
      <path
        d="M359.74,246.67a8.67,8.67,0,0,0-8.31,8.65,8.22,8.22,0,0,0,.13,1.41,4.38,4.38,0,0,0,.1.53,15.67,15.67,0,0,0,5.34,8.63c-3.35-4.64-5.24-7.6-4.43-12.19A8.8,8.8,0,0,1,359.74,246.67Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "355.585px 256.28px 0px",
        }}
        id="el06q1828t2atd"
        className="animable"
      />
      <path
        d="M365.51,268.3A3.57,3.57,0,0,1,364,265a11.22,11.22,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l6.52,3.77A7.19,7.19,0,1,1,372,272.1Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "373.383px 264.295px 0px",
        }}
        id="elz2nqahxu2vb"
        className="animable"
      />
      <path
        d="M356.52,263.1a7.18,7.18,0,1,1,7.19-12.44l9,5.22a3.56,3.56,0,0,0-3.59.35A11.22,11.22,0,0,0,364,265a3.57,3.57,0,0,0,1.48,3.28Z"
        style={{
          fill: "rgb(146, 227, 169)",
          transformOrigin: "362.82px 258.987px 0px",
        }}
        id="ely64geyz7t1"
        className="animable"
      />
      <g id="eluddghkcq4os">
        <path
          d="M358.14,252.56a2.76,2.76,0,0,0,1,3.76l10.86,6.3a10.38,10.38,0,0,0,1.49-5c0-.17,0-.33,0-.49l-9.58-5.56A2.74,2.74,0,0,0,358.14,252.56Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "364.631px 256.904px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M365.51,268.3A3.57,3.57,0,0,1,364,265a11.22,11.22,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l6.52,3.77A7.19,7.19,0,1,1,372,272.1Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "373.383px 264.295px 0px",
        }}
        id="elelqmyziflal"
        className="animable"
      />
      <g id="elxyek9h83f1">
        <path
          d="M369.1,256.23A11.22,11.22,0,0,0,364,265a3.57,3.57,0,0,0,1.48,3.28L365,268l-.39-.23a3.55,3.55,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l.9.52A3.56,3.56,0,0,0,369.1,256.23Z"
          style={{
            opacity: 0.1,
            transformOrigin: "367.9px 261.65px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M382.75,265.11h0a7.17,7.17,0,0,1-10.73,7l-6.51-3.8A3.57,3.57,0,0,1,364,265a8.58,8.58,0,0,1,.31-2.2l10.89,6.3C378.81,271.19,382.71,267.47,382.75,265.11Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "373.387px 267.941px 0px",
        }}
        id="elzb9ic4stan"
        className="animable"
      />
      <path
        d="M380.27,265.39a2.75,2.75,0,0,1-3.75,1l-11-6.37a10.37,10.37,0,0,1,3.59-3.8l.44-.23,9.73,5.63A2.76,2.76,0,0,1,380.27,265.39Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "373.083px 261.374px 0px",
        }}
        id="elv1piii1mfo"
        className="animable"
      />
      <g id="elev7pmsjdpj7">
        <path
          d="M354.4,252.54a4,4,0,0,1,.26-.32,2,2,0,0,1,.19-.22,2.5,2.5,0,0,1,.23-.23l.24-.24.14-.11a6.91,6.91,0,0,1,1.23-.85l0,0,.49-.23h0c-2,1.21-3.29,6.45.29,8.52,1.22.7,3.05,1.79,6.84,4A8.58,8.58,0,0,0,364,265a3.57,3.57,0,0,0,1.48,3.28l-9-5.2a7.19,7.19,0,0,1-2.63-9.81c.12-.2.24-.38.37-.56A1.33,1.33,0,0,1,354.4,252.54Z"
          style={{
            opacity: 0.1,
            transformOrigin: "359.185px 259.31px 0px",
          }}
          className="animable"
        />
      </g>
      <g id="el69yao6y4lgm">
        <path
          d="M429.46,292.64h0a9.49,9.49,0,0,0-.11-1.42c0-.1-.08-.45-.1-.52a15.26,15.26,0,0,0-6.72-9.57l-12.15-7-.11-.05a8.67,8.67,0,0,0-13.33,7.31,8.1,8.1,0,0,0,.13,1.4c0,.11.07.38.1.53a15.29,15.29,0,0,0,6.7,9.58l12.15,7,.1.05a8.66,8.66,0,0,0,13.34-7.3Z"
          style={{
            fill: "rgb(250, 250, 250)",
            opacity: 0.7,
            mixBlendMode: "hard-light",
            transformOrigin: "413.2px 287.022px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M403.45,277.62c0,4.15,3.1,9.3,6.93,11.51l12.15,7c3.82,2.21,6.93.64,6.93-3.5s-3.11-9.3-6.93-11.51l-12.15-7C406.55,271.9,403.45,273.47,403.45,277.62Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "416.455px 285.122px 0px",
        }}
        id="elaela33cco"
        className="animable"
      />
      <path
        d="M405.25,272.72a8.66,8.66,0,0,0-8.31,8.65,8.1,8.1,0,0,0,.13,1.4c0,.11.07.38.1.53a15.63,15.63,0,0,0,5.34,8.63c-3.34-4.64-5.24-7.59-4.43-12.19A8.78,8.78,0,0,1,405.25,272.72Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "401.095px 282.325px 0px",
        }}
        id="el5l9wg28cxkb"
        className="animable"
      />
      <path
        d="M411,294.35a3.59,3.59,0,0,1-1.48-3.29,11.23,11.23,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l6.52,3.77a7.19,7.19,0,1,1-7.19,12.45Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "418.961px 290.384px 0px",
        }}
        id="eli9123pdoka"
        className="animable"
      />
      <path
        d="M402,289.14a7.18,7.18,0,0,1,7.19-12.44l9,5.22a3.56,3.56,0,0,0-3.59.35,11.23,11.23,0,0,0-5.07,8.79,3.59,3.59,0,0,0,1.48,3.29Z"
        style={{
          fill: "rgb(146, 227, 169)",
          transformOrigin: "408.3px 285.042px 0px",
        }}
        id="ela50nzw2tp5"
        className="animable"
      />
      <g id="el9dd66u86mgh">
        <path
          d="M403.65,278.6a2.76,2.76,0,0,0,1,3.76l10.86,6.3a10.38,10.38,0,0,0,1.49-5c0-.17,0-.33,0-.49l-9.58-5.56A2.75,2.75,0,0,0,403.65,278.6Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "410.141px 282.946px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M411,294.35a3.59,3.59,0,0,1-1.48-3.29,11.23,11.23,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l6.52,3.77a7.19,7.19,0,1,1-7.19,12.45Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "418.961px 290.384px 0px",
        }}
        id="ela9wgnnl9got"
        className="animable"
      />
      <g id="el9vfydg0gyed">
        <path
          d="M414.61,282.27a11.23,11.23,0,0,0-5.07,8.79,3.59,3.59,0,0,0,1.48,3.29l-.51-.3-.39-.23a3.54,3.54,0,0,1-1.48-3.28,11.23,11.23,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l.9.52A3.56,3.56,0,0,0,414.61,282.27Z"
          style={{
            opacity: 0.1,
            transformOrigin: "413.409px 287.71px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M428.26,291.16h0a7.12,7.12,0,0,1-.91,4.35,7.19,7.19,0,0,1-9.82,2.63L411,294.35a3.59,3.59,0,0,1-1.48-3.29,8.58,8.58,0,0,1,.31-2.2l10.89,6.3C424.32,297.23,428.22,293.51,428.26,291.16Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "418.902px 293.982px 0px",
        }}
        id="elr2ldi0rm5de"
        className="animable"
      />
      <path
        d="M425.78,291.43a2.74,2.74,0,0,1-3.75,1l-11-6.37a10.45,10.45,0,0,1,3.59-3.8l.44-.22,9.73,5.63A2.74,2.74,0,0,1,425.78,291.43Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "418.594px 287.42px 0px",
        }}
        id="eljbxi2v57ypa"
        className="animable"
      />
      <g id="elbmj19al3r4v">
        <path
          d="M399.91,278.58a4,4,0,0,1,.26-.32c.06-.07.12-.15.19-.22l.23-.23.25-.24.13-.11a6.86,6.86,0,0,1,1.23-.84l0,0a3.58,3.58,0,0,1,.48-.24h0c-2,1.21-3.29,6.45.29,8.52,1.22.71,3.06,1.8,6.84,4a8.58,8.58,0,0,0-.31,2.2,3.59,3.59,0,0,0,1.48,3.29l-9-5.21a7.17,7.17,0,0,1-2.62-9.81,6.65,6.65,0,0,1,.36-.56Z"
          style={{
            opacity: 0.1,
            transformOrigin: "404.686px 285.385px 0px",
          }}
          className="animable"
        />
      </g>
      <g id="eluoygc76wr9b">
        <path
          d="M384,229.05h0a8.21,8.21,0,0,0-.12-1.41c0-.11-.07-.46-.09-.53a15.3,15.3,0,0,0-6.73-9.57l-12.14-7-.11-.05a8.66,8.66,0,0,0-13.33,7.3,8.22,8.22,0,0,0,.13,1.41,5.24,5.24,0,0,0,.1.53,15.29,15.29,0,0,0,6.7,9.57l12.15,7,.1,0a8.66,8.66,0,0,0,13.34-7.3Z"
          style={{
            fill: "rgb(250, 250, 250)",
            opacity: 0.7,
            mixBlendMode: "hard-light",
            transformOrigin: "367.74px 223.398px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M357.93,214c0,4.15,3.11,9.3,6.94,11.51l12.14,7c3.83,2.21,6.94.64,6.94-3.51s-3.11-9.3-6.94-11.51l-12.14-7C361,208.32,357.93,209.89,357.93,214Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "370.94px 221.513px 0px",
        }}
        id="elvaghp3gkjgt"
        className="animable"
      />
      <path
        d="M359.74,209.13a8.66,8.66,0,0,0-8.31,8.65,8.22,8.22,0,0,0,.13,1.41,5.24,5.24,0,0,0,.1.53,15.67,15.67,0,0,0,5.34,8.63c-3.35-4.64-5.24-7.6-4.43-12.2A8.78,8.78,0,0,1,359.74,209.13Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "355.585px 218.74px 0px",
        }}
        id="eltg2rj2ub88"
        className="animable"
      />
      <path
        d="M365.51,230.76a3.59,3.59,0,0,1-1.48-3.29,11.2,11.2,0,0,1,5.07-8.78,3.57,3.57,0,0,1,3.59-.36l6.52,3.78A7.18,7.18,0,1,1,372,234.55Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "373.403px 226.763px 0px",
        }}
        id="elh0z1ybgz1k"
        className="animable"
      />
      <path
        d="M356.52,225.56a7.19,7.19,0,0,1,7.19-12.45l9,5.22a3.57,3.57,0,0,0-3.59.36,11.2,11.2,0,0,0-5.07,8.78,3.59,3.59,0,0,0,1.48,3.29Z"
        style={{
          fill: "rgb(146, 227, 169)",
          transformOrigin: "362.88px 221.489px 0px",
        }}
        id="elq4287wyorv"
        className="animable"
      />
      <g id="elw9xzp4vdh5r">
        <path
          d="M358.14,215a2.75,2.75,0,0,0,1,3.75l10.86,6.3a10.35,10.35,0,0,0,1.49-5c0-.18,0-.34,0-.5L361.89,214A2.74,2.74,0,0,0,358.14,215Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "364.631px 219.34px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M365.51,230.76a3.59,3.59,0,0,1-1.48-3.29,11.2,11.2,0,0,1,5.07-8.78,3.57,3.57,0,0,1,3.59-.36l6.52,3.78A7.18,7.18,0,1,1,372,234.55Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "373.403px 226.763px 0px",
        }}
        id="elp1hk7efdpbr"
        className="animable"
      />
      <g id="el1zwln8b0632">
        <path
          d="M369.1,218.69a11.2,11.2,0,0,0-5.07,8.78,3.59,3.59,0,0,0,1.48,3.29l-.51-.3-.39-.22a3.57,3.57,0,0,1-1.48-3.29,11.2,11.2,0,0,1,5.07-8.78,3.57,3.57,0,0,1,3.59-.36l.9.52A3.57,3.57,0,0,0,369.1,218.69Z"
          style={{
            opacity: 0.1,
            transformOrigin: "367.9px 224.123px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M382.75,227.57h0a7.17,7.17,0,0,1-10.73,7l-6.51-3.79a3.59,3.59,0,0,1-1.48-3.29,8.45,8.45,0,0,1,.31-2.19l10.89,6.3C378.81,233.64,382.71,229.92,382.75,227.57Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "373.403px 230.421px 0px",
        }}
        id="elz3hkak43alj"
        className="animable"
      />
      <path
        d="M380.27,227.84a2.74,2.74,0,0,1-3.75,1l-11-6.37a10.42,10.42,0,0,1,3.59-3.79,4.64,4.64,0,0,1,.44-.23l9.73,5.63A2.75,2.75,0,0,1,380.27,227.84Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "373.082px 223.83px 0px",
        }}
        id="elltq6h4omg3e"
        className="animable"
      />
      <g id="elp84dogsqmu">
        <path
          d="M354.4,215l.26-.32.19-.22.23-.23.24-.24.14-.11a7.42,7.42,0,0,1,1.23-.84l0,0,.49-.24h0c-2,1.22-3.29,6.45.29,8.52,1.22.71,3.05,1.8,6.84,4a8.45,8.45,0,0,0-.31,2.19,3.59,3.59,0,0,0,1.48,3.29l-9-5.2a7.2,7.2,0,0,1-2.63-9.82c.12-.19.24-.38.37-.56Z"
          style={{
            opacity: 0.1,
            transformOrigin: "359.185px 221.8px 0px",
          }}
          className="animable"
        />
      </g>
      <g id="elcpretv57hmf">
        <path
          d="M429.46,255.09h0a9.36,9.36,0,0,0-.11-1.41c0-.11-.08-.46-.1-.53a15.28,15.28,0,0,0-6.72-9.57l-12.15-7-.11,0a8.66,8.66,0,0,0-13.33,7.3,8.22,8.22,0,0,0,.13,1.41c0,.1.07.37.1.53a15.27,15.27,0,0,0,6.7,9.57l12.15,7,.1,0a8.66,8.66,0,0,0,13.34-7.3Z"
          style={{
            fill: "rgb(250, 250, 250)",
            opacity: 0.7,
            mixBlendMode: "hard-light",
            transformOrigin: "413.2px 249.488px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M403.45,240.08c0,4.14,3.1,9.3,6.93,11.51l12.15,7c3.82,2.21,6.93.64,6.93-3.51s-3.11-9.3-6.93-11.51l-12.15-7C406.55,234.36,403.45,235.93,403.45,240.08Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "416.455px 247.58px 0px",
        }}
        id="elwjw49odnguh"
        className="animable"
      />
      <path
        d="M405.25,235.17a8.67,8.67,0,0,0-8.31,8.65,8.22,8.22,0,0,0,.13,1.41c0,.1.07.37.1.53a15.67,15.67,0,0,0,5.34,8.63c-3.34-4.64-5.24-7.6-4.43-12.19A8.8,8.8,0,0,1,405.25,235.17Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "401.095px 244.78px 0px",
        }}
        id="el2h8t573psxm"
        className="animable"
      />
      <path
        d="M411,256.8a3.58,3.58,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.57,3.57,0,0,1,3.59-.36l6.52,3.78a7.18,7.18,0,1,1-7.19,12.44Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "418.896px 252.8px 0px",
        }}
        id="elg3xcwtkoey"
        className="animable"
      />
      <path
        d="M402,251.6a7.18,7.18,0,0,1,7.19-12.44l9,5.21a3.57,3.57,0,0,0-3.59.36,11.22,11.22,0,0,0-5.07,8.79A3.58,3.58,0,0,0,411,256.8Z"
        style={{
          fill: "rgb(146, 227, 169)",
          transformOrigin: "408.3px 247.497px 0px",
        }}
        id="elu5fcwlit6s"
        className="animable"
      />
      <g id="elh1ki6rmnnlh">
        <path
          d="M403.65,241.06a2.76,2.76,0,0,0,1,3.76l10.86,6.3a10.42,10.42,0,0,0,1.49-5c0-.17,0-.34,0-.5l-9.58-5.56A2.74,2.74,0,0,0,403.65,241.06Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "410.141px 245.4px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M411,256.8a3.58,3.58,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.57,3.57,0,0,1,3.59-.36l6.52,3.78a7.18,7.18,0,1,1-7.19,12.44Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "418.896px 252.8px 0px",
        }}
        id="el20kxefvgdhk"
        className="animable"
      />
      <g id="el3sncorx20lt">
        <path
          d="M414.61,244.73a11.22,11.22,0,0,0-5.07,8.79A3.58,3.58,0,0,0,411,256.8l-.51-.29-.39-.23a3.56,3.56,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.57,3.57,0,0,1,3.59-.36l.9.52A3.57,3.57,0,0,0,414.61,244.73Z"
          style={{
            opacity: 0.1,
            transformOrigin: "413.39px 250.163px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M428.26,253.61h0a7.17,7.17,0,0,1-10.73,7L411,256.8a3.58,3.58,0,0,1-1.48-3.28,8.52,8.52,0,0,1,.31-2.2c3.81,2.19,8.46,4.9,10.89,6.3C424.32,259.69,428.22,256,428.26,253.61Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "418.903px 256.451px 0px",
        }}
        id="elix6o7hlmvvd"
        className="animable"
      />
      <path
        d="M425.78,253.89a2.74,2.74,0,0,1-3.75,1l-11-6.37a10.42,10.42,0,0,1,3.59-3.79c.15-.09.3-.16.44-.23l9.73,5.63A2.76,2.76,0,0,1,425.78,253.89Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "418.59px 249.88px 0px",
        }}
        id="elmmas9fmnak"
        className="animable"
      />
      <g id="elll1mm0znxiq">
        <path
          d="M399.91,241a4,4,0,0,1,.26-.32,2,2,0,0,1,.19-.22l.23-.24a2.73,2.73,0,0,1,.25-.23.69.69,0,0,1,.13-.11,6.91,6.91,0,0,1,1.23-.85l0,0c.15-.08.31-.16.48-.23h0c-2,1.21-3.29,6.45.29,8.51,1.22.71,3.06,1.8,6.84,4a8.52,8.52,0,0,0-.31,2.2A3.58,3.58,0,0,0,411,256.8l-9-5.2a7.18,7.18,0,0,1-2.62-9.81,5.5,5.5,0,0,1,.36-.57Z"
          style={{
            opacity: 0.1,
            transformOrigin: "404.707px 247.8px 0px",
          }}
          className="animable"
        />
      </g>
      <g id="elckgn7xe5i2">
        <path
          d="M384,191.51h0a8.33,8.33,0,0,0-.12-1.42c0-.1-.07-.46-.09-.52A15.29,15.29,0,0,0,377,180l-12.14-7-.11,0a8.67,8.67,0,0,0-13.33,7.3,8.22,8.22,0,0,0,.13,1.41,4.38,4.38,0,0,0,.1.53,15.31,15.31,0,0,0,6.7,9.58l12.15,7,.1.05a8.66,8.66,0,0,0,13.34-7.3Z"
          style={{
            fill: "rgb(250, 250, 250)",
            opacity: 0.7,
            mixBlendMode: "hard-light",
            transformOrigin: "367.71px 185.942px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M357.93,176.49c0,4.15,3.11,9.3,6.94,11.51L377,195c3.83,2.21,6.94.64,6.94-3.5s-3.11-9.3-6.94-11.51l-12.14-7C361,170.77,357.93,172.34,357.93,176.49Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "370.935px 183.992px 0px",
        }}
        id="elo2fjjsryrzr"
        className="animable"
      />
      <path
        d="M359.74,171.58a8.67,8.67,0,0,0-8.31,8.65,8.22,8.22,0,0,0,.13,1.41,4.38,4.38,0,0,0,.1.53A15.63,15.63,0,0,0,357,190.8c-3.35-4.64-5.24-7.6-4.43-12.19A8.8,8.8,0,0,1,359.74,171.58Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "355.585px 181.19px 0px",
        }}
        id="elexsga7tr3jc"
        className="animable"
      />
      <path
        d="M365.51,193.21a3.57,3.57,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l6.52,3.77A7.19,7.19,0,1,1,372,197Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "373.45px 189.242px 0px",
        }}
        id="elxyr839vaey8"
        className="animable"
      />
      <path
        d="M356.52,188a7.18,7.18,0,1,1,7.19-12.44l9,5.22a3.56,3.56,0,0,0-3.59.35,11.22,11.22,0,0,0-5.07,8.79,3.57,3.57,0,0,0,1.48,3.28Z"
        style={{
          fill: "rgb(146, 227, 169)",
          transformOrigin: "362.82px 183.897px 0px",
        }}
        id="elr5clbpe2nih"
        className="animable"
      />
      <g id="elj9nyunokanb">
        <path
          d="M358.14,177.47a2.76,2.76,0,0,0,1,3.76l10.86,6.3a10.38,10.38,0,0,0,1.49-5c0-.17,0-.33,0-.49l-9.58-5.56A2.74,2.74,0,0,0,358.14,177.47Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "364.631px 181.814px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M365.51,193.21a3.57,3.57,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l6.52,3.77A7.19,7.19,0,1,1,372,197Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "373.45px 189.242px 0px",
        }}
        id="el6s07z0ehebs"
        className="animable"
      />
      <g id="el5ofbl8w3faj">
        <path
          d="M369.1,181.14a11.22,11.22,0,0,0-5.07,8.79,3.57,3.57,0,0,0,1.48,3.28l-.51-.29-.39-.23a3.55,3.55,0,0,1-1.48-3.28,11.22,11.22,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l.9.52A3.56,3.56,0,0,0,369.1,181.14Z"
          style={{
            opacity: 0.1,
            transformOrigin: "367.9px 186.575px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M382.75,190h0a7.12,7.12,0,0,1-.91,4.35A7.2,7.2,0,0,1,372,197l-6.51-3.8a3.57,3.57,0,0,1-1.48-3.28,8.58,8.58,0,0,1,.31-2.2l10.89,6.3C378.81,196.1,382.71,192.38,382.75,190Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "373.392px 192.841px 0px",
        }}
        id="elh4bgce0qcgi"
        className="animable"
      />
      <path
        d="M380.27,190.3a2.75,2.75,0,0,1-3.75,1l-11-6.37a10.37,10.37,0,0,1,3.59-3.8l.44-.23,9.73,5.63A2.76,2.76,0,0,1,380.27,190.3Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "373.083px 186.284px 0px",
        }}
        id="elfzc5yit5qtq"
        className="animable"
      />
      <g id="el6vmm2vh612x">
        <path
          d="M354.4,177.45a4,4,0,0,1,.26-.32,2,2,0,0,1,.19-.22l.23-.23.24-.24.14-.11a6.91,6.91,0,0,1,1.23-.85h0a4.44,4.44,0,0,1,.49-.24h0c-2,1.21-3.29,6.45.29,8.52,1.22.7,3.05,1.8,6.84,4a8.58,8.58,0,0,0-.31,2.2,3.57,3.57,0,0,0,1.48,3.28l-9-5.2a7.19,7.19,0,0,1-2.63-9.81c.12-.2.24-.38.37-.56A1.33,1.33,0,0,1,354.4,177.45Z"
          style={{
            opacity: 0.1,
            transformOrigin: "359.185px 184.24px 0px",
          }}
          className="animable"
        />
      </g>
      <g id="el0qv91q2mxb39">
        <path
          d="M429.46,217.55h0a9.49,9.49,0,0,0-.11-1.42c0-.1-.08-.45-.1-.52a15.26,15.26,0,0,0-6.72-9.57l-12.15-7-.11-.05a8.67,8.67,0,0,0-13.33,7.31,8.1,8.1,0,0,0,.13,1.4c0,.11.07.38.1.53a15.29,15.29,0,0,0,6.7,9.58l12.15,7,.1,0a8.66,8.66,0,0,0,13.34-7.3Z"
          style={{
            fill: "rgb(250, 250, 250)",
            opacity: 0.7,
            mixBlendMode: "hard-light",
            transformOrigin: "413.2px 211.907px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M403.45,202.53c0,4.15,3.1,9.3,6.93,11.51l12.15,7c3.82,2.21,6.93.64,6.93-3.51s-3.11-9.3-6.93-11.51l-12.15-7C406.55,196.81,403.45,198.38,403.45,202.53Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "416.455px 210.03px 0px",
        }}
        id="elexcxgj9zrmr"
        className="animable"
      />
      <path
        d="M405.25,197.63a8.66,8.66,0,0,0-8.31,8.65,8.1,8.1,0,0,0,.13,1.4c0,.11.07.38.1.53a15.63,15.63,0,0,0,5.34,8.63c-3.34-4.64-5.24-7.59-4.43-12.19A8.78,8.78,0,0,1,405.25,197.63Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "401.095px 207.235px 0px",
        }}
        id="elrl0aynh97zo"
        className="animable"
      />
      <path
        d="M411,219.26a3.59,3.59,0,0,1-1.48-3.29,11.23,11.23,0,0,1,5.07-8.79,3.59,3.59,0,0,1,3.59-.35l6.52,3.78a7.18,7.18,0,1,1-7.19,12.44Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "418.896px 215.26px 0px",
        }}
        id="eles9zpgig6lm"
        className="animable"
      />
      <path
        d="M402,214.05a7.18,7.18,0,0,1,7.19-12.44l9,5.22a3.59,3.59,0,0,0-3.59.35,11.23,11.23,0,0,0-5.07,8.79,3.59,3.59,0,0,0,1.48,3.29Z"
        style={{
          fill: "rgb(146, 227, 169)",
          transformOrigin: "408.3px 209.952px 0px",
        }}
        id="el26i483xoqo8"
        className="animable"
      />
      <g id="el7giv6h4ye43">
        <path
          d="M403.65,203.51a2.76,2.76,0,0,0,1,3.76l10.86,6.3a10.38,10.38,0,0,0,1.49-5c0-.17,0-.33,0-.49l-9.58-5.56A2.75,2.75,0,0,0,403.65,203.51Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "410.141px 207.856px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M411,219.26a3.59,3.59,0,0,1-1.48-3.29,11.23,11.23,0,0,1,5.07-8.79,3.59,3.59,0,0,1,3.59-.35l6.52,3.78a7.18,7.18,0,1,1-7.19,12.44Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "418.896px 215.26px 0px",
        }}
        id="elrdsk8uzb02c"
        className="animable"
      />
      <g id="elwzdjmg43hhe">
        <path
          d="M414.61,207.18a11.23,11.23,0,0,0-5.07,8.79,3.59,3.59,0,0,0,1.48,3.29l-.51-.3-.39-.22a3.57,3.57,0,0,1-1.48-3.29,11.23,11.23,0,0,1,5.07-8.79,3.56,3.56,0,0,1,3.59-.35l.9.52A3.59,3.59,0,0,0,414.61,207.18Z"
          style={{
            opacity: 0.1,
            transformOrigin: "413.41px 212.62px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M428.26,216.07h0a7.12,7.12,0,0,1-.91,4.35,7.19,7.19,0,0,1-9.82,2.63L411,219.26a3.59,3.59,0,0,1-1.48-3.29,8.58,8.58,0,0,1,.31-2.2l10.89,6.3C424.32,222.14,428.22,218.42,428.26,216.07Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "418.902px 218.892px 0px",
        }}
        id="elkozrmbosrh"
        className="animable"
      />
      <path
        d="M425.78,216.34a2.74,2.74,0,0,1-3.75,1L411,211a10.45,10.45,0,0,1,3.59-3.8l.44-.22,9.73,5.63A2.75,2.75,0,0,1,425.78,216.34Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "418.567px 212.345px 0px",
        }}
        id="elo99zytv5l"
        className="animable"
      />
      <g id="eldngo7im8zs7">
        <path
          d="M399.91,203.49a4,4,0,0,1,.26-.32c.06-.07.12-.15.19-.22l.23-.23.25-.24.13-.11a6.86,6.86,0,0,1,1.23-.84l0,0a3.58,3.58,0,0,1,.48-.24h0c-2,1.22-3.29,6.45.29,8.52,1.22.71,3.06,1.8,6.84,4a8.58,8.58,0,0,0-.31,2.2,3.59,3.59,0,0,0,1.48,3.29l-9-5.21a7.17,7.17,0,0,1-2.62-9.81,6.65,6.65,0,0,1,.36-.56Z"
          style={{
            opacity: 0.1,
            transformOrigin: "404.686px 210.295px 0px",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--pillls-bottle-1--inject-8"
      style={{
        transformOrigin: "274.04px 231.38px 0px",
      }}
      className="animable"
    >
      <g
        id="freepik--medicine-bottle--inject-8"
        style={{
          transformOrigin: "274.04px 231.38px 0px",
        }}
        className="animable"
      >
        <path
          d="M321.05,161.3a69.23,69.23,0,0,0-9-4.33,15.33,15.33,0,0,1-9.81-14.16V125.53H245.82v17.28A15.31,15.31,0,0,1,236,157a69.23,69.23,0,0,0-9,4.33c-13,7.5-19.47,17.33-19.47,27.15v130.3c0,9.82,6.49,19.65,19.47,27.15,26,15,68.07,15,94,0,13-7.5,19.48-17.32,19.48-27.15V188.45C340.53,178.62,334,168.8,321.05,161.3Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "274.005px 241.355px 0px",
          }}
          id="el3il7yh18mca"
          className="animable"
        />
        <g id="elk03t4ni5yik">
          <path
            d="M321.05,161.3a69.23,69.23,0,0,0-9-4.33,15.33,15.33,0,0,1-9.81-14.16V125.53H245.82v17.28A15.31,15.31,0,0,1,236,157a69.23,69.23,0,0,0-9,4.33c-13,7.5-19.47,17.33-19.47,27.15v130.3c0,9.82,6.49,19.65,19.47,27.15,26,15,68.07,15,94,0,13-7.5,19.48-17.32,19.48-27.15V188.45C340.53,178.62,334,168.8,321.05,161.3Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.7,
              transformOrigin: "274.005px 241.355px 0px",
            }}
            className="animable"
          />
        </g>
        <ellipse
          cx={274.07}
          cy={319.16}
          rx={66.45}
          ry={37.99}
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "274.07px 319.16px 0px",
          }}
          id="elvbzvraz13s"
          className="animable"
        />
        <g id="el620w8le7iw">
          <ellipse
            cx={274.07}
            cy={319.16}
            rx={66.45}
            ry={37.99}
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.5,
              transformOrigin: "274.07px 319.16px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elkrixi1xdqur">
          <path
            d="M307.63,143.7c-12.23-7.06-29.51-9.46-45.19-7.17v-11H245.82V142.8A15.3,15.3,0,0,1,236,157a72.41,72.41,0,0,0-9,4.33c-.11,0-.2.13-.32.18-12.78,7.48-19.15,17.22-19.15,27V318.74c0,9.84,6.49,19.65,19.47,27.15,18.68,10.79,45.7,13.77,69.06,9.05C242,358.65,229,335.73,229,319.15V192.46c0-5.85.88-10.68,5-14.75a35.63,35.63,0,0,0,6.57,4.76c18.55,10.7,48.6,10.7,67.13,0S326.18,154.42,307.63,143.7Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "264.55px 241.318px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="eloonb3tfm6we">
          <path
            d="M321.05,161.3a69.23,69.23,0,0,0-9-4.33,15.33,15.33,0,0,1-9.81-14.16V125.53h-6.16v23.13c0,5.33,4.59,10.4,9.9,13.3s22.19,10.23,26.28,18.25c3.9,7.63,2.63,22.8,2.63,40.8v92c0,11.68-4.87,28.58-26.56,38.74a75.92,75.92,0,0,0,12.73-5.7c13-7.5,19.47-16.93,19.47-27.27V188.45C340.53,178.62,334,168.8,321.05,161.3Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "318.305px 238.64px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M321.55,149.78v4.92c0,7-4.61,14.06-13.92,19.45-18.53,10.69-48.57,10.69-67.15,0a32,32,0,0,1-10.41-9.08,17.71,17.71,0,0,1-3.46-10.28v-5.11a12.34,12.34,0,0,1,.37-3.18c1.15-5.94,5.67-11.7,13.5-16.22a5.23,5.23,0,0,1,.7-.37c18.61-10.32,48.15-10.18,66.45.37,7.83,4.52,12.4,10.28,13.55,16.18A13.37,13.37,0,0,1,321.55,149.78Z"
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "274.08px 152.218px 0px",
          }}
          id="elai0dj8hpxqp"
          className="animable"
        />
        <path
          d="M278,177.06V159.59c0-6.91,1.75,2.44-10.37,2.44-10.51,0-19.86-24.24-26.4-32.12a5.23,5.23,0,0,0-.7.37c-7.83,4.52-12.35,10.28-13.5,16.22a12.34,12.34,0,0,0-.37,3.18v5.11a17.71,17.71,0,0,0,3.46,10.28,32,32,0,0,0,10.41,9.08c18.58,10.69,48.62,10.69,67.15,0C294.54,179.31,278,183.65,278,177.06Z"
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "267.169px 156.039px 0px",
          }}
          id="elbqdk4f415w5"
          className="animable"
        />
        <ellipse
          cx={274.07}
          cy={149.67}
          rx={47.47}
          ry={27.41}
          style={{
            fill: "rgb(69, 90, 100)",
            transformOrigin: "274.07px 149.67px 0px",
          }}
          id="elyuujy9px82"
          className="animable"
        />
        <path
          d="M321.55,133.32v9.82a13.42,13.42,0,0,1-.37,3.32c-1.15,5.9-5.72,11.66-13.55,16.17-8.3,4.8-18.89,7.42-29.68,7.89-13.41.64-27.19-2-37.47-7.93a32.55,32.55,0,0,1-10.41-9A18,18,0,0,1,227,146.5a12.64,12.64,0,0,1-.37-3.22V133.23c0-7,4.61-14,13.87-19.4.83-.46,1.66-.92,2.54-1.34,18.66-9.35,46.91-8.89,64.61,1.34C316.94,119.22,321.59,126.27,321.55,133.32Z"
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "274.09px 138.208px 0px",
          }}
          id="el1b9m8aknsm3"
          className="animable"
        />
        <ellipse
          cx={274.07}
          cy={132.99}
          rx={47.47}
          ry={27.41}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "274.07px 132.99px 0px",
          }}
          id="eld38yr10q3it"
          className="animable"
        />
        <path
          d="M230.07,153.56a32.55,32.55,0,0,0,10.41,9c10.28,5.94,24.06,8.57,37.47,7.93h0c.13-3.45,2.06-6.09-10.37-8.49-10.32-2-25.58-6.28-32.7-17.56-6.54-10.37-2.78-24,8.14-32-.88.42-1.71.88-2.54,1.34-9.26,5.35-13.87,12.35-13.87,19.4v10.05a12.64,12.64,0,0,0,.37,3.22A18,18,0,0,0,230.07,153.56Z"
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "252.371px 141.512px 0px",
          }}
          id="el54eg4lb58u7"
          className="animable"
        />
        <path
          d="M321.28,235.53c-26.09,14.92-68.39,14.92-94.48,0-13.05-7.45-19.57-17.23-19.57-27v64.52c0,9.77,6.52,19.55,19.57,27,26.09,14.92,68.39,14.92,94.48,0,13.05-7.45,19.57-17.23,19.57-27V208.53C340.85,218.3,334.33,228.08,321.28,235.53Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "274.04px 259.885px 0px",
          }}
          id="elgyav210prgi"
          className="animable"
        />
        <path
          d="M321.28,229.35c-26.09,14.91-68.39,14.91-94.48,0-13.05-7.46-19.57-17.23-19.57-27v64.52c0,9.78,6.52,19.55,19.57,27,26.09,14.91,68.39,14.91,94.48,0,13.05-7.46,19.57-17.23,19.57-27V202.34C340.85,212.12,334.33,221.89,321.28,229.35Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "274.04px 253.696px 0px",
          }}
          id="elacug682lojo"
          className="animable"
        />
        <g
          id="freepik--Heart--inject-8"
          style={{
            transformOrigin: "232.355px 261.923px 0px",
          }}
          className="animable"
        >
          <path
            id="freepik--heart--inject-8"
            d="M252.37,267.39a30.3,30.3,0,0,0-2.54-11.72h0l0-.08c-1.85-4.22-4.53-7.76-7.51-9.48-4.35-2.5-8,1.61-9.45,7.36-.14.58-.82.61-.91,0-1.32-7.5-5.12-16.29-9.6-18.85-3-1.71-5.66-1.25-7.5.86l0,0h0c-1.57,1.84-2.53,4.87-2.52,8.82,0,12.24,7.39,23.23,13.57,34.8l6.67,11.17,6.34-3.7C245,282.16,252.39,279.63,252.37,267.39Z"
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "232.355px 261.923px 0px",
            }}
            className="animable"
          />
          <path
            d="M223.23,263.39l2.85-7.47a1.14,1.14,0,0,1,1.1-.82,1.09,1.09,0,0,1,1.06.87l4.41,17.15,3.51-9.6a1.12,1.12,0,0,1,1.08-.73h0a1.11,1.11,0,0,1,1,.81l2.61,9.28,9.07,5.23-.21.32a15.3,15.3,0,0,1-1,1.25l-.19.22-9.21-5.32a1.09,1.09,0,0,1-.51-.66l-1.8-6.39-3.65,10a1.12,1.12,0,0,1-1.12.73,1.11,1.11,0,0,1-1-.87L227,260.89l-2,4.49a1.1,1.1,0,0,1-.65.73,1.13,1.13,0,0,1-1-.06l-7.25-4.19-.05-.1c-.35-.79-.69-1.57-1-2.36l-.38-.94Z"
            style={{
              fill: "rgb(250, 250, 250)",
              transformOrigin: "232.295px 267.5px 0px",
            }}
            id="el079xzsdip3cp"
            className="animable"
          />
        </g>
        <path
          d="M274.54,263.27c-2.55,0-5.12-.08-7.73-.24a1,1,0,0,1-.93-1.06,1,1,0,0,1,1.06-.93c17.57,1.07,34-1.57,47.56-7.65a1,1,0,0,1,.82,1.83C303.5,260.53,289.52,263.27,274.54,263.27Z"
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "290.895px 258.286px 0px",
          }}
          id="elua1nuv5ecc"
          className="animable"
        />
        <path
          d="M274.54,274.13c-2.55,0-5.12-.08-7.73-.24a1,1,0,0,1-.93-1.06,1,1,0,0,1,1.06-.93c17.57,1.07,34-1.57,47.56-7.65a1,1,0,0,1,.82,1.83C303.5,271.39,289.52,274.13,274.54,274.13Z"
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "290.895px 269.146px 0px",
          }}
          id="eljpq4m996y0l"
          className="animable"
        />
        <path
          d="M274.54,285c-2.55,0-5.12-.08-7.73-.24a1,1,0,0,1,.13-2c17.57,1.08,34-1.56,47.56-7.64a1,1,0,0,1,1.32.5,1,1,0,0,1-.5,1.33C303.5,282.24,289.52,285,274.54,285Z"
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "290.892px 280.016px 0px",
          }}
          id="el8q6rheez0gd"
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Pills--inject-8"
      style={{
        transformOrigin: "315.731px 410.367px 0px",
      }}
      className="animable"
    >
      <g
        id="freepik--Pill--inject-8"
        style={{
          transformOrigin: "221.875px 427.122px 0px",
        }}
        className="animable"
      >
        <path
          d="M238.36,422.51a6.91,6.91,0,0,0-.38-.9,6.71,6.71,0,0,0-.42-.72,10.26,10.26,0,0,0-2.68-2.66V421l-2.37-1.38v-2.75c-6.6-3.13-16.36-2.9-22.52.65a9.71,9.71,0,0,0-4.61,5c0,.16-.09.31-.13.47a5.52,5.52,0,0,0-.18,1.39v5.49a6.33,6.33,0,0,0,1.23,3.65,11.52,11.52,0,0,0,3.69,3.21c6.57,3.79,17.21,3.79,23.76,0,3.28-1.9,4.93-4.38,4.93-6.86v-5.49A5.73,5.73,0,0,0,238.36,422.51Z"
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "221.875px 427.126px 0px",
          }}
          id="elngmtgsmon1"
          className="animable"
        />
        <path
          d="M227.14,415.16c-5.83-1.11-12.51-.33-17.15,2.35a9.71,9.71,0,0,0-4.61,5c0,.16-.09.31-.13.47a5.52,5.52,0,0,0-.18,1.39v5.49a6.33,6.33,0,0,0,1.23,3.65,11.52,11.52,0,0,0,3.69,3.21c4.64,2.68,11.32,3.45,17.15,2.34Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "216.105px 427.111px 0px",
          }}
          id="elkyv2wj0lp4"
          className="animable"
        />
        <path
          d="M210,417.51c-6.16,3.56-6.54,9.19-1.13,13l23.64-13.65C225.91,413.74,216.16,414,210,417.51Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "218.794px 422.599px 0px",
          }}
          id="el5wy138ra5s3"
          className="animable"
        />
        <path
          d="M208.86,429.07s-6.53-5,.73-11.31c-5.76,3.56-6,9-.72,12.75l23.64-13.65Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "218.796px 423.685px 0px",
          }}
          id="el1zst92fzafy"
          className="animable"
        />
        <path
          d="M234.88,418.23l-23.64,13.65c6.6,3.12,16.36,2.91,22.52-.65S240.29,422,234.88,418.23Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "224.958px 426.15px 0px",
          }}
          id="elizvtwp836vk"
          className="animable"
        />
        <path
          d="M211.24,431.88c6.6,3.12,16.36,2.91,22.52-.65a13.25,13.25,0,0,0,1.94-1.35c-12.09,6.06-21.81,1.79-21.81,1.79l21-13.44Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "223.47px 426.15px 0px",
          }}
          id="elyriulnlz0jn"
          className="animable"
        />
        <polygon
          points="232.51 419.61 208.87 433.32 208.87 430.51 232.51 416.86 232.51 419.61"
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "220.69px 425.09px 0px",
          }}
          id="el7mnkh81s4at"
          className="animable"
        />
        <g id="elbf9v34iewh">
          <polygon
            points="211.24 431.88 211.25 434.69 208.87 433.32 211.24 431.88"
            style={{
              opacity: 0.2,
              transformOrigin: "210.06px 433.285px 0px",
            }}
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--pill--inject-8"
        style={{
          transformOrigin: "228.484px 418.308px 0px",
        }}
        className="animable"
      >
        <path
          d="M245,413.71a6.49,6.49,0,0,0-.38-.91,7.45,7.45,0,0,0-.43-.71,10.21,10.21,0,0,0-2.67-2.66v2.75l-2.38-1.38v-2.75c-6.6-3.12-16.35-2.9-22.51.66a9.62,9.62,0,0,0-4.61,5c-.05.16-.1.31-.14.46a6,6,0,0,0-.17,1.39v5.5a6.33,6.33,0,0,0,1.23,3.65,11.39,11.39,0,0,0,3.69,3.2c6.56,3.79,17.2,3.79,23.76,0,3.28-1.89,4.92-4.37,4.92-6.85v-5.5A5.66,5.66,0,0,0,245,413.71Z"
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "228.51px 418.308px 0px",
          }}
          id="el6uz7s8rk6b4"
          className="animable"
        />
        <path
          d="M233.73,406.35c-5.84-1.11-12.51-.32-17.15,2.36a9.62,9.62,0,0,0-4.61,5c-.05.16-.1.31-.14.46a6,6,0,0,0-.17,1.39v5.5a6.33,6.33,0,0,0,1.23,3.65,11.39,11.39,0,0,0,3.69,3.2c4.64,2.68,11.31,3.46,17.15,2.35Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "222.695px 418.306px 0px",
          }}
          id="el1ss1ky27db5"
          className="animable"
        />
        <path
          d="M216.58,408.71c-6.16,3.55-6.54,9.18-1.13,13l23.64-13.64C232.49,404.93,222.74,405.15,216.58,408.71Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "225.374px 413.789px 0px",
          }}
          id="elyrbnln7genk"
          className="animable"
        />
        <path
          d="M215.45,420.27s-6.53-5,.73-11.32c-5.77,3.57-6,9-.73,12.75l23.64-13.64Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "225.379px 414.88px 0px",
          }}
          id="elm6vfdqrendg"
          className="animable"
        />
        <path
          d="M241.47,409.43l-23.64,13.65c6.6,3.12,16.35,2.9,22.51-.66S246.87,413.24,241.47,409.43Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "231.544px 417.348px 0px",
          }}
          id="el6amozatvqyq"
          className="animable"
        />
        <path
          d="M217.83,423.08c6.6,3.12,16.35,2.9,22.51-.66a13.46,13.46,0,0,0,1.94-1.34c-12.08,6.05-21.81,1.78-21.81,1.78l21-13.43Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "230.055px 417.348px 0px",
          }}
          id="ela2aa4bezefl"
          className="animable"
        />
        <polygon
          points="239.09 410.8 215.45 424.52 215.45 421.7 239.09 408.06 239.09 410.8"
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "227.27px 416.29px 0px",
          }}
          id="elmqdijdz51yd"
          className="animable"
        />
        <g id="el4tpqgfpxbil">
          <polygon
            points="217.83 423.08 217.83 425.89 215.45 424.52 217.83 423.08"
            style={{
              opacity: 0.2,
              transformOrigin: "216.64px 424.485px 0px",
            }}
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--Capsule--inject-8"
        style={{
          transformOrigin: "288.918px 420.634px 0px",
        }}
        className="animable"
      >
        <path
          d="M286.56,428.38c-1-.58-1.63-1.83-1.63-3.61a12.35,12.35,0,0,1,5.58-9.67,3.92,3.92,0,0,1,3.94-.39l7.18,4.16a7.9,7.9,0,1,1-7.91,13.68Z"
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "295.254px 423.982px 0px",
          }}
          id="elz6e05nbk8l"
          className="animable"
        />
        <path
          d="M276.67,422.66A7.91,7.91,0,1,1,284.58,409l9.87,5.74a3.92,3.92,0,0,0-3.94.39,12.35,12.35,0,0,0-5.58,9.67c0,1.78.62,3,1.63,3.61Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "283.355px 418.033px 0px",
          }}
          id="el51jngki96m5"
          className="animable"
        />
        <g id="elv9jyo8xac5f">
          <path
            d="M278.45,411.07a3,3,0,0,0,1.1,4.13l11.95,6.93a11.45,11.45,0,0,0,1.64-5.51c0-.19,0-.37,0-.54L282.58,410A3,3,0,0,0,278.45,411.07Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "285.587px 415.854px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M286.56,428.38c-1-.58-1.63-1.83-1.63-3.61a12.35,12.35,0,0,1,5.58-9.67,3.92,3.92,0,0,1,3.94-.39l7.18,4.16a7.9,7.9,0,1,1-7.91,13.68Z"
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "295.254px 423.982px 0px",
          }}
          id="el1huinj74cm3i"
          className="animable"
        />
        <g id="el85hghps48jw">
          <path
            d="M290.51,415.1a12.35,12.35,0,0,0-5.58,9.67c0,1.78.62,3,1.63,3.61l-.56-.32-.43-.25c-1-.58-1.63-1.83-1.63-3.61a12.32,12.32,0,0,1,5.58-9.67,3.92,3.92,0,0,1,3.94-.39l1,.57A3.92,3.92,0,0,0,290.51,415.1Z"
            style={{
              opacity: 0.1,
              transformOrigin: "289.2px 421.081px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M305.52,424.87h0a7.81,7.81,0,0,1-1,4.79,7.9,7.9,0,0,1-10.8,2.89l-7.16-4.17c-1-.58-1.63-1.83-1.63-3.61a9.41,9.41,0,0,1,.34-2.42l12,6.93C301.19,431.56,305.48,427.46,305.52,424.87Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "295.25px 427.981px 0px",
          }}
          id="elzeli3zqd7cj"
          className="animable"
        />
        <path
          d="M302.8,425.18a3,3,0,0,1-4.13,1.1l-12.11-7a11.31,11.31,0,0,1,3.95-4.18l.48-.25,10.7,6.19A3,3,0,0,1,302.8,425.18Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "294.889px 420.773px 0px",
          }}
          id="elazbu86kznjc"
          className="animable"
        />
        <g id="el7o36qujvnkg">
          <path
            d="M274.34,411c.09-.12.18-.24.28-.35l.21-.24.25-.26a3.45,3.45,0,0,1,.28-.26l.14-.12a8.11,8.11,0,0,1,1.35-.93l0,0,.53-.26h0c-2.21,1.33-3.61,7.09.32,9.37,1.35.78,3.37,2,7.53,4.38a9.41,9.41,0,0,0-.34,2.42c0,1.78.62,3,1.63,3.61l-9.89-5.72a7.91,7.91,0,0,1-2.89-10.8c.12-.21.26-.41.4-.61Z"
            style={{
              opacity: 0.1,
              transformOrigin: "279.6px 418.47px 0px",
            }}
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--capsule--inject-8"
        style={{
          transformOrigin: "409.944px 394.012px 0px",
        }}
        className="animable"
      >
        <path
          d="M412.51,401.63a3.94,3.94,0,0,0,1.64-3.62,12.34,12.34,0,0,0-5.58-9.66c-1.54-.89-2.94-1-3.95-.39l-7.17,4.15a7.9,7.9,0,0,0,7.9,13.69Z"
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "403.833px 397.218px 0px",
          }}
          id="elkwf60c0617"
          className="animable"
        />
        <path
          d="M422.4,395.91a7.9,7.9,0,0,0-7.9-13.69L404.62,388c1-.59,2.41-.5,3.95.39a12.34,12.34,0,0,1,5.58,9.66,3.94,3.94,0,0,1-1.64,3.62Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "415.487px 391.415px 0px",
          }}
          id="el50udw0zixz5"
          className="animable"
        />
        <g id="el6fghj8xut4e">
          <path
            d="M420.63,384.31a3,3,0,0,1-1.11,4.13l-11.94,6.93a11.42,11.42,0,0,1-1.64-5.51c0-.18,0-.36,0-.54l10.54-6.12A3,3,0,0,1,420.63,384.31Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "413.491px 389.074px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M412.51,401.63a3.94,3.94,0,0,0,1.64-3.62,12.34,12.34,0,0,0-5.58-9.66c-1.54-.89-2.94-1-3.95-.39l-7.17,4.15a7.9,7.9,0,0,0,7.9,13.69Z"
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "403.833px 397.218px 0px",
          }}
          id="elofq17ha2ss"
          className="animable"
        />
        <g id="elgk87yixyrc">
          <path
            d="M408.57,388.35a12.34,12.34,0,0,1,5.58,9.66,3.94,3.94,0,0,1-1.64,3.62l.57-.33.42-.25a3.91,3.91,0,0,0,1.64-3.61,12.33,12.33,0,0,0-5.58-9.66,3.94,3.94,0,0,0-3.95-.4l-1,.58C405.63,387.37,407,387.46,408.57,388.35Z"
            style={{
              opacity: 0.1,
              transformOrigin: "409.886px 394.328px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M393.55,398.12h0a7.89,7.89,0,0,0,11.8,7.68l7.16-4.17a3.94,3.94,0,0,0,1.64-3.62,9.36,9.36,0,0,0-.35-2.41l-12,6.93C397.88,404.8,393.6,400.71,393.55,398.12Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "403.837px 401.232px 0px",
          }}
          id="ele0lwkk90fyw"
          className="animable"
        />
        <path
          d="M396.28,398.42a3,3,0,0,0,4.13,1.11l12.11-7a11.44,11.44,0,0,0-3.95-4.17,4.55,4.55,0,0,0-.49-.25l-10.7,6.19A3,3,0,0,0,396.28,398.42Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "404.195px 394.026px 0px",
          }}
          id="elyt5g6o9ycjo"
          className="animable"
        />
        <g id="elnvyqvzj9sed">
          <path
            d="M424.74,384.29l-.28-.36-.22-.24-.25-.25-.27-.26-.14-.12a7.62,7.62,0,0,0-1.36-.93l0,0-.53-.26h0c2.22,1.34,3.62,7.1-.32,9.37-1.35.78-3.36,2-7.53,4.39a9.36,9.36,0,0,1,.35,2.41,3.94,3.94,0,0,1-1.64,3.62l9.89-5.72a7.9,7.9,0,0,0,2.89-10.8c-.12-.21-.25-.42-.39-.62Z"
            style={{
              opacity: 0.1,
              transformOrigin: "419.471px 391.765px 0px",
            }}
            className="animable"
          />
        </g>
      </g>
    </g>
    <g
      id="freepik--pillls-bottle-2--inject-8"
      style={{
        transformOrigin: "345.115px 342.379px 0px",
      }}
      className="animable"
    >
      <g
        id="freepik--pill-bottle--inject-8"
        style={{
          transformOrigin: "345.115px 342.379px 0px",
        }}
        className="animable"
      >
        <path
          d="M377.41,308.87c0-4.77-3.15-9.55-9.46-13.19-12.61-7.28-33.06-7.28-45.67,0-6.31,3.64-9.46,8.42-9.46,13.19h0v82.91h0c0,4.77,3.15,9.54,9.46,13.18,12.61,7.29,33.06,7.29,45.67,0,6.31-3.64,9.46-8.41,9.46-13.18h0Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "345.115px 350.324px 0px",
          }}
          id="ellvos8b1m5er"
          className="animable"
        />
        <g id="el4xh6bq32fo">
          <path
            d="M365,294.19V406.46A31.36,31.36,0,0,0,368,405a24,24,0,0,0,5.13-3.89V299.58a24.3,24.3,0,0,0-5.13-3.9C367,295.14,366,294.65,365,294.19Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "369.065px 350.325px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elqlj1naj8q09">
          <path
            d="M356.53,409.21a48,48,0,0,0,4.65-1.27V292.71a45.31,45.31,0,0,0-4.65-1.27Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "358.855px 350.325px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="el3eckvkw9ex3">
          <path
            d="M376.85,305.43a23.26,23.26,0,0,0-7.51-6.53c-12.45-7.19-32.09-7.68-45.54-1.49v-2.52c-.51.26-1,.51-1.52.79-6.31,3.64-9.46,8.42-9.46,13.19v12.62h0V373.7h0v18.08c0,4.77,3.15,9.54,9.46,13.18,12.61,7.29,33.06,7.29,45.67,0a23.92,23.92,0,0,0,5.76-4.5c-6,5.61-21,8.7-32.07,6.51s-17.84-9.27-17.84-14.31V376.24c6.23,3.37,14.38,5,22.34,5V347.7c-8,0-16.11-1.23-22.34-4.58V328.36c13.45,6.19,33.09,5.7,45.54-1.49a22,22,0,0,0,8.07-7.45V308.87A11.06,11.06,0,0,0,376.85,305.43Z"
            style={{
              opacity: 0.1,
              transformOrigin: "345.115px 351.769px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elcswopgvey2w">
          <path
            d="M318.27,394.09V298.5c-3.63,3.13-5.45,6.75-5.45,10.37v82.91c0,4.77,3.15,9.54,9.46,13.18a40.51,40.51,0,0,0,12.26,4.42C322.41,406.09,318.27,397.8,318.27,394.09Z"
            style={{
              opacity: 0.1,
              transformOrigin: "323.68px 353.94px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M379.37,294.12c0-5.06-3.34-10.13-10-14-13.38-7.72-35.07-7.72-48.45,0-6.69,3.86-10,8.93-10,14h0V309.3h0c0,5.06,3.34,10.13,10,14,13.38,7.72,35.07,7.72,48.45,0,6.69-3.86,10-8.93,10-14h0V294.12Z"
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "345.145px 301.71px 0px",
          }}
          id="elkc45zuu4tao"
          className="animable"
        />
        <g
          style={{
            clipPath: "url(&quot",
            transformOrigin: "345.115px 309.965px 0px",
          }}
          id="el5svv2izeg3"
          className="animable"
        >
          <g id="eltknxfnx9lub">
            <g
              style={{
                opacity: 0.2,
                transformOrigin: "345.115px 312.3px 0px",
              }}
              className="animable"
            >
              <rect
                x={378.55}
                y={292.71}
                width={1.64}
                height={23.62}
                id="elu5up13wxtkk"
                style={{
                  transformOrigin: "379.37px 304.52px 0px",
                }}
                className="animable"
              />
              <rect
                x={374.77}
                y={299.09}
                width={1.64}
                height={23.75}
                id="eluw3lwkvbi1i"
                style={{
                  transformOrigin: "375.59px 310.965px 0px",
                }}
                className="animable"
              />
              <rect
                x={369.05}
                y={303.48}
                width={1.64}
                height={23.87}
                id="elxwflh3fvj6p"
                style={{
                  transformOrigin: "369.87px 315.415px 0px",
                }}
                className="animable"
              />
              <rect
                x={362.53}
                y={306.21}
                width={1.64}
                height={24}
                id="elmmu217tb4hq"
                style={{
                  transformOrigin: "363.35px 318.21px 0px",
                }}
                className="animable"
              />
              <rect
                x={355.36}
                y={308.03}
                width={1.64}
                height={24.13}
                id="elsde6p863tjj"
                style={{
                  transformOrigin: "356.18px 320.095px 0px",
                }}
                className="animable"
              />
              <rect
                x={347.77}
                y={308.94}
                width={1.64}
                height={24.26}
                id="el75fejp8a3wb"
                style={{
                  transformOrigin: "348.59px 321.07px 0px",
                }}
                className="animable"
              />
              <rect
                x={340.06}
                y={308.91}
                width={1.64}
                height={24.38}
                id="elwtyo37iqpyc"
                style={{
                  transformOrigin: "340.88px 321.1px 0px",
                }}
                className="animable"
              />
              <rect
                x={332.54}
                y={307.91}
                width={1.64}
                height={24.51}
                id="elflhb6mf1jiw"
                style={{
                  transformOrigin: "333.36px 320.165px 0px",
                }}
                className="animable"
              />
              <rect
                x={325.49}
                y={305.88}
                width={1.64}
                height={24.64}
                id="elmgn8sa524s9"
                style={{
                  transformOrigin: "326.31px 318.2px 0px",
                }}
                className="animable"
              />
              <rect
                x={319.1}
                y={302.74}
                width={1.64}
                height={24.77}
                id="elxn2ussytud"
                style={{
                  transformOrigin: "319.92px 315.125px 0px",
                }}
                className="animable"
              />
              <rect
                x={314.03}
                y={298.37}
                width={1.64}
                height={24.89}
                id="el41tuy9wx61h"
                style={{
                  transformOrigin: "314.85px 310.815px 0px",
                }}
                className="animable"
              />
              <rect
                x={310.04}
                y={291.31}
                width={1.64}
                height={25.02}
                id="elu5o33szndlb"
                style={{
                  transformOrigin: "310.86px 303.82px 0px",
                }}
                className="animable"
              />
            </g>
          </g>
          <g id="elntqh1k725nb">
            <path
              d="M353.31,328.48c-16.95.1-25.9-5.41-28.61-9.9-2.07-3.42-2.42-9.72-2.42-16,0,0-1.91-14.24-1.54-14.28s-4.07-1-7.36-1.66a12.84,12.84,0,0,0-2.52,7.45h0V309.3h0c0,5.06,3.34,10.13,10,14C329.68,328.36,342.06,330.08,353.31,328.48Z"
              style={{
                opacity: 0.1,
                transformOrigin: "332.085px 307.849px 0px",
              }}
              className="animable"
            />
          </g>
        </g>
        <ellipse
          cx={345.11}
          cy={294.12}
          rx={34.26}
          ry={19.78}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "345.11px 294.12px 0px",
          }}
          id="el2xg2coygem7"
          className="animable"
        />
        <path
          d="M371.18,306.93c11.48-7.77,10.88-19.45-1.84-26.8-6.52-3.76-15-5.68-23.57-5.77a38.08,38.08,0,0,0-12.5,4.26c-11.56,6.61-11.56,19.81,0,26.42C343.59,310.94,359.63,311.56,371.18,306.93Z"
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "351.979px 292.175px 0px",
          }}
          id="elzx6bn12itue"
          className="animable"
        />
        <g id="eltl1h2saj9v">
          <path
            d="M324.14,403.49c11.58,5.82,30.37,5.82,41.95,0s11.59-15.26,0-21.08-30.37-5.82-41.95,0S312.55,397.67,324.14,403.49Z"
            style={{
              opacity: 0.1,
              transformOrigin: "345.115px 392.95px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M312.82,330.66c0,4.77,3.15,9.54,9.46,13.18s14.57,5.46,22.83,5.46v32.87c-8.26,0-16.53-1.82-22.83-5.46s-9.46-8.41-9.46-13.18Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "328.965px 356.415px 0px",
          }}
          id="elqktv2ru03zd"
          className="animable"
        />
        <path
          d="M313.48,301.71s11.14,11.22,32.66,10.63,30.14-12.34,31.27-16a13.44,13.44,0,0,0-2.9-12.4,14.28,14.28,0,0,1,4.86,10.16V309.3c0,1.28.66,5.7-6.29,11.42,0,0,4.08-4.63,4.08-9.08v-10.5s-7.62,12.74-31,12.74S314.66,303.62,313.48,301.71Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "346.433px 302.33px 0px",
          }}
          id="eljmtgl67fieo"
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Character--inject-8"
      style={{
        transformOrigin: "115.678px 214.577px 0px",
      }}
      className="animable"
    >
      <g
        id="freepik--character--inject-8"
        style={{
          transformOrigin: "115.678px 220.705px 0px",
        }}
        className="animable"
      >
        <path
          d="M169.88,331.41c-.15-3.77,2.86-40.94,2.86-40.94l-18,11.92c1.9,21.75,3.13,28.56,3.2,32.19h0a2.87,2.87,0,0,0-.1.93c0,4.3,5.34,6.62,9.5,9.49a78.32,78.32,0,0,0,11.24,6.69c3,1.38,9.15,1.51,9.91-.26-5.06-3.52-12.42-8.79-16.44-12.81C170.1,336.7,170,335.18,169.88,331.41Z"
          style={{
            fill: "rgb(255, 168, 167)",
            transformOrigin: "171.615px 321.606px 0px",
          }}
          id="elkc5iob98dbg"
          className="animable"
        />
        <path
          d="M197.08,356.06c.26,0,.65,2.6-.76,3.71-1.63,1.28-6.4,3.38-12.7,2.63s-9.72-2.52-12.25-4.45-6.06-4.64-8.91-5.21c-2.65-.53-5.53-1.63-6.37-2.37s-.73-4.07-.73-4.07Z"
          style={{
            fill: "rgb(69, 90, 100)",
            transformOrigin: "176.345px 354.429px 0px",
          }}
          id="elu6y35i7wne"
          className="animable"
        />
        <path
          d="M157.87,333.53a1,1,0,0,0-1,.77c-.2.92-.41,3.58-.82,5.08a24.4,24.4,0,0,0-.62,8.06c.34,1.7,4.66,2.88,7.42,3.84a35.82,35.82,0,0,1,8.94,5.15c3,2.23,9.2,4.55,13.31,4.52,4.86,0,10.34-1.47,11.59-3.52,1.55-2.55.34-4-6.87-7.06-1.37-.58-6.16-3.17-7.8-4.11-4.42-2.53-8.79-6.06-10.83-10.55a2.46,2.46,0,0,0-1-1.37,2.49,2.49,0,0,0-1.41-.12,12.94,12.94,0,0,0-3.47.95c-1,.42-2.34.91-2.32,2a3.53,3.53,0,0,0,.28,1.15c.08.23.15.54-.07.67s-.49,0-.68-.17a17.74,17.74,0,0,1-1.94-2.12,6.37,6.37,0,0,0-1.14-1.06,13.3,13.3,0,0,1-1.23-.73C157.79,334.55,157.89,334.06,157.87,333.53Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "176.308px 347.24px 0px",
          }}
          id="el96lgnjmnbj5"
          className="animable"
        />
        <g id="elrd3c18baha">
          <path
            d="M157.87,333.53a1,1,0,0,0-1,.77c-.2.92-.41,3.58-.82,5.08a24.4,24.4,0,0,0-.62,8.06c.34,1.7,4.66,2.88,7.42,3.84a35.82,35.82,0,0,1,8.94,5.15c3,2.23,9.2,4.55,13.31,4.52,4.86,0,10.34-1.47,11.59-3.52,1.55-2.55.34-4-6.87-7.06-1.37-.58-6.16-3.17-7.8-4.11-4.42-2.53-8.79-6.06-10.83-10.55a2.46,2.46,0,0,0-1-1.37,2.49,2.49,0,0,0-1.41-.12,12.94,12.94,0,0,0-3.47.95c-1,.42-2.34.91-2.32,2a3.53,3.53,0,0,0,.28,1.15c.08.23.15.54-.07.67s-.49,0-.68-.17a17.74,17.74,0,0,1-1.94-2.12,6.37,6.37,0,0,0-1.14-1.06,13.3,13.3,0,0,1-1.23-.73C157.79,334.55,157.89,334.06,157.87,333.53Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.9,
              transformOrigin: "176.308px 347.24px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M171.84,356.43c-1.25-.94-2.59-1.86-3.93-2.67h0s8.48,4.7,11.91,2.57c2.2-1.38,1.15-3.05,1.1-3.58s5.39-2.82,7.54-3.05h0c.59.3,1.09.54,1.41.68,7.21,3,8.42,4.51,6.87,7.06-1.25,2.05-5.22,3.66-11.5,3.66C181.13,361.09,174.81,358.66,171.84,356.43Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "182.622px 355.4px 0px",
          }}
          id="elg7ojocodzh"
          className="animable"
        />
        <g id="el7e31sftirxn">
          <path
            d="M171.84,356.43c-1.25-.94-2.59-1.86-3.93-2.67h0s8.48,4.7,11.91,2.57c2.2-1.38,1.15-3.05,1.1-3.58s5.39-2.82,7.54-3.05h0c.59.3,1.09.54,1.41.68,7.21,3,8.42,4.51,6.87,7.06-1.25,2.05-5.22,3.66-11.5,3.66C181.13,361.09,174.81,358.66,171.84,356.43Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.8,
              transformOrigin: "182.622px 355.4px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M180.33,351.18c4.48,1.64,2.57,2.4,1.31,2.57s-8.8-3.86-13.4-7.18c-4.8-3.46-7.83-7.25-9.61-8.52-.67-.47-1.38-1.05-2-1.6.12-.9.22-1.72.31-2.15a1,1,0,0,1,1-.77c0,.53-.08,1,.36,1.42a13.3,13.3,0,0,0,1.23.73,6.37,6.37,0,0,1,1.14,1.06,17.74,17.74,0,0,0,1.94,2.12C166.46,343.39,175.84,349.55,180.33,351.18Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "169.887px 343.642px 0px",
          }}
          id="el60b8m3wmv6"
          className="animable"
        />
        <path
          d="M181.32,353.14a.51.51,0,0,1-.28-.09.38.38,0,0,1-.06-.57,11.24,11.24,0,0,1,8.21-3,.43.43,0,0,1,.4.45.44.44,0,0,1-.49.36,10.34,10.34,0,0,0-7.43,2.66A.5.5,0,0,1,181.32,353.14Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "185.234px 351.303px 0px",
          }}
          id="el7bekzup9mw"
          className="animable"
        />
        <path
          d="M177.41,351a.49.49,0,0,1-.28-.09.38.38,0,0,1-.06-.57,11.21,11.21,0,0,1,8.21-3,.41.41,0,0,1,.4.44.43.43,0,0,1-.49.36,10.38,10.38,0,0,0-7.43,2.66A.47.47,0,0,1,177.41,351Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "181.325px 349.163px 0px",
          }}
          id="elwhwo2qh5cgj"
          className="animable"
        />
        <path
          d="M173.5,348.87a.47.47,0,0,1-.28-.09.38.38,0,0,1-.06-.57,11.21,11.21,0,0,1,8.21-2.95.4.4,0,0,1,.39.45.41.41,0,0,1-.48.35,10.38,10.38,0,0,0-7.43,2.66A.47.47,0,0,1,173.5,348.87Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "177.411px 347.057px 0px",
          }}
          id="el1lbly2oeo63"
          className="animable"
        />
        <path
          d="M169.88,346.5a.47.47,0,0,1-.28-.09.39.39,0,0,1-.07-.57,11.26,11.26,0,0,1,8.22-2.95.41.41,0,0,1,.39.44.43.43,0,0,1-.49.36,10.4,10.4,0,0,0-7.43,2.66A.45.45,0,0,1,169.88,346.5Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "173.789px 344.687px 0px",
          }}
          id="el2m4upw2il1w"
          className="animable"
        />
        <path
          d="M166.33,344.13a.47.47,0,0,1-.28-.09.39.39,0,0,1-.07-.57c1.74-2,5.67-3.32,9-3a.41.41,0,0,1,.39.44.43.43,0,0,1-.49.36c-3-.29-6.63.92-8.18,2.69A.44.44,0,0,1,166.33,344.13Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "170.629px 342.276px 0px",
          }}
          id="elatniqu8ytpa"
          className="animable"
        />
        <path
          d="M135,303.71l-18.25-.34c.27,12.19,2.21,25.71,2.52,37.13.06,2.16,0,16.72.76,21.15,1.22,6.65,9.11,5.75,9.53,1.77s-.51-21-.48-22.74c-.07-7.33,3.36-19.7,5.17-32.86C134.37,307.18,135,304.34,135,303.71Z"
          style={{
            fill: "rgb(255, 168, 167)",
            transformOrigin: "125.875px 334.949px 0px",
          }}
          id="elreubbu8iahe"
          className="animable"
        />
        <path
          d="M133.37,366.19c.18,3.91-.2,6.54-1.47,9s-4.05,3.4-7,3S118,376.3,116.79,373a19.6,19.6,0,0,1-1-9.25Z"
          style={{
            fill: "rgb(69, 90, 100)",
            transformOrigin: "124.516px 371.014px 0px",
          }}
          id="eljabcwsn4b2a"
          className="animable"
        />
        <path
          d="M118.71,339.11c-1.19.19-1,5.44-1.07,10.73-.12,5.66-1.7,8.15-1.86,12.8-.17,5.31.39,7.63,2.77,10.61s9.88,5.45,12.91.58c2.47-4,2.13-8.08,1.29-13.29A121.71,121.71,0,0,1,131,347.23c-.2-4-.33-7.78-1.41-8.12,0,.58,0,1.32,0,1.89s.1,2.58-.57,2.66a8,8,0,0,1-.17-1.47,1.66,1.66,0,0,0-.47-1.24,2.41,2.41,0,0,0-1.39-.42c-1.2-.1-2.4-.18-3.61-.17a17.73,17.73,0,0,0-1.79.09,5.11,5.11,0,0,0-1.5.34,1,1,0,0,0-.58.59,1.51,1.51,0,0,0,0,.6,4,4,0,0,1,0,1.09c0,.16-.12.36-.3.34s-.21-.17-.23-.3c-.07-.63,0-1.1-.09-1.84,0-.34,0-.81,0-1.15S118.72,339.44,118.71,339.11Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "124.562px 357.85px 0px",
          }}
          id="elgrh2mb8hi08"
          className="animable"
        />
        <g id="elw2weayd0xpi">
          <path
            d="M118.71,339.11c-1.19.19-1,5.44-1.07,10.73-.12,5.66-1.7,8.15-1.86,12.8-.17,5.31.39,7.63,2.77,10.61s9.88,5.45,12.91.58c2.47-4,2.13-8.08,1.29-13.29A121.71,121.71,0,0,1,131,347.23c-.2-4-.33-7.78-1.41-8.12,0,.58,0,1.32,0,1.89s.1,2.58-.57,2.66a8,8,0,0,1-.17-1.47,1.66,1.66,0,0,0-.47-1.24,2.41,2.41,0,0,0-1.39-.42c-1.2-.1-2.4-.18-3.61-.17a17.73,17.73,0,0,0-1.79.09,5.11,5.11,0,0,0-1.5.34,1,1,0,0,0-.58.59,1.51,1.51,0,0,0,0,.6,4,4,0,0,1,0,1.09c0,.16-.12.36-.3.34s-.21-.17-.23-.3c-.07-.63,0-1.1-.09-1.84,0-.34,0-.81,0-1.15S118.72,339.44,118.71,339.11Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.9,
              transformOrigin: "124.562px 357.85px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M115.89,361.12c0,.77-.18,5.84,1.42,6.61s2.19-3.19,2.19-3.19,3.7-1.41,9.49.29c-.19.84,1,3.44,2.42,2.73s1.41-6.5,1.41-6.57c.79,5,1,9-1.36,12.84-3,4.87-10.54,2.41-12.91-.58s-2.94-5.3-2.77-10.61C115.8,362.11,115.84,361.61,115.89,361.12Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "124.557px 368.791px 0px",
          }}
          id="elvp0crfzt0mj"
          className="animable"
        />
        <g id="el7qndi3octk9">
          <path
            d="M115.89,361.12c0,.77-.18,5.84,1.42,6.61s2.19-3.19,2.19-3.19,3.7-1.41,9.49.29c-.19.84,1,3.44,2.42,2.73s1.41-6.5,1.41-6.57c.79,5,1,9-1.36,12.84-3,4.87-10.54,2.41-12.91-.58s-2.94-5.3-2.77-10.61C115.8,362.11,115.84,361.61,115.89,361.12Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.8,
              transformOrigin: "124.557px 368.791px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M120,364.83c.19,1.9-1.94,1.45-2.33-1.22s.52-9.17,0-13.77c.11-5.29-.12-10.54,1.07-10.73a1.23,1.23,0,0,0,0,.19l.13,2.67a11.17,11.17,0,0,0,.06,1.14c.4,3.87.63,11.4.65,15A59.13,59.13,0,0,0,120,364.83Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "118.791px 352.55px 0px",
          }}
          id="elty59hjzhw7"
          className="animable"
        />
        <path
          d="M128.34,364.83a54.76,54.76,0,0,0,.4-6.75c0-3.54.25-11,.65-14.88a8.8,8.8,0,0,0,.17-2.2c0-.53,0-1.21,0-1.77v-.12c.79.26,1.07,2.35,1.24,5,0,1.53,0,3.43-.16,5.74-.53,4.6.39,11.14,0,13.81S128.16,366.73,128.34,364.83Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "129.565px 352.56px 0px",
          }}
          id="elitpz7mctj4"
          className="animable"
        />
        <path
          d="M129.13,365a.51.51,0,0,1-.17,0,15.52,15.52,0,0,0-9.3,0,.48.48,0,0,1-.58-.24.4.4,0,0,1,.26-.52,16.31,16.31,0,0,1,9.95,0,.4.4,0,0,1,.25.53A.46.46,0,0,1,129.13,365Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "124.312px 364.244px 0px",
          }}
          id="el14605omsgfe"
          className="animable"
        />
        <path
          d="M129.67,360.76a.39.39,0,0,1-.15,0,19.15,19.15,0,0,0-10.42,0,.47.47,0,0,1-.56-.26.39.39,0,0,1,.28-.51,19.94,19.94,0,0,1,11,0,.4.4,0,0,1,.27.52A.46.46,0,0,1,129.67,360.76Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "124.316px 359.998px 0px",
          }}
          id="elv58eown3xvt"
          className="animable"
        />
        <path
          d="M119,356.78a.46.46,0,0,1-.41-.24.39.39,0,0,1,.22-.53c5.18-2.14,10.75-.15,11-.06a.39.39,0,0,1,.25.52.47.47,0,0,1-.58.23s-5.46-1.95-10.28,0A.39.39,0,0,1,119,356.78Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "124.321px 355.901px 0px",
          }}
          id="elvqmq01xz81"
          className="animable"
        />
        <path
          d="M118.86,353.07a.45.45,0,0,1-.4-.23.38.38,0,0,1,.21-.54,15,15,0,0,1,11.19-.11.38.38,0,0,1,.21.53.47.47,0,0,1-.59.2,14.28,14.28,0,0,0-10.43.11A.65.65,0,0,1,118.86,353.07Z"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "124.263px 352.116px 0px",
          }}
          id="elqsmaw2kh91r"
          className="animable"
        />
        <path
          d="M118.76,348.89a.46.46,0,0,1-.4-.22.38.38,0,0,1,.2-.54c5.32-2.39,11.05-.11,11.29,0a.39.39,0,0,1,.23.53.47.47,0,0,1-.59.21c-.06,0-5.6-2.22-10.53,0A.45.45,0,0,1,118.76,348.89Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "124.214px 347.974px 0px",
          }}
          id="ely1ooh1icw3c"
          className="animable"
        />
        <path
          d="M125.93,177.68a75,75,0,0,0-5.73,21.5c-2.07,27-3.12,66.11-3.12,66.11-.23,2.82-2,9.22-3.22,21.62-1.42,14.65,2.52,63.87,2.52,63.87s8.77,5.22,15.59-.73c0,0,7.92-68.26,9.76-82.82,1.61-12.72,6.09-40.23,6.09-40.23l3.45,42.08s-.49,11.44-.15,18.83c.4,8.6,4.6,51,4.6,51s12,4.07,17.43-1.71c0,0,1.27-60.78,1.46-71.13.35-20.31-.71-58.16-1.43-78.78Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "144.115px 265.289px 0px",
          }}
          id="el329nfzrstn"
          className="animable"
        />
        <g id="elvbxt03pzqur">
          <path
            d="M125.93,177.68a75,75,0,0,0-5.73,21.5c-2.07,27-3.12,66.11-3.12,66.11-.23,2.82-2,9.22-3.22,21.62-1.42,14.65,2.52,63.87,2.52,63.87s8.77,5.22,15.59-.73c0,0,7.92-68.26,9.76-82.82,1.61-12.72,6.09-40.23,6.09-40.23l3.45,42.08s-.49,11.44-.15,18.83c.4,8.6,4.6,51,4.6,51s12,4.07,17.43-1.71c0,0,1.27-60.78,1.46-71.13.35-20.31-.71-58.16-1.43-78.78Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.2,
              transformOrigin: "144.115px 265.289px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="el7s1k0rzplam">
          <path
            d="M147.82,227l1.88-12.94s10.94-1.46,15.89-6.12a17.93,17.93,0,0,1-12.88,8.14l-1.94,11.71-.13,36.41Z"
            style={{
              opacity: 0.2,
              transformOrigin: "156.705px 236.07px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M182.89,181.44,174.35,192s-5.95-9.85-5.7-9.85,7.52-11.94,7.52-11.94Z"
          style={{
            fill: "rgb(255, 168, 167)",
            transformOrigin: "175.766px 181.105px 0px",
          }}
          id="el0ppegiu5neq"
          className="animable"
        />
        <path
          d="M155.54,114.6c6.7-.11,14.14-.29,20.61,7.41,5,6,18.62,24.52,21.35,29.27,3.09,5.36,3.52,7.54,1.1,11.55-2.3,3.82-16.94,20.66-16.94,20.66-3.2-2.5-5.52-6.26-7.32-10.78l8.55-14.28-16.42-24.25Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "177.848px 149.03px 0px",
          }}
          id="elf8orcioio6h"
          className="animable"
        />
        <g id="eluks2e5g8jq9">
          <path
            d="M155.54,114.6c6.7-.11,14.14-.29,20.61,7.41,5,6,18.62,24.52,21.35,29.27,3.09,5.36,3.52,7.54,1.1,11.55-2.3,3.82-16.94,20.66-16.94,20.66-3.2-2.5-5.52-6.26-7.32-10.78l8.55-14.28-16.42-24.25Z"
            style={{
              opacity: 0.4,
              transformOrigin: "177.848px 149.03px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M155.11,114.3c10.44-.89,15,.59,19,4.81,2.8,3,19.88,25.79,19.88,25.79s-.72,4.5-13.41,11.26l-13.86-16.22Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "174.55px 135.106px 0px",
          }}
          id="el8s0wxrelkeg"
          className="animable"
        />
        <g id="elmjr2888lpy">
          <path
            d="M155.11,114.3c10.44-.89,15,.59,19,4.81,2.8,3,19.88,25.79,19.88,25.79s-.72,4.5-13.41,11.26l-13.86-16.22Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.2,
              transformOrigin: "174.55px 135.106px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M138.84,114.78c-2.35.57-10.75.57-16.3,2.54-3,1.08-3.68,4.83-4.05,9.13-.79,9.22.65,26.5.65,26.5L119,197c7.63,5.56,39,8.39,55.05-3.34,0,0-2-52.48-2-60.65s-4.54-18-17.07-18.71Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "146.152px 158.058px 0px",
          }}
          id="eleyss7f4xtwr"
          className="animable"
        />
        <g id="el4wjkzb0sa1x">
          <path
            d="M138.84,114.78c-2.35.57-10.75.57-16.3,2.54-3,1.08-3.68,4.83-4.05,9.13-.79,9.22.65,26.5.65,26.5L119,197c7.63,5.56,39,8.39,55.05-3.34,0,0-2-52.48-2-60.65s-4.54-18-17.07-18.71Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.55,
              transformOrigin: "146.152px 158.058px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M154,117.14c-2.79,2.26-10.24,2.91-15.41.82a132.59,132.59,0,0,0,15.82,14.93C155.94,129.1,156.1,122.56,154,117.14Z"
          style={{
            fill: "rgb(69, 90, 100)",
            transformOrigin: "147.079px 125.015px 0px",
          }}
          id="ell35h2v335t"
          className="animable"
        />
        <path
          d="M141.42,80a14.71,14.71,0,1,0,14.7-14.7A14.71,14.71,0,0,0,141.42,80Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "156.13px 80.0102px 0px",
          }}
          id="elt8qys5i51wd"
          className="animable"
        />
        <path
          d="M130.7,90.42s2.39,11.19,3.34,13a5.11,5.11,0,0,0,2.93,2.37l-.17-7.88Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "133.835px 98.105px 0px",
          }}
          id="el70qu033668n"
          className="animable"
        />
        <path
          d="M133.69,74.38a5.51,5.51,0,0,0-4.56,1.6c-2,2-.87,8.29,1.57,14.44l4.49.51Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "131.646px 82.6363px 0px",
          }}
          id="el4u6k95wcljw"
          className="animable"
        />
        <path
          d="M136.54,90.94c-.9.54-2.19-1.19-3.3-2.36s-4.73-2.74-6.54,1.06,1.6,9,4.45,9.91c4.92,1.54,5.65-1.61,5.65-1.61l.37,18.28h0c3.4,4.36,11.57,12.24,17.27,16.67,1.68-4.25,1.68-12-1.26-17.68l0-5.4a31.46,31.46,0,0,0,6.63.34c3.62-.56,5.89-3.41,7-7.33,1.76-6.28,2.43-11.36.93-23.72-1.63-13.55-17.4-13.69-25.91-8.34S136.54,90.94,136.54,90.94Z"
          style={{
            fill: "rgb(255, 168, 167)",
            transformOrigin: "147.381px 100.182px 0px",
          }}
          id="elq7ibz25tzk"
          className="animable"
        />
        <path
          d="M167.45,73.05c-3,2.42-4.9,3.71-10.65,3.91a25.72,25.72,0,0,1-11.4-2.24,7.47,7.47,0,0,1-1.7,3.72,11.28,11.28,0,0,1-4,2.65c-.31,2.43-.3,5.56-.45,8-.05.82,0,5.14-2,3.91a10.42,10.42,0,0,1-1.71-1.93,29.77,29.77,0,0,0-2.26-2.51c-1.55-1.41.45-14.2.45-14.2a6.21,6.21,0,0,1,1.07-6.91c2.13-2.34,5.1-5.2,14.75-4.06,6.88.82,14.36,3,20.41,0C170.76,66.87,169.58,71.32,167.45,73.05Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "151.451px 78.1751px 0px",
          }}
          id="elth22l0tkboh"
          className="animable"
        />
        <path
          d="M133.7,75.37l-5.44-2.92a3,3,0,0,1,4.12-1.36A3.25,3.25,0,0,1,133.7,75.37Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "131.15px 73.0539px 0px",
          }}
          id="elfnb7kelov3n"
          className="animable"
        />
        <path
          d="M153.16,109.81s-8.07-1.63-10.89-3.13a9.26,9.26,0,0,1-3.9-3.86,12.74,12.74,0,0,0,2.22,4.56c2.06,2.63,12.57,4.58,12.57,4.58Z"
          style={{
            fill: "rgb(242, 143, 143)",
            transformOrigin: "145.765px 107.39px 0px",
          }}
          id="elpdbg4fr4s7"
          className="animable"
        />
        <path
          d="M151.48,88.87a1.58,1.58,0,0,1-1.55,1.61,1.62,1.62,0,1,1,1.55-1.61Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "149.86px 88.8615px 0px",
          }}
          id="elkd2qp7w1s3"
          className="animable"
        />
        <path
          d="M149.93,83l-3.59,2a2.15,2.15,0,0,1,.84-2.85A2,2,0,0,1,149.93,83Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "148.015px 83.4509px 0px",
          }}
          id="eld7k1mgd45iu"
          className="animable"
        />
        <path
          d="M150.32,99l5.86,2.2a3.07,3.07,0,0,1-4,1.94A3.29,3.29,0,0,1,150.32,99Z"
          style={{
            fill: "rgb(242, 143, 143)",
            transformOrigin: "153.156px 101.167px 0px",
          }}
          id="el4oh392z33be"
          className="animable"
        />
        <path
          d="M166.28,84.32,163,81.8a2,2,0,0,1,2.84-.43A2.17,2.17,0,0,1,166.28,84.32Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "164.836px 82.6406px 0px",
          }}
          id="elbljhgxtmlp7"
          className="animable"
        />
        <path
          d="M165,88.68a1.51,1.51,0,1,1-1.51-1.57A1.54,1.54,0,0,1,165,88.68Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "163.491px 88.62px 0px",
          }}
          id="el4xdagh59fg2"
          className="animable"
        />
        <polygon
          points="156.93 86.37 157.5 96.92 163.01 95.04 156.93 86.37"
          style={{
            fill: "rgb(242, 143, 143)",
            transformOrigin: "159.97px 91.645px 0px",
          }}
          id="el591va4qo76d"
          className="animable"
        />
        <path
          d="M135.87,115.94c-2.13.46-2.71,9.48-2.2,20.41.09,1.81.21,3.23.18,4l-1.22-.05c0-.65-.06-2.05-.17-3.82-.55-8.64-.61-21.2,3.16-21.77a5.09,5.09,0,0,1,1.52-.14v1.29Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "134.663px 127.456px 0px",
          }}
          id="el1ci4m8guzqp"
          className="animable"
        />
        <g id="el4t98b390hkg">
          <path
            d="M135.87,115.94c-2.13.46-2.71,9.48-2.2,20.41.09,1.81.21,3.23.18,4l-1.22-.05c0-.65-.06-2.05-.17-3.82-.55-8.64-.61-21.2,3.16-21.77a5.09,5.09,0,0,1,1.52-.14v1.29Z"
            style={{
              opacity: 0.2,
              transformOrigin: "134.663px 127.456px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M130.88,168.91h-.1l0-.85a5.46,5.46,0,0,0,2.8-1,10.23,10.23,0,0,1-2.33-2.11c-1.37-1.77-4.38-14.46-3.45-17.42.78-2.48,3.21-7.94,5.38-8h.06c2.16,0,5,5.16,6,7.55,1.18,2.87-.74,15.76-2,17.65a10.59,10.59,0,0,1-2.14,2.3,5.49,5.49,0,0,0,2.88.8l.1.84a6.65,6.65,0,0,1-3.73-1.08A6.85,6.85,0,0,1,130.88,168.91Zm2.39-28.61h0c-1.28,0-3.56,4.11-4.61,7.44-.85,2.71,2,15,3.32,16.64a9.62,9.62,0,0,0,2.4,2.1,9.86,9.86,0,0,0,2.21-2.29c1.13-1.75,3-14.23,1.89-16.87C137.15,144.12,134.56,140.3,133.27,140.3Z"
          style={{
            fill: "rgb(69, 90, 100)",
            transformOrigin: "133.617px 154.22px 0px",
          }}
          id="elrgv4vz8b9c"
          className="animable"
        />
        <path
          d="M139.33,168.12a1.25,1.25,0,1,1-1.3-1.2A1.25,1.25,0,0,1,139.33,168.12Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "138.081px 168.169px 0px",
          }}
          id="elv8puwp2bj5h"
          className="animable"
        />
        <path
          d="M132.05,168.43a1.26,1.26,0,1,1-1.31-1.2A1.25,1.25,0,0,1,132.05,168.43Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "130.791px 168.489px 0px",
          }}
          id="elf54i7h5ikj5"
          className="animable"
        />
        <path
          d="M139.46,152.59l-.71-.05a.28.28,0,0,1-.26-.3,14.85,14.85,0,0,0-.22-4.84c-1.27-3.07-3.88-6.93-5-6.89s-3.41,4.12-4.4,7.29a14.29,14.29,0,0,0,.19,4.84.28.28,0,0,1-.24.32l-.71.11a.28.28,0,0,1-.32-.24c-.39-2.65-.44-4.47-.14-5.41.53-1.7,3-8.08,5.57-8.18s5.57,6,6.25,7.68a14.5,14.5,0,0,1,.31,5.4A.28.28,0,0,1,139.46,152.59Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "133.68px 146.156px 0px",
          }}
          id="elxpdg2nm299"
          className="animable"
        />
        <g id="elczq0r1lxy1">
          <g
            style={{
              opacity: 0.2,
              transformOrigin: "133.68px 146.156px 0px",
            }}
            className="animable"
          >
            <path
              d="M139.46,152.59l-.71-.05a.28.28,0,0,1-.26-.3,14.85,14.85,0,0,0-.22-4.84c-1.27-3.07-3.88-6.93-5-6.89s-3.41,4.12-4.4,7.29a14.29,14.29,0,0,0,.19,4.84.28.28,0,0,1-.24.32l-.71.11a.28.28,0,0,1-.32-.24c-.39-2.65-.44-4.47-.14-5.41.53-1.7,3-8.08,5.57-8.18s5.57,6,6.25,7.68a14.5,14.5,0,0,1,.31,5.4A.28.28,0,0,1,139.46,152.59Z"
              id="elnyuvi51k5ch"
              style={{
                transformOrigin: "133.68px 146.156px 0px",
              }}
              className="animable"
            />
          </g>
        </g>
        <path
          d="M153.16,113.32c1.93.26,7.85,3.19,10.66,12s2.64,22.6,2.55,26.46-.35,6.75-.35,6.75h-1.38s.2-4.91.25-7.38c.2-10.84-.48-20.08-2.63-25.67-2.56-6.66-5.54-8.79-9.1-10.49Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "159.785px 135.925px 0px",
          }}
          id="eln4hfagc94c"
          className="animable"
        />
        <g id="el23amckej9ll">
          <path
            d="M153.16,113.32c1.93.26,7.85,3.19,10.66,12s2.64,22.6,2.55,26.46-.35,6.75-.35,6.75h-1.38s.2-4.91.25-7.38c.2-10.84-.48-20.08-2.63-25.67-2.56-6.66-5.54-8.79-9.1-10.49Z"
            style={{
              opacity: 0.2,
              transformOrigin: "159.785px 135.925px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M160.11,159.17a5,5,0,1,0,5-5A5,5,0,0,0,160.11,159.17Z"
          style={{
            fill: "rgb(69, 90, 100)",
            transformOrigin: "165.11px 159.17px 0px",
          }}
          id="elaga0s95ze2"
          className="animable"
        />
        <circle
          cx={165.09}
          cy={159.17}
          r={2.83}
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "165.09px 159.17px 0px",
          }}
          id="elswu3aw1bbm"
          className="animable"
        />
        <path
          d="M122.52,117.14c-6.1,1.26-8.17,2.7-11.19,7.59s-14.47,39.42-14.47,39.42l-35.31,6.34c-2.62.3-6.63-1.31-11.59-1.46-5.64-.18-9-3.9-9-.62,0,2.65,5.92,5,5.92,5a59.22,59.22,0,0,1-10,1.16c-3.34.05-6.23,2.19-5.59,4.6a11.17,11.17,0,0,0,3.54,6.09c4.92,3.91,15.73,1.8,25.38-.55,7.43-1.81,25.94-2.79,35.47-4.16,11-1.19,12.86-2.84,16.59-9.91,2.6-5,11.09-30.31,11.09-30.31C125,128.38,124.18,121.8,122.52,117.14Z"
          style={{
            fill: "rgb(255, 168, 167)",
            transformOrigin: "77.7267px 152.286px 0px",
          }}
          id="elo6yn0x7chv"
          className="animable"
        />
        <path
          d="M124.24,116.8c-4.2.3-9.59-.45-14.77,10.6S96.4,163.35,96.4,163.35l-24.26,5.24s1.9,9.67,1.78,14.16c0,0,25-1.78,30.38-2.67s4.85-1.87,9.25-10.35,10.69-28.18,10.69-28.18S127.55,127.52,124.24,116.8Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "98.9256px 149.775px 0px",
          }}
          id="elailm7t1ewrg"
          className="animable"
        />
        <g id="el4txyg99wrae">
          <path
            d="M124.24,116.8c-4.2.3-9.59-.45-14.77,10.6S96.4,163.35,96.4,163.35l-24.26,5.24s1.9,9.67,1.78,14.16c0,0,25-1.78,30.38-2.67s4.85-1.87,9.25-10.35,10.69-28.18,10.69-28.18S127.55,127.52,124.24,116.8Z"
            style={{
              opacity: 0.4,
              transformOrigin: "98.9256px 149.775px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M126.41,116.3c-2.93.22-9.9-.15-13.6,4.86-3.36,4.55-13.89,29.93-13.89,29.93s4.9,7.82,19.33,10.06c0,0,5.12-13.44,7.46-19.18S128.87,123.82,126.41,116.3Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "113.412px 138.725px 0px",
          }}
          id="elvbzcx923cd"
          className="animable"
        />
        <g id="elrolcsq9j46p">
          <path
            d="M126.41,116.3c-2.93.22-9.9-.15-13.6,4.86-3.36,4.55-13.89,29.93-13.89,29.93s4.9,7.82,19.33,10.06c0,0,5.12-13.44,7.46-19.18S128.87,123.82,126.41,116.3Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.2,
              transformOrigin: "113.412px 138.725px 0px",
            }}
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--Syringe--inject-8"
        style={{
          transformOrigin: "68.8292px 214.577px 0px",
        }}
        className="animable"
      >
        <path
          d="M70.93,119.15c-9.61-4.21-24.26-2.58-32.72,3.65-4.22,3.11-6.1,6.78-5.72,10.2h0L48.18,273.54h.06c.66,3.12,3.23,6,7.68,7.93,9.62,4.21,24.27,2.57,32.72-3.65,3.91-2.88,5.79-6.24,5.75-9.43h.06L78.76,127.84C78.38,124.42,75.74,121.25,70.93,119.15Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "63.4461px 200.309px 0px",
          }}
          id="elazq3hkmly8t"
          className="animable"
        />
        <g id="elsibaxn86uu">
          <path
            d="M70.93,119.15c-9.61-4.21-24.26-2.58-32.72,3.65-4.22,3.11-6.1,6.78-5.72,10.2h0L48.18,273.54h.06c.66,3.12,3.23,6,7.68,7.93,9.62,4.21,24.27,2.57,32.72-3.65,3.91-2.88,5.79-6.24,5.75-9.43h.06L78.76,127.84C78.38,124.42,75.74,121.25,70.93,119.15Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.7,
              transformOrigin: "63.4461px 200.309px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M40.32,141.69C30.7,137.48,29.76,129,38.21,122.8s23.11-7.86,32.72-3.65S81.5,131.81,73,138,49.94,145.9,40.32,141.69Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "55.6221px 130.412px 0px",
          }}
          id="elr28byetinu"
          className="animable"
        />
        <g id="elqqe8qjgtlcr">
          <path
            d="M40.32,141.69C30.7,137.48,29.76,129,38.21,122.8s23.11-7.86,32.72-3.65S81.5,131.81,73,138,49.94,145.9,40.32,141.69Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.8,
              transformOrigin: "55.6221px 130.412px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M66.72,378.24c-9.61-4.21-10.56-12.67-2.1-18.89s23.1-7.86,32.72-3.66,10.56,12.67,2.1,18.89S76.34,382.44,66.72,378.24Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "82.0317px 366.965px 0px",
          }}
          id="eljbw95s8mlqo"
          className="animable"
        />
        <g id="elwroewedre4l">
          <path
            d="M66.72,378.24c-9.61-4.21-10.56-12.67-2.1-18.89s23.1-7.86,32.72-3.66,10.56,12.67,2.1,18.89S76.34,382.44,66.72,378.24Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.7,
              transformOrigin: "82.0317px 366.965px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M80.15,353.66a33.35,33.35,0,0,0-15.53,5.69c-8.46,6.22-7.51,14.68,2.1,18.89a33.57,33.57,0,0,0,16.41,2.12Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "70.9878px 367.132px 0px",
          }}
          id="elmapm7vkiy2"
          className="animable"
        />
        <g id="elawhlmy0g1en">
          <ellipse
            cx={81.72}
            cy={364.23}
            rx={23.28}
            ry={13.44}
            style={{
              fill: "rgb(146, 227, 169)",
              transformOrigin: "81.72px 364.23px 0px",
              transform: "rotate(-6.37deg)",
            }}
            className="animable"
          />
        </g>
        <g id="elfxoz6leqt0p">
          <ellipse
            cx={81.72}
            cy={364.23}
            rx={23.28}
            ry={13.44}
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.8,
              transformOrigin: "81.72px 364.23px 0px",
              transform: "rotate(-6.37deg)",
            }}
            className="animable"
          />
        </g>
        <path
          d="M79,270.37c0-1.24-.86-2.42-2.59-3.17a12.13,12.13,0,0,0-10.91,1.21,3.87,3.87,0,0,0-1.91,3.41h0L74,365.09h0a3.87,3.87,0,0,0,2.61,2.9,12.13,12.13,0,0,0,10.91-1.22c1.52-1.12,2.1-2.45,1.83-3.67Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "76.5017px 317.593px 0px",
          }}
          id="eliilgrfolq5"
          className="animable"
        />
        <g id="el2aj3in0dx4">
          <path
            d="M79,270.37c0-1.24-.86-2.42-2.59-3.17a12.13,12.13,0,0,0-10.91,1.21,3.87,3.87,0,0,0-1.91,3.41h0L74,365.09h0a3.87,3.87,0,0,0,2.61,2.9,12.13,12.13,0,0,0,10.91-1.22c1.52-1.12,2.1-2.45,1.83-3.67Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.7,
              transformOrigin: "76.5017px 317.593px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elmwlv23jwqc">
          <g
            style={{
              opacity: 0.3,
              transformOrigin: "79.955px 317.484px 0px",
            }}
            className="animable"
          >
            <path
              d="M77.16,267.59a6.42,6.42,0,0,0-.74-.39,7.93,7.93,0,0,0-.86-.3l11.2,100.35c.26-.16.53-.3.77-.48a7.25,7.25,0,0,0,.64-.54Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "81.865px 317.075px 0px",
              }}
              id="elwrxplbx724l"
              className="animable"
            />
            <path
              d="M74.08,266.56a13,13,0,0,0-2.34-.11L83.13,368.53h0c.37-.07.74-.15,1.1-.25l.14,0c.35-.1.69-.21,1-.34h0Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "78.555px 317.484px 0px",
              }}
              id="elt2zp3wrv5a"
              className="animable"
            />
          </g>
        </g>
        <path
          d="M78.16,365.73c-1.08-1.47-1.4-7.36-2.1-13.62-.36-3.23-8.4-75.24-9.42-84.39a9.72,9.72,0,0,0-1.13.69,3.87,3.87,0,0,0-1.91,3.41h0L74,365.09a3.87,3.87,0,0,0,2.61,2.9,12.13,12.13,0,0,0,10.91-1.22l.31-.25c.65-.67,1-1.13,1-1.13C86.73,367.28,79.77,367.91,78.16,365.73Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "76.2147px 318.24px 0px",
          }}
          id="elnplbnox5gz"
          className="animable"
        />
        <path
          d="M76.42,267.2a12.13,12.13,0,0,0-10.91,1.21,3.87,3.87,0,0,0-1.91,3.41h0l1.32,11.81a39.48,39.48,0,0,0,15.38-1.71L79,270.37C79,269.13,78.15,268,76.42,267.2Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "71.9497px 275.102px 0px",
          }}
          id="elc1az8winm3w"
          className="animable"
        />
        <g id="eld0ihcqvjd8u">
          <g
            style={{
              opacity: 0.3,
              transformOrigin: "75.275px 274.924px 0px",
            }}
            className="animable"
          >
            <path
              d="M77.16,267.59a6.42,6.42,0,0,0-.74-.39,7.93,7.93,0,0,0-.86-.3l1.76,15.83c.5-.11,1-.23,1.49-.37Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "77.185px 274.815px 0px",
              }}
              id="eleiphs4rq6aj"
              className="animable"
            />
            <path
              d="M73.63,283.41c.77-.1,1.53-.23,2.29-.38l-1.84-16.47a13,13,0,0,0-2.34-.11Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "73.83px 274.924px 0px",
              }}
              id="elet7a3auqpdi"
              className="animable"
            />
          </g>
        </g>
        <g id="eldh5pfpj0gwf">
          <path
            d="M66.64,267.72a9.72,9.72,0,0,0-1.13.69,3.87,3.87,0,0,0-1.91,3.41h0l1.32,11.81a34.1,34.1,0,0,0,3.51.14C67.93,279.23,67.26,273.28,66.64,267.72Z"
            style={{
              opacity: 0.05,
              transformOrigin: "66.0147px 275.746px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M76.9,251.48c0-1.25-.86-2.42-2.59-3.17a12.13,12.13,0,0,0-10.91,1.21,3.87,3.87,0,0,0-1.91,3.4h0l2.11,18.9h0a3.86,3.86,0,0,0,2.61,2.89,12.13,12.13,0,0,0,10.91-1.22c1.52-1.12,2.1-2.45,1.83-3.66Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "70.2517px 261.508px 0px",
          }}
          id="el85kxxnjm4zw"
          className="animable"
        />
        <g id="el1zp5hbaawru">
          <path
            d="M76.9,251.48c0-1.25-.86-2.42-2.59-3.17a12.13,12.13,0,0,0-10.91,1.21,3.87,3.87,0,0,0-1.91,3.4h0l2.11,18.9h0a3.86,3.86,0,0,0,2.61,2.89,12.13,12.13,0,0,0,10.91-1.22c1.52-1.12,2.1-2.45,1.83-3.66Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.5,
              transformOrigin: "70.2517px 261.508px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="eluhdncrengp">
          <path
            d="M76.9,251.48c0-1.25-.86-2.42-2.59-3.17a12.13,12.13,0,0,0-10.91,1.21,3.87,3.87,0,0,0-1.91,3.4h0l2.11,18.9h0a3.86,3.86,0,0,0,2.61,2.89,12.13,12.13,0,0,0,10.91-1.22c1.52-1.12,2.1-2.45,1.83-3.66Z"
            style={{
              opacity: 0.05,
              transformOrigin: "70.2517px 261.508px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="el55khpslf27w">
          <g
            style={{
              opacity: 0.3,
              transformOrigin: "74.225px 265.15px 0px",
            }}
            className="animable"
          >
            <path
              d="M76.35,274a7.62,7.62,0,0,0,.77-.48,5.86,5.86,0,0,0,.64-.54l-2-17.94-1.51.17Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "76.005px 264.52px 0px",
              }}
              id="elpgmntqofbis"
              className="animable"
            />
            <path
              d="M75,274.63l-2-18.32-2.31.26,2.09,18.69A13.41,13.41,0,0,0,75,274.63Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "72.845px 265.785px 0px",
              }}
              id="elvs6ankdgamj"
              className="animable"
            />
          </g>
        </g>
        <g id="el3pk8za1ocnw">
          <path
            d="M66.21,274.71a7.48,7.48,0,0,0,1.26.43c-.88-7.88-2-18.36-2.47-22.09a8.57,8.57,0,0,0-1.13.7,3.87,3.87,0,0,0-1.91,3.4l1.64,14.67h0A3.86,3.86,0,0,0,66.21,274.71Z"
            style={{
              opacity: 0.1,
              transformOrigin: "64.7147px 264.095px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M54.69,245.71c-7,5.19-6.26,12.24,1.75,15.75s20.22,2.14,27.27-3S90,246.18,82,242.67,61.74,240.53,54.69,245.71Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "69.2171px 252.066px 0px",
          }}
          id="el4gjxye4ji4x"
          className="animable"
        />
        <path
          d="M54.5,244c-7,5.19-6.26,12.23,1.75,15.74s20.22,2.15,27.27-3,6.27-12.24-1.75-15.75S61.55,238.84,54.5,244Z"
          style={{
            fill: "rgb(55, 71, 79)",
            transformOrigin: "69.0218px 250.365px 0px",
          }}
          id="els7e797d6ek"
          className="animable"
        />
        <path
          d="M74.91,128.27c-.3-2.62-2.47-5-6.46-6.61-8-3.15-20.18-1.79-27.26,3-3.55,2.41-5.14,5.24-4.85,7.86h0l13.23,118.5h0c.32,2.85,2.52,5.49,6.52,7.24,8,3.51,20.22,2.14,27.27-3,3.52-2.6,5.09-5.66,4.77-8.51Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "62.2385px 190.085px 0px",
          }}
          id="elr0q5ae78f0i"
          className="animable"
        />
        <path
          d="M40.32,141.69C49.94,145.9,64.59,144.26,73,138a19.34,19.34,0,0,0,2.69-2.39l-.82-7.38c-.3-2.62-2.47-5-6.46-6.61-8-3.15-20.18-1.79-27.26,3-3.55,2.41-5.14,5.24-4.85,7.86l.83,7.38A18.73,18.73,0,0,0,40.32,141.69Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "55.9782px 131.972px 0px",
          }}
          id="eludmvv3zwvra"
          className="animable"
        />
        <g id="el96izpkmf24">
          <path
            d="M40.32,141.69C49.94,145.9,64.59,144.26,73,138a19.34,19.34,0,0,0,2.69-2.39l-.82-7.38c-.3-2.62-2.47-5-6.46-6.61-8-3.15-20.18-1.79-27.26,3-3.55,2.41-5.14,5.24-4.85,7.86l.83,7.38A18.73,18.73,0,0,0,40.32,141.69Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.2,
              transformOrigin: "55.9782px 131.972px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M54.35,242.68c-3.52,2.59-5.08,5.65-4.77,8.5s2.52,5.49,6.52,7.24c8,3.51,20.22,2.14,27.27-3,3.52-2.6,5.09-5.66,4.77-8.51s-2.51-5.48-6.52-7.24-9.58-6.9-14.51-6.35S57.88,240.08,54.35,242.68Z"
          style={{
            fill: "rgb(69, 90, 100)",
            transformOrigin: "68.861px 246.824px 0px",
          }}
          id="elk32j24vmtji"
          className="animable"
        />
        <g id="ell0vti84lcj">
          <path
            d="M55.2,252c.25-3.9,4.89-6.36,7.18-11.61,2.22-5.08,4.28-6.88,6.81-7.06a8.1,8.1,0,0,0-2.08-.08c-4.94.55-9.23,6.8-12.76,9.4s-5.08,5.65-4.77,8.5,2.52,5.49,6.52,7.24c8,3.51,20.21,2.2,27.33-3.09C65.31,263.79,54.94,256.13,55.2,252Z"
            style={{
              opacity: 0.2,
              transformOrigin: "66.4857px 246.783px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M54.35,242.68c-3.52,2.59-5.08,5.65-4.77,8.5s2.52,5.49,6.52,7.24c8,3.51,20.22,2.14,27.27-3,3.52-2.6,5.09-5.66,4.77-8.51s-2.51-5.48-6.52-7.24-9.58-6.9-14.51-6.35S57.88,240.08,54.35,242.68Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "68.861px 246.824px 0px",
          }}
          id="elnij0ddnnn3g"
          className="animable"
        />
        <g id="elbetb4esztab">
          <path
            d="M80.78,256.86a20.7,20.7,0,0,0,2.58-1.59A15.74,15.74,0,0,0,85.87,253l-14-125-5.45.61Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.3,
              transformOrigin: "76.145px 192.43px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elr0hk0czgc2">
          <path
            d="M75.52,259q1.47-.44,2.85-1L64.2,131.05l-2.92.33Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.3,
              transformOrigin: "69.825px 195.025px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elt6dkcmmtqc">
          <path
            d="M80.62,257c-13.19,4.7-22.43-3.22-23.11-9.36S46.89,152.47,45,135.43L37.75,128a6.74,6.74,0,0,0-1.41,4.62l13.23,118.5c.32,2.85,2.52,5.49,6.52,7.24C63.14,261.39,73.46,260.77,80.62,257Z"
            style={{
              opacity: 0.1,
              transformOrigin: "58.4717px 194.142px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M41.19,124.71c-7.09,4.83-6.37,11.31,1.6,14.46s20.18,1.79,27.27-3,6.37-11.31-1.61-14.47S48.27,119.87,41.19,124.71Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "55.6245px 130.428px 0px",
          }}
          id="elknmuxpuv46o"
          className="animable"
        />
        <g id="elpeq4micrn8c">
          <path
            d="M41.19,124.71c-7.09,4.83-6.37,11.31,1.6,14.46s20.18,1.79,27.27-3,6.37-11.31-1.61-14.47S48.27,119.87,41.19,124.71Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.3,
              transformOrigin: "55.6245px 130.428px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M65.79,116.53c-.19-1.71-1.51-3.29-3.92-4.34-4.8-2.11-12.13-1.29-16.36,1.82-2.11,1.56-3,3.39-2.86,5.1h0l1.41,12.6h0c.19,1.71,1.51,3.29,3.91,4.34,4.81,2.11,12.14,1.29,16.36-1.82,2.12-1.56,3.06-3.39,2.87-5.1h0l-1.41-12.6Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "54.9299px 124.12px 0px",
          }}
          id="elx3mti7r5dk"
          className="animable"
        />
        <g id="elg9dwuvfzppl">
          <path
            d="M65.79,116.53c-.19-1.71-1.51-3.29-3.92-4.34-4.8-2.11-12.13-1.29-16.36,1.82-2.11,1.56-3,3.39-2.86,5.1h0l1.41,12.6h0c.19,1.71,1.51,3.29,3.91,4.34,4.81,2.11,12.14,1.29,16.36-1.82,2.12-1.56,3.06-3.39,2.87-5.1h0l-1.41-12.6Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.7,
              transformOrigin: "54.9299px 124.12px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elu96j62j2maf">
          <path
            d="M63.72,134.6c.2-.13.43-.23.61-.37a9.46,9.46,0,0,0,1.49-1.36l-2-18-2.26.25Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "63.69px 124.735px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elamytfkttjxh">
          <path
            d="M60.86,136a15,15,0,0,0,1.58-.68l-2.12-19-1.64.18Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "60.56px 126.16px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M48.26,131.61c-.2-1.8-.35-3.17-1.09-9.81l-3-6.62a5,5,0,0,0-1.55,3.93l1.41,12.6c.19,1.71,1.51,3.29,3.91,4.34,4.81,2.11,12.14,1.29,16.36-1.82a9.46,9.46,0,0,0,1.49-1.36C59,137.86,48.69,135.46,48.26,131.61Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "54.2004px 126.201px 0px",
          }}
          id="elt0w5wl6vo5"
          className="animable"
        />
        <path
          d="M45.51,114c-4.22,3.12-3.75,7.35,1.06,9.45s12.13,1.29,16.36-1.83,3.75-7.34-1.06-9.44S49.74,110.9,45.51,114Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "54.222px 117.816px 0px",
          }}
          id="el5fr0jkdzgbb"
          className="animable"
        />
        <g id="el4tfbw2gf9rq">
          <path
            d="M45.51,114c-4.22,3.12-3.75,7.35,1.06,9.45s12.13,1.29,16.36-1.83,3.75-7.34-1.06-9.44S49.74,110.9,45.51,114Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.8,
              transformOrigin: "54.222px 117.816px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M56.42,102.57a8.6,8.6,0,0,0-7.71.86,2.73,2.73,0,0,0-1.35,2.41h0l1.41,12.59a2.73,2.73,0,0,0,1.84,2,8.59,8.59,0,0,0,7.72-.86,2.73,2.73,0,0,0,1.34-2.41h0l-1.4-12.59h0A2.74,2.74,0,0,0,56.42,102.57Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "53.5151px 111.502px 0px",
          }}
          id="elxulp4wh47wd"
          className="animable"
        />
        <g id="elqopwywvwzx">
          <path
            d="M56.42,102.57a8.6,8.6,0,0,0-7.71.86,2.73,2.73,0,0,0-1.35,2.41h0l1.41,12.59a2.73,2.73,0,0,0,1.84,2,8.59,8.59,0,0,0,7.72-.86,2.73,2.73,0,0,0,1.34-2.41h0l-1.4-12.59h0A2.74,2.74,0,0,0,56.42,102.57Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.7,
              transformOrigin: "53.5151px 111.502px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elgispzpo0ixt">
          <path
            d="M56.88,120.39a6.67,6.67,0,0,0,1.45-.77c.09-.07.15-.14.24-.22L57,105.56l-1.77.2Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "56.9px 112.975px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elhjprbpsder5">
          <path
            d="M54.57,121a10.71,10.71,0,0,0,1.28-.24l-1.56-14-1.29.15Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "54.425px 113.88px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M59.23,118.69a5.93,5.93,0,0,1-6.36.9c-2-1-2-2.38-2.2-4.56s-.62-5.54-1.1-9.81L48.13,104a2.39,2.39,0,0,0-.77,1.89h0l1.41,12.59a2.73,2.73,0,0,0,1.84,2,8.59,8.59,0,0,0,7.72-.86A3.92,3.92,0,0,0,59.23,118.69Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "53.2932px 112.513px 0px",
          }}
          id="el9bkqo1qpbwa"
          className="animable"
        />
        <path
          d="M48.71,103.43c-2,1.47-1.77,3.46.5,4.46a8.57,8.57,0,0,0,7.71-.87c2-1.46,1.77-3.46-.5-4.45A8.6,8.6,0,0,0,48.71,103.43Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "52.815px 105.23px 0px",
          }}
          id="elmbn5xl2vmhp"
          className="animable"
        />
        <g id="elmtmyeh2erl">
          <path
            d="M48.71,103.43c-2,1.47-1.77,3.46.5,4.46a8.57,8.57,0,0,0,7.71-.87c2-1.46,1.77-3.46-.5-4.45A8.6,8.6,0,0,0,48.71,103.43Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.8,
              transformOrigin: "52.815px 105.23px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M52,104.67a9.5,9.5,0,0,1-.26-1.79l-.7-6.42c-.28-2.66-.65-5.76-1-9.08L49.07,77,47.2,57.44c-.25-2.66-.47-4.88-.61-6.43-.08-.78-.14-1.39-.18-1.8s0-.65.08-.66h0c.07,0,.17.21.22.62s.13,1,.23,1.8c.19,1.55.48,3.76.81,6.41.68,5.3,1.58,12.37,2.48,19.45.44,3.53.88,7.07,1.3,10.39s.74,6.41,1,9.07.56,4.87.74,6.42a9.42,9.42,0,0,1,.14,1.8A1,1,0,0,1,52,104.67Z"
          style={{
            fill: "rgb(69, 90, 100)",
            transformOrigin: "49.9029px 76.7199px 0px",
          }}
          id="el576zru2bdjp"
          className="animable"
        />
        <g id="elgf93ykvpxv9">
          <path
            d="M69.23,122c6.77,3.78,5.2,9.42-.79,13.74s-17.56,5.36-21.65,4.56c7.65,1.5,17.28-.1,23.27-4.18C76.9,131.46,76.45,125.27,69.23,122Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.5,
              transformOrigin: "60.8592px 131.427px 0px",
            }}
            className="animable"
          />
        </g>
        <path
          d="M86,279.48c-8.63,4.8-21.39,5.79-30.08,2-4.44-2-7-4.82-7.68-7.94h-.07L32.49,133c.38,3.43,3,6.6,7.83,8.7L54.76,271C55.55,278.15,66.36,284.87,86,279.48Z"
          style={{
            fill: "rgb(146, 227, 169)",
            transformOrigin: "59.245px 208.399px 0px",
          }}
          id="elj6oe5iu12jd"
          className="animable"
        />
        <g id="ell71uj531lfh">
          <path
            d="M50.68,144c8,.41,16.6-1.69,22.36-5.93,8.45-6.23,7.51-14.69-2.11-18.89a24.09,24.09,0,0,0-2.63-1c5.07,1.84,10.45,6.53,8.74,12.67S65.37,143.69,50.68,144Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.3,
              transformOrigin: "64.7447px 131.115px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elvrny7g5z6no">
          <path
            d="M78.76,127.83,94.45,268.37h-.07c.05,3.19-1.82,6.55-5.74,9.43l-.56.38c6.27-5.51,4.61-10.38,3.75-18L77.64,133A8.72,8.72,0,0,0,78.76,127.83Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "86.045px 203.005px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="el3aftol7jdvd">
          <path
            d="M61.87,112.19c-.14-.07-.3-.1-.45-.16,2.39,1.23,4.67,4,2.57,7.1s-10.69,7.07-19.61,3a8.26,8.26,0,0,0,2.19,1.35c4.81,2.1,12.13,1.29,16.36-1.83S66.68,114.29,61.87,112.19Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.3,
              transformOrigin: "55.0973px 118.338px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elq92r817aiy">
          <path
            d="M56.42,102.57a6.53,6.53,0,0,0-1.52-.42c1.52.5,3.11,1.85,2.55,3.36-.48,1.3-3.63,3.73-8.62,2.17a4.32,4.32,0,0,0,.38.21,8.57,8.57,0,0,0,7.71-.87C58.91,105.56,58.69,103.56,56.42,102.57Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.3,
              transformOrigin: "53.5544px 105.291px 0px",
            }}
            className="animable"
          />
        </g>
        <g id="elasd7boynema">
          <path
            d="M97,353c-.71-.31-1.46-.58-2.23-.83,14.54,4.95,9.16,17.84-3.44,22.31-12.84,4.55-25.82.88-29.3-1.61a17.39,17.39,0,0,0,4.36,2.67c9.62,4.21,24.27,2.58,32.72-3.65S106.65,357.17,97,353Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.4,
              transformOrigin: "83.4599px 365.024px 0px",
            }}
            className="animable"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
    <defs>
      <clipPath id="freepik--clip-path--inject-8">
        <path
          d="M379.37,294.12c0-5.06-3.34-10.13-10-14-13.38-7.72-35.07-7.72-48.45,0-6.69,3.86-10,8.93-10,14h0V309.3h0c0,5.06,3.34,10.13,10,14,13.38,7.72,35.07,7.72,48.45,0,6.69-3.86,10-8.93,10-14h0V294.12Z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
    </defs>
  </svg>
)
export default MedicationItemsAnimated

import {axiosRequest} from "../../api";
import {API_PATH} from "../../../constants";

export const VisitService = {
    getVisitsList(params){ return axiosRequest('GET', API_PATH.VISIT.LIST(params)) },
    getVisit(data){ return axiosRequest('POST', API_PATH.VISIT.INFO, data) },
    addVisit(data){ return axiosRequest('POST', API_PATH.VISIT.ADD, data) },
    changeStatusVisit(data){ return axiosRequest('POST', API_PATH.VISIT.STATUS, data) },
    checkInsurance(data){ return axiosRequest('POST', API_PATH.VISIT.CHECK_INSURANCE, data) },
    visitEHRCheck(data){ return axiosRequest('POST', API_PATH.VISIT.EHR_CHECK, data) },
    medicalHistory(data){ return axiosRequest('POST', API_PATH.VISIT.MEDICAL_HISTORY, data) },
    visitAdmission(data){ return axiosRequest('POST', API_PATH.VISIT.ADMISSION, data) },
    urgentRequest(data){ return axiosRequest('POST', API_PATH.VISIT.URGENT_REQUEST, data) },
    urgentApprove(data){ return axiosRequest('POST', API_PATH.VISIT.URGENT_APPROVE, data) },
    urgentAccept(data){ return axiosRequest('POST', API_PATH.VISIT.URGENT_ACCEPT, data) },
    visitCallRequest(data){ return axiosRequest('POST', API_PATH.VISIT.VISIT_CALL_REQUEST, data) },
    visitCallApprove(data){ return axiosRequest('POST', API_PATH.VISIT.VISIT_CALL_APPROVE, data) },
    visitCallAccept(data){ return axiosRequest('POST', API_PATH.VISIT.VISIT_CALL_ACCEPT, data) },
}
import React from "react";
import { Form } from "react-bootstrap";
import Icon from "../../../../general/icon";
import Tooltip from "../../../../general/tooltip";
import {useWindowSize} from "../../../../../../hook/useWindowSize";

/*
    کامپوننت رندر کردن هر دارو و چک باکس آن.
    Parent: <addParaclinicalPrescriptionItemModal/>
    Children: -
    در این کامپوننت چک باکس هر خدمت پاراکلینیک و اطلاعات آن نمایش داده میشود،
    همچنین اسم خدمت بر اساس کلمه سرچ شده هایلایت میشود.
*/


type ParaclinicalServiceItemBoxProps = {
  searchStr: string | undefined;
  language: string;
  item: any;
  fieldValue: any;
  setFieldValue: React.Dispatch<React.SetStateAction<any>>;
}

const ParaclinicalItemBox = ({
 searchStr,
 language,
 item,
 fieldValue,
 setFieldValue
}: ParaclinicalServiceItemBoxProps) => {

  const [width] = useWindowSize();
  /*
    بررسی و اضافه و پاک کردن اقلام دارویی از لیست انتخاب شده ها.
    @param: e
  */
  const handleParaclinicalServiceItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFieldValue((prevState: any) => {
      if (checked) {
        return [...prevState, item];
      } else {
        return prevState.filter((find: string) => find !== item);
      }
    });
  }

  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  /*
    هایلایت کلمه های سرچ شده.
    @param: string
  */
  const highlightText = (text: string, searchQuery: string | undefined) => {
    if (!searchQuery) return text;
    const searchWords = searchQuery.split(' ').filter(word => word.trim() !== '').map(escapeRegExp);
    if (searchWords.length === 0) return text;
    const regex = new RegExp(`(${searchWords.join('|')})`, 'gi');
    const segments = text.split(regex);
    return segments.map((segment, index) =>
      regex.test(segment) ? <span key={index} className="highlight rounded rounded-1 fw-bold py-2">{segment}</span> : segment
    );
  };

  const checked = fieldValue.some((find: any) => find?.id === item?.id)

  return (
    <div key={`inline-checkbox-${item?.id}`}
         className={`border-bottom px-2 
         ${!item?.active ? 'bg-gray-disabled' : !item?.support ? 'bg-red-hover' : 'background-gray-hover'}
         ${language == 'en' ? 'justify-content-end' : 'justify-content-start'}`}
    >
      <Form.Check
        className='py-3'
        reverse={language == 'fa'}
      >
        <Form.Check.Label className={`d-flex align-items-center w-100`}>
          <div className={`d-flex align-items-center w-100 ltr ${language == 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
            <div
              className={`d-flex align-content-center ${language == 'en' ? 'flex-row ms-3' : 'flex-row-reverse me-3'}`}>
              <Form.Check.Input
                className='rounded rounded-3 font-22 mb-1'
                name={`checkbox-${item?.id}`}
                type='checkbox'
                id={`inline-checkbox-${item?.id}`}
                checked={checked ? checked : null}
                onChange={handleParaclinicalServiceItemChange}
                disabled={!item?.active}
              />
              <div
                className={`d-flex align-items-center justify-content-center bg-primary bg-opacity-10 rounded rounded-3 border border-primary font-12 text-primary text-nowrap mx-2 ${width > 900 ? 'w-110' : 'w-20'}`}>
                {width > 900 ? item?.type?.name : item?.type?.name[0]}
              </div>
            </div>
            <span className={`${language == 'en' && item?.enName ? 'font-en text-start' : 'text-end'}`}>
              {language == 'en' ?
                item?.enName ? highlightText(item?.enName, searchStr) : highlightText(item?.name, searchStr)
                : highlightText(item?.name, searchStr)
              }
            </span>
            <div className={`d-flex ${language == 'en' ? ' ms-auto' : ' me-auto'}`}>
              {
                !item?.active &&
                <div className={`d-flex align-items-center `}>
                  <Tooltip alt={'با توجه به بیمه بیمار، این خدمت غیر فعال می‌باشد'} className="tooltip-pre-line">
                    <div className='d-flex align-items-center text-red font-18 ms-2'>
                      <Icon name='info-circle'/>
                    </div>
                  </Tooltip>
                </div>
              }
              {
                !item?.support &&
                <div className={`d-flex align-items-center`}>
                  <Tooltip alt={'این قلم تحت پوشش بیمه بیمار نمی‌باشد'} className="tooltip-pre-line">
                    <div className='d-flex align-items-center text-red font-18 ms-2'>
                      <Icon name='info-circle'/>
                    </div>
                  </Tooltip>
                </div>
              }
            </div>
          </div>
        </Form.Check.Label>
      </Form.Check>
    </div>
  )
};

export default ParaclinicalItemBox;

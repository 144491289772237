export type TError = {
  hasError: boolean;
  dataError?: any;
  message?: string;
}

export type TGIdNameData = {
  id: number;
  name?: string
}

export const TDataListInitialValue = {
  data: [],
  count: 0
}

export type TDataList = {
  data?: any[];
  count?: number;
}

export type TGQuantityData = {
  magnitude: number;
  unit: string;
}
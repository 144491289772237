import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Modal from "../../../general/modal";
import Icon from "../../../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {general} from "../../../../../store/action";
import VisitMedicationPrescriptionTable from "./dataList/medication/visitMedicationPrescriptionTable";
import VisitParaclinicalPrescriptionTable from "./dataList/paraclinical/visitParaclinicalPrescriptionTable";
import {useAddPrescription} from "../../../../../hook/request/visit/prescription";
import {useMedicalHistory} from "../../../../../hook/request/visit/visits";
import {TPrescriptionFields} from "../../../../../types/prescription";
import BoxLoader from "../../../general/boxLoader";
import {baseFormat} from "../../../../../utils/date";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import VisitMedicationPrescriptionCards from "./dataList/medication/visitMedicationPrescriptionCards";
import VisitParaclinicalPrescriptionCards from "./dataList/paraclinical/visitParaclinicalPrescriptionCards";

const medicationDataListTitles = [
  "برند",
  "واحد",
  "مقدار مصرف",
  "عدد",
  "دوز",
  "نام دارو",
  "شکل دارو",
]

const paraclinicalDataListTitles = [
  "تعداد",
  "تاریخ",
  "نوع خدمت",
  "عنوان",
]


type AddVisitPrescriptionModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
  handleShowModal: any;
}

type ModalInfoProps = {
  id: string;
  visitId: string;
  patientData?: any;
  // medicationItemsFieldValue?: any;
  // paraclinicalItemsFieldValue?: any;
  prescriptionFieldValue?: TPrescriptionFields;
  medicalHistoryFieldValue?: any;
  draftDescription?: string;
  handlePrescriptionModalSubmit: any;
}

const AddVisitPrescriptionModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
  handleShowModal,
}: AddVisitPrescriptionModalProps) => {
  const [width] = useWindowSize()
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    visitId: '',
    patientData: '',
    handlePrescriptionModalSubmit: null
  });
  const [medicalHistoryFieldValue, setMedicalHistoryFieldValue] = useState([]);
  const [draftDescription, setDraftDescription] = useState();
  const [prescriptionData, setPrescriptionData] = useState<TPrescriptionFields>()

  // services
  const addPrescriptionRequest = useAddPrescription({
    onSuccess (result: any){
      console.log('res', result)
      handleSetShowModal();
      if(!result?.insurerStatus?.errorMessage) {
        handleShowModal('endVisitModal')
      }
    }
  })
  const addMedicalHistoryRequest = useMedicalHistory({
    onSuccess (){}
  })

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addVisitPrescriptionModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      setPrescriptionData(modalData?.prescriptionFieldValue)
      setMedicalHistoryFieldValue(modalData?.medicalHistoryFieldValue)
      setDraftDescription(modalData?.draftDescription)
      handleResetModal();
    }
  }, [modalData, modalName])

  useEffect(() => {
    addPrescriptionRequest?.data?.data?.result &&
    setPrescriptionData(addPrescriptionRequest?.data?.data.result)
  }, [addPrescriptionRequest.data])

  useEffect(() => {
    if(prescriptionData && prescriptionData.id) {
      const {id, insurerStatus} = prescriptionData
      modalInfo.handlePrescriptionModalSubmit(id, insurerStatus)
    }
  }, [prescriptionData])

  const endVisit = () => {
    const transformedMedicationArray = prescriptionData?.medicationItems?.map(({
        checkCode,
        item,
        frequency,
        dosage,
        itemCount,
        activeDate,
        futureDates,
        drugDescription
      }) => {
        const { id: itemId} = item || {};
        return { item: {id: itemId},
          frequency: {id: frequency},
          dosage: {id: dosage},
          ihioCheck: checkCode,
          activeDate: baseFormat(activeDate),
          itemCount, futureDates, drugDescription };
    });

    const transformedParaclinicalArray = prescriptionData?.paraclinicalItems?.map(({
      checkCode,
      service,
      itemCount,
      emergency,
      note,
      illness,
      plan,
      activeDate
    }) => {
        const {id: serviceId} = service || {},
          physioPlans = plan?.map((item:any) => {
            return ({id: item?.value})
          });
        return {
          service: {
            id: serviceId
          },
          itemCount,
          emergency,
          physioIllness: {
            id: illness
          },
          physioPlans: physioPlans,
          note,
          ihioCheck: checkCode,
          activeDate: baseFormat(activeDate)
        };
    });

    const prescriptionBody = {
      id: prescriptionData?.id,
      visit: {id: modalInfo?.visitId},
      medicationItems: transformedMedicationArray,
      paraclinicalItems: transformedParaclinicalArray,
      draftDescription: modalInfo?.draftDescription
    }
    const medicalHistoryBody = {
      id: modalInfo?.visitId,
      ...medicalHistoryFieldValue,
    }

    addMedicalHistoryRequest.mutate(medicalHistoryBody)
    addPrescriptionRequest.mutate(prescriptionBody)
  }

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
      fullscreen={width <= 900 ? true : 'false'}
    >
      {(addPrescriptionRequest?.isPending || addMedicalHistoryRequest.isPending) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              خلاصه اطلاعات ویزیت
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
            <Icon name='close-square'/>
          </span>
        </div>
        <h4 className='d-flex font-14 mb-2 pb-2'>
          <div className="d-flex align-items-center align-content-center border-bottom ms-auto ps-5">
            <span className="d-flex font-22"><Icon name='pills'/></span>
            <span className='me-2'>تجویز دارویی</span>
            <span className='bg-gray3 text-gray rounded-3 fw-semibold font-12 me-2 px-2 py-1'>
              {prescriptionData?.medicationItems?.length} قلم
            </span>
          </div>
          <div
            className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
            <span
              onClick={() => handleShowModal('favoritePrescriptionModal',
                {
                  prescriptionData: prescriptionData?.medicationItems,
                  draftMedication: true
                })}
              className={`btn btn-gray rounded d-flex align-items-center ${width > 900 ? 'px-4 mx-2' : 'px-2'}`}>
              <span className={`d-inline-flex font-20 ${width > 900 && 'ms-2'}`}><Icon name="add-square"/></span>
              {width > 900 && 'افزودن به نسخ پر استفاده'}
            </span>
          </div>
        </h4>
        <section className="table-cont table-responsive custom-scrollbar border rounded mb-5" style={{maxHeight: '25vh'}}>
          { width > 900 ?
            <VisitMedicationPrescriptionTable
              dataList={prescriptionData?.medicationItems}
              dataListTitles={medicationDataListTitles}
              isRequesting={false}
            /> :
            <VisitMedicationPrescriptionCards
              dataList={prescriptionData?.medicationItems}
              dataListTitles={medicationDataListTitles}
              isRequesting={false}
            />
          }

        </section>
        <h4 className='d-flex font-14 mb-2 pb-2'>
          <div className="d-flex align-items-center align-content-center border-bottom ms-auto ps-5">
            <span className="d-flex font-22"><Icon name='test'/></span>
            <span className='me-2'>تجویز پاراکلینیک</span>
            <span className='bg-gray3 text-gray rounded-3 fw-semibold font-12 me-2 px-2 py-1'>{prescriptionData?.paraclinicalItems?.length} خدمت</span>
          </div>
          <div
            className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
            <span
              onClick={() => handleShowModal('favoritePrescriptionModal',
                {
                  prescriptionData: prescriptionData?.paraclinicalItems,
                  draftMedication: false
                })}
              className={`btn btn-gray rounded d-flex align-items-center ${width > 900 ? 'px-4 mx-2' : 'px-2'}`}>
              <span className={`d-inline-flex font-20 ${width > 900 && 'ms-2'}`}><Icon name="add-square"/></span>
              {width > 900 && 'افزودن به نسخ پر استفاده'}
                </span>
          </div>
        </h4>
        <section className="table-cont table-responsive custom-scrollbar border rounded overflow-y-auto" style={{maxHeight: '25vh'}}>
          {width > 900 ?
            <VisitParaclinicalPrescriptionTable
              dataList={prescriptionData?.paraclinicalItems}
              dataListTitles={paraclinicalDataListTitles}
              isRequesting={false}
            /> :
            <VisitParaclinicalPrescriptionCards
              dataList={prescriptionData?.paraclinicalItems}
              dataListTitles={paraclinicalDataListTitles}
              isRequesting={false}
            />
          }

        </section>
        <div className="col-12 d-flex justify-content-end mt-4">
          <div className="d-flex w-100 align-items-stretch form-group">
            <button
              type='submit'
              className="btn btn-primary rounded d-flex w-100 justify-content-center align-items-center"
              onClick={endVisit}
            >
              ثبت و تایید نسخه
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVisitPrescriptionModal);
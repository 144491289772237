import React from "react";
import {connect, useSelector} from "react-redux";
import {general} from "../../../../../../store/action";
import {getCellValue} from "../../../../../../utils/utils";
import {Dispatch} from "redux";
import {
  monthDay,
  MtoJDateFormat,
  MtoJHHMMFormat,
  yearTitle
} from "../../../../../../utils/date";
import Icon from "../../../../general/icon";
import {useWindowSize} from "../../../../../../hook/useWindowSize";
import Tooltip from "../../../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type PatientVisitCardsItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const PatientVisitCardsItem = ({
  item,
  dataListTitles,
  handleShowModal
}: PatientVisitCardsItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [width] = useWindowSize();

  const determineTextColor = () => {
    switch (item?.status?.id) {
      case 300:
        return 'text-green2'
      case 400:
        return 'text-primary'
      default:
        return 'text-black'
    }
  }

  return (
    <>
      <div className="card shadow-none border cur-pointer mb-4"
           onClick={() => handleShowModal('showPatientVisitModal', { visitId: item?.id, patientData: item?.patient})}
      >
        <div className='bg-gray rounded'>
            <div className='col-12 row my-3 align-items-center'>
              <div className={`col-lg-2 col-md-4 col-sm-6  d-flex text-gray font-12 my-1 px-4 ${width > 750 ? 'justify-content-center' : 'justify-content-between'}`}>
                تاریخ ویزیت
                <Tooltip alt={
                  getCellValue(monthDay(item?.startDateTime)) + ' ' +
                  getCellValue(yearTitle(item?.startDateTime)) + ' - ' +
                  getCellValue(MtoJHHMMFormat(item?.startDateTime))
                } className="tooltip-pre-line">
                  <bdi className='me-3'>
                    {
                      MtoJDateFormat(item?.startDateTime)
                    }
                  </bdi>
                </Tooltip>
              </div>
              <div
                className={`col-lg-2 col-md-4 col-sm-6  d-flex rmdp-border-right font-12 my-1 px-4 ${width > 750 ? 'justify-content-center' : 'justify-content-between'}`}>
              <span className="text-gray">
               نام پزشک
              </span>
                <span className='me-3'>
                  {getCellValue(item?.provider?.name)}
                </span>
              </div>
              <div className={`col-lg-2 col-md-4 col-sm-6  d-flex rmdp-border-right font-12 my-1 px-4 ${width > 750 ? 'justify-content-center' : 'justify-content-between'}`}>
            <span className="text-gray">
              نام مرکز
            </span>
                <span className='me-3'>
              {getCellValue(item?.healthUnit?.name)}
            </span>
              </div>
              <div className={`col-lg-2 col-md-4 col-sm-6  d-flex rmdp-border-right font-12 my-1 px-4 ${width > 750 ? 'justify-content-center' : 'justify-content-between'}`}>
           <span className="text-gray">
              نوع ویزیت
            </span>
                <span className='me-3'>
              {getCellValue(item?.visitType?.name)}
            </span>
              </div>
              <div className={`col-lg-2 col-md-4 col-sm-6  d-flex rmdp-border-right font-12 my-1 px-4 ${width > 750 ? 'justify-content-center' : 'justify-content-between'}`}>
              <span className="text-gray">
                  وضعیت
              </span>
                <span className={`${determineTextColor()} me-3`}>
                {getCellValue(item?.status?.name)}
              </span>
              </div>
              {(isProvider || findAccessInAccessList('EHR_VISIT')) &&
                <div
                  className={`col-lg-2 col-md-4 col-sm-6  d-flex ${(width > 1000 || width < 750) ? 'justify-content-end' : 'justify-content-center'}`}>
                  <div className='btn btn-gray rounded rounded-3 p-1 mx-2'>
                    <span
                      onClick={() => handleShowModal('showPatientVisitModal', {
                        visitId: item?.id,
                        patientData: item?.patient
                      })}
                      className='font-20 d-flex h-100 m-1'>
                      <Icon name='eye'/>
                      <span className="font-12 me-1">
                        مشاهده ویزیت
                      </span>
                    </span>
                  </div>
                </div>
              }
            </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientVisitCardsItem);
import { useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { fileToBase64 } from '../../../../utils/fileConvertor';
import { useUploadMultimedia } from '../../../../hook/request/general';

type DropzoneUploaderBoxProps = {
  setFieldValue: any;
  fieldValue: any;
  setIsFileUploading?: any;
  name: string;
  multiple?: boolean;
  maxFiles?: number;
  inputContent?: string;
  error?: any;
  accept?: string
}

type TImageInfo = {
  imageUrl: string;
  imageData: any;
  id?: number
} | null;

let _imageInfo: TImageInfo = null;
const DropzoneUploaderBox = ({
  setFieldValue,
  fieldValue,
  setIsFileUploading,
  name,
  multiple,
  maxFiles,
  inputContent,
  error,
  accept
}: DropzoneUploaderBoxProps) => {

  // services
  const uploadMultimediaRequest = useUploadMultimedia();

  useEffect(() => {
    if (uploadMultimediaRequest?.data?.data?.result) {
      const { id } = uploadMultimediaRequest?.data?.data?.result;
      setFieldValue({ 
        ...fieldValue,
        [name]: {
          ..._imageInfo,
          id
        }
      });
      setIsFileUploading(false);
    }
  }, [uploadMultimediaRequest?.data?.data])

  const handleChangeStatus = ({ file }: any, status: string) => {
    if(status === "preparing") setIsFileUploading(true);
    else if(status === "done") addFile(file);
    else if(status === "removed") removeFile();
  }

  const addFile = async (file: any) => {
    const fileType = file.type.split('/')[0];
    const base64Value = await fileToBase64(file, fileType);
    const fileTypeName = fileType === 'image' ? 'تصویر' : fileType === 'application' ? 'سند' : 'فایل';
    console.log('pdf')

    const _fileInfo = {
      fileUrl: base64Value,
      fileData: file
    }

    const body = {
      base64: base64Value.split(',')[1],
      fileName: file?.name,
      fileType: {
        id: 100,
        name: fileTypeName
      }
    }

    uploadMultimediaRequest.mutate(body);
  }

  const removeFile = () => {
    setFieldValue({
      ...fieldValue,
      [name]: {}
    });
  }

  const showError = error?.hasError && error?.dataError?.[name];

  return (
    <div className="w-100 ltr">
      <Dropzone
        onChangeStatus={handleChangeStatus}
        // initialFiles={[]}
        accept={ accept ? accept : "image/*,.pdf"}
        maxFiles={multiple ? maxFiles : 1}
        multiple={multiple}
        canCancel={false}
        inputContent={inputContent || "یک فایل در اینجا رها کنید!"}
        inputWithFilesContent="افزودن فایل"
        styles={{
          dropzone: {
            minHeight: 100,
            maxHeight: 500,
            border: "1px solid #dfdfe0",
            borderRadius: "14px",
            overflow: 'hidden'
          },
          dropzoneActive: {
            minHeight: 120,
            color: "#2e57ff",
            border: "1px dashed #2e57ff",
          },
          inputLabel: {
            fontFamily: "Vazir, Arial, sans-serif",
            fontSize: "16px",
            color: "gray"
          }
        }}
      />
      { showError && <p className="text-red font-10 mt-1">{error?.dataError?.[name]}</p> }
    </div>
  );
};

export default DropzoneUploaderBox;

import React from "react";
import FavoriteMedicationItemRowItemResponsive from "./favoriteMedicationItemRow_ItemResponsive";


type FavoriteMedicationItemRowResponsiveProps = {
  item?: any
  pushFavoriteMedicationItem: any
}


const FavoriteMedicationItemRowResponsive = ({
 item,
 pushFavoriteMedicationItem
}: FavoriteMedicationItemRowResponsiveProps) => {

  // Function to handle changes in body checkboxes
  const handleBodyCheckboxChange = (checked: boolean, item: any) => {
    pushFavoriteMedicationItem(item, checked)
  };

  return (
    <div className=" ">
      <div className="form-group">
        <FavoriteMedicationItemRowItemResponsive
          key={item.id}
          item={item}
          onChange={handleBodyCheckboxChange}
        />
      </div>
    </div>
  );
};


export default FavoriteMedicationItemRowResponsive
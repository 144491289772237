// src/redux/reducers/idReducer.js

import {SET_ID, SET_IS_IN_VIDEO_CALL, SET_VIDEO_CALL_OPEN_PIP, SET_LOCAL_MIC_STATUS, SET_LOCAL_CAM_STATUS} from "../../slice/videoCall";

const initialState = {
  value: 0, // Initial state of the ID
  videoCallOpenPiP: false,
  isInVideoCall: false,
  localMicStatus: true,
  localCamStatus: true,
};

const videoCallReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ID:
      return {
        ...state,
        value: action.payload,
      };
    case SET_VIDEO_CALL_OPEN_PIP:
      return {
        ...state,
        videoCallOpenPiP: action.payload,
      };
    case SET_IS_IN_VIDEO_CALL:
      return {
        ...state,
        isInVideoCall: action.payload,
      };
    case SET_LOCAL_MIC_STATUS:
      return {
        ...state,
        localMicStatus: action.payload,
      };
    case SET_LOCAL_CAM_STATUS:
      return {
        ...state,
        localCamStatus: action.payload,
      };
    default:
      return state;
  }
};

export default videoCallReducer;

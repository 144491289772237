class UserModel {
  connection;
  connectionId;
  audioActive;
  videoActive;
  speakerActive;
  screenShareActive;
  nickname;
  streamManager;
  type; // 'remote' | 'local'
  participant;

  constructor() {
    this.connection = null
    this.connectionId = '';
    this.audioActive = true;
    this.videoActive = true;
    this.speakerActive = true;
    this.screenShareActive = false;
    this.nickname = '';
    this.streamManager = null;
    this.type = 'local';
    this.participant = null;
  }

  isAudioActive() {
    return this.audioActive;
  }

  isVideoActive() {
    return this.videoActive;
  }

  isSpeakerActive() {
    return this.speakerActive;
  }

  isScreenShareActive() {
    return this.screenShareActive;
  }

  getConnectionId() {
    return this.connectionId;
  }

  getNickname() {
    return this.nickname;
  }

  getStreamManager() {
    return this.streamManager;
  }

  isLocal() {
    return this.type === 'local';
  }
  isRemote() {
    return !this.isLocal();
  }
  setAudioActive(isAudioActive) {
    this.audioActive = isAudioActive;
  }
  setVideoActive(isVideoActive) {
    this.videoActive = isVideoActive;
  }
  setSpeakerActive(isSpeakerActive) {
    this.speakerActive = isSpeakerActive;
  }
  setScreenShareActive(isScreenShareActive) {
    this.screenShareActive = isScreenShareActive;
  }
  setStreamManager(streamManager) {
    this.streamManager = streamManager;
  }
  setConnection(connection) {
    this.connection = connection;
  }

  setConnectionId(conecctionId) {
    this.connectionId = conecctionId;
  }
  setNickname(nickname) {
    this.nickname = nickname;
  }
  setType(type) {
    if (type === 'local' |  type === 'remote') {
      this.type = type;
    }
  }

  setParticipant(participant) {
    this.participant = participant;
  }
}

export default UserModel;

import React from "react";
import {Form} from "react-bootstrap";


type FavoriteParaclinicalItemRowItemResponsiveProps = {
  item?: any;
  onChange?: any
}


const FavoriteParaclinicalItemRowItemResponsive = ({
 item,
 onChange,
}: FavoriteParaclinicalItemRowItemResponsiveProps) => {

  const handleCheckboxChange = (e: any) => {
    const { checked } = e.target;
    onChange(checked, item);
  };

  return (
    <div className="background-gray-hover border-bottom px-2">
      <Form.Check
        className='py-3'
      >
        <Form.Check.Label className='d-flex align-items-center w-100'>
          <div className="d-flex align-items-center col-12 w-100 fw-bold ltr">
            <div className="ms-3">
              <Form.Check.Input
                className='rounded rounded-3 font-22 mb-1'
                name={`checkbox-${item?.id}`}
                type='checkbox'
                // checked={checked}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="text-center">
              <span
                className={`bg-primary bg-opacity-10 rounded rounded-3 border border-primary font-12 fw-bold text-primary text-nowrap px-1 py-2 mx-2`}>
                {item?.service?.type?.name[0]}
              </span>
            </div>
            <div className={`${item?.service?.enName ? 'text-start font-en' : 'text-end'}`}>
              <span>{item?.service?.enName || item?.service?.name}</span>
              <span className='mx-1 text-green2'>{item?.itemCount}</span>
            </div>
          </div>
        </Form.Check.Label>
      </Form.Check>
    </div>
  );
};


export default FavoriteParaclinicalItemRowItemResponsive
import { useMutation, useQuery } from "@tanstack/react-query";
import { UserService } from "../../services";
import { handleResponse, queryClient } from "./setup";
import Noti from "../../view/components/general/noti";
import { AccountService } from "../../services/requests/account";
import { ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";
import { SettingService } from "../../services/requests/setting";



const useGetProviderInsurer = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation({
      mutationFn:SettingService.AddProviderInsurerSave,
      onSuccess: (data: any) => {
        handleResponse({
          data,
          onSuccess: () => {
            console.log(data);
            Noti("success", data?.data?.msg);
            onSuccess();
          },
        });
    },
  });
};

// useGetAllergyReactions
const useGetAllINsurers = () => {
    return useQuery({
      queryKey: ["getInsuerers"],
      queryFn: ({ queryKey }: any) => {
        const [_, params] = queryKey;
        return SettingService.getProviderInsurer({id:0});
      },
    });
  }


const useAddProviderInsurerSave = () => {
    return useMutation({
        mutationFn:SettingService.AddProviderInsurerSave,
        onSuccess: (data: any) => {
          handleResponse({
            data,
            // onSuccess: () => {
            //   console.log(data);
            //   Noti("success", data?.data?.msg);
            //   onSuccess();
            // },
          });
      },
    });
  };


export {
    useGetProviderInsurer,
    useAddProviderInsurerSave,
    useGetAllINsurers
}
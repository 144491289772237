// src/redux/reducers/idReducer.js

import {SET_FULL_NAME, SET_IS_PROVIDER} from "../../slice/userData";

const initialState = {
  isProvider: null,
  fullName: ''
};

const userDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_IS_PROVIDER:
      return {
        ...state,
        isProvider: action.payload,
      };
    case SET_FULL_NAME:
      return {
        ...state,
        fullName: action.payload,
      };
    default:
      return state;
  }
};

export default userDataReducer;

import React from 'react';
import Icon from '../icon';

type PaginationProps = {
  doChangePage: any;
  paginationData: any;
}

const Pagination = ({
  doChangePage,
  paginationData
}: PaginationProps) => {

  let { perPage: pageSize, count: totalCount, pageNumber: index } = paginationData,
    _currentPage = +index,
    totalPages = Math.ceil( totalCount / pageSize) || 1,
    startIndex = ( _currentPage ) * pageSize + 1,
    endIndex = +startIndex + +pageSize - 1;

  startIndex = startIndex > totalCount ? totalCount : startIndex;
  endIndex = endIndex > totalCount ? totalCount : endIndex;

  const paginationBuilder = () => {
    let _paginationBtn = [],
      start = 0,
      end = 0;

    if((_currentPage - 2) < 1){
      start = 1;
      end = start + 4;
    }
    else if((_currentPage + 2) > totalPages){
      end = totalPages;
      start = end - 4;
    }
    else{
      start = _currentPage - 2;
      end = _currentPage + 2;
    }

    for(let i = start; i <= end; i++){
      if(i >= 1 && i <= totalPages) _paginationBtn.push(<button onClick={(event) => _doChangePage(event, i - 1)} className={`btn btn-pgn border rounded ${i === _currentPage + 1 ? ' active' : ''}`} key={i}>{i}</button>)
    }
    if(totalPages > 5 && totalPages - 2 > _currentPage){
      _paginationBtn.push(
        <>
          <button className="btn btn-pgn border rounded">...</button>
          <button onClick={(event) => _doChangePage(event, totalPages - 1)} className="btn btn-pgn border rounded">{totalPages}</button>
        </>
      )
    }
    return _paginationBtn;
  }

  const _doChangePage = (event: React.MouseEvent, pageNum: number) => {
    event.preventDefault();
    doChangePage({ index: pageNum });
  }

  return(
    <>
      {
        !!paginationData?.count &&
        paginationData?.count > pageSize
          &&
          <div className="table-pagination justify-content-center p-3 mb-4 mb-md-0 mt-3">
            <div className="d-none d-md-flex align-items-center text-gray3 px-3">
              <div>نمایش {startIndex} تا {endIndex} ردیف از {totalCount}</div>
            </div>
            <div className="d-flex flex-row-reverse me-md-auto">
              <button
                onClick={(event) => _doChangePage(event,_currentPage - 1)}
                className="btn-arrow-left btn btn-pgn d-flex justify-content-center align-items-center font-15 border rounded"
                disabled={_currentPage <= 0}
              >
                <Icon name="chevron-left" />
              </button>
              {paginationBuilder()}
              <button
                onClick={(event) => _doChangePage(event,_currentPage + 1)}
                className="btn-arrow-right btn btn-pgn d-flex justify-content-center align-items-center font-15 border rounded"
                disabled={_currentPage >= totalPages - 1}
              >
                <Icon name="chevron-right" />
              </button>
            </div>
          </div>
      }
    </>
  )
}

export default Pagination
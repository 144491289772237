import React, { useEffect } from "react";
import Image from "../../general/image";
import GalleryAddImage from "../../../../assets/images/svg/gallery-add.svg";
import { fileToBase64 } from "../../../../utils/fileConvertor";
import { useUploadMultimedia } from "../../../../hook/request/general";
import BoxLoader from "../../general/boxLoader";

type ImageUploaderBoxFileProps = {
  setFieldValue: any;
  fieldValue: any;
  name: string;
}

type TImageInfo = {
  imageUrl: string;
  imageData: any;
  id?: number
} | null;

let _imageInfo: TImageInfo = null;
const ImageUploaderBoxFile = ({
  setFieldValue,
  fieldValue,
  name,
}: ImageUploaderBoxFileProps) => {

  // services
  const uploadMultimediaRequest = useUploadMultimedia();

  useEffect(() => {
    if (uploadMultimediaRequest?.data?.data?.result) {
      const { id } = uploadMultimediaRequest?.data?.data?.result;
      setFieldValue({ 
        ...fieldValue,
        [name]: {
          ..._imageInfo,
          id
        }
      });
    }
  }, [uploadMultimediaRequest?.data?.data])

  const addPackageImage = async (e: any) => {
    const files = e.target.files;
    if(!files?.length) return;
    await fileToBase64(files[0], 'image')
      .then((value) => {
        _imageInfo = {
          imageUrl: value,
          imageData: files[0]
        }
        const body = {
          base64: value.split(',')[1],
          fileName: files[0]?.name,
          fileType: {
            id: 100,
            name: "تصویر"
          }
        }
        uploadMultimediaRequest.mutate(body);
      })
  }

  return (
    <div className="w-100 h-00 rounded overflow-hidden bg-white pos-rel">
      {uploadMultimediaRequest?.isPending && <BoxLoader type="cover" />}
      <div className='inp-box file-box file-uploader-box h-100'>
        <span className="position-absolute pe-none zIndex-1">
          <Image src={GalleryAddImage} />
        </span>
        <input
          className='form-control file-uploader'
          type="file"
          name={name}
          onChange={addPackageImage}
          multiple
        />
      </div>
    </div>
  );
};

export default ImageUploaderBoxFile;

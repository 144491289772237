import {useMutation, useQuery} from "@tanstack/react-query";
import { PatientService } from "../../services"
import {ConditionService} from "../../services/requests/condition";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {toast} from "react-hot-toast";
import {localStorageGet} from "../../utils/localStorage";

const useGetPatients = (params: any) => {
  return useQuery({
    queryKey: ["getPatients", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PatientService.getPatientsList(params);
    },
  });
}

const useGetPatient = () => {
  return useMutation({
    mutationFn: PatientService.getPatient,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          // Noti("success", data?.data?.msg)
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const useAddPatient = () => {
  return useMutation({
    mutationFn: PatientService.addPatient,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const usePatientAdmission = () => {
  return useMutation({
    mutationFn: PatientService.patientAdmission,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          const providerData = localStorageGet('PROVIDER_DATA')
          !providerData.ihio &&
          toast('با توجه به عدم فعال سازی تنظیمات بیمه، استعلام بیمار برای شما فعال نمی‌باشد.', {
            position: 'top-left',
            duration: 10000,
            style: {
              backgroundColor: '#f9f4e3',
              color: '#ffaa05'
            },
          });
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetPatients,
  useGetPatient,
  useAddPatient,
  usePatientAdmission
}

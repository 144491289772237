import React, {useEffect, useRef, useState} from "react";
import {Rnd} from "react-rnd";
import {Collapse, Fade} from "react-bootstrap";
import {useWindowSize} from "../../../hook/useWindowSize";
import Icon from "../general/icon";
import Image from "../general/image";
import {getAge, getCellValue} from "../../../utils/utils";
import EmptyAvatar from "../../../assets/images/svg/profile-empty-avatar.svg";
import {TVisitRequest} from "../../../types/notification";
import {useUrgentAccept, useVisitCallAccept} from "../../../hook/request/visit/visits";
import urgentCallRingtone from "../../../assets/audio/ringtones/urgent_call_ringtone.mp3"
import visitCallRingtone from "../../../assets/audio/ringtones/call_ringtone.mp3"
import {Dispatch} from "redux";
import {general} from "../../../store/action";
import {connect} from "react-redux";
import WaitForApprovedNotifModal from "./waitForApprovedNotifModal";
import {NOTIFICATION_TYPE_ENUM, VISIT_STATUS_ENUM} from "../../../constants/constant/enum";

type EmergencyNotificationProps = {
  showDrawer: boolean;
  visitRequest?: TVisitRequest;
  visitRequestType?: string;
  handleDequeueVisitRequest?: any;
  handleEmptyVisitRequestQueue?: any;
  requestIsApproved?: boolean;
  setApproveAcceptId?: any;
  handleShowModal?: any;
}

const EmergencyNotification = ({
  showDrawer,
  visitRequest,
  visitRequestType,
  handleDequeueVisitRequest,
  handleEmptyVisitRequestQueue,
  requestIsApproved,
  setApproveAcceptId,
  handleShowModal,
}: EmergencyNotificationProps) => {

  const [width] = useWindowSize();
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const [open, setOpen] = useState(true);
  const [acceptData, setAcceptData] = useState<number>(0)
  const audioRef = useRef<HTMLAudioElement>(null);
  const soundIntervalRef = useRef<NodeJS.Timeout | null>(null);

  //services
  const urgentAcceptRequest = useUrgentAccept({
    onSuccess: (data) => {
      if(data?.data?.result?.accept) {
        handleShowModal('waitForApprovedNotifModal', {handleEmptyVisitRequestQueue: handleEmptyVisitRequestQueue, handleDequeueVisitRequest: handleDequeueVisitRequest, handleClose: handleClose});
      } else {
        handleClose(handleDequeueVisitRequest);
      }
    },
  })

  const visitCallAcceptRequest = useVisitCallAccept({
    onSuccess: (data) => {
      console.log(data)
      if(data?.data?.result?.accept) {
        handleShowModal('waitForApprovedNotifModal', {handleEmptyVisitRequestQueue: handleEmptyVisitRequestQueue, handleDequeueVisitRequest: handleDequeueVisitRequest, handleClose: handleClose});
      } else {
        handleClose(handleDequeueVisitRequest);
      }
    },
  })

  useEffect(() => {
    setOpen(true)
  }, [visitRequest]);

  useEffect(() => {
    const playSound = () => {
      if (audioRef.current) {
        // Ensure the audio can play
        audioRef.current.play().catch((error: any) => {
          // Handle any errors that might occur during playback
          console.error('Error playing sound:', error);
        });
      }
    }

    soundIntervalRef.current = setInterval(() => {
      playSound();
    }, 1000);

    const stopSoundTimeout = setTimeout(() => {
      clearInterval(soundIntervalRef.current!); // Stop playing sound
      // Perform additional actions after 30 seconds
      handleClose(handleDequeueVisitRequest);
    }, 30 * 1000);

    // Cleanup: clear intervals and timeouts when the component unmounts
    return () => {
      clearInterval(soundIntervalRef.current!);
      clearTimeout(stopSoundTimeout);
    };
  }, [visitRequest]);

  useEffect(() => {
    setApproveAcceptId(acceptData)
  }, [acceptData]);

  const handleVideoCallRequest = (response: boolean) => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the audio
      audioRef.current.currentTime = 0; // Reset playback position to the start
    }
    clearInterval(soundIntervalRef.current!);

    if(visitRequest?.id && response) {
      setAcceptData(visitRequest?.id)
    }

    const body = {
      id: visitRequest?.id,
      accept: response
    }
    visitRequestType === NOTIFICATION_TYPE_ENUM.URGENT_VISIT_REQUEST.type ? urgentAcceptRequest.mutate(body) :
      visitCallAcceptRequest.mutate(body)
  }

  const handleClose = (queueFunction: any) => {
    setShowPatientDetails(false);
    setOpen(false)
    const timer = setTimeout(() => {
      queueFunction();// Run after 1 second
    }, 1000); // Delay before starting the animation
    // Clear the timeout if handleClose is called again
    return () => clearTimeout(timer);
  };


  return (
    <Rnd
      className={`${open ? 'notification-show-animation' : 'notification-close-animation'}`}
      style={{
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
        borderRadius: '10px',
        background: "rgba(31, 31, 47, 0.7)",  // Semi-transparent background
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
        zIndex: 300,
        overflow: "hidden",
        position: 'fixed',
        backdropFilter: 'blur(10px)',  // Blur effect behind the component
        WebkitBackdropFilter: 'blur(10px)', // For compatibility with WebKit browsers
        transition: 'top 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55)', // Spring animation for y position
      }}
      size={{
        width: width < 992 ? (width - 40) : showDrawer ? (width - 380) : (width - 178),
        height: 'auto',
      }}
      disableDragging={true}
      enableResizing={false}
    >
      <audio ref={audioRef}
             src={visitRequestType === NOTIFICATION_TYPE_ENUM.URGENT_VISIT_REQUEST.type ? urgentCallRingtone :
               visitCallRingtone}
      />
      <div className='d-flex w-100 mx-2 p-2'>
        <div className='d-flex text-white align-items-center'>
          <span className="btn d-flex font-20 rounded-circle p-2"
                onClick={() => setShowPatientDetails(!showPatientDetails)}
          >
            <Icon name='arrow-diagonal'/>
          </span>
          {
            visitRequestType === NOTIFICATION_TYPE_ENUM.URGENT_VISIT_REQUEST.type ? (
              <span className='me-2'>
                شما یک تماس اورژانسی دارید، آیا قبول می‌کنید؟
              </span>
            ) :
              (
                <span className='me-2'>
            شما یک تماس از طرف      {visitRequest?.patient?.firstName + ' ' + visitRequest?.patient?.lastName} دارید، آیا قبول می‌کنید؟
                </span>
              )
          }

        </div>
        <Fade in={!showPatientDetails} unmountOnExit>
          <div className='d-flex align-items-center h-100 me-auto'>
            <a className="btn btn-green rounded-3 align-items-center fw-bold float-start p-3"
               onClick={() => handleVideoCallRequest(true)}
            >
              {width > 900 ? 'قبول درخواست' :
                <span className='font-25'>
                  <Icon name='clipboard-check'/>
                </span>
              }
            </a>
            <a className="btn btn-danger rounded-3 align-items-center fw-bold float-start me-2 p-3"
               onClick={() => handleVideoCallRequest(false)}
            >
              {width > 900 ? 'رد درخواست' :
                <span className='font-25'>
                  <Icon name='close-square'/>
                </span>
              }
            </a>
          </div>
        </Fade>
      </div>
      <Collapse in={showPatientDetails}>
        <div>
          <div className='rounded-4 text-white my-4 p-4' style={{backgroundColor: '#454855', minWidth: '35vw'}}>
            <div className="d-flex justify-content-center">
              <div className='align-content-center align-items-center text-center mx-2 w-80'>
                <Image
                  className='rounded mb-4'
                  src={EmptyAvatar}
                />
              </div>
            </div>
            <div className='row row-cols-2'>
              <div className='d-flex justify-content-center col'>
                <span>نام بیمار:</span>
                <span
                  className='me-2'>{getCellValue(visitRequest?.patient?.firstName)} {getCellValue(visitRequest?.patient?.lastName)}</span>
              </div>
              <div className='d-flex justify-content-center col'>
                <span>کد ملی:</span>
                <span className='me-2'>{getCellValue(visitRequest?.patient?.nationalCode)}</span>
              </div>
              <div className='d-flex justify-content-center col'>
                <span>جنسیت:</span>
                <span className='me-2'>{getCellValue(visitRequest?.patient?.gender?.name)}</span>
              </div>
              <div className='d-flex justify-content-center col'>
                <span>سن:</span>
                <span className='me-2'>{getCellValue(getAge(visitRequest?.patient?.birthDate))}</span>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <Fade in={showPatientDetails} unmountOnExit>
        <div className='d-flex align-items-center h-100 mb-4'>
          <a className="btn btn-green rounded-3 align-items-center fw-bold float-start p-3"
             onClick={() => handleVideoCallRequest(true)}
          >
            قبول درخواست
          </a>
          <a className="btn btn-danger rounded-3 align-items-center fw-bold float-start me-2 p-3"
             onClick={() => handleVideoCallRequest(false)}
          >
            رد درخواست
          </a>
        </div>
      </Fade>
      <WaitForApprovedNotifModal isApproved={requestIsApproved}/>
    </Rnd>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(EmergencyNotification);
import {useMutation, useQuery} from "@tanstack/react-query";
import {ExaminationService} from "../../../services/requests/visit/examination";

const useGetExaminationList = (params: any) => {
  return useQuery({
    queryKey: ["getExaminationList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return ExaminationService.getExaminationList(params);
    },
  });
}

const useGetExamination = () => {
  return useMutation({
    mutationFn: ExaminationService.getExamination,
  })
}


const useExaminationDetail = () => {
  return useMutation({
    mutationFn: ExaminationService.lastExaminationDetail,
  })
}

const useECGLead = () => {
  return useMutation({
    mutationFn: ExaminationService.ECGLead,
  })
}

const useECGSegment = () => {
  return useMutation({
    mutationFn: ExaminationService.ECGSegment,
  })
}

export {
  useGetExaminationList,
  useGetExamination,
  useExaminationDetail,
  useECGLead,
  useECGSegment
}

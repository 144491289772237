import React from "react";
import Card from "react-bootstrap/Card";
import Image from "../general/image";
import SuccessChecked from "../../../assets/images/svg/success-checked.svg";
import SuccessCheckedGray from "../../../assets/images/svg/success-checked-grey.svg";
import {ISSUER_TYPE} from "../../../constants/constant/enum";


// کامپوننت مربوط باکس  هربیمه  
// Parent:SettingInsurance
// در این کامپونتت با توجه به ورودی ها کامپونتت مربوط به هرکدام سخاته می شود

interface ISettingInsuranceCard {
  titleIcon: any;
  title: string;
  subTitle: string;
  active?: boolean;
  icon?: any;
  onClick?: () => void;
}

const SettingInsuranceCard: React.FC<ISettingInsuranceCard> = ({
  titleIcon,
  title,
  subTitle,
  active,
  onClick,
}) => {
  return (
    <Card
      className="w-100 h-100 border-light-blue cursor-pointer box-shadow cur-pointer"
      onClick={onClick}
    >
      <Card.Body className="d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <div>
            <Image src={titleIcon} alt={title} />
          </div>
          <div className="d-flex flex-column justify-content-center gap-3">
            <Card.Title className="font-12 font-weight-semibold">
             سازمان   {title}
            </Card.Title>
            <Card.Text
              className={`font-10 font-weight-medium ${
                !active ? "text-gray3" : " text-black"
              }`}
            >
              {subTitle}
            </Card.Text>
          </div>
        </div>
        <div>
          {title === ISSUER_TYPE.ESATA.label ?
            <button
              disabled
              className="btn rounded btn-gray-disabled font-weight-70 text-gray30 font-12"
            >
              {" "}
              به زودی
            </button> :
            active ? (
              <Image src={SuccessChecked} alt="ایکون فعال"/>
            ) : (
              <Image src={SuccessCheckedGray} alt="ایکون غیر فعال"/>
            )
          }
      </div>
    </Card.Body>
</Card>
  );
};

export default SettingInsuranceCard;

import {useEffect, useState} from "react";
import DoctorDashboard from "./doctorDashboard";
import OperatorDashboard from "./operatorDashboard";
import {localStorageGet} from "../../../utils/localStorage";
import {useSelector} from "react-redux";

const MainDashboard = () => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);

  if (isProvider === undefined) {
    // You can return null to render nothing, or return a loading spinner or message
    return null; // Or you can replace null with a loading component, e.g., <LoadingSpinner />
  }
  return (
    <>
      {isProvider ? <DoctorDashboard /> : <OperatorDashboard />}
    </>
  );
}

export default MainDashboard;
import React from "react";
import {connect, useSelector} from "react-redux";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import {MtoJDateFormat, MtoJHHMMFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const VacationTableRowItem = ({
  item,
  handleShowModal
}) => {
  const isProvider = useSelector((state) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return(
    <tr>
      <td>{getCellValue(item?.provider?.name)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJDateFormat(item?.fromDateTime))} {getCellValue(MtoJHHMMFormat(item?.fromDateTime))}</bdi>
      </td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJDateFormat(item?.toDateTime))} {getCellValue(MtoJHHMMFormat(item?.toDateTime))}</bdi>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {/* <span
            onClick={() => navigate(generatePath(ROUTES.VACATION_EDIT.PATH, { id: item?.id }))}
            className="d-flex text-hover mx-2 font-18 cur-pointer"
          >
            <Icon name='pen-square' />
          </span> */}
          {
            (isProvider || findAccessInAccessList('PROVIDER_VACATION_DELETE')) &&
            <Tooltip alt={'حذف'} className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deleteVacationModal', item?.id)}
                className="d-flex text-red-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='trash' />
              </span>
            </Tooltip>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VacationTableRowItem);

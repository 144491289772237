import React from "react";
import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../../utils/date";


type PatientAppointmentTableRowItemProps = {
  item: any;
};

const PatientCigarettesTableRowItem = ({
  item,
}: PatientAppointmentTableRowItemProps) => {
  return (
    <tr>
      <td>
        <div>{getCellValue(item?.frequency.name)}</div>
      </td>
      <td>
      <div>{getCellValue(MtoJDateFormat(item?.startDate))}</div>
      </td>
      <td>
        <div className="word-spacing-10">
          {getCellValue(MtoJDateFormat(item?.endDate))}
        </div>
      </td>
      <td>
      <div>{getCellValue(MtoJDateFormat(item?.updateDate))}</div>
      </td>
      <td>
        <div>{getCellValue(item?.description)}</div>
      </td>
    </tr>
  );
};

export default PatientCigarettesTableRowItem;

import React from "react";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { general } from "../../../store/action";

import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector,
} from "../../../store/selector/general";
import Image from "../../components/general/image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// icons
import socialSecurity from "../../../assets/images/svg/tamin.svg";
import HeathOrganization from "../../../assets/images/svg/salamat.svg";
import niroohayeMosalah from "../../../assets/images/svg/niroohaye-mosalah.svg";
import warning from "../../../assets/images/svg/warning.svg";
// children component
import SettingInsuranceCard from "../../components/setting/settingInsuranceCard";
import AddSettingInsuranceModal from "../../components/setting/addSettingInsuranceModal";
import {
  useGetAllINsurers,
} from "../../../hook/request/setting";
import { ISSUER_TYPE } from "../../../constants/constant/enum";
import EmptyBox from "../../components/general/emptyBox";

// کامپوننت مربوط به تنظیمات و تنظیمات ارتباط بیمه
// Parent:
// Children:SettingInsuranceCard
// در این کامپوننت لیست بیمه ها رندر می شود 
// و در صورت انتخاب هر بیمه با توجه با فعال یا غیر فعال بودن ان نمایش داده می شود


type IIssuerType = {
  id: number;
  name: string;
};
interface InsurersData {
  active: boolean;
  issuerType: IIssuerType;
  password: null;
  username: string;
}
// ISSUER_TYPE
const insurerIcons: any = {
  0: HeathOrganization,
  1: socialSecurity,
  2: niroohayeMosalah,
};
const SettingInsurance = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleShowModal,
}: any) => {
  // services
  const getInsurers = useGetAllINsurers();
  const [insurersData, setInsurersData] = React.useState<InsurersData[]>([]);

  React.useEffect(() => {
    if (getInsurers?.data?.data?.result) {
      const data = getInsurers.data.data.result;
      setInsurersData(data);
    }
  }, [getInsurers.data]);

  const handleSuccess = (issuerTypeId: number) => {
    const issuerIndex = insurersData.findIndex(
      (item) => item.issuerType?.id === issuerTypeId
    );
    if (issuerIndex !== -1) {
      const updatedInsurersData = insurersData.map((item, index) =>
        index === issuerIndex
          ? { ...item, active: true }
          : item
      );
      setInsurersData(updatedInsurersData);
    }
  }

  return (
    <>
      <Row className="card-box">
        <Card className="border-light-blue">
          <div className="d-flex align-items-center p-3 py-4  gap-2">
            <Image src={warning} alt="" />
            <h4 className="font-14 font-weight-semibold ">
              وضعیت ارتباط با بیمه‌های طرف قرارداد
            </h4>
          </div>
          <Row className="d-flex flex-wrap bg-gray5 p-4">
            {insurersData.length > 0 ? (
              insurersData?.map((item, index) => (
                <Col sm={12} md={6} lg={4} className="d-flex align-items-center my-2">
                  <SettingInsuranceCard
                    subTitle=""
                    active={item.active}
                    titleIcon={insurerIcons[`${index}`]}
                    title={item.issuerType.name}
                    onClick={() =>
                      item.issuerType.id !== ISSUER_TYPE.ESATA.value &&
                        handleShowModal("showAddInsurer", {
                          id: item.issuerType.id,
                          name: item.issuerType.name,
                          active: item.active,
                          handleSuccess: handleSuccess
                      })
                    }
                  />
                </Col>
              ))
            ) : (
              <EmptyBox />
            )}
          </Row>
        </Card>
      </Row>
      <AddSettingInsuranceModal />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) =>
    dispatch(general.handleShowModal(data, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingInsurance);

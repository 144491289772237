import Image from "../../../components/general/image";
import {getAge, getCellValue} from "../../../../utils/utils";
import React from "react";
import {TPatientFields} from "../../../../types/patient";
import {TCheckInsuranceFields} from "../../../../types/prescription";
import EmptyAvatar from "../../../../assets/images/svg/profile-empty-avatar.svg"

type PatientDetailsProps = {
  patient: TPatientFields
  insurance?: TCheckInsuranceFields;
  isRequesting?: boolean;
}

const PatientDetails = ({
  patient,
  insurance,
  isRequesting
}: PatientDetailsProps) => {

  return (
    <div className="align-items-center px-4 mb-4">
      <div className='d-lg-flex rounded mx-3'>
        <div className="d-flex col-lg-2 col-sm-12 col-md-12 justify-content-center">
          <div className='align-content-center align-items-center text-center mx-2 w-50'>
            <Image
              className='rounded mb-4'
              src={insurance?.avatar ? getCellValue(insurance?.avatar) : EmptyAvatar}
            />
            <div className='fw-medium font-12 mb-2'>{getCellValue(patient?.firstName)} {getCellValue(patient?.lastName)}</div>
            <div className='font-10 mb-2'>{patient.birthDate && getAge(getCellValue(patient.birthDate))} سال</div>
          </div>
        </div>
        <div className='col-lg-10 col-sm-12 col-md-12 row row-cols-5 my-4'>
          <div className="col px-2 pb-2 text-center rmdp-border-right border-2 border-light-blue">
            <div className='font-10 text-gray fw-medium mb-3'>بیمه</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''>{getCellValue(insurance?.issuerType?.name)}</span>
            </div>
          </div>
          <div className="col px-2 pb-2 text-center rmdp-border-right border-2 border-light-blue">
            <div className='font-10 text-gray fw-medium mb-3'>شماره تماس</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''>{getCellValue(patient?.mobile)}</span>
            </div>
          </div>
          <div className="col px-2 pb-2 text-center rmdp-border-right border-2 border-light-blue">
            <div className='font-10 text-gray fw-medium mb-3'>بیماری خاص</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''>{getCellValue(insurance?.specialAccount)}</span>
            </div>
          </div>
          <div className="col px-2 pb-2 text-center rmdp-border-right border-2 border-light-blue">
            <div className='font-10 text-gray fw-medium mb-3'>نسبت با سرپرست</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''>{getCellValue(insurance?.relationType)}</span>
            </div>
          </div>
          <div className="col px-2 pb-2 text-center rmdp-border-right border-2 border-light-blue">
            <div className='font-10 text-gray fw-medium mb-3'>نام سرپرست</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''>{getCellValue(insurance?.responsibleFullName)}</span>
            </div>
          </div>
          <div className="col px-2 pt-2 mb-2 text-center border-top rmdp-border-right border-2 border-light-blue align-content-end">
            <div className='font-10 text-gray fw-medium mb-3'>آخرین ویزیت</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''>--</span>
            </div>
          </div>
          <div className="col px-2 pt-2 mb-2 text-center border-top rmdp-border-right border-2 border-light-blue align-content-end">
            <div className='font-10 text-gray fw-medium mb-3'>کد ملی</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''>{getCellValue(patient?.nationalCode)}</span>
            </div>
          </div>
          <div className="col px-2 pt-2 mb-2 text-center border-top rmdp-border-right border-2 border-light-blue align-content-end">
            <div className='font-10 text-gray fw-medium mb-3'>تاریخ اعتبار</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''>--</span>
            </div>
          </div>
          <div className="col px-2 pt-2 mb-2 text-center border-top rmdp-border-right border-2 border-light-blue align-content-end">
            <div className='font-10 text-gray fw-medium mb-3 '>صندوق بیمه</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''>{getCellValue(insurance?.insuranceBox)}</span>
            </div>
          </div>
          <div className="col px-2 pt-2 mb-2 text-center border-top rmdp-border-right border-2 border-light-blue align-content-end">
            <div className='font-10 text-gray fw-medium mb-3'>پزشک خانواده</div>
            <div className='font-13 d-flex justify-content-center'>
              <span className=''> {getCellValue(insurance?.familyPhysician)} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientDetails;
import React, {useEffect, useMemo, useRef, useState} from "react";
import { ROUTES } from "../../../constants";
import BoxLoader from "../../components/general/boxLoader";
import NumberField from "../../components/general/form/numberField";
import {AsyncSelectField} from "../../components/general/form/selectField";
import TextField from "../../components/general/form/textField";
import PasswordField from "../../components/general/form/passwordField";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ImageUploaderBox from "../../components/utility/imageUploader/imageUploaderBox";
import { useAddDoctor, useGetDoctor, useGetSpecialitiesList } from "../../../hook/request/doctors";
import { TAddDoctorFields } from "../../../types/doctor";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDoctorSchema, editDoctorSchema } from "../../../schema/doctor-schema";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import {asyncSelectLoadOptions} from "../../../utils/asyncSelectLoadOptions";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const AddDoctor = () => {

  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const { id } = useParams();
  const { getQueryParams } = useMySearchParams();
  const [searchParams] = useSearchParams();
  const [specialtiesFilterValues, setSpecialtiesFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "20",
    search: "",
  });
  const [fieldValue, setFieldValue] = useState<TAddDoctorFields>({
    firstName: '',
    lastName: '',
    specialty: '',
    medId: '',
    nationalCode: '',
    mobile: '',
    password: ''
  });
  const [avatarData, setAvatarData] = useState({
    avatar: {
      id: 0,
      imageUrl: "",
      imageData: {
        name: ""
      }
    }
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddDoctorFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(id ? editDoctorSchema : addDoctorSchema),
  });
  const [specialitiesList, setSpecialitiesList] = useState([]);
  const [fetch, setFetch] = useState<boolean>(true)

  const callbackRef = useRef<any>(null);

  // services
  const getSpecialitiesListRequest = useGetSpecialitiesList(getQueryParams(specialtiesFilterValues), fetch);
  const getDoctorRequest = useGetDoctor();
  const addDoctorRequest = useAddDoctor();

  useEffect(() => {
    let _SpecialtiesFilterValues = {};
    _SpecialtiesFilterValues = {
      ...specialtiesFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "20",
      search: searchParams.get("search") || ""
    }
    setSpecialtiesFilterValues(_SpecialtiesFilterValues)
    setFetch(false)
  }, [searchParams]);

  useEffect(() => {
    id && getDoctorRequest.mutate({id});
  }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getDoctorRequest?.data?.data?.result) {
      const { avatar, firstName, lastName, specialty, medId, nationalCode, mobile } = getDoctorRequest.data.data.result,
        _fieldValue = {
          firstName, lastName, medId, nationalCode, mobile,
          specialty: specialty?.id,
          // specialty: { label: specialty?.name, value: specialty?.id },
        };
      setFieldValue(_fieldValue);
      setAvatarData({
        avatar: {
          id: avatar?.id,
          imageUrl: avatar?.name,
          imageData: {
            name: ""
          }
        }
      })
    }
  }, [getDoctorRequest.data])

  useEffect(() => {
    if (getSpecialitiesListRequest?.data?.data?.result) {
      const { data } = getSpecialitiesListRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id }
        ))
      setSpecialitiesList(_data);
    }
  }, [getSpecialitiesListRequest.data])

  const changeRequestFilters = (inputValue: any, callback: any) => {
    callbackRef.current = callback;
    setSpecialtiesFilterValues({
      ...specialtiesFilterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(callbackRef.current) {
      specialtiesLoadOptions(specialtiesFilterValues.search, callbackRef.current);
    }
  }, [specialtiesFilterValues?.search]);

  const specialtiesLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, specialtiesFilterValues, setSpecialtiesFilterValues, getSpecialitiesListRequest);
  };

  const addDoctorSubmit: SubmitHandler<TAddDoctorFields> = (data) => {
    const { firstName, lastName, specialty, medId, nationalCode, mobile, password } = data,
      { avatar } = avatarData,
      body = {
        ...(id ? {id} : {}),
        firstName, lastName, medId, nationalCode, mobile, password,
        specialty: {
          id: specialty,
          name: ""
        },
        avatar: avatar?.id ? {
            id: avatar?.id,
            name: avatar?.imageData?.name
          } : null
      };
    addDoctorRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getSpecialitiesListRequest?.isLoading ||
          getDoctorRequest?.isPending ||
          addDoctorRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(addDoctorSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">افزودن تصویر نمایه</h4>
            </div>
            <div className="col-12 px-2 px-lg-4">
              <div className="form-group mb-5">
                <ImageUploaderBox
                  setFieldValue={setAvatarData}
                  fieldValue={avatarData}
                  name='avatar'
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات پزشک</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="firstName">نام</label>
                <TextField
                  name="firstName"
                  placeholder="نام"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="lastName">نام خانوادگی</label>
                <TextField
                  name="lastName"
                  placeholder="نام خانوادگی"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="specialty">تخصص</label>
                <AsyncSelectField
                  options={specialitiesList}
                  name="specialty"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                  asyncLoadOptions={changeRequestFilters}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="medId">‌شماره نظام پزشکی</label>
                <NumberField
                  name="medId"
                  placeholder="شماره نظام پزشکی"
                  className="ltr plc-right"
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={8}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="nationalCode">‌کد ملی</label>
                <NumberField
                  name="nationalCode"
                  format='##########'
                  className="ltr plc-right"
                  placeholder='کدملی'
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={10}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="mobile">‌شماره همراه</label>
                <NumberField
                  name="mobile"
                  format='##########'
                  className="ltr plc-right"
                  placeholder="شماره همراه"
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={11}
                />
              </div>
            </div>
            {!id &&
              <div className="col-12 col-md-6 px-4">
                <div className="form-group mb-5">
                  <label className="inp-lbl me-2 mb-2" htmlFor="password">‌کلمه عبور</label>
                  <PasswordField
                    name="password"
                    placeholder="‌کلمه عبور"
                    className="ltr plc-right font-en"
                    control={control}
                    error={errors}
                  />
                </div>
              </div>
            }
            <div className="col-12 px-4 d-flex justify-content-end mt-2">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.DOCTOR?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                {
                  ((findAccessInAccessList('PROVIDER_EDIT') && id) || (findAccessInAccessList('PROVIDER_CREATE') && !id)) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    { id ? "ذخیره تغییرات" : "افزودن پزشک" }
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDoctor;
import { Controller } from "react-hook-form";

type SwitchFieldProps = {
  onChange: (e: any) => void;
  name: string;
  value?: boolean;
  label: string;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  error?: any;
  control?: any;
}

const SwitchField = ({
  onChange,
  name,
  value,
  label,
  className = '',
  children,
  error,
  disabled,
  control
}: SwitchFieldProps) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, onFieldChange: any) => {
    onChange && onChange(e);
    onFieldChange(e);
  }

  let showError = error?.hasError && error?.dataError?.[name];
  return(
    <>
      <div className={`inp-box d-flex ${showError ? 'invalid-field' : ''} ${className}`}>
        <div className="d-flex align-items-center">
          <label className="custom-switch mb-0">
          {
            control ? 
              <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                  <input
                    type="checkbox"
                    name={name}
                    checked={value}
                    onChange={(e) => handleChange(e, onChange)}
                    className="custom-control-input"
                    id={name}
                    disabled={disabled}
                  />
                )}
              /> :
              <input
                type="checkbox"
                name={name}
                checked={value}
                onChange={onChange}
                className="custom-control-input"
                id={name}
                disabled={disabled}
              />
            }
            <span className="slider round"></span>
          </label>
          { label && <label className="mr-2 mb-0" htmlFor={name}>{label}</label> }
        </div>
        {children}
      </div>
      { showError && <p className="text-danger font-10 mt-1">{error?.dataError?.[name]}</p> }
    </>
  )
}

export default SwitchField;
import React from "react";
import BoxLoader from "../../general/boxLoader";
import EmptyBox from "../../general/emptyBox";
import VacationCardsBoxItem from "./vacationCardsBox_item";

const VacationCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting
}) => {
    const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item, i) => {
      return <VacationCardsBoxItem item={item} dataListTitles={dataListTitles} key={i} />
    })
  return(
    <div>
      { isRequesting ? <BoxLoader /> : _dataList ? _dataList : <EmptyBox /> }
    </div>
  )
}

export default VacationCardsBox;
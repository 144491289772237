import {useMutation, useQuery} from "@tanstack/react-query";
import {MAXAService} from "../../services/requests/maxa";


// get maxa list
const useGetMAXAList = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getMAXAList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return MAXAService.getMAXAList(params);
    },
    enabled: modalName ?? true
  });
}


export {
  useGetMAXAList
}

import * as React from "react"
import { SVGProps } from "react"
const LeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={17}
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      opacity={0.3}
      width={1.30942}
      height={6.41821}
      rx={0.65471}
      transform="matrix(-0.00105707 0.999999 -1 -0.000890902 15.1914 8.12891)"
      fill="#3F8DFD"
    />
    <path
      d="M9.99307 5.31347C10.2718 5.05804 10.2723 4.6435 9.99405 4.38757C9.71582 4.13165 9.26429 4.13124 8.98552 4.38668L4.70256 8.31112C4.43233 8.55874 4.42246 8.95783 4.68018 9.21646L8.59827 13.1482C8.86412 13.415 9.31521 13.4334 9.6058 13.1893C9.89639 12.9453 9.91644 12.5311 9.65058 12.2643L6.19339 8.79509L9.99307 5.31347Z"
      fill="#97ABFF"
    />
  </svg>
)
export default LeftArrow

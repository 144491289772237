import React from 'react';
import Icon from '../icon';
import { DebounceInput } from 'react-debounce-input';
import { Controller } from 'react-hook-form';

// a DebounceField for text and number types
type DebounceFieldProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: () => void;
  name: string;
  value?: string;
  placeholder?: string;
  className?: string;
  minLength?: number;
  timeout?: number;
  isNumber?: boolean;
  icon?: string;
  disabled?: boolean;
  readOnly?: boolean;
  children?: React.ReactNode;
  error?: any;
  control?: any;
  register?: any;
  watch?: any
}

const DebounceField = ({
  onChange,
  onKeyDown,
  name,
  value,
  placeholder,
  className = '',
  minLength,
  timeout,
  isNumber,
  icon,
  disabled,
  readOnly,
  children,
  error,
  control,
  register,
  watch
}: DebounceFieldProps) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, onFieldChange: any) => {
    onChange && onChange(e);
    onFieldChange(e);
  }

  let showError = error?.hasError && error?.dataError?.[name];
  return(
    <>
      <div className={`inp-box ${showError ? 'invalid-field' : ''}`}>
        <span className="inp-icon"><Icon name={icon} /></span>
        {
          watch ?
            <Controller
              control={control}
              name={name}
              {...register}
              render={({ field: { onChange, value } }) => (
                <DebounceInput
                  className={`form-control placeholder-field-color ${icon ? 'pl-5' : ''} ${className}`}
                  value={watch}
                  name={name}
                  type={isNumber ? "number" : "text"}
                  onChange={(e) => handleChange(e, onChange)}
                  onKeyDown={onKeyDown}
                  placeholder={placeholder}
                  disabled={disabled}
                  readOnly={readOnly}
                  minLength={minLength || 0}
                  debounceTimeout={timeout || 500}
                />
              )}
            /> :
            control ?
              <Controller
                control={control}
                name={name}
                {...register}
                render={({ field: { onChange, value } }) => (
                  <DebounceInput
                    className={`form-control placeholder-field-color ${icon ? 'pl-5' : ''} ${className}`}
                    value={value}
                    name={name}
                    type={isNumber ? "number" : "text"}
                    onChange={(e) => handleChange(e, onChange)}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={readOnly}
                    minLength={minLength || 0}
                    debounceTimeout={timeout || 500}
                  />
                )}
              /> :
              <DebounceInput
                className={`form-control placeholder-field-color ${icon ? 'pl-5' : ''} ${className}`}
                value={value}
                name={name}
                type={isNumber ? "number" : "text"}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                minLength={minLength || 0}
                debounceTimeout={timeout || 500}
              />
        }
        {children}
      </div>
      { showError && <p className="text-danger font-10 mt-1">{error?.dataError?.[name]}</p> }
    </>
  )
}

export default DebounceField;
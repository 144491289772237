import { Controller } from 'react-hook-form';
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

export const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];

type DatePickerFieldProps = {
  onChange?: (e: any) => void;
  name?: string;
  value?: any;
  minDate?: string;
  maxDate?: string;
  placeholder?: string;
  className?: string;
  icon?: string;
  ref?: any;
  showTodayButton?: boolean;
  children?: React.ReactNode;
  format?: string;
  showTimePicker?: boolean | "only";
  onlyMonthPicker?: boolean;
  onlyYearPicker?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: any;
  control?: any;
  register?: any;
  watch?: any
}

const DatePickerField = ({
  onChange,
  name = "",
  minDate,
  maxDate,
  placeholder,
  className,
  children,
  format,
  showTimePicker,
  onlyMonthPicker,
  onlyYearPicker,
  disabled,
  readOnly,
  ref,
  error,
  control,
  register,
  watch
}: DatePickerFieldProps) => {

  const handleChange = (val: any, onFieldChange: any) => {
    if(val) {
      let returnValue = {
        target: {
          name: name,
          value: val.toDate(),
          type: 'date'
        }
      };
      onChange && onChange(returnValue);
      onFieldChange(returnValue);
    }
  }

  let showError = error?.[name] && error?.[name]?.message;
  return(
    <>
      <div className={`inp-box ${showError ? 'invalid-field' : ''}`}>
        {
          watch ?
            <Controller
              control={control}
              name={name}
              defaultValue={""}
              {...register}
              render={({ field: { onChange, value } }) => {
                return(
                  <DatePicker
                    ref={ref}
                    calendar={persian}
                    locale={persian_fa}
                    containerClassName="w-100"
                    inputClass={"form-control placeholder-field-color"}
                    className={`${className} ${disabled ? ' text-black-50 ' : ''}`}
                    onChange={(val) => handleChange(val, onChange)}
                    name={name}
                    value={watch ? new Date(watch) : null}
                    weekDays={weekDays}
                    mapDays={({ date }) => {
                      let props: any = {},
                        isWeekend = [6].includes(date.weekDay.index)
                      if (isWeekend) props.className = "highlight highlight-red";
                      return props
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholder={placeholder}
                    disableDayPicker={showTimePicker === "only" ? true : false}
                    format={format}
                    plugins={[
                      !!showTimePicker ? <TimePicker position="bottom" hideSeconds /> : []
                    ]}
                    onlyMonthPicker={onlyMonthPicker}
                    onlyYearPicker={onlyYearPicker}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                )
              }}
            /> :
            control &&
              <Controller
                control={control}
                name={name}
                defaultValue={""}
                {...register}
                render={({ field: { onChange, value } }) => {
                  return(
                    <DatePicker
                      ref={ref}
                      calendar={persian}
                      locale={persian_fa}
                      containerClassName="w-100"
                      inputClass={"form-control placeholder-field-color"}
                      className={` ${className}`}
                      onChange={(val) => handleChange(val, onChange)}
                      name={name}
                      value={value ? new Date(value) : null}
                      weekDays={weekDays}
                      mapDays={({ date }) => {
                        let props: any = {},
                          isWeekend = [6].includes(date.weekDay.index)
                        if (isWeekend) props.className = "highlight highlight-red";
                        return props
                      }}
                      minDate={minDate}
                      maxDate={maxDate}
                      placeholder={placeholder}
                      disableDayPicker={showTimePicker === "only" ? true : false}
                      format={format}
                      plugins={[
                        !!showTimePicker ? <TimePicker position="bottom" hideSeconds /> : []
                      ]}
                      onlyMonthPicker={onlyMonthPicker}
                      onlyYearPicker={onlyYearPicker}
                      disabled={disabled}
                      readOnly={readOnly}
                    />
                  )
                }}
              />
        }
        {children}
      </div>
      {showError && <p className="text-danger font-10 mt-1 me-2">{error?.[name]?.message}</p>}
    </>
  )
}

export default DatePickerField;
import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect, useSelector} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {general} from "../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {useAddPatientAllergy, useGetPatientAllergy} from "../../../../../hook/request/ehr/patientAllergy";
import Modal from "../../../general/modal";
import BoxLoader from "../../../general/boxLoader";
import Icon from "../../../general/icon";
import TextField from "../../../general/form/textField";
import SelectField, {AsyncSelectField} from "../../../general/form/selectField";
import DatePickerField from "../../../general/form/datePickerField";
import {TAddPatientAllergyFields} from "../../../../../types/patient";
import {addPatientAllergySchema} from "../../../../../schema/patient-schema";
import TextAreaField from "../../../general/form/textAreaField";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import {ALLERGY_SEVERITY} from "../../../../../constants/constant/enum";
import {useGetAllergyReactions} from "../../../../../hook/request/allergyReaction";
import {useGetAllergies} from "../../../../../hook/request/allergy";
import {asyncSelectLoadOptions} from "../../../../../utils/asyncSelectLoadOptions";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";


type AddPatientAllergyModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type ModalInfoProps = {
  id: string,
  patientId: string,
  patientData?: any,
}


const AddPatientAllergyModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPatientAllergyModalProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    patientId: '',
    patientData: ''
  });

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  const fieldValueInitialState = {
    allergy: "",
    severity: "",
    reactions: [],
    otherReactions: '',
    startDate: null,
    endDate: null,
    description: '',
  }
  const [fieldValue, setFieldValue] = useState<TAddPatientAllergyFields>(fieldValueInitialState);
  const [showOtherReactions, setShowOtherReactions] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TAddPatientAllergyFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addPatientAllergySchema),
  });
  const [AllergyFilterValues, setAllergyFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [allergyList, setAllergyList] = useState([]);
  const [defaultAllergies, setDefaultAllergies] = useState<boolean>(false);
  const [AllergyReactionFilterValues, setAllergyReactionFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [allergyReactionList, setAllergyReactionList] = useState([]);
  const [defaultAllergyReactions, setDefaultAllergyReactions] = useState<boolean>(false);

  const allergyCallbackRef = useRef<any>(null);
  const reactionCallbackRef = useRef<any>(null);

  // services
  const getAllergyListRequest = useGetAllergies(getQueryParams(AllergyFilterValues), modalName === 'addPatientAllergyModal');
  const getAllergyReactionListRequest = useGetAllergyReactions(getQueryParams(AllergyReactionFilterValues), modalName === 'addPatientAllergyModal');
  const addPatientAllergyRequest = useAddPatientAllergy({
    onSuccess: () => {
      handleSetShowModal();
    }
  });
  const getPatientAllergyRequest = useGetPatientAllergy();

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);


  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addPatientAllergyModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData?.id
      }
      handleGetPatientAllergy(data)
    }
  }, [modalData, modalName])

  const handleGetPatientAllergy = (data: any) => {
    data.id && getPatientAllergyRequest.mutate(data);
  }

  useEffect(() => {
    let _AllergyReactionFilterValues = {};
    _AllergyReactionFilterValues = {
      ...AllergyReactionFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setAllergyReactionFilterValues(_AllergyReactionFilterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getAllergyReactionListRequest?.data?.data?.result) {
      const { data } = getAllergyReactionListRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id }
        ))
      _data.push({
        label: 'Other',
        value: 0
      })
      setAllergyReactionList(_data);
      setDefaultAllergyReactions(true);

    }
  }, [getAllergyReactionListRequest.data])

  useEffect(() => {
    let _AllergyFilterValues = {};
    _AllergyFilterValues = {
      ...AllergyFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setAllergyFilterValues(_AllergyFilterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getAllergyListRequest?.data?.data?.result && !defaultAllergies) {
      const { data } = getAllergyListRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id }
        ))
      setAllergyList(_data);
      setDefaultAllergies(true);
    }
  }, [getAllergyListRequest.data])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);


  useEffect(() => {
    if (getPatientAllergyRequest?.data?.data?.result) {
      const {
          imei, updateDate
        } = getPatientAllergyRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          imei,
          updateDate
        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getPatientAllergyRequest.data])

  const checkForOtherReactions = (e: any) => {
    const selected = e?.target?.value;
    const findOther = selected.some((item: any) => item.label === "Other");
    if(findOther) {
      setShowOtherReactions(true)
    } else {
      setShowOtherReactions(false);
    }
  }

  const addPatientAllergy: SubmitHandler<TAddPatientAllergyFields> = (data) => {
    const { allergy, severity, reactions, otherReactions, startDate, endDate, description } = data;
    let reactionsBody = reactions?.filter((item: any) => item?.value !== 0)
      .map((item:any) => (
      { id: item?.value}
    ));
    const body = {
      id: modalInfo?.id ? modalInfo?.id : 0,
      patient: {
        id: modalInfo?.patientId
      },
      entrySource:{
        id:200
      },
      allergy: {
        id: allergy,
      },
      severity: {
        id: severity,
      },
      reactions: reactionsBody,
      otherReactions, startDate, endDate, description
    };
    addPatientAllergyRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue(fieldValueInitialState)
    handleHideModal();
  }

  const changeAllergyRequestFilters = (inputValue: any, callback: any) => {
    allergyCallbackRef.current = callback;
    setAllergyFilterValues({
      ...AllergyFilterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(allergyCallbackRef.current) {
      allergyLoadOptions(AllergyReactionFilterValues.search, allergyCallbackRef.current);
    }
  }, [AllergyReactionFilterValues?.search]);

  const allergyLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, AllergyFilterValues, setAllergyFilterValues, getAllergyListRequest);
  };

  const changeReactionRequestFilters = (inputValue: any, callback: any) => {
    reactionCallbackRef.current = callback;
    setAllergyReactionFilterValues({
      ...AllergyReactionFilterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(reactionCallbackRef.current) {
      allergyReactionsLoadOptions(AllergyReactionFilterValues.search, reactionCallbackRef.current);
    }
  }, [AllergyReactionFilterValues?.search]);

  const allergyReactionsLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, AllergyReactionFilterValues, setAllergyReactionFilterValues, getAllergyReactionListRequest);
  };

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          getPatientAllergyRequest?.isPending ||
          // getAllergyListRequest?.isPending ||
          getAllergyReactionListRequest?.isLoading ||
          addPatientAllergyRequest?.isPending
        ) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {modalInfo?.id ? "ویرایش حساسیت" : "ثبت حساسیت"}
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.firstName + ' ' + modalInfo?.patientData?.lastName}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(addPatientAllergy)}>
          <div className="row">
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="allergy">نام حساسیت</label>
                <AsyncSelectField
                  options={allergyList}
                  name="allergy"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  // returnObjectValue={true}
                  control={control}
                  error={errors}
                  asyncLoadOptions={changeAllergyRequestFilters}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="severity">شدت حساسیت</label>
                <SelectField
                  options={ALLERGY_SEVERITY}
                  name="severity"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  // returnObjectValue={true}
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="reactions">واکنش ها</label>
                <AsyncSelectField
                  options={allergyReactionList}
                  name="reactions"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  onChange={(e) => checkForOtherReactions(e)}
                  control={control}
                  error={errors}
                  isMulti
                  asyncLoadOptions={changeReactionRequestFilters}
                />
              </div>
            </div>
              <div className="col-12 col-md-6 px-4">
                { showOtherReactions &&

                  <div className="form-group mb-3">
                  <label className="inp-lbl me-2 mb-2" htmlFor="otherReactions">سایر واکنش ها</label>
                  <TextField
                    name="otherReactions"
                    placeholder="سایر واکنش ها"
                    control={control}
                    error={errors}
                  />
                </div>
                }
              </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="startDate">تاریخ شروع</label>
                <DatePickerField
                  name="startDate"
                  placeholder="تاریخ شروع"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="endDate">تاریخ پایان</label>
                <DatePickerField
                  name="endDate"
                  placeholder="تاریخ پایان"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  rows={2}
                  placeholder="توضیحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                {(isProvider || findAccessInAccessList('EHR_ALLERGY_CREATE')) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    {modalInfo?.id ? "ویرایش حساسیت" : "ثبت حساسیت"}
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientAllergyModal);
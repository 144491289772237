import UserAccessLevelBoxItem from "./userAccessLevelBox_item";
import { TUserAccessFields } from "../../../types/user";

type UserAccessLevelBoxProps = {
  item: any;
  fieldValue: any;
  setFieldValue: React.Dispatch<React.SetStateAction<TUserAccessFields>>;
}

const UserAccessLevelBox = ({
  item,
  fieldValue,
  setFieldValue
}: UserAccessLevelBoxProps) => {

  const _accessProcess = item?.accessProcess.map((data: any) => {
    return (
      <UserAccessLevelBoxItem
        data={data}
        fieldValue={fieldValue}
        setFieldValue={setFieldValue}
      />
    )
  })

  return (
    <div className="my-5">
      <h4>{item?.name}</h4>
      {_accessProcess}
    </div>
  );
};

export default UserAccessLevelBox;

import { ACTION_TYPES } from "../../../constants";

const initialState = {
  requesting: false,
  success: false,
  error: false,
  data: null,
};

const deleteClinicReducer = (state = initialState, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case ACTION_TYPES.CLINIC.DELETE.SUCCESS: {
      return {
        success: true,
        requesting: false,
        error: false,
        data: payload,
      }
    }
    case ACTION_TYPES.CLINIC.DELETE.REQUESTING:
      return { success: false, requesting: true, error: false };
    case ACTION_TYPES.CLINIC.DELETE.ERROR:
      return { success: false, requesting: false, error: true, data: payload };
    case ACTION_TYPES.CLINIC.DELETE.RESET:
      return initialState;
    default:
      return state
  }
}

export default deleteClinicReducer
import React from "react";
import Loader from "../../../../general/boxLoader";
import EmptyBox from "../../../../general/emptyBox";
import PatientPrescriptionCardsItem from "./patientPrescriptionCards_item";

type PatientPrescriptionsCardsProps = {
  dataList?: any;
  isRequesting: boolean
}

const PatientPrescriptionCards = ({
 dataList,
 isRequesting
}: PatientPrescriptionsCardsProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <PatientPrescriptionCardsItem item={item} key={i}/>
  })
  return (
    <div>
        {isRequesting ? <Loader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default PatientPrescriptionCards;
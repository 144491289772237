import { useNavigate, generatePath } from 'react-router-dom';
import {TVisitFields} from "../../../types/visit";
import {useChangeStatusVisit} from "../../../hook/request/visit/visits";
import {ROUTES} from "../../../constants";
import {VISIT_MODE, VISIT_STATUS_ENUM} from "../../../constants/constant/enum";
import {toast} from "react-hot-toast";
import Icon from "../general/icon";
import BrokenVideoAdd from "../../../assets/images/svgComponents/icon/brokenVideoAdd";
import bellNotification from "../../../assets/audio/notification/Bell.mp3"
import {useEffect, useRef, useState} from "react";
import {setVideoCallOpenPiP, videoCall} from "../../../store/slice/videoCall";
import {useDispatch, useSelector} from "react-redux";



export const usePatientPresenceToast = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [patientPresenceData, setPatientPresenceData] = useState<TVisitFields | null>(null)
  const isInVideoCall = useSelector((state: any) => state.general.videoCallReducer.isInVideoCall); // Access the ID from the Redux store
  const isProviderInVideoCallRef = useRef(isInVideoCall);

  useEffect(() => {
    isProviderInVideoCallRef.current = isInVideoCall;
  }, [isInVideoCall]);

  const changeStatusVisitRequest = useChangeStatusVisit({
    onSuccess: () => {
      navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, { id: patientPresenceData?.id }), { state: { mode: VISIT_MODE.EDIT.value } });
      if(patientPresenceData?.id) {
        dispatch(videoCall(+patientPresenceData?.id));
        dispatch(setVideoCallOpenPiP(true));
      }
      setPatientPresenceData(null);
    }
  });

  const triggerPatientPresenceToast = (data: TVisitFields) => {
    setPatientPresenceData((prev) => {
      // The new data is now set in state, you can proceed safely
      const updatedData = data;

      const audio = new Audio(bellNotification);

      const handleNavigateToVisit = () => {
        if (updatedData?.status?.id && +updatedData?.status?.id === VISIT_STATUS_ENUM.RESERVED.value) {
          changeStatusVisitRequest.mutate({
            id: updatedData?.id,
            status: VISIT_STATUS_ENUM.IN_PROGRESS.value,
          });
        } else if (updatedData?.status?.id && +updatedData?.status?.id === VISIT_STATUS_ENUM.IN_PROGRESS.value && updatedData?.id) {
          navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, { id: updatedData?.id }), { state: { mode: VISIT_MODE.EDIT.value } });
          dispatch(videoCall(+updatedData?.id));
          dispatch(setVideoCallOpenPiP(true));
        } else {
        }
      };

      if (+updatedData?.status?.id === VISIT_STATUS_ENUM.RESERVED.value || +updatedData?.status?.id === VISIT_STATUS_ENUM.IN_PROGRESS.value) {
        // Play the notification sound
        audio.play();

        // Show the toast after confirming patientPresenceData is set
        toast(
          <div>
            <div className="d-flex align-items-center text-white">
            <span className='d-flex align-items-center font-25 ms-2'>
              <Icon name='phone' />
            </span>
              <span>
              بیمار شما {data?.patient?.name} در صفحه ویزیت است و منتظر شماست.
            </span>
            </div>
            {!isProviderInVideoCallRef.current && (
              <div
                className="d-flex align-items-center justify-content-center justify-content-md-end m-md-0 px-2"
                onClick={() => handleNavigateToVisit()}
              >
              <span
                className="btn btn-light-blue rounded rounded-3 font-10 d-flex align-items-center fw-bold px-3"
              >
                <span className='ms-2'>
                  <BrokenVideoAdd heightAndWidth={20}/>
                </span>
                برو به ویزیت آنلاین
              </span>
              </div>
            )}
          </div>,
          {
            position: 'top-left',
            duration: 10000,
            style: {
              borderRadius: '10px',
              background: "rgba(31, 31, 47, 0.7)",  // Semi-transparent background
              boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
              overflow: "hidden",
              backdropFilter: 'blur(10px)',  // Blur effect behind the component
              WebkitBackdropFilter: 'blur(10px)', // For compatibility with WebKit browsers
              width: '1000px'
            },
          }
        );
      }

      return updatedData; // Ensure that the state update persists
    });
  };

  return { triggerPatientPresenceToast };
};

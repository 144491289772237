import React from "react";
import {connect, useSelector} from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import {MtoJDateFormat, MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const PatientTableRowItem = ({
  item,
}) => {
  const navigate = useNavigate();
  const isProvider = useSelector((state) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return(
    <tr>
      <td>{getCellValue(item?.firstName)} {getCellValue(item?.lastName)}</td>
      <td>{getCellValue(item?.nationalCode)}</td>
      <td>{getCellValue(item?.passportNo)}</td>
      <td>{getCellValue(item?.mobile)}</td>
      <td>{getCellValue(MtoJDateFormat(item?.birthDate))}</td>
      <td>{getCellValue(item?.gender?.name)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {
            (isProvider || findAccessInAccessList('PATIENT_EHR_VIEW')) &&
            <Tooltip alt={'پرونده سلامت'} className="tooltip-pre-line">
                <span
                  onClick={() => navigate(generatePath(ROUTES.PATIENT_EHR.PATH, { id: item?.id }))}
                  className="d-flex text-hover mx-2 font-18 cur-pointer"
                >
                  <Icon name='eye' />
                </span>
              </Tooltip>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientTableRowItem);

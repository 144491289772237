import * as React from "react"
import { SVGProps } from "react"
const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={43}
    height={40}
    viewBox="0 0 43 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.3}
      d="M36.9351 21.5007C35.8946 21.5007 35.201 20.834 35.201 19.834C35.201 18.834 35.8946 18.1673 36.9351 18.1673H38.4958C37.6287 10.334 31.2125 4.00065 23.0621 3.33398V4.83399C23.0621 5.83399 22.3684 6.50065 21.328 6.50065C20.2875 6.50065 19.5939 5.83399 19.5939 4.83399V3.33398C11.4435 4.16732 4.85381 10.334 4.16016 18.1673H5.72086C6.76133 18.1673 7.45498 18.834 7.45498 19.834C7.45498 20.834 6.76133 21.5007 5.72086 21.5007H4.16016C5.02722 29.334 11.4435 35.6673 19.5939 36.334V34.834C19.5939 33.834 20.2875 33.1673 21.328 33.1673C22.3684 33.1673 23.0621 33.834 23.0621 34.834V36.334C31.2125 35.5006 37.8021 29.334 38.4958 21.5007H36.9351Z"
      fill="#2E57FF"
    />
    <path
      d="M29.998 18.166H24.2754C23.9286 17.666 23.5817 17.3327 23.0615 16.9994V9.83268C23.0615 8.83268 22.3678 8.16602 21.3274 8.16602C20.2869 8.16602 19.5932 8.83268 19.5932 9.83268V16.9994C19.073 17.3327 18.7262 17.666 18.3794 18.166H17.8591C16.8186 18.166 16.125 18.8327 16.125 19.8327C16.125 20.8327 16.8186 21.4993 17.8591 21.4993H18.3794C18.7262 21.9993 19.073 22.3327 19.5932 22.666V23.166C19.5932 24.166 20.2869 24.8327 21.3274 24.8327C22.3678 24.8327 23.0615 24.166 23.0615 23.166V22.666C23.5817 22.3327 23.9286 21.9993 24.2754 21.4993H29.998C31.0385 21.4993 31.7321 20.8327 31.7321 19.8327C31.7321 18.8327 31.0385 18.166 29.998 18.166Z"
      fill="#2E57FF"
    />
  </svg>
)
export default Clock

import React from "react";
import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../../../../utils/date";

type PatientFamilyHistoryCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
}

const PatientFamilyHistoryCardsBoxItem = ({
  item,
  dataListTitles,
}: PatientFamilyHistoryCardsBoxItemProps) => {

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.icd11?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="provider">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.relation?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="dateTime">{dataListTitles[2]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="visitType">{dataListTitles[3]}</label>
        <span className="mr-2">{item?.deceased ? 'بله' : 'خیر'}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="visitType">{dataListTitles[4]}</label>
        <span className="mr-2">{getCellValue(item?.description)}</span>
      </div>
    </div>
  )
}

export default PatientFamilyHistoryCardsBoxItem;
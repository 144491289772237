import * as yup from "yup";

const addVacationSchema = yup.object({
  provider: yup
    .string()
    .required("این فیلد الزامیست."),
  fromDateTime: yup
    .string()
    .required("این فیلد الزامیست."),
  toDateTime: yup
    .string()
    .required("این فیلد الزامیست."),
});

export {
  addVacationSchema
};

import * as yup from "yup";

const addMedicationCategorySchema = yup.object({
  name: yup
    .string()
    .required("این فیلد الزامیست."),
});

export {
  addMedicationCategorySchema,
};

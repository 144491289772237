import React, {useEffect, useState} from "react";
import {general} from "../../../../store/action";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import BoxLoader from "../boxLoader";
import Icon from "../icon";
import {useGetLatestAnnouncements} from "../../../../hook/request/announcements";
import {useGetOrganizations} from "../../../../hook/request/organizations";
import {Link, useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import EmptyVisits from "../../../../assets/images/svgComponents/EmptyVisits";
import Tooltip from "../tooltip";
import {getCellValue} from "../../../../utils/utils";
import {
  diffDay,
  diffDayBasedOnStartOfDay,
  MtoJDateFormat,
  MtoJFullDateFormat,
  MtoJHHMMFormat
} from "../../../../utils/date";
import {TGIdNameData} from "../../../../types/general";
import {ANNOUNCEMENT_TYPE_ENUM} from "../../../../constants/constant/enum";
import EmptyBoxes from "../../../../assets/images/svgComponents/EmptyBoxes";


type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  status?: string
}

type AnnouncementsListProps = {
  openTab: string
  handleShowModal: any;
}

const AnnouncementsList = ({
 openTab,
 handleShowModal,
}: AnnouncementsListProps) => {

  const { getQueryParams } = useMySearchParams();
  const [announcementsListData, setAnnouncementsListData] = useState<any[]>([]);

  // services
  const getLatestAnnouncementsListRequest = useGetLatestAnnouncements(getQueryParams({}), openTab === 'latestAnnouncements');

  useEffect(() => {
    if (getLatestAnnouncementsListRequest?.data?.data?.result) {
      setAnnouncementsListData(getLatestAnnouncementsListRequest?.data?.data?.result)
    }
  }, [getLatestAnnouncementsListRequest?.data?.data?.result]);

  const determineAnnouncementTypeColor = (type: TGIdNameData) => {
    switch (type?.id) {
      case ANNOUNCEMENT_TYPE_ENUM.INFORMATION.value:
        return 'bg-primary-subtle text-primary'
      case ANNOUNCEMENT_TYPE_ENUM.WARNING.value:
        return 'bg-yellow1 text-yellow'
      case ANNOUNCEMENT_TYPE_ENUM.ALERT.value:
        return 'bg-light-red text-red'
      case ANNOUNCEMENT_TYPE_ENUM.UPDATE.value:
        return 'bg-blue-update text-blue-update'
      case ANNOUNCEMENT_TYPE_ENUM.EVENT.value:
        return 'bg-purple text-purple'
      default:
        return 'bg-primary-subtle text-primary'
    }  }

  return (
    <div className="h-100">
      {getLatestAnnouncementsListRequest?.isPending && <BoxLoader type="cover"/>}
      <div className="h-90 m-2 overflow-y-auto custom-scrollbar">
        {announcementsListData && announcementsListData?.length > 0 ? announcementsListData?.map((item: any, key) => {
          return (
            <div key={key} className='rounded-3 border border-gray my-1'>
              <div className='p-2'>
                <div className='d-flex justify-content-between mb-2'>
                  <Tooltip alt={MtoJDateFormat(item?.dateTime)} placement='top' className="tooltip-pre-line">
                    <span className='font-12 fw-semibold'>
                      {MtoJHHMMFormat(item?.dateTime)}
                    </span>
                  </Tooltip>
                    <span className={`w-70 rounded-3 border text-center fw-semibold ${determineAnnouncementTypeColor(item?.type)}`}>
                    {item?.type?.name}
                  </span>
                </div>
                <div className='d-flex justify-content-start mb-1'>
                  <span className='font-20 text-primry-light ms-1'>
                    <Icon name='mailbox'/>
                  </span>
                  <span className='d-flex align-items-center text-end'>
                    {item?.title}
                  </span>
                </div>
                <div className='d-flex justify-content-end mb-1'>
                  <span className='font-12 text-black-50 text-end'>
                    {item?.message}
                  </span>
                </div>
                <div className='d-flex justify-content-between mt-1'>
                  <span className='font-12 text-black-50'>
                    {diffDayBasedOnStartOfDay(item?.dateTime) >= 0 ? 'امروز' : Math.abs(diffDayBasedOnStartOfDay(item?.dateTime)) + ' روز پیش'}
                  </span>
                  {item?.link &&
                    <span className='d-flex align-items-center fw-semibold cur-pointer text-green2'>
                      <Link to={item?.link} className='text-green2'>اطلاعات بیشتر</Link>
                      <span className='d-flex align-items-center font-18 me-1'>
                        <Icon name='chevron-left'/>
                      </span>
                    </span>
                  }
                </div>
              </div>
            </div>
          )
        }) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <EmptyBoxes/>
            <div className='font-12 mt-2'>
              در حال حاضر هیچ اعلانی وجود ندارد است.
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AnnouncementsList);
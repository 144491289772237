import {API_PATH} from "../../constants";
import {axiosRequest} from "../api";


export const AnnouncementsService = {
  getAnnouncementsList(params: any){ return axiosRequest('GET', API_PATH.ANNOUNCEMENTS.LIST(params)) },
  getLatestAnnouncementsList(params: any){ return axiosRequest('GET', API_PATH.ANNOUNCEMENTS.LAST(params)) },
  getAnnouncement(data: any){ return axiosRequest('POST', API_PATH.ANNOUNCEMENTS.INFO, data) },
  addAnnouncement(data: any){ return axiosRequest('POST', API_PATH.ANNOUNCEMENTS.ADD, data) },
  deleteAnnouncement(data: any){ return axiosRequest('POST', API_PATH.ANNOUNCEMENTS.DELETE, data) },
}
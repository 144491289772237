import {Link, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import SearchBox from "../../../components/general/searchBox";
import Icon from "../../../components/general/icon";
import Pagination from "../../../components/general/table/pagination";
import {useGetMedicationCategories} from "../../../../hook/request/medicationCategory";
import MedicationCategoriesTable from "../../../components/basicInformation/medicationCategory/dataList/medicationCategoriesTable";
import MedicationCategoriesCardsBox from "../../../components/basicInformation/medicationCategory/dataList/medicationCategoryCardsBox";
import {ROUTES} from "../../../../constants";
import DeleteMedicationCategoryModal from "../../../components/basicInformation/medicationCategory/deleteMedicationCategoryModal";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const dataListTitles = [
  "نام دسته بندی",
  "توضیحات",
  "مجموعه",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const MedicationCategoriesList = () => {

  const [width] = useWindowSize();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [medicationCategoriesData, setMedicationCategoriesData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getMedicationCategoriesRequest = useGetMedicationCategories(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);


  useEffect(() => {
    getMedicationCategoriesRequest?.data?.data?.result &&
    setMedicationCategoriesData(getMedicationCategoriesRequest?.data?.data.result)
  }, [getMedicationCategoriesRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی دسته بندی دارو"
            />
          </div>
          {
            findAccessInAccessList('BASIC_MEDICATION_CATEGORY') &&
            <div className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <Link to={ROUTES.MEDICATION_CATEGORY_ADD.PATH}
                    className="btn btn-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                افزودن دسته بندی
              </Link>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <MedicationCategoriesCardsBox
                dataList={medicationCategoriesData}
                dataListTitles={dataListTitles}
                isRequesting={getMedicationCategoriesRequest.isPending}
              /> :
              <MedicationCategoriesTable
                dataList={medicationCategoriesData}
                dataListTitles={dataListTitles}
                isRequesting={getMedicationCategoriesRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...medicationCategoriesData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <DeleteMedicationCategoryModal/>
    </>
  );
};

export default MedicationCategoriesList;
import {useQuery,useMutation} from '@tanstack/react-query'
import { PatientFamilyHistory } from '../../../services/requests/ehr/patientFamilyHistory';
import { PatientSurgeryList } from '../../../services/requests/ehr/surgery';

import Noti from "../../../view/components/general/noti";
import {handleResponse, queryClient} from '../setup';


const useGetListFamilyHistory = (params:any, modalName: boolean)=>{
  return useQuery({
      queryKey:["getListFamilyHistory",params],
      queryFn:({queryKey})=>{
       const [_,params] = queryKey;
       return PatientFamilyHistory.getFamilyList(params)
      },
    enabled: modalName ?? true
  })
}



const useGetFamilyRelation = (params:any, modalName?: boolean)=>{
  return useQuery({
      queryKey:["getListFamilyRelation",params],
      queryFn:({queryKey})=>{
       const [_,params] = queryKey;
       return PatientFamilyHistory.getListFamilyRelation(params)
      },
    enabled: modalName ?? true
  })
}

const useAddFamilyHistory = ({ onSuccess }: { onSuccess: () => void }) => {
    return useMutation({
        mutationFn:PatientFamilyHistory.addFamilyPatient,
        onSuccess: (data: any) => {
          handleResponse({
            data,
            onSuccess: () => {
              console.log(data);
              Noti("success", data?.data?.msg);
              queryClient.invalidateQueries({ queryKey: ["getListFamilyHistory"] })
              onSuccess();
            },
          });
      },
    });
  }



  export {
      useAddFamilyHistory,
      useGetListFamilyHistory,
      useGetFamilyRelation
  }
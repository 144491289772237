import { API_PATH } from "../../../constants";
import { axiosRequest } from "../../api";

const PatientFamilyHistory = {
  addFamilyPatient(data: any) {
    return axiosRequest("POST", API_PATH.FAMILY_HISTORY.ADD, data);
  },
  getFamilyList(params: any) {
    return axiosRequest("GET", API_PATH.FAMILY_HISTORY.GET_LIST(params));
  },
  getListFamilyRelation(params:any){
    return axiosRequest('GET', API_PATH.FAMILY_HISTORY.GET_FAMILY_RELATION(params))
  }
};

export { PatientFamilyHistory };

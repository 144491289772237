import DoctorProfile from "./doctorProfile";
import UserProfile from "./userProfile";
import { localStorageGet } from "../../../utils/localStorage";

const Profile = () => {
  const _userData = localStorageGet('USER_DATA'),
    isProvider = _userData?.providerId > 0;
  return isProvider ? <DoctorProfile /> : <UserProfile />
}

export default Profile;
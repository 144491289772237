import { useMutation, useQuery } from "@tanstack/react-query";
import { DeviceService } from "../../services"
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import Noti from "../../view/components/general/noti";
import { handleResponse, queryClient } from "./setup";

const useGetDevices = (params: any) => {
  return useQuery({
    queryKey: ["getDevices", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return DeviceService.getDevicesList(params);
    },
  });
}

const useGetDevice = () => {
  return useMutation({
    mutationFn: DeviceService.getDevice,
  })
}

const useAddDevice = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: DeviceService.addDevice,
    onSuccess: (data) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.DEVICE.PATH);
        }
      })
    },
  })
}

const useDeleteDevice = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: DeviceService.deleteDevice,
    onSuccess: (data) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getDevices"] })
          onSuccess();
        }
      })
    },
  })
}

// get assignable account list
const useGetAssignableAccountsList = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getAssignableAccountsList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return DeviceService.getAssignableAccountsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetDeviceAssignedList = (params: any) => {
  return useQuery({
    queryKey: ["getDeviceAssignedList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return DeviceService.getDeviceAssignedList(params);
    },
  });
}

const useGetAssignDevice = () => {
  return useMutation({
    mutationFn: DeviceService.getAssignDevice,
  })
}

const useAssignDevice = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: DeviceService.assignDevice,
    onSuccess: (data) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(-1);
        }
      })
    },
  })
}

const useDeleteAssignDevice = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: DeviceService.deleteAssignDevice,
    onSuccess: (data) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg);
          queryClient.invalidateQueries({ queryKey: ["getDeviceAssignedList"] })
          onSuccess();
        }
      })
    },
  })
}

export {
  useGetDevices,
  useGetDevice,
  useAddDevice,
  useDeleteDevice,
  useGetAssignableAccountsList,
  useGetDeviceAssignedList,
  useGetAssignDevice,
  useAssignDevice,
  useDeleteAssignDevice
}

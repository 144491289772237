import {useMutation, useQuery} from "@tanstack/react-query";
import { UserService } from "../../services"
import { useNavigate } from "react-router-dom";
import Noti from "../../view/components/general/noti";
import { ROUTES } from "../../constants";
import {handleResponse, queryClient} from "./setup";
import {localStorageSet} from "../../utils/localStorage";
import {setAccessProcessList} from "../../store/slice/accessProcess";
import {useDispatch} from "react-redux";

const useGetUsers = (params: any) => {
  return useQuery({
    queryKey: ["getUsers", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return UserService.getUsersList(params);
    },
  });
}

const useGetUser = () => {
  return useMutation({
    mutationFn: UserService.getUser,
    onSuccess: (data, variables, context) => {
      handleResponse({
        data,
      })
    },
  })
}

const useGetMyOperatorInfo = () => {
  return useMutation({
    mutationFn: UserService.getMyOperatorInfo,
    onSuccess: (data, variables, context) => {
      handleResponse({
        data,
        onSuccess: () => {
          localStorageSet('OPERATOR_DATA', data.data.result);
        }
      })
    },
  })
}

const useAddUser = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: UserService.addUser,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.USER.PATH);
        }
      })
    },
  })
}

const useUpdateUserProfile = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: UserService.updateUserProfile,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.DASHBOARD.PATH);
        }
      })
    },
  })
}

const useDeleteUser = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: UserService.deleteUser,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getUsers"] })
          onSuccess();
        }
      })
    },
  })
}

const useGetUserAccess = () => {
  return useMutation({
    mutationFn: UserService.getUserAccess,
  })
}

const useAddUserAccess = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: UserService.AddUserAccess,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.USER.PATH);
        }
      })
    },
  })
}

const useGetAccessProcess = () => {
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: UserService.getAccessProcess,
    onSuccess: (data, variables, context) => {
      handleResponse({
        data,
        onSuccess: () => {
          localStorageSet('ACCESS_PROCESS', data.data.result);
          dispatch(setAccessProcessList(data.data.result))
        }
      })
    },
  })
}

export {
  useGetUsers,
  useGetUser,
  useGetMyOperatorInfo,
  useAddUser,
  useUpdateUserProfile,
  useDeleteUser,
  useGetUserAccess,
  useAddUserAccess,
  useGetAccessProcess
}

import {TGIdNameData} from "../../types/general";
import {useSelector} from "react-redux";

const HDAccessGroupConstant = {
  OPERATOR: { id: 1_000, name: "اپراتور‌ها" },
  HEALTH_ORGANIZATION: { id: 2_000, name: "مراکز درمانی" },
  VISIT: { id: 3_000, name: "ویزیت‌ها" },
  PATIENT: { id: 4_000, name: "بیماران" },
  EHR: { id: 5_000, name: "پرونده الکترونیک سلامت" },
  PROVIDER: { id: 6_000, name: "پزشکان" },
  PROVIDER_SCHEDULE: { id: 7_000, name: "برنامه زمان‌بندی پزشکان" },
  PROVIDER_VACATION: { id: 8_000, name: "مرخصی پزشکان" },
  DEVICE: { id: 9_000, name: "دستگاه‌ها" },
  APPOINTMENT: { id: 10_000, name: "نوبت‌ها" },
  EXAMINATION: { id: 11_000, name: "معاینات" },
  ANNOUNCEMENT: { id: 886_000, name: "اعلانات" },
  NOTIFICATION: { id: 887_000, name: "نوتیفیکیشن" },
  BASIC: { id: 888_000, name: "اطلاعات پایه" },
};

const HDAccessProcessConstant = {
  OPERATOR_VIEW: { accessGroup: HDAccessGroupConstant.OPERATOR, id: HDAccessGroupConstant.OPERATOR.id + 10, label: 'مشاهده' },
  OPERATOR_CREATE: { accessGroup: HDAccessGroupConstant.OPERATOR, id: HDAccessGroupConstant.OPERATOR.id + 20, label: 'ایجاد' },
  OPERATOR_EDIT: { accessGroup: HDAccessGroupConstant.OPERATOR, id: HDAccessGroupConstant.OPERATOR.id + 30, label: 'ویرایش' },
  OPERATOR_DELETE: { accessGroup: HDAccessGroupConstant.OPERATOR, id: HDAccessGroupConstant.OPERATOR.id + 40, label: 'حذف' },

  OPERATOR_CHANGE_PASSWORD: { accessGroup: HDAccessGroupConstant.OPERATOR, id: HDAccessGroupConstant.OPERATOR.id + 50, label: 'تغییر رمز عبور' },
  OPERATOR_LOGIN_HISTORY: { accessGroup: HDAccessGroupConstant.OPERATOR, id: HDAccessGroupConstant.OPERATOR.id + 60, label: 'تاریخچه ورود' },
  OPERATOR_CHANGE_BLOCK: { accessGroup: HDAccessGroupConstant.OPERATOR, id: HDAccessGroupConstant.OPERATOR.id + 70, label: 'توقیف یا فعال کردن کاربران' },

  OPERATOR_VIEW_ACCESS: { accessGroup: HDAccessGroupConstant.OPERATOR, id: HDAccessGroupConstant.OPERATOR.id + 80, label: 'مشاهده دسترسی‌ها' },
  OPERATOR_CHANGE_ACCESS: { accessGroup: HDAccessGroupConstant.OPERATOR, id: HDAccessGroupConstant.OPERATOR.id + 90, label: 'تغییر دسترسی' },

  PROVIDER_VIEW: { accessGroup: HDAccessGroupConstant.PROVIDER, id: HDAccessGroupConstant.PROVIDER.id + 10, label: 'مشاهده' },
  PROVIDER_CREATE: { accessGroup: HDAccessGroupConstant.PROVIDER, id: HDAccessGroupConstant.PROVIDER.id + 20, label: 'ایجاد' },
  PROVIDER_EDIT: { accessGroup: HDAccessGroupConstant.PROVIDER, id: HDAccessGroupConstant.PROVIDER.id + 30, label: 'ویرایش' },
  PROVIDER_DELETE: { accessGroup: HDAccessGroupConstant.PROVIDER, id: HDAccessGroupConstant.PROVIDER.id + 40, label: 'حذف' },

  PROVIDER_SCHEDULE_VIEW: { accessGroup: HDAccessGroupConstant.PROVIDER_SCHEDULE, id: HDAccessGroupConstant.PROVIDER_SCHEDULE.id + 10, label: 'مشاهده' },
  PROVIDER_SCHEDULE_CREATE: { accessGroup: HDAccessGroupConstant.PROVIDER_SCHEDULE, id: HDAccessGroupConstant.PROVIDER_SCHEDULE.id + 20, label: 'ایجاد' },
  PROVIDER_SCHEDULE_EDIT: { accessGroup: HDAccessGroupConstant.PROVIDER_SCHEDULE, id: HDAccessGroupConstant.PROVIDER_SCHEDULE.id + 30, label: 'ویرایش' },
  PROVIDER_SCHEDULE_DELETE: { accessGroup: HDAccessGroupConstant.PROVIDER_SCHEDULE, id: HDAccessGroupConstant.PROVIDER_SCHEDULE.id + 40, label: 'حذف' },

  // Provider Vacation Processes
  PROVIDER_VACATION_VIEW: { accessGroup: HDAccessGroupConstant.PROVIDER_VACATION, id: HDAccessGroupConstant.PROVIDER_VACATION.id + 10, label: "مشاهده" },
  PROVIDER_VACATION_CREATE: { accessGroup: HDAccessGroupConstant.PROVIDER_VACATION, id: HDAccessGroupConstant.PROVIDER_VACATION.id + 20, label: "ایجاد" },
  PROVIDER_VACATION_EDIT: { accessGroup: HDAccessGroupConstant.PROVIDER_VACATION, id: HDAccessGroupConstant.PROVIDER_VACATION.id + 30, label: "ویرایش" },
  PROVIDER_VACATION_DELETE: { accessGroup: HDAccessGroupConstant.PROVIDER_VACATION, id: HDAccessGroupConstant.PROVIDER_VACATION.id + 40, label: "حذف" },

  // Device Processes
  DEVICE_VIEW: { accessGroup: HDAccessGroupConstant.DEVICE, id: HDAccessGroupConstant.DEVICE.id + 10, label: "مشاهده" },
  DEVICE_CREATE: { accessGroup: HDAccessGroupConstant.DEVICE, id: HDAccessGroupConstant.DEVICE.id + 20, label: "ایجاد" },
  DEVICE_EDIT: { accessGroup: HDAccessGroupConstant.DEVICE, id: HDAccessGroupConstant.DEVICE.id + 30, label: "ویرایش" },
  DEVICE_DELETE: { accessGroup: HDAccessGroupConstant.DEVICE, id: HDAccessGroupConstant.DEVICE.id + 40, label: "حذف" },

  DEVICE_CONFIRM: { accessGroup: HDAccessGroupConstant.DEVICE, id: HDAccessGroupConstant.DEVICE.id + 50, label: "تایید دستگاه" },

  DEVICE_ASSIGNMENT_VIEW: { accessGroup: HDAccessGroupConstant.DEVICE, id: HDAccessGroupConstant.DEVICE.id + 60, label: "مشاهده تخصیص دستگاه" },
  DEVICE_ASSIGNMENT_CREATE: { accessGroup: HDAccessGroupConstant.DEVICE, id: HDAccessGroupConstant.DEVICE.id + 70, label: "ایجاد تخصیص دستگاه" },
  DEVICE_ASSIGNMENT_EDIT: { accessGroup: HDAccessGroupConstant.DEVICE, id: HDAccessGroupConstant.DEVICE.id + 80, label: "ویرایش تخصیص دستگاه" },
  DEVICE_ASSIGNMENT_DELETE: { accessGroup: HDAccessGroupConstant.DEVICE, id: HDAccessGroupConstant.DEVICE.id + 90, label: "حذف تخصیص دستگاه" },

  // Appointment Processes
  APPOINTMENT_VIEW: { accessGroup: HDAccessGroupConstant.APPOINTMENT, id: HDAccessGroupConstant.APPOINTMENT.id + 10, label: "مشاهده" },
  APPOINTMENT_CREATE: { accessGroup: HDAccessGroupConstant.APPOINTMENT, id: HDAccessGroupConstant.APPOINTMENT.id + 20, label: "رزرو نوبت" },
  APPOINTMENT_CHANGE_STATUS: { accessGroup: HDAccessGroupConstant.APPOINTMENT, id: HDAccessGroupConstant.APPOINTMENT.id + 30, label: "تغییر وضعیت نوبت" },

  // Visit Processes
  VISIT_VIEW: { accessGroup: HDAccessGroupConstant.VISIT, id: HDAccessGroupConstant.VISIT.id + 10, label: "مشاهده" },

  // Examination Processes
  EXAMINATION_VIEW: { accessGroup: HDAccessGroupConstant.EXAMINATION, id: HDAccessGroupConstant.EXAMINATION.id + 10, label: "مشاهده" },
  EXAMINATION_AI: { accessGroup: HDAccessGroupConstant.EXAMINATION, id: HDAccessGroupConstant.EXAMINATION.id + 20, label: "هوش مصنوعی" },
  EXAMINATION_AI_BUG_REPORT: { accessGroup: HDAccessGroupConstant.EXAMINATION, id: HDAccessGroupConstant.EXAMINATION.id + 30, label: "ثبت ایراد هوش مصنوعی" },

  // Patient Processes
  PATIENT_VIEW: { accessGroup: HDAccessGroupConstant.PATIENT, id: HDAccessGroupConstant.PATIENT.id + 10, label: "مشاهده" },
  PATIENT_EHR_VIEW: { accessGroup: HDAccessGroupConstant.PATIENT, id: HDAccessGroupConstant.PATIENT.id + 20, label: "مشاهده پرونده سلامت" },
  PATIENT_EHR_EDIT: { accessGroup: HDAccessGroupConstant.PATIENT, id: HDAccessGroupConstant.PATIENT.id + 30, label: "تغییر اطلاعات در پرونده سلامت بیمار" },

  // EHR Processes
  EHR_SUMMARY: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 10, label: "خلاصه بیمار" },
  EHR_VISIT: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 20, label: "ویزیت‌ها" },
  EHR_APPOINTMENT: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 30, label: "وقت‌های ملاقات" },
  EHR_PRESCRIPTIONS: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 40, label: "نسخه‌ها" },
  EHR_MEDICATION: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 50, label: "دارو‌ها" },
  EHR_SERVICE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 60, label: "پاراکلینیک" },
  EHR_PRESCRIPTION: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 70, label: "نسخه‌ها" },

  EHR_FAMILY_HISTORY_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 80, label: "سابقه خانوادگی" },
  EHR_FAMILY_HISTORY_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 90, label: "ایجاد سابقه خانوادگی" },
  EHR_FAMILY_HISTORY_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 100, label: "حذف سابقه خانوادگی" },

  EHR_VITAL_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 110, label: "شاخص‌های حیاتی" },
  EHR_VITAL_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 120, label: "ایجاد شاخص‌های حیاتی" },
  EHR_VITAL_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 130, label: "حذف شاخص‌های حیاتی" },

  EHR_SURGERY_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 140, label: "جراحی" },
  EHR_SURGERY_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 150, label: "ایجاد جراحی" },
  EHR_SURGERY_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 160, label: "حذف جراحی" },

  EHR_BIOMETRIC_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 170, label: "بایومتریک" },
  EHR_BIOMETRIC_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 180, label: "ایجاد بایومتریک" },
  EHR_BIOMETRIC_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 190, label: "حذف بایومتریک" },

  EHR_ALLERGY_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 200, label: "حساسیت‌ها" },
  EHR_ALLERGY_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 210, label: "ایجاد حساسیت‌ها" },
  EHR_ALLERGY_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 220, label: "حذف حساسیت‌ها" },

  EHR_MEDICAL_CONDITIONS_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 230, label: "شرایط پزشکی" },
  EHR_MEDICAL_CONDITIONS_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 240, label: "ایجاد شرایط پزشکی" },
  EHR_MEDICAL_CONDITIONS_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 250, label: "حذف شرایط پزشکی" },

  EHR_VACCINATIONS_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 260, label: "واکسیناسیون" },
  EHR_VACCINATIONS_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 270, label: "ایجاد واکسیناسیون" },
  EHR_VACCINATIONS_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 280, label: "حذف واکسیناسیون" },

  EHR_ATTACHMENTS_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 290, label: "ضمایم" },
  EHR_ATTACHMENTS_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 300, label: "ایجاد ضمایم" },
  EHR_ATTACHMENTS_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 310, label: "حذف ضمایم" },

  EHR_PROGRAM_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 320, label: "برنامه‌ها" },
  EHR_PROGRAM_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 330, label: "ایجاد برنامه‌ها" },
  EHR_PROGRAM_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 340, label: "حذف برنامه‌ها" },

  EHR_SUBSTANCE_MISUSE_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 350, label: "سابقه سو مصرف" },
  EHR_SUBSTANCE_MISUSE_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 360, label: "ایجاد سابقه سو مصرف" },
  EHR_SUBSTANCE_MISUSE_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 370, label: "حذف سابقه سو مصرف" },

  EHR_SUBSTANCE_CIGARETTE_VIEW: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 380, label: "سابقه مصرف سیگار" },
  EHR_SUBSTANCE_CIGARETTE_CREATE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 390, label: "ایجاد سابقه مصرف سیگار" },
  EHR_SUBSTANCE_CIGARETTE_DELETE: { accessGroup: HDAccessGroupConstant.EHR, id: HDAccessGroupConstant.EHR.id + 400, label: "حذف سابقه مصرف سیگار" },

  //NOTIFICATION
  NOTIFICATION_PUSH: { accessGroup: HDAccessGroupConstant.NOTIFICATION, id: HDAccessGroupConstant.NOTIFICATION.id + 10, label: "ارسال نوتیفیکیشن" },
  ANNOUNCEMENT_VIEW: { accessGroup: HDAccessGroupConstant.NOTIFICATION, id: HDAccessGroupConstant.NOTIFICATION.id + 20, label: "نمایش لیست اعلانات" },
  ANNOUNCEMENT_CREATE: { accessGroup: HDAccessGroupConstant.NOTIFICATION, id: HDAccessGroupConstant.NOTIFICATION.id + 30, label: "ایجاد اعلانات" },
  ANNOUNCEMENT_DELETE: { accessGroup: HDAccessGroupConstant.NOTIFICATION, id: HDAccessGroupConstant.NOTIFICATION.id + 40, label: "حذف اعلانات" },

  //BASIC_INFORMATION
  BASIC_MAXA: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 10, label: "مکسا" },
  BASIC_MEDICAL_PROGRAMS: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 110, label: "برنامه‌های پزشکی" },
  BASIC_ALLERGY: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 140, label: "حساسیت" },

  //موارد زیر هنوز دیتا اینتری ندارن ولی من دسترسیشونو گذاشتم تا بعدا اضافه بشه
  BASIC_MAXA_ICD11: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 20, label: "مکسا" },
  BASIC_MEDICATION: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 30, label: "دارو‌ها" },
  BASIC_MEDICATION_CATEGORY: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 40, label: "دسته‌بندی دارو‌ها" },
  BASIC_MEDICATION_FREQUENCY: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 50, label: "تواتر مصرف دارو" },
  BASIC_MEDICATION_ROUTE: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 60, label: "نحوه مصرف دارو" },
  BASIC_MEDICATION_SHAPE: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 70, label: "شکل دارو" },
  BASIC_MEDICATION_DOSAGE: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 80, label: "میزان مصرف دارو" },
  BASIC_VACCINE: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 90, label: "واکسن" },
  BASIC_MEDICAL_CONDITIONS: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 100, label: "شرایط پزشکی" },
  BASIC_DOCTOR_SPECIALITY: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 120, label: "تخصص پزشکان" },
  BASIC_SURGERY: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 130, label: "جراحی" },
  BASIC_ALLERGY_TYPE: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 150, label: "نوع حساسیت" },
  BASIC_ALLERGY_SEVERITY: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 160, label: "شدت حساسیت" },
  BASIC_FAMILY_RELATION: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 170, label: "نسبت خانوادگی" },
  BASIC_EXAMINATION_POSITION: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 180, label: "حالت‌های بدن در برداشت آزمایش" },
  BASIC_CIGARETTE_FREQUENCY: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 190, label: "میزان مصرف سیگار" },

  BASIC_SYSTEM_MENU: { accessGroup: HDAccessGroupConstant.BASIC, id: HDAccessGroupConstant.BASIC.id + 200, label: "تغییر منو‌های سیستم" },

  //ORGANIZATIONS
  HEALTH_ORGANIZATION_VIEW: { accessGroup: HDAccessGroupConstant.HEALTH_ORGANIZATION, id: HDAccessGroupConstant.HEALTH_ORGANIZATION.id + 10, label: "مشاهده" },
  HEALTH_ORGANIZATION_CREATE: { accessGroup: HDAccessGroupConstant.HEALTH_ORGANIZATION, id: HDAccessGroupConstant.HEALTH_ORGANIZATION.id + 20, label: "ایجاد" },
  HEALTH_ORGANIZATION_EDIT: { accessGroup: HDAccessGroupConstant.HEALTH_ORGANIZATION, id: HDAccessGroupConstant.HEALTH_ORGANIZATION.id + 30, label: "ویرایش" },
  HEALTH_ORGANIZATION_DELETE: { accessGroup: HDAccessGroupConstant.HEALTH_ORGANIZATION, id: HDAccessGroupConstant.HEALTH_ORGANIZATION.id + 40, label: "حذف" },
};

const useFindAccessInAccessList = () => {
  const userAccessList = useSelector((state: any) => state.general.accessProcessReducer.accessProcessList);

  const findAccessInAccessList = (accessEnum: keyof typeof HDAccessProcessConstant) => {
    return userAccessList.some((access: TGIdNameData) =>
      access?.id === HDAccessProcessConstant[accessEnum].id
    );
  };

  return {
    findAccessInAccessList
  }
}

export {
  HDAccessGroupConstant,
  HDAccessProcessConstant,
  useFindAccessInAccessList
};


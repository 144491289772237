import { useMutation, useQuery } from "@tanstack/react-query";
import {NotificationService} from "../../services/requests/notification";

const useGetNotificationToken = (params: any, valid: boolean) => {
  return useQuery({
    queryKey: ["getNotificationToken"],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return NotificationService.getNotificationToken(params);
    },
    enabled: valid ?? true
  });
}

const usePushMessage = () => {
  return useMutation({
    mutationFn: NotificationService.pushMessage,
  })
}


export {
  useGetNotificationToken,
  usePushMessage,
}

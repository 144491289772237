import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {AllergyService} from "../../services/requests/allergy";

const useGetAllergies = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getAllergies", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return AllergyService.getAllergiesList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetAllergy = () => {
  return useMutation({
    mutationFn: AllergyService.getAllergy,
  })
}

const useAddAllergy = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: AllergyService.addAllergy,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.IMAGING_SERVICE.PATH);
        }
      })
    },
  })
}

const useDeleteAllergy = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: AllergyService.deleteAllergy,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getAllergies"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetAllergies,
  useGetAllergy,
  useAddAllergy,
  useDeleteAllergy
}

import { Link } from "react-router-dom";
import { localStorageGet } from "../../../utils/localStorage";
import Image from "../../components/general/image";
import { ROUTES } from "../../../constants";
import { useEffect, useState } from "react";
import {useGetDoctor, useGetMyProviderInfo} from "../../../hook/request/doctors";
import StatisticsSection from "../../components/account/statisticsSection";
import {useGetMyOperatorInfo, useGetUser} from "../../../hook/request/users";

const AccountPage = () => {

  const [fieldValue, setFieldValue] = useState<any>({});

  const _userData = localStorageGet('USER_DATA'),
    isProvider = _userData?.providerId > 0;

  // services
  const getDoctorRequest = useGetMyProviderInfo();
  const getUserRequest = useGetMyOperatorInfo();

  useEffect(() => {
    isProvider ? 
      getDoctorRequest.mutate({}) :
      getUserRequest.mutate({});
  }, [])

  useEffect(() => {
    if (getDoctorRequest?.data?.data?.result) {
      const { avatar, firstName, lastName, specialty, medId, nationalCode, mobile } = getDoctorRequest.data.data.result,
        _fieldValue = { firstName, lastName, medId, nationalCode, mobile, avatar, specialty };
      setFieldValue(_fieldValue);
    }
  }, [getDoctorRequest.data])

  useEffect(() => {
    if (getUserRequest?.data?.data?.result) {
      const { avatar, firstName, lastName, nationalCode, email, mobile } = getUserRequest.data.data.result,
        _fieldValue = { firstName, lastName, nationalCode, mobile, avatar, email };
      setFieldValue(_fieldValue);
    }
  }, [getUserRequest.data])

  return (
    <div className="card-box">

      <div className="prof-bg d-flex justify-content-center rounded pos-rel mb-6">
        <div className="position-absolute size-110 top-50 end-5 rounded overflow-hidden zIndex-2">
          <Image src={fieldValue?.avatar?.name}/>
        </div>
      </div>
      
      <div className="d-flex justify-content-between border-bottom pb-5 mb-5">
        <div className="d-flex align-items-center title-dot font-15 font-weight-bold text-gray me-3">
          نام:
          <span className="d-inline-flex font-weight-bold text-black font-14 mx-2">
            {fieldValue?.firstName} {fieldValue?.lastName}
          </span>
        </div>
        {
          isProvider ?
          <>
            <div className="d-flex align-items-center title-dot font-15 font-weight-bold text-gray me-3">
              تخصص:
              <span className="d-inline-flex font-weight-bold text-black font-14 mx-2">
                {fieldValue?.specialty?.name}
              </span>
            </div>
            <div className="d-flex align-items-center title-dot font-15 font-weight-bold text-gray me-3">
              نظام پزشکی:
              <span className="d-inline-flex font-weight-bold text-black font-14 mx-2">
                {fieldValue?.medId}
              </span>
            </div>
          </> :
          <>
            <div className="d-flex align-items-center title-dot font-15 font-weight-bold text-gray me-3">
              شماره همراه:
              <span className="d-inline-flex font-weight-bold text-black font-14 mx-2">
                {fieldValue?.mobile}
              </span>
            </div>
            <div className="d-flex align-items-center title-dot font-15 font-weight-bold text-gray me-3">
              کدملی:
              <span className="d-inline-flex font-weight-bold text-black font-14 mx-2">
                {fieldValue?.nationalCode}
              </span>
            </div>
          </>
        }
        {/*<Link to={ROUTES?.PROFILE?.PATH} className="btn btn-primary">*/}
        {/*  ویرایش اطلاعات کاربری*/}
        {/*</Link>*/}
      </div>

      <StatisticsSection isProvider={isProvider} />

      {/* {
        isProvider ? 
          <StatisticsSection /> :
          <div className="mb-5">
            <LoginHistoryList />
          </div>
      } */}

    </div>
  )
}

export default AccountPage;
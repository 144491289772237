import {useFieldArray, useForm} from "react-hook-form";
import {connect} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import Icon from "../../../../components/general/icon";
import {general} from "../../../../../store/action";
import {TVisitFields} from "../../../../../types/visit";
import {
  useGetMedicationDosage,
  useGetMedicationFrequency
} from "../../../../../hook/request/medication";
import MedicationPrescriptionEmptyRow
  from "../../../../components/visits/visitDetail/prescription/medication/medicationPrescriptionEmptyRow";
import AddPrescriptionMedicationItemModal
  from "../../../../components/visits/visitDetail/prescription/medication/addMedicationPrescriptionItemModal";
import MedicationPrescriptionRowItem
  from "../../../../components/visits/visitDetail/prescription/medication/medicationPrescriptionRowItem";
import FavoriteMedicationItemModal
  from "../../../../components/visits/visitDetail/prescription/medication/favoriteMedicationItem/favoriteMedicationItemModal";
import FavoriteMedicationPrescriptionModal
  from "../../../../components/visits/visitDetail/prescription/medication/favoriteMedicationPrescription/favoriteMedicationPrescriptionModal";
import MedicationPrescriptionHistoryModal
  from "../../../../components/visits/visitDetail/prescription/medication/history/recentMedicationPrescriptionModal";
import FavoritePrescriptionModal
  from "../../../../components/visits/visitDetail/prescription/favoritePrescriptionModal";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import {usePrescriptionItemIhioCheck} from "../../../../../hook/request/visit/prescription";
import {useAddDraftPrescriptionMedicationItem} from "../../../../../hook/request/visit/draftPrescriptionMedicationItem";
import {IhioData, TIhioDataAndPrescriptionMedicationItemFields} from "../../../../../types/prescription";
import {ISSUER_TYPE, VISIT_MODE} from "../../../../../constants/constant/enum";
import {useLocation, useParams} from "react-router-dom";
import PrescriptionActiveDateModal
  from "../../../../components/visits/visitDetail/prescription/medication/prescriptionActiveDateModal";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import MedicationPrescriptionRowItemResponsive
  from "../../../../components/visits/visitDetail/prescription/medication/medicationPrescriptionRowItemResponsive";
import {Accordion} from "react-bootstrap";
import {TVisitRequest} from "../../../../../types/notification";
import axios from "axios";

/*
    کامپوننت مربوط به قسمت ثبت دارو برای نسخه
    Parent: <Prescription/>
    Children: <MedicationPrescriptionEmptyRow/>, <MedicationPrescriptionRowItem/>
              <AddPrescriptionMedicationItemModal/>, <FavoriteMedicationItemModal/>,
              <FavoriteMedicationPrescriptionModal/>, <MedicationPrescriptionHistoryModal/>
              <FavoritePrescriptionModal/>
    در این کامپوننت لیستی از دارو های تجویز شده رندر میشود،
    و در صورتی که بیمه شخص بیمه سلامت باشد، ریکوئست های لازم
    زده میشود و چک کد از بیمه دریافت میشود.
*/

type medicalHistoryProps = {
  visitData?: TVisitFields;
  insurance?: number;
  isRequesting?: boolean;
  medicationPrescriptionFieldValues: TIhioDataAndPrescriptionMedicationItemFields[];
  paraclinicalItemsCheckCodes?: any;
  handleShowModal: any;
  updateData: any
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
}

type TDosageListByShape = {
  defaultId?: number,
  list?: {
    name?: string,
    label?: string,
    value?: number
  }[]
}[]

type IhioAndPrescriptionMedicationItemFieldArray = {
  root: TIhioDataAndPrescriptionMedicationItemFields[]
}

const PrescriptionMedicationItem = ({
  visitData,
  insurance,
  isRequesting,
  medicationPrescriptionFieldValues,
  paraclinicalItemsCheckCodes,
  handleShowModal,
  updateData
}: medicalHistoryProps) => {

  //<editor-fold desc="States">
  const filterValuesInitialState = {
    pageNumber: "0",
    perPage: "999",
  }
  const [width] = useWindowSize();
  const { id } = useParams();
  const location = useLocation();
  const  mode  = location?.state?.mode || VISIT_MODE.SHOW.value;
  const { getQueryParams } = useMySearchParams();
  const [filterValues] = useState<TFilterValues>(filterValuesInitialState);
  const [medicationFieldValue, setMedicationFieldValue] = useState<IhioAndPrescriptionMedicationItemFieldArray>({root: []});
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useForm<IhioAndPrescriptionMedicationItemFieldArray>({
    defaultValues: useMemo(() => medicationFieldValue, [medicationFieldValue]),
  });
  const {fields, append, update, remove} = useFieldArray({
    name: 'root',
    control
  })
  const [medicationItemsValueChangeQueue, setMedicationItemsValueChangeQueue] = useState<any>([]);

  const [medicationFrequencyList, setMedicationFrequencyList] = useState([])
  const [medicationDosageList, setMedicationDosageList] = useState([])
  const [dosageListByShape, setDosageListByShape] = useState<any>([])

  //</editor-fold>

  //<editor-fold desc="Services">
  const getMedicationFrequencyRequest = useGetMedicationFrequency(getQueryParams(filterValues));
  const getMedicationDosageRequest = useGetMedicationDosage(getQueryParams(filterValues));
  const prescriptionItemIhioCheckRequest = usePrescriptionItemIhioCheck({
    onSuccess: () => {
    }
  });
  const addDraftPrescriptionMedicationItemRequest = useAddDraftPrescriptionMedicationItem({
    onSuccess: () => {
    }
  })
  //</editor-fold>

  //<editor-fold desc="UseEffects">
  useEffect(() => {
    append(medicationPrescriptionFieldValues)
  }, [medicationPrescriptionFieldValues.length]);

  /*
    آپدیت داده ها در کامپوننت پرنت
    @Dependency: medicationFieldValue
  */
  useEffect(() => {
    updateData(fields, 'medication');
  }, [fields]);

  /*
    گرفتن لیست تواتر مصرف (فرکانس)
     برای ارسال به کامپوننت فرزند برای استفاده در سلکت
    @Dependency: getMedicationFrequencyRequest
   */
  useEffect(() => {
    if (getMedicationFrequencyRequest?.data?.data?.result) {
      const { data } = getMedicationFrequencyRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id, maxaValue: item?.maxa?.value}
        ))
      setMedicationFrequencyList(_data);
    }
  }, [getMedicationFrequencyRequest.data])

  /*
    گرفتن لیست دستور مصرف‌ (دوز)
     برای ارسال به کامپوننت فرزند برای استفاده در سلکت
    @Dependency: getMedicationDosageRequest
  */
  useEffect(() => {
    if (getMedicationDosageRequest?.data?.data?.result) {
      const { data } = getMedicationDosageRequest.data.data.result,
        _data = data.map((item: any) => (
          {
            id: item?.id,
            name: item?.name,
            faName: item?.faName,
            shape: item?.shape,
            defaultDosage: item?.defaultDosage,
            amount: item?.amount
          }
        ))
      setMedicationDosageList(_data);
    }
  }, [getMedicationDosageRequest.data])

  useEffect(() => {
    let temp: TDosageListByShape = []
    const _data = medicationDosageList.reduce((acc: any[], dosageItem: any) => {
      const shapeId = dosageItem?.shape?.id;
      if (shapeId !== undefined) {
        if (!temp[shapeId]) {
          temp[shapeId] = {list: []};
        }
        temp[shapeId].list?.push({
          name: dosageItem?.name,
          label: dosageItem?.faName,
          value: dosageItem?.id,
        });
        if (dosageItem?.defaultDosage) {
          temp[shapeId].defaultId = dosageItem?.id;
        }
      }
      return acc
    },[]);
    setDosageListByShape(temp)
  }, [medicationDosageList]);

  // useEffect(  () => {
  //   const handleMutate = async () => {
  //     if (medicationItemsValueChangeQueue[0]) {
  //       const temp = fields[medicationItemsValueChangeQueue[0]?.index]
  //       let fieldValue = {}
  //       if(medicationItemsValueChangeQueue[0].fieldName !== 'brand') {
  //         if(medicationItemsValueChangeQueue[0]?.fieldName === 'dosage' || medicationItemsValueChangeQueue[0]?.fieldName === 'frequency'){
  //           fieldValue = {...temp, [medicationItemsValueChangeQueue[0]?.fieldName]: medicationItemsValueChangeQueue[0]?.value}
  //         } else {
  //           fieldValue = {...temp, [medicationItemsValueChangeQueue[0]?.fieldName]: medicationItemsValueChangeQueue[0]?.value}
  //         }
  //       } else {
  //       }
  //       let otherItems = fields.filter((_, index) =>
  //         index !== medicationItemsValueChangeQueue[0]?.index)
  //         .map(item => item.checkCode)
  //         .filter(checkCode => checkCode);
  //       // در صورتی که نام وارد شده برابر با فیلد بود، مقدار جدید فرستاده شده در بادی قرار میگیرد وگرنه داده ای که از قبل داخل استیت بوده.
  //       const body = {
  //         id: fields[medicationItemsValueChangeQueue[0]?.index]?.item?.id,
  //         visit: id,
  //         medication: true,
  //         frequency: medicationItemsValueChangeQueue[0]?.fieldName === 'frequency' ? medicationItemsValueChangeQueue[0]?.value : temp?.frequency,
  //         douse: medicationItemsValueChangeQueue[0]?.fieldName === 'dosage' ? medicationItemsValueChangeQueue[0]?.value : temp?.dosage,
  //         itemCount: medicationItemsValueChangeQueue[0]?.fieldName === 'itemCount' ? medicationItemsValueChangeQueue[0]?.value : temp?.itemCount,
  //         otherItems: [...otherItems, ...paraclinicalItemsCheckCodes]
  //       }
  //       console.log('teeeeeemp', fieldValue)
  //
  //       try {
  //         const result = await prescriptionItemIhioCheckRequest.mutateAsync(body);
  //         let ihio: IhioData = result?.data?.result || {};
  //         update(medicationItemsValueChangeQueue[0]?.index, {
  //           ...fields[medicationItemsValueChangeQueue[0]?.index],
  //           [medicationItemsValueChangeQueue[0]?.fieldName]: medicationItemsValueChangeQueue[0]?.value,
  //           ...ihio,
  //         });
  //         setMedicationItemsValueChangeQueue((prevQueue: any) => prevQueue.slice(1));
  //       } catch (error) {
  //         console.error("Error in mutateAsync:", error);
  //       }
  //     }
  //   }
  //   handleMutate();
  // }, [medicationItemsValueChangeQueue[0]]);

  //</editor-fold>

  /*
    ساخت مدل دارو برای نسخه
    @param: result
    @returns: TAddPrescriptionMedicationItemFields
  */
  const addResultToMedicationDataList = async (checkCodes: string[], targetIndex: number, item: any) => {
    let prescriptionMedicationItem: TIhioDataAndPrescriptionMedicationItemFields = {
      item: item?.item ? item.item : item,
      frequency: item?.frequency || 28,
      dosage: item?.dosage || dosageListByShape[item?.shape?.id]?.defaultId || 1,
      itemCount: item?.itemCount || 1,
      brand: item?.brand || 0,
      activeDate: item?.activeDate
    }
    const otherItems = fields.filter((_, index) =>
      index !== targetIndex)
      .map(item => item.checkCode)
      .filter(checkCode => checkCode);

    const body ={
      id: item?.item ? item?.item?.id : item?.id,
      visit: id,
      medication: true,
      frequency: prescriptionMedicationItem.frequency,
      douse: prescriptionMedicationItem.dosage,
      itemCount: prescriptionMedicationItem.itemCount,
      activeDate: item?.activeDate,
      otherItems: [...otherItems, ...checkCodes, ...paraclinicalItemsCheckCodes]
    }
    let requestResult;
    if(insurance === ISSUER_TYPE.IHIO.value) {
      requestResult = await prescriptionItemIhioCheckRequest.mutateAsync(body)
    }
    let ihio: IhioData = {};
    if(requestResult?.status === 200 && requestResult?.data?.result) {
      ihio = requestResult?.data?.result;
    }
    prescriptionMedicationItem = {...prescriptionMedicationItem, ...ihio}
    return prescriptionMedicationItem;
  }

  /*
    بعد از سابمیت کردن مودال اضافه کردن دارو
    @param: fieldValue
    @param: index
  */
  const handleMedicationModalSubmit = async (fieldValue: any, index: number) => {
    let targetIndex = index;
    let checkCodes: string[] = [];
    for (const item of fieldValue) {
      try {
        const result = await addResultToMedicationDataList(checkCodes, targetIndex, item);
        if (result.checkCode) {
          checkCodes.push(result.checkCode);
        }
        if (targetIndex !== -1) {
          update(targetIndex, result);
          targetIndex = -1
        } else {
          append(result);
        }

        // Move on to the next item
      } catch (error) {
        console.error("Error processing prescription:", error);
      }
    }
  }

  /*
    پاک کردن دارو از لیست تجویز.
    این فانکشن به فرزند فرستاده میشه و اونجا روش کلیک میشه
    @param: index
  */
  const deleteMedicationItemFromList = (index: number) => {
    if (index >= 0 && index < fields.length) {
      // دارو براساس ایندکسش فیلتر میشه
      remove(index)
    }
  }

  /*
  پاک کردن دارو از لیست تجویز.
  این فانکشن به فرزند فرستاده میشه و اونجا روش کلیک میشه
  @param: index
*/
  const addTaminActiveDates = async (dates: any[], index: number) => {
    if (index >= 0 && index < fields.length) {
      const currentItem = fields[index]
      let targetIndex = index;
      currentItem.futureDates = dates;
      currentItem.activeDate = new Date();
      update(targetIndex, currentItem)
    }
  }

  /*
  پاک کردن دارو از لیست تجویز.
  این فانکشن به فرزند فرستاده میشه و اونجا روش کلیک میشه
  @param: index
*/
  const addIhioActiveDates = async (dates: any[], index: number) => {
    if (index >= 0 && index < fields.length) {
      const currentItem = fields[index]
      let targetIndex = index;
      if(!currentItem.activeDate) {
        currentItem.activeDate = new Date();
        update(targetIndex, currentItem);
      }
      let checkCodes: string[] = [];
      for (const date of dates) {
        try {
          currentItem.activeDate = date
          console.log(currentItem.activeDate)
          const result = await addResultToMedicationDataList(checkCodes, targetIndex, currentItem);
          if (result.checkCode) {
            checkCodes.push(result.checkCode);
          }
          append(result);
          // Move on to the next item
        } catch (error) {
          console.error("Error processing prescription:", error);
        }
      }
    }
  }

  /*
    اضافه کردن دارو به لیست علاقه مندی ها.
    این فانکشن به فرزند فرستاده میشه و اونجا روش کلیک میشه
    @param: index
  */
  const addMedicationItemToFavorites = (index: number) => {
    const body = {
      item: {id: fields[index]?.item?.id},
      frequency: {id: fields[index]?.frequency},
      dosage: {id: fields[index]?.dosage},
      itemCount: fields[index]?.itemCount,
      drugDescription: fields[index]?.drugDescription
    }
    addDraftPrescriptionMedicationItemRequest.mutate(body)
  }

  /*
    بعد از تغییر مقدار هر اینپوت، استیت اون قسمت تغییر کنه
    و ریکوئست با داده جدید به بیمه سلامت زده بشه.
    @param: name
    @param: value
    @param: index
  */
  const onFieldsChange = (name: string, value: any, label: any, index: number, medItem: any, brand?: any) => {
    // جدا کردن اسم اینپوت از آی دی
    const fieldName = name.split('_')[0];
    let fieldValue = {}
    if(fieldName !== 'brand') {
      if(fieldName === 'dosage' || fieldName === 'frequency'){
        fieldValue = {...medItem, [fieldName]: value, [fieldName + 'Name']: label}
      } else {
        fieldValue = {...medItem, [fieldName]: value}
      }
    } else {
      console.log(value, label)
      fieldValue = {...medItem,
        item: {
        ...medItem.item,
          id: value,
          erx: brand?.erx,
          support: insurance === ISSUER_TYPE.TAMIN.value ? brand?.taminSupport : brand?.ihioSupport,
        },
        brand: value,
        brandName: label
      }
    }
    update(index, { ...fieldValue})
    // ریکوئست زدن به بیمه سلامت با دیتا جدید.
    if(insurance === ISSUER_TYPE.IHIO.value && fieldName !== 'drugDescription') {
      // IhioCheck(fieldName, value, index, fieldValue)
      // const queueItem = {
      //   index: index,
      //   item: fieldValue,
      //   fieldName: fieldName,
      //   value: value
      // }
      // setMedicationItemsValueChangeQueue((prevQueue: any) => [...prevQueue, queueItem]);
    }
  }

  /*
    ریکوئست زدن به بیمه سلامت برای دریافت چک کد و ارور ها.
    @param: name
    @param: value
    @param: targetIndex
  */
  const IhioCheck = async (targetIndex: number) => {
    // ساخت آرایه ای از چک کد ها بر اساس دارو های انتخاب شده دیگر در استیت.
    let otherItems = fields.filter((_, index) =>
      index !== targetIndex)
      .map(item => item.checkCode)
      .filter(checkCode => checkCode);
    // در صورتی که نام وارد شده برابر با فیلد بود، مقدار جدید فرستاده شده در بادی قرار میگیرد وگرنه داده ای که از قبل داخل استیت بوده.
    const body ={
      id: fields[targetIndex]?.item?.id,
      visit: id,
      medication: true,
      frequency: fields[targetIndex]?.frequency,
      douse: fields[targetIndex]?.dosage,
      itemCount: fields[targetIndex]?.itemCount,
      otherItems: [...otherItems, ...paraclinicalItemsCheckCodes]
    }
    const result = await prescriptionItemIhioCheckRequest.mutateAsync(body)
    let ihio: IhioData = {};
    if(result?.status === 200 && result?.data?.result && result?.data?.result?.checkCode && result?.data?.result?.checkCode != '') {
      ihio = result?.data?.result;
    }
    // جایگزینی فیلد های مرتبط با بیمه سلامت در مدل،‌ با پاسخ دریافت شده از بیمه سلامت.
    update(targetIndex, { ...fields[targetIndex] ,...ihio})
  }

  const _renderRowItems = (responsive: boolean) => fields.map((item, index) => {
    return (
      responsive ?
        <MedicationPrescriptionRowItemResponsive
          item={item}
          index={index}
          insurance={insurance}
          frequency={medicationFrequencyList}
          dosage={dosageListByShape[item?.item?.shape?.id || 10]?.list}
          control={control}
          errors={errors}
          onFieldsChange={onFieldsChange}
          addMedicationItemToFavorites={addMedicationItemToFavorites}
          handleMedicationModalSubmit={handleMedicationModalSubmit}
          deleteMedicationItemFromList={deleteMedicationItemFromList}
          addIhioActiveDates={addIhioActiveDates}
          addTaminActiveDates={addTaminActiveDates}
          register={register}
          watch={watch}
          update={update}
          IhioCheck={IhioCheck}
        /> :
        <MedicationPrescriptionRowItem
          item={item}
          index={index}
          insurance={insurance}
          frequency={medicationFrequencyList}
          dosage={dosageListByShape[item?.item?.shape?.id || 10]?.list}
          control={control}
          errors={errors}
          onFieldsChange={onFieldsChange}
          addMedicationItemToFavorites={addMedicationItemToFavorites}
          handleMedicationModalSubmit={handleMedicationModalSubmit}
          deleteMedicationItemFromList={deleteMedicationItemFromList}
          addIhioActiveDates={addIhioActiveDates}
          addTaminActiveDates={addTaminActiveDates}
          register={register}
          watch={watch}
          update={update}
          IhioCheck={IhioCheck}
        />
    )
  })

  return (
    <>
      <h4 className='d-flex border-bottom font-14 mb-4 pb-2'>
        <div className="d-flex align-items-center align-content-center ms-auto">
          <span className="d-flex font-22"><Icon name='pills'/></span>
          <span className='me-2'>تجویز دارو</span>
        </div>
        { mode === VISIT_MODE.EDIT.value && (
          <>
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
              <span
                onClick={() => handleShowModal('favoritePrescriptionMedicationModal',
                  {
                    provider: visitData?.provider?.id,
                    handleFavoriteModalSubmit: handleMedicationModalSubmit
                  })}
                className={`btn btn-gray rounded d-flex align-items-center me-2 ${width > 900 ? 'px-4 ' : 'justify-content-center p-2'}`}>
                <span className={`d-inline-flex font-20 ${width > 900 && 'ms-2'}`}><Icon name="document-add-fill"/></span>
                {width > 900 && 'نسخ پر استفاده'}
              </span>
            </div>
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
              <span
                onClick={() => handleShowModal('favoriteMedicationItemModal',
                  {
                    provider: visitData?.provider?.id,
                    handleFavoriteModalSubmit: handleMedicationModalSubmit
                  })}
                className={`btn btn-gray rounded d-flex align-items-center me-2 ${width > 900 ? 'px-4' : 'justify-content-center p-2'}`}>
                <span className={`d-inline-flex font-20 ${width > 900 && 'ms-2'}`}><Icon name="star-fill"/></span>
                {width > 900 && 'علاقه مندی‌ها'}
              </span>
            </div>
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
              <span
                onClick={() => handleShowModal('recentMedicationPrescriptionModal',
                  {
                    patientData: visitData?.patient,
                    provider: visitData?.provider?.id,
                    handleFavoriteModalSubmit: handleMedicationModalSubmit
                  })}
                className={`btn btn-gray rounded d-flex align-items-center me-2 ${width > 900 ? 'px-4' : 'justify-content-center p-2'}`}>
                <span className={`d-inline-flex font-20 ${width > 900 && 'ms-2'}`}><Icon name="history"/></span>
                {width > 900 && 'سوابق'}
              </span>
            </div>
          </>
        )}

      </h4>
      <div className="">
        {
          fields.length === 0 ?
            <MedicationPrescriptionEmptyRow/>
            :
            width < 900 ?
              <Accordion className='prescription-items-accordion rounded' alwaysOpen>
                {_renderRowItems(true)}
              </Accordion> :
                _renderRowItems(false)

        }
      </div>
      {
        (mode === VISIT_MODE.EDIT.value) &&
        <div className="d-flex flex-row-reverse">
          <div
            className="flex-grow-1 align-items-center justify-content-center justify-content-md-start m-md-0">
            <span
            onClick={() => handleShowModal('addPrescriptionMedicationItemModal',
              {
                handleMedicationModalSubmit: handleMedicationModalSubmit,
                insurance: insurance
              })}
            className="btn btn-primary justify-content-center rounded d-flex align-items-center font-14 fw-bolder px-4 mb-3">
              <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
              {width > 900 ? 'افزودن داروی جدید' : 'داروی جدید'}
            </span>
          </div>
          {
            fields.length !== 0 &&
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
              <span
                onClick={() => handleShowModal('favoritePrescriptionModal',
                  {
                    prescriptionData: fields,
                    draftMedication: true
                  })}
                className="btn btn-dark rounded d-flex align-items-center px-4 mx-2 mb-3">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="document-add-fill"/></span>
                {width > 900 ? 'افزودن به عنوان نسخه پراستفاده' : 'نسخه پر‌استفاده'}

              </span>
            </div>
          }
        </div>
      }
      <div className="border border-black"></div>
      <AddPrescriptionMedicationItemModal/>
      <FavoriteMedicationItemModal/>
      <FavoriteMedicationPrescriptionModal/>
      <MedicationPrescriptionHistoryModal/>
      <FavoritePrescriptionModal/>
      <PrescriptionActiveDateModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PrescriptionMedicationItem);
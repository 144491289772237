import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {ProgramService} from "../../services/requests/program";

const useGetPrograms = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getPrograms", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return ProgramService.getProgramsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetProgram = () => {
  return useMutation({
    mutationFn: ProgramService.getProgram,
  })
}

const useAddProgram = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: ProgramService.addProgram,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.PROGRAM.PATH);
        }
      })
    },
  })
}

const useDeleteProgram = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: ProgramService.deleteProgram,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPrograms"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetPrograms,
  useGetProgram,
  useAddProgram,
  useDeleteProgram
}

import React from "react";
import BoxLoader from "../../../../general/boxLoader";
import EmptyBox from "../../../../general/emptyBox";
import PatientProgramCardsBoxItem from "./patientProgramCardsBox_Item";

type PatientProgramsCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean;
  readonly?: boolean;
}

const PatientProgramCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting,
  readonly = false
}: PatientProgramsCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <PatientProgramCardsBoxItem item={item} dataListTitles={dataListTitles} key={i} readonly={readonly}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default PatientProgramCardsBox;
import { localStorageGet } from './localStorage';

export const isLoggedIn = () => {
  return !!localStorageGet('USER_DATA');
}

export const hasRoutePermission = item => {
  return true;
  let _userData = localStorageGet('USER_DATA'),
    _permission = _userData?.permission;
  for (let prmsn in _permission) {
    if(
      !item.needPermission ||
      (
        _permission[prmsn] && 
        item.path.startsWith(prmsn, 1)
      )
    ) return true;
  }
  return false;
}
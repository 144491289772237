import * as React from "react"
import { SVGProps } from "react"
const TwoPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={27}
    height={27}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.625 7.875C5.625 10.3603 7.63972 12.375 10.125 12.375C12.6103 12.375 14.625 10.3603 14.625 7.875C14.625 5.38972 12.6103 3.375 10.125 3.375C7.63972 3.375 5.625 5.38972 5.625 7.875ZM16.875 12.375C16.875 14.239 18.386 15.75 20.25 15.75C22.114 15.75 23.625 14.239 23.625 12.375C23.625 10.511 22.114 9 20.25 9C18.386 9 16.875 10.511 16.875 12.375Z"
      fill="#3F8DFD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1063 14.625C4.79465 14.625 0.436791 17.3548 0.000733145 22.7241C-0.0230195 23.0166 0.536301 23.625 0.81844 23.625H19.4025C20.2477 23.625 20.2608 22.9449 20.2477 22.725C19.918 17.2048 15.4926 14.625 10.1063 14.625ZM26.3879 23.625L22.05 23.625C22.05 21.0927 21.2133 18.7558 19.8013 16.8757C23.6336 16.9176 26.7628 18.8551 26.9983 22.95C27.0078 23.1149 26.9983 23.625 26.3879 23.625Z"
      fill="#E0E6FF"
    />
  </svg>
)
export default TwoPerson

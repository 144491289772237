import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {MedicationCategoryService} from "../../services/requests/medicationCategory";

const useGetMedicationCategories = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getMedicationCategories", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return MedicationCategoryService.getMedicationCategoriesList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetMedicationCategory = () => {
  return useMutation({
    mutationFn: MedicationCategoryService.getMedicationCategory,
  })
}

const useAddMedicationCategory = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: MedicationCategoryService.addMedicationCategory,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.MEDICATION_CATEGORY.PATH);
        }
      })
    },
  })
}

const useDeleteMedicationCategory = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: MedicationCategoryService.deleteMedicationCategory,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getMedicationCategories"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetMedicationCategories,
  useGetMedicationCategory,
  useAddMedicationCategory,
  useDeleteMedicationCategory
}

import * as yup from "yup";

const addVaccineSchema = yup.object({
  name: yup
    .string()
    .required("این فیلد الزامیست."),
  maxa: yup
    .string()
    .required("این فیلد الزامیست."),
});


export {
  addVaccineSchema,
};

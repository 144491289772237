import React from "react";

const AnalysisGuide = () => {

  return (
    <div className='row row-cols-2 row-gap-2 mt-4'>
      <div className='col-lg-1 col-md-2 col-sm-2 col-2'>
        <div
          className='d-flex align-items-center justify-content-center rounded rounded-3 border-2 border-green-success bg-light-green-success h-100 text-green2 text-nowrap fw-bold py-4'>
          کم خطر
        </div>
      </div>
      <div className='col-lg-11 col-md-10 col-sm-10 col-10'>
        <div
          className='d-flex align-items-center rounded rounded-3 border-2 border-green-success bg-light-green-success h-100 py-4'>
          <span className='fw-medium mx-3'>ضربان نرمال (N)، ضربان غیرقابل طبقه‌بندی (U)</span>
        </div>
      </div>
      <div className='col-lg-1 col-md-2 col-sm-2 col-2'>
        <div
          className='d-flex align-items-center justify-content-center rounded rounded-3 border border-2 border-primary bg-primary-subtle h-100 text-primary text-nowrap fw-bold py-4'>
          کم اهمیت
        </div>
      </div>
      <div className='col-lg-11 col-md-10 col-sm-10 col-10'>
        <div
          className='d-flex align-items-center rounded rounded-3 border border-2 border-primary bg-primary-subtle h-100 py-4'>
          <span className='fw-medium mx-3'>ضربان با بلوک شاخه چپ (L)، ضربان با بلوک شاخه راست (R)، ضربان فرار گرهی (j)، ضربان زودرس دهلیزی انحرافی (a)، ترکیب ضربان ضربان‌ساز نرمال (f)</span>
        </div>
      </div>
      <div className='col-lg-1 col-md-2 col-sm-2 col-2'>
        <div
          className='d-flex align-items-center justify-content-center rounded rounded-3 border-2 border-orange bg-warning-subtle h-100 text-orange fw-bold py-4'>
          مهم
        </div>
      </div>
      <div className='col-lg-11 col-md-10 col-sm-10 col-10'>
        <div
          className='d-flex align-items-center rounded rounded-3 border-2 border-orange bg-warning-subtle h-100 py-4'>
          <span className='fw-medium mx-3'>ضربان زودرس دهلیزی (A)، ضربان زودرس گرهی (اتصالی) (J)، ترکیب ضربان بطنی و نرمال (F)، ضربان ضربان‌ساز (/)</span>
        </div>
      </div>
      <div className='col-lg-1 col-md-2 col-sm-2 col-2'>
        <div
          className='d-flex align-items-center justify-content-center rounded rounded-3 border-2 border-red bg-danger-subtle h-100 text-danger fw-bold py-4'>
          خطرناک
        </div>
      </div>
      <div className='col-lg-11 col-md-10 col-sm-10 col-10'>
        <div
          className='d-flex align-items-center rounded rounded-3 border-2 border-red bg-danger-subtle h-100 py-4'>
          <span className='fw-medium mx-3'>انقباض زودرس بطنی (V)، ضربان فرار بطنی (E)</span>
        </div>
      </div>
    </div>
  );
};

export default AnalysisGuide;
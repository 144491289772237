import BoxLoader from "../../../general/boxLoader";
import EmptyBox from "../../../general/emptyBox";
import LoginHistoryCardsBoxItem from "./loginHistoryCardsBox_item";

const LoginHistoryCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting
}) => {
    const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item, i) => {
      return <LoginHistoryCardsBoxItem item={item} dataListTitles={dataListTitles} key={i} />
    })
  return(
    <div>
      { isRequesting ? <BoxLoader /> : _dataList ? _dataList : <EmptyBox /> }
    </div>
  )
}

export default LoginHistoryCardsBox;
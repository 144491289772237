import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchBox from "../../components/general/searchBox";
import Pagination from "../../components/general/table/pagination";
import { useWindowSize } from "../../../hook/useWindowSize";
import PendingDeviceTable from "../../components/pendingDevices/dataList/pendingDeviceTable";
import PendingDeviceCardsBox from "../../components/pendingDevices/dataList/pendingDeviceCardsBox";
import { useGetPendingDevices } from "../../../hook/request/pendingDevices";
import { useMySearchParams } from "../../../hook/useMySearchParams";
import RejectPendingDeviceModal from "../../components/pendingDevices/rejectPendingDeviceModal";
import ApprovePendingDeviceModal from "../../components/pendingDevices/approvePendingDeviceModal";
import PendingDeviceInfoModal from "../../components/pendingDevices/pendingDeviceInfoModal";
import {useSelector} from "react-redux";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

const dataListTitles = [
  "سریال دستگاه",
  "کد دستگاه",
  "IMEI",
  "تاریخ درخواست",
  "وضعیت",
  "عملیات ها",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const PendingDevicesList = () => {

  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [pendingDevicesData, setPendingDevicesData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getPendingDevicesRequest = useGetPendingDevices(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  // useEffect(() => {
  //   getPendingDevicesRequest.refetch();
  // }, [filterValues])


  useEffect(() => {
    getPendingDevicesRequest?.data?.data?.result && 
      setPendingDevicesData(getPendingDevicesRequest?.data?.data?.result)
  }, [getPendingDevicesRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجو دستگاه"
            />
          </div>
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <PendingDeviceCardsBox
                dataList={pendingDevicesData}
                dataListTitles={dataListTitles}
                isRequesting={getPendingDevicesRequest.isPending}
              /> :
              <PendingDeviceTable
                dataList={pendingDevicesData}
                dataListTitles={dataListTitles}
                isRequesting={getPendingDevicesRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...pendingDevicesData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>

      { findAccessInAccessList('DEVICE_CONFIRM') && <RejectPendingDeviceModal/>}
      { findAccessInAccessList('DEVICE_CONFIRM') && <ApprovePendingDeviceModal />}
      { findAccessInAccessList('DEVICE_CONFIRM') && <PendingDeviceInfoModal />}
    </>
  );
};

export default PendingDevicesList;
import { useMutation } from "@tanstack/react-query";
import { GeneralService } from "../../services"
import { handleResponse } from "./setup";
import Noti from "../../view/components/general/noti";

const useUploadMultimedia = () => {
  return useMutation({
    mutationFn: GeneralService.uploadMultimedia,
    onSuccess: (data) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg || 'با موفقیت آپلود شد!');
        }
      })
    },
  })
}

export {
  useUploadMultimedia
}

import {axiosRequest} from "../../api";
import {API_PATH} from "../../../constants";

export const PrescriptionService = {
  getPrescriptionList(params: any){ return axiosRequest('GET', API_PATH.PRESCRIPTION.LIST(params)) },
  getRecentPrescriptionList(params: any){ return axiosRequest('GET', API_PATH.PRESCRIPTION.RECENT_LIST(params)) },
  getPrescription(data: any){ return axiosRequest('POST', API_PATH.PRESCRIPTION.INFO, data) },
  addPrescription(data: any){ return axiosRequest('POST', API_PATH.PRESCRIPTION.ADD, data) },
  deletePrescription(data: any){ return axiosRequest('POST', API_PATH.PRESCRIPTION.DELETE, data) },
  prescriptionItemIhioCheck(data: any){ return axiosRequest('POST', API_PATH.PRESCRIPTION.ITEM_IHIO_CHECK, data) },
  prescriptionInsurerSave(data: any){ return axiosRequest('POST', API_PATH.PRESCRIPTION.INSURER_SAVE, data) },
}
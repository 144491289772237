import * as yup from "yup";

const addOrganizationSchema = yup.object({
  name: yup
    .string()
    .required("این فیلد الزامیست."),
  departmentType: yup
    .string()
    .required("این فیلد الزامیست."),
});

export {
  addOrganizationSchema
};

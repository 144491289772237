import React from "react";
import {connect} from "react-redux";
import {generatePath, useNavigate} from "react-router-dom";
import {general} from "../../../../../store/action";
import Icon from "../../../general/icon";
import {Dispatch} from "redux";
import {ROUTES} from "../../../../../constants";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

type MenuCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const MenuCardsBoxItem = ({
 item,
 dataListTitles,
 handleShowModal
}: MenuCardsBoxItemProps) => {
  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{(item?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[1]}</label>
        <span className="mr-2">{(item?.url)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[2]}</label>
        <span className="mr-2">{(item?.icon)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[2]}</label>
        <span className="mr-2">{(item?.accessGroup?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[2]}</label>
        <span className="mr-2">{(item?.accessProcess?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[2]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            {
              findAccessInAccessList('BASIC_SYSTEM_MENU') &&
              <>
                <span
                  onClick={() => navigate(generatePath(ROUTES.VACCINE_EDIT.PATH, {id: item?.id}))}
                  className="d-flex text-hover mx-2 font-20 cur-pointer"
                >
                  <Icon name='pen-square'/>
                </span>
                <span
                  onClick={() => handleShowModal('deleteMenuModal', item?.id)}
                  className="d-flex text-red-hover mx-2 font-20 cur-pointer"
                >
                  <Icon name='trash'/>
                </span>
              </>
            }
          </div>
        </div>
      </div>

    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(MenuCardsBoxItem);
import React, { useState } from 'react';
import { useWindowSize } from '../../../hook/useWindowSize';

const routeNameObj = {
  edit: 'edit',
  add: 'add',
  info: 'info',
  details: 'details',
  assign: 'assign',
}

const Breadcrumb = () => {

  // const history = useHistory();
  const [width] = useWindowSize();
  const [breadcrumbItems, setBreadcrumbItems] = useState('');

  // useEffect(() => {
  //   breadcrumbGenerator();
  //   return history.listen(() => {
  //     breadcrumbGenerator();
  //   });
  // }, [history, width]);

  // const breadcrumbGenerator = () => {
  //   const _pathItems = history.location.pathname.split('/').filter(item => item.length);
  //   let _breadcrumbItems = [];
  //   _breadcrumbItems.push(<li className="breadcrumb-item pl-0 pr-2" key='-1'><Link to='/'>{'sidebar.home'}</Link></li>)
  //   for(let index in _pathItems){
  //     const item = _pathItems[index],
  //       routeName = ROUTES[item.toUpperCase()]?.TITLE || routeNameObj[item];
  //     routeName && _breadcrumbItems.push(
  //       ROUTES[item.toUpperCase()]?.PATH && _pathItems.length - 1 !== +index ?
  //         <li className="breadcrumb-item pl-0 pr-2" key={index}><Link to={ROUTES[item.toUpperCase()]?.PATH}>{routeName}</Link></li> :
  //         <li className="breadcrumb-item pl-0 pr-2 active" key={index}>{routeName}</li>
  //     )
  //   }
  //   width < 768 ? setBreadcrumbItems(_breadcrumbItems[_breadcrumbItems.length - 1]) : setBreadcrumbItems(_breadcrumbItems);
  // }

  return(
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb d-flex align-items-center bg-transparent px-0 px-md-3 mb-0">
        {breadcrumbItems}
      </ol>
    </nav>
  )
}

export default Breadcrumb;

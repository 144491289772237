import * as yup from "yup";

const insurerSchema = yup.object({
  username: yup
    .string()
    .required("این فیلد الزامیست."),
  password: yup
    .string()
    .required("این فیلد الزامیست.")
});

export {
  insurerSchema
};

import {useEffect, useState} from "react";
import Modal from "../general/modal";
import BoxLoader from "../general/boxLoader";
import Icon from "../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import {connect} from "react-redux";
import {useGetPendingDevice} from "../../../hook/request/pendingDevices";
import {useWindowSize} from "../../../hook/useWindowSize";

type PendingDeviceInfoModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

const PendingDeviceInfoModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: PendingDeviceInfoModalProps) => {

  const [width] = useWindowSize()
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [fieldValue, setFieldValue] = useState([])

  const getPendingDeviceRequest = useGetPendingDevice();

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'pendingDeviceInfoModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData
      }
      handleGetPendingDevice(data)
    }
  }, [modalData, modalName])

  const handleGetPendingDevice = (data: any) => {
    getPendingDeviceRequest.mutate(data);
  }

  useEffect(() => {
    if (getPendingDeviceRequest?.data?.data?.result) {
      const data = getPendingDeviceRequest.data.data.result
      setFieldValue(data);
    }
  }, [getPendingDeviceRequest.data])


  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-600 mw-100"
      bodyClassName="px-3 pos-rel"
      fullscreen={width <= 900 ? true : 'false'}
    >
      {getPendingDeviceRequest?.isPending && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-2">
        <div className='d-flex mb-5 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              فرم اطلاعات دستگاه
            </h4>
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 px-4 w-100">
            <pre className='ltr text-start'>{JSON.stringify(fieldValue, null, 2)}</pre>
          </div>
          <div className="col-12 px-4 d-flex justify-content-end">
            <div className="d-flex form-group mb-2 mr-auto mt-3 pb-1">
              <button
                type='button'
                className="btn btn-outline-gray rounded d-flex align-items-center px-5 p-2"
                onClick={() => setShowState(false)}
              >
                بستن
              </button>
            </div>
          </div>
        </div>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingDeviceInfoModal);
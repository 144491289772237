import { SETTING } from "../constants/constant/environment";
import { MtoJDateFormat, diffYear } from "./date";
import { localStorageGet } from "./localStorage";
import { createUrlParameter, getUrlParameter } from "./urlHelper";

export const handleChange = (e, setFieldValue, fieldValue) => {
  const { name, value, type, checked, files } = e.target;
  let _value = '';
  switch (type){
    case 'checkbox': 
      _value = checked;
      break;
    case 'file': 
      _value = files;
      break;
    case 'date': 
      _value = MtoJDateFormat(value);
      break;
    default: _value = value;
  }
  setFieldValue({ 
    ...fieldValue,
    [name]: _value
  });
}

export const getLang = () => {
  const _siteSettingStorage = localStorageGet('SITE_SETTING');
  return _siteSettingStorage?.LANG || SETTING.DEF_LANG;
};

export const getLayoutDirection = lang => {
  const _lang = lang || getLang();
  return _lang === 'en' ? 'ltr' : 'rtl';
};

export const getCellValue = value => {
  return (value && String(value).trim()) || '-';
};

export const textShorter = (text = '', limited = 10) => {
  return text && text.length > limited ? `${text.substring(0, limited)}...` : text;
};

export const scrollToPoint = point => window.scrollTo(0, point || document.body.scrollHeight);

export const getAllParams = (paginationData, filterValue) => {
  let { pageSize, index } = paginationData,
    filterParams = createUrlParameter(filterValue),
    params = `pageNumber=${+index}&perPage=${pageSize}${filterParams}`;
  return params;
}

export const fillFilteredFields = (filterFieldsArr, params) => {
  let urlParams = getUrlParameter(null, params),
    otherPaginationData = { pageNumber: +urlParams.pageNumber || 0 },
    otherFilterValue = {};
  if(filterFieldsArr){
    for(const item of filterFieldsArr) {
      otherFilterValue[item] = urlParams[item.toLowerCase()] || '';
    }
  }
  return { otherFilterValue, otherPaginationData }
}

export const downloadFile = (fileUrl, fileName) => window.open(fileUrl, fileName);

// clear a object from null and '' values
export const removeEmptyValues = (object) => { 
  return Object.fromEntries(Object.entries(object).filter(([_, v]) => v !== null && v !== ''));
}

export const isImageFile = fileName => {
  if(!fileName) return false;
  return(
    fileName.includes('jpg') || 
    fileName.includes('jpeg') || 
    fileName.includes('png') || 
    fileName.includes('svg') || 
    fileName.includes('webp')
  );
}

export const loadImage = imagePath => {
  const images = require.context('../assets/images', true);
  return images(`./${imagePath}`).default;
}

export const getDoctorName = () => {
  const _userInfo = localStorageGet('USER_INFO');
  return `${_userInfo?.physicianInfo?.firstName} ${_userInfo?.physicianInfo?.lastName}`
}

export const getAge = date => {
  const diff = diffYear(date);
  return Math.floor(diff);
  // return diff > Math.floor(diff) + .5 ? Math.floor(diff) + .5 : Math.floor(diff);
}

export const toHHMMSS = secondValue => {
  let sec_num = parseInt(secondValue, 10),
    hours   = Math.floor(sec_num / 3600),
    minutes = Math.floor((sec_num - (hours * 3600)) / 60),
    seconds = sec_num - (hours * 3600) - (minutes * 60),
    _hours = null;

  if (hours   < 10) { _hours   = "0" + hours }
  if (minutes < 10) { minutes = "0" + minutes }
  if (seconds < 10) { seconds = "0" + seconds }
  return hours ? (_hours + ':' + minutes + ':' + seconds) : (minutes + ':' + seconds);
}

export const convertTo2Digits = num => num < 10 ? `0${num}` : num

export const generateNumericArray = total => {
  let _numericArray = [];
  for(let i = 0; i <= total; i++) _numericArray.push(convertTo2Digits(i));
  return _numericArray;
}

export const startTime = () => {
  const today = new Date();
  let h = today.getHours();
  let m = today.getMinutes();
  let s = today.getSeconds();
  m = convertTo2Digits(m);
  s = convertTo2Digits(s);
  setTimeout(startTime, 1000);
  return h + ":" + m + ":" + s;
}

// Short representation of time in the past.
export const shortDateFormat = (date, locale) => {
  locale = locale || 'fa';
  const now = new Date();
  if (date.getFullYear() === now.getFullYear()) {
    if (date.getMonth() === now.getMonth() && date.getDate() === now.getDate()) {
	    return date.toLocaleTimeString(locale, {hour12: false, hour: '2-digit', minute: '2-digit'});
    } else {
	    return date.toLocaleDateString(locale,
        {hour12: false, month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    }
  }
  return date.toLocaleDateString(locale,
    {hour12: false, year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'});
}

export const getHour = (time) => { // 12:30:00 >> 12
  return time ? +time.split(":")[0] : 0;
}

export const convertToHourFormat = (time) => {  // 2 >> 02:00
  return time < 10 ? `0${time}:00` : `${time}:00`
}

export const convertToShortTime = (time) => { // 02:30:00 >> 02:30
  const _timeArr = time.split(":");
  return `${_timeArr[0]}:${_timeArr[1]}`
}

export const diffTwoTime = (time1, time2) => { // (02:30:00, 02:30:00) => 90 (minutes)
  const date1 = new Date(`2024-01-01T${time1}.000Z`),
    date2 = new Date(`2024-01-01T${time2}.000Z`),
    diff = date2 - date1;
  return diff / 60000;
}

export const toEnDigit = (s) => {
  return s.replace(/([۰-۹])/g, token => String.fromCharCode(token.charCodeAt(0) - 1728));
}
import {getCellValue} from "../../../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../../../utils/date";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect, useSelector} from "react-redux";
import React from "react";
import Tooltip from "../../../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type PatientVaccineTableRowItemProps = {
  item: any;
  handleShowModal: any;
  readonly: boolean;
}

const PatientVaccineTableRowItem = ({
  item,
  handleShowModal,
  readonly
}: PatientVaccineTableRowItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return (
    <tr>
      <td>{getCellValue(item?.vaccine?.name)}</td>
      <td>{getCellValue(item?.manufacturer)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.vaccinationDateTime))}</bdi>
      </td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.expirationDate))}</bdi>
      </td>
      <td>{getCellValue(item?.lotNumber)}</td>
      <td>{getCellValue(item?.doseNumber)}</td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {((!readonly && isProvider) || findAccessInAccessList('EHR_VACCINATIONS_DELETE')) &&
            <Tooltip alt={'حذف'} className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deletePatientVaccineModal', item?.id)}
                className="d-flex text-red-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='trash'/>
              </span>
            </Tooltip>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientVaccineTableRowItem);

import React from "react";
import DiagnosisCardsBoxItem from "./diagnosisCardsBox_Item";
import BoxLoader from "../../../../general/boxLoader";

type VisitCardsBoxProps = {
  dataList?: any;
  isRequesting: boolean
  readOnly?: boolean;
}

const DiagnosisCardsBox = ({
  dataList,
  isRequesting,
  readOnly = false
}: VisitCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <DiagnosisCardsBoxItem item={item} key={i} readOnly={readOnly}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList && _dataList}
    </div>
  )
}

export default DiagnosisCardsBox;
import * as React from "react"
import { SVGProps } from "react"
const ComputerAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={26}
    viewBox="0 0 20 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5907_6592)">
      <path
        d="M17.4854 0.912109H3.30818C2.00503 0.912109 0.945312 2.29982 0.945312 4.00631V17.4145C0.945312 19.121 2.00503 20.5087 3.30818 20.5087H7.94297C7.74241 21.6912 7.20688 22.7418 6.41887 23.4297L6.22043 23.6029H4.88343C4.44866 23.6029 4.0958 24.065 4.0958 24.6343C4.0958 25.2037 4.44866 25.6657 4.88343 25.6657H15.9102C16.3449 25.6657 16.6978 25.2037 16.6978 24.6343C16.6978 24.065 16.3449 23.6029 15.9102 23.6029H14.5732L14.3747 23.4297C13.5867 22.7418 13.0512 21.6912 12.8506 20.5087H17.4854C18.7886 20.5087 19.8483 19.121 19.8483 17.4145V4.00631C19.8483 2.29982 18.7886 0.912109 17.4854 0.912109ZM12.2557 23.6029H8.53791C9.06287 22.702 9.41072 21.6429 9.54302 20.5087H11.2509C11.3829 21.6429 11.7307 22.702 12.2557 23.6029ZM18.273 17.4145C18.273 17.9834 17.9198 18.4459 17.4854 18.4459H3.30818C2.8738 18.4459 2.52056 17.9834 2.52056 17.4145V4.00631C2.52056 3.43748 2.8738 2.97491 3.30818 2.97491H17.4854C17.9198 2.97491 18.273 3.43748 18.273 4.00631V17.4145Z"
        fill="#97ABFF"
      />
      <path
        d="M14.5649 6.88792L11.5775 10.8L10.1653 8.95072C9.858 8.54808 9.35881 8.54808 9.05153 8.95072L5.11342 14.1077C4.80594 14.5106 4.80594 15.1633 5.11342 15.5662C5.26706 15.7679 5.46858 15.8684 5.67029 15.8684C5.872 15.8684 6.07353 15.7679 6.22717 15.5662L9.60841 11.1384L11.0206 12.9877C11.3279 13.3903 11.8271 13.3903 12.1343 12.9877L15.6786 8.34638C15.9861 7.94349 15.9861 7.29081 15.6786 6.88792C15.3714 6.48528 14.8722 6.48528 14.5649 6.88792Z"
        fill="#2E57FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_5907_6592">
        <rect
          width={18.903}
          height={24.7536}
          fill="white"
          transform="translate(0.945312 0.912109)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default ComputerAnalytics

import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import Icon from "../../../../components/general/icon";
import TextAreaField from "../../../../components/general/form/textAreaField";
import {general} from "../../../../../store/action";
import {TDraftDescriptionField, TVisitFields} from "../../../../../types/visit";
import AlertBox from "../../../../components/general/alert";
import PrescriptionMedicationItem from "./prescriptionMedicationItem";
import PrescriptionParaclinicalItem from "./prescriptionParaclinicalItem";
import {ISSUER_TYPE, VISIT_MODE} from "../../../../../constants/constant/enum";
import {
  TCheckInsuranceFields,
  TIhioDataAndPrescriptionMedicationItemFields, TIhioDataAndPrescriptionParaclinicalItemFields,
  TPrescriptionFields
} from "../../../../../types/prescription";
import {useGetPrescription} from "../../../../../hook/request/visit/prescription";
import {useLocation} from "react-router-dom";

type PrescriptionProps = {
  visitData?: TVisitFields;
  checkInsurance?: TCheckInsuranceFields;
  prescriptionData?: TPrescriptionFields;
  medicationItemsCheckCodes?: string[]
  paraclinicalItemsCheckCodes?: string[]
  isRequesting?: boolean;
  submitEndVisitCheck?: boolean;
  handleShowModal: any;
  updatePrescriptionFields: any;
  handlePrescriptionModalSubmit: any;
}

const Prescription = ({
  visitData,
  checkInsurance,
  updatePrescriptionFields,
  prescriptionData,
  medicationItemsCheckCodes,
  paraclinicalItemsCheckCodes,
  handlePrescriptionModalSubmit,
  isRequesting,
}: PrescriptionProps) => {

  //<editor-fold desc="States">
  const location = useLocation();
  const  mode  = location?.state?.mode || VISIT_MODE.SHOW.value;
  const [fieldValue, setFieldValue] = useState<TDraftDescriptionField>({
    draftDescription: '',
  });
  const {
    control,
    reset,
    formState: { errors },
  } = useForm<TDraftDescriptionField>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });
  const [insurance, setInsurance] = useState(0)
  const [medicationPrescriptionFieldValue, setMedicationPrescriptionFieldValue] = useState<TIhioDataAndPrescriptionMedicationItemFields[]>([])
  const [paraclinicalPrescriptionFieldValue, setParaclinicalPrescriptionFieldValue] = useState<TIhioDataAndPrescriptionParaclinicalItemFields[]>([])
  //</editor-fold>

  //<editor-fold desc="Services">
  const getPrescriptionRequest = useGetPrescription()
  //</editor-fold>

  //<editor-fold desc="UseEffects">
  useEffect(() => {
    visitData?.id && getPrescriptionRequest.mutate({visit: {id: visitData.id}})
  }, [visitData])

  useEffect(() => {
    setFieldValue({draftDescription: prescriptionData?.draftDescription})
  }, [prescriptionData]);

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getPrescriptionRequest?.data?.data?.result) {
      const {
          id, insurerStatus, medicationItems, paraclinicalItems, draftDescription
        } = getPrescriptionRequest.data.data.result;
      handlePrescriptionModalSubmit(id, insurerStatus, draftDescription);
      const extractedData: TIhioDataAndPrescriptionMedicationItemFields[] = medicationItems.map((medication: any) => {
        if (Object.keys(medication).length === 0) {
          // Return an empty object if the prescription object is empty
          return {};
        }
        // Return the mapped object if the prescription object is not empty
        return {
          item: medication.item,
          dosage: medication.dosage.id,
          dosageName: medication.dosage.faName,
          frequency: medication.frequency.id,
          frequencyName: medication.frequency.name,
          itemCount: medication.itemCount,
          checkCode: medication.ihioCheck,
          drugDescription: medication.drugDescription,
          activeDate: medication.activeDate,
          brand: medication?.item?.id,
          brandName: medication?.item?.brand
        };
      });

      const extractedParaData: TIhioDataAndPrescriptionParaclinicalItemFields[] = paraclinicalItems.map((paraclinical: any) => {
        // Return the mapped object if the paraclinical object is not empty
        return {
          service: paraclinical.service,
          emergency: paraclinical.emergency,
          itemCount: paraclinical.itemCount,
          checkCode: paraclinical.ihioCheck,
          note: paraclinical.note,
          activeDate: paraclinical.activeDate,
        };
      });

      setMedicationPrescriptionFieldValue(extractedData);
      setParaclinicalPrescriptionFieldValue(extractedParaData);
    }
  }, [getPrescriptionRequest.data])

  /*
    مشخص کردن نوع بیمه بیمار جهت ثبت نسخه
    و دریافت اطلاعات دارو ها و پاراکلینیک بر اساس آن.
    @Dependency: string
  */
  useEffect(() => {
    const insuranceId = checkInsurance?.issuerType?.id;
    if(insuranceId === ISSUER_TYPE.TAMIN.value) {
      setInsurance(ISSUER_TYPE.TAMIN.value);
    } else if(insuranceId === ISSUER_TYPE.M.value || insuranceId === ISSUER_TYPE.ESATA.value) {
      setInsurance(ISSUER_TYPE.M.value);
    } else if(insuranceId === ISSUER_TYPE.B.value) {
      setInsurance(ISSUER_TYPE.B.value);
    } else {
      setInsurance(ISSUER_TYPE.IHIO.value);
    }
  }, [checkInsurance]);
  //</editor-fold>

  /*
    آپدیت داده ها در پرنت جهت ارسال به مودال ثبت نسخه.
    @param: item
    @param: name
  */
  const updateData = (item: any, name: string) => {
    updatePrescriptionFields(item, name)
  }

  /*
    آپدیت داده توضیحات تکمیلی.
    @param: e
  */
  const handleFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>)=> {
    const { name, value } = e?.target;
    updateData(value, name);
  };

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        {
          prescriptionData?.insurerStatus?.errorMessage &&
          <AlertBox type='danger' icon='info-circle' iconClass='fs-2' className='border-red border-2 mb-3'>
            <div className="d-flex align-items-center w-100 justify-content-between">
              <span>{prescriptionData?.insurerStatus?.errorMessage}</span>
              <div className="d-flex justify-content-md-end mb-3 m-md-0 ">
                <span
                  // onClick={() => handleShowModal('activeScheduleModal', id)}
                  className="btn btn-dark text-nowrap rounded-3 d-flex align-items-center px-3"
                >
                  ارسال مجدد به بیمه
                </span>
              </div>
            </div>
          </AlertBox>
        }
        {
          (checkInsurance?.snackMessage?.warning?.length ?? 0) > 0 &&
          <AlertBox type='warning' icon='info-circle' iconClass='fs-2' text={checkInsurance?.snackMessage?.warning}
                    className='border-orange border-2 mb-3' children=''/>
        }
        <PrescriptionMedicationItem visitData={visitData} insurance={insurance} updateData={updateData}
                                    medicationPrescriptionFieldValues={medicationPrescriptionFieldValue}
                                    paraclinicalItemsCheckCodes={paraclinicalItemsCheckCodes}
                                    isRequesting={getPrescriptionRequest.isPending}/>
        <PrescriptionParaclinicalItem visitData={visitData} insurance={insurance} updateData={updateData}
                                      paraclinicalPrescriptionFieldValues={paraclinicalPrescriptionFieldValue}
                                      medicationItemsCheckCodes={medicationItemsCheckCodes}
                                      isRequesting={getPrescriptionRequest.isPending}/>
        <h4 className='d-flex font-14 mb-4 mt-4 pb-2'>
        <div className="align-content-center ms-auto">
            <span><Icon name='message-add'/></span>
            <span className='me-2'>ثبت توضیحات تکمیلی</span>
          </div>
        </h4>
        <form>
          <div className="d-flex">
            <div className="col-12">
              <div className="form-group mb-4">
                <TextAreaField
                  name="draftDescription"
                  placeholder="‌توضیحات"
                  control={control}
                  error={errors}
                  onChange={handleFieldChange}
                  disabled={mode === VISIT_MODE.SHOW.value}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(Prescription);
import React from "react";
import {connect, useSelector} from "react-redux";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const VacationCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}) => {
  const isProvider = useSelector((state) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return(
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.provider?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="fromDateTime">{dataListTitles[1]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.fromDateTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="toDateTime">{dataListTitles[2]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.toDateTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-end my-3">
        <div className="d-flex align-items-center">
          {/* <span
            onClick={() => navigate(generatePath(ROUTES.VACATION_EDIT.PATH, {id: item?.id}))}
            className="d-flex text-hover mx-2 font-18 cur-pointer"
          >
            <Icon name='pen-square'/>
          </span> */}
          {
            (isProvider || findAccessInAccessList('PROVIDER_VACATION_DELETE')) &&
            <Tooltip alt={'حذف'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deleteVacationModal', item?.id)}
                className="d-flex text-red-hover mr-2 font-20 cur-pointer"
              >
                <Icon name='trash'/>
              </span>
            </Tooltip>
          }
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VacationCardsBoxItem);
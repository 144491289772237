import {connect, useSelector} from "react-redux";
import {general} from "../../../../../../store/action";
import {getCellValue} from "../../../../../../utils/utils";
import {Dispatch} from "redux";
import {
  monthDay,
  MtoJDateFormat,
  MtoJHHMMFormat, yearTitle,
} from "../../../../../../utils/date";
import Icon from "../../../../general/icon";
import {useWindowSize} from "../../../../../../hook/useWindowSize";
import Tooltip from "../../../../general/tooltip";
import React from "react";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";


type PatientPrescriptionCardsItemProps = {
  item: any,
  handleShowModal: any
}

const PatientPrescriptionCardsItem = ({
 item,
 handleShowModal
}: PatientPrescriptionCardsItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [width] = useWindowSize();

  return (
    <>
      <div className="card shadow-none border cur-pointer mb-4"
           onClick={() => handleShowModal('showPatientPrescriptionModal', {prescriptionFieldValue: item, patientData: item?.patient})}
      >
        <div className='bg-gray rounded'>
          <div className='col-12 row my-3 align-items-center'>
            <div className={`col-lg-2 col-md-6 col-sm-12  d-flex text-gray font-12 my-1 px-4 ${width > 1000 ? 'justify-content-center' : 'justify-content-between'}`}>
              تاریخ ویزیت
              <Tooltip alt={
                getCellValue(monthDay(item?.insurerStatus?.insertDate)) + ' ' +
                getCellValue(yearTitle(item?.insurerStatus?.insertDate)) + ' - ' +
                getCellValue(MtoJHHMMFormat(item?.insurerStatus?.insertDate))
              } className="tooltip-pre-line">
                <bdi className='me-3'>
                  {
                    MtoJDateFormat(item?.insurerStatus?.insertDate)
                  }
                </bdi>
              </Tooltip>
            </div>
            <div className={`col-lg-3 col-md-6 col-sm-12  d-flex rmdp-border-right font-12 my-1 px-4 ${width > 1000 ? 'justify-content-center' : 'justify-content-between'}`}>
              <span className="text-gray">
                حاوی
              </span>
              <span className='me-3'>
                {item?.medicationItems?.length > 0 && getCellValue(item?.medicationItems?.length) + ' قلم دارویی'}
                {item?.medicationItems?.length > 0 && item?.paraclinicalItems?.length > 0 && ' و '}
                {item?.paraclinicalItems?.length > 0 && getCellValue(item?.paraclinicalItems?.length) + ' خدمت پاراکلینیک'}
                {item?.medicationItems?.length === 0 && item?.paraclinicalItems?.length === 0 && ' فاقد دارو و پاراکلینیک '}
              </span>
            </div>
            <div className={`col-lg-3 col-md-6 col-sm-12  d-flex rmdp-border-right font-12 my-1 px-4 ${width > 1000 ? 'justify-content-center' : 'justify-content-between'}`}>
             <span className="text-gray">
               نام پزشک
              </span>
              <span className='me-3'>
                {getCellValue(item?.provider?.name)}
              </span>
            </div>
            <div className={`col-lg-2 col-md-6 col-sm-12  d-flex rmdp-border-right font-12 my-1 px-4 ${width > 1000 ? 'justify-content-center' : 'justify-content-between'}`}>
             <span className="text-gray">
                بیمه
              </span>
              <span className='me-3'>
                {getCellValue(item?.insurerStatus?.issuerType?.name)}
              </span>
            </div>
            {(isProvider || findAccessInAccessList('EHR_PRESCRIPTION')) &&
              <div className={`col-lg-2 col-md-12 col-sm-12  d-flex justify-content-end`}>
                <div className='btn btn-gray rounded rounded-3 p-1 mx-2'>
                  <span
                    onClick={() => handleShowModal('showPatientPrescriptionModal', {
                      prescriptionFieldValue: item,
                      patientData: item?.patient
                    })}
                    className='font-20 d-flex h-100 m-1'>
                    <Icon name='eye'/>
                    <span className="font-12 me-1">
                      مشاهده نسخه
                    </span>
                  </span>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientPrescriptionCardsItem);
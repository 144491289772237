import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { general } from "../../../store/action";
import { isHideModalDataSelector, isShowModalDataSelector, modalDataSelector, modalNameSelector } from "../../../store/selector/general";
import BoxLoader from "../general/boxLoader";
import Icon from "../general/icon";
import Modal from "../general/modal";
import {Socket} from "socket.io-client";
import Noti from "../general/noti";

type WaitForApprovedNotifModalProps = {
  isApproved?: boolean
  modalData: any;
  modalName: any;
  isShowModalData: any;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  handleDequeueVisitRequest: any,
  handleEmptyVisitRequestQueue: any,
  handleClose: any
}

const WaitForApprovedNotifModal = ({
 isApproved,
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: WaitForApprovedNotifModalProps) => {

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>()
  const [request, setRequest] = useState<boolean>(false)
  useEffect(() => {
    if(
      isShowModalData &&
      modalName === 'waitForApprovedNotifModal'
    ){
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  useEffect(() => {
    isApproved && setRequest(isApproved)
  }, [isApproved]);

  useEffect(() => {
    if (showState) {
      // Set a timeout to perform an action after 5 seconds
      const timer = setTimeout(() => {
        if (!request) {
          // If the request is still false after 5 seconds
          handleSetShowModal();
          Noti('error', 'درخواست از سمت بیمار لغو شد.');
        }
      }, 10000); // 10 seconds delay

      // If request becomes true before the timeout ends
      if (request) {
        handleSetShowModal();
        clearTimeout(timer); // Clear the timer since request is true
      }

      // Cleanup the timeout if the component unmounts or showState changes
      return () => clearTimeout(timer);
    }
  }, [showState, request]);


  const handleSetShowModal = () => {
    if(request) {
      modalInfo?.handleClose(modalInfo?.handleEmptyVisitRequestQueue);
    } else {
      modalInfo?.handleClose(modalInfo?.handleDequeueVisitRequest);
    }
    setShowState(false);
    setRequest(false)
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-400 mw-100"
      bodyClassName="py-5 px-3 px-lg-5 pos-rel"
    >
      <div className="text-center">
        <div className="d-flex justify-content-center mb-4">
          <span className="delete-item-bg d-flex justify-content-center align-items-center text-red font-25 pt-4">
            <Icon name='trash' />
          </span>
        </div>
        <h4 className="font-14 mb-2">پزشک گرامی</h4>
        <p className="text-black">
          در حال ایجاد جلسه ویزیت. لطفا کمی منتظر بمانید.
        </p>
        <BoxLoader type=""/>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitForApprovedNotifModal);

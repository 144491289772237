import React from 'react';
import { Controller } from 'react-hook-form';

type TextAreaFieldProps = {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name: string;
  value?: string;
  placeholder: string;
  className?: string;
  rows?: number | undefined;
  cols?: number | undefined;
  readOnly?: boolean;
  disabled?: boolean;
  error: any;
  control?: any;
  register?: any;
  watch?: any
}

const TextAreaField = ({
  onChange,
  name,
  value,
  placeholder,
  className = '',
  rows,
  cols,
  readOnly,
  disabled,
  error,
  control,
  register,
  watch
}: TextAreaFieldProps) => {

  const handleChange = (val: any, onFieldChange: any) => {
    onChange && onChange(val);
    onFieldChange(val);
  }

  let showError = error?.[name] && error?.[name]?.message;
  return(
    <>
      <div className={`inp-box ${showError ? 'invalid-field' : ''}`}>
        {
          watch ?
            <Controller
              control={control}
              name={name}
              {...register}
              render={({ field: { onChange, value } }) => (
                <textarea
                  className={`form-control placeholder-field-color ${className}`}
                  name={name}
                  value={watch}
                  onChange={(e) => handleChange(e, onChange)}
                  placeholder={placeholder}
                  cols={cols || 30}
                  rows={rows || 5}
                  readOnly={readOnly}
                  disabled={disabled}
                ></textarea>
              )}
            />
          :
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => (
                <textarea
                  className={`form-control placeholder-field-color ${className}`}
                  name={name}
                  value={value}
                  onChange={(e) => handleChange(e, onChange)}
                  placeholder={placeholder}
                  cols={cols || 30}
                  rows={rows || 5}
                  readOnly={readOnly}
                  disabled={disabled}
                ></textarea>
            )}
          />
        }
      </div>
      {showError && <p className="text-danger font-10 mt-1 me-2">{error?.[name]?.message}</p>}
    </>
  )
}

export default TextAreaField;
import React from "react";
import {Carousel} from "react-bootstrap";

type DashboardCarouselCardsProps = {
  icon: any[],
  text: string[],
  amount: number[],
  svg: any[],
}
const DashboardCarouselCards = ({
  icon,
  text,
  amount,
  svg
}: DashboardCarouselCardsProps) => {

  return (
      <Carousel interval={Math.random() * 2000 + 5000} className='dashboard-carousel-indicators carousel-container remove-carousel-arrows h-100'>
        {text && text.map((item: string, index: number) => {
          if (item === '') return null;
          return (
            <Carousel.Item>
              <div className="d-flex justify-content-center flex-column px-2">
                <div className="d-flex justify-content-center flex-wrap align-items-center">
                <span className="font-25 text-gray2 fw-medium me-2">
                  {icon[index]}
                </span>
                </div>
                <div className="d-flex justify-content-center flex-wrap align-items-center">
                <span className="font-18 text-gray2 fw-medium me-2">
                  {item}
                </span>
                </div>
                <div className='d-flex justify-content-center h3 fw-semibold'>
                  {amount[index]}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {svg[index]}
              </div>
            </Carousel.Item>
          )
        })}
      </Carousel>
  )
}

export default DashboardCarouselCards;
import React from "react";
import { connect } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const UserCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}) => {
  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();


  return(
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.firstName)} {getCellValue(item?.lastName)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="nationalCode">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.nationalCode)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="mobile">{dataListTitles[2]}</label>
        <span className="font-en mr-2">{getCellValue(item?.mobile)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="updateDate">{dataListTitles[3]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="gender">{dataListTitles[4]}</label>
        <div className="d-flex justify-content-center align-items-center">
          {item?.banned ?
            <div>
            <span className='bg-light-red text-red px-3 py-1 rounded text-nowrap'>
              غیر فعال
            </span>
            </div>
            :
            <div>
            <span className='px-3 py-1 text-green2 rounded text-nowrap' style={{backgroundColor: '#E6FAF2'}}>
              فعال
            </span>
            </div>
          }
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="gender">{dataListTitles[5]}</label>
        <div className="d-flex justify-content-center align-items-center">
          {
            findAccessInAccessList('OPERATOR_VIEW_ACCESS') &&
            <Tooltip alt={'سطح دسترسی'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.USER_ACCESS.PATH, {id: item?.id, accountId: item?.accountId}))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='move'/>
              </span>
            </Tooltip>
          }
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end my-3">
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-center align-items-center">
            {
              findAccessInAccessList('OPERATOR_CHANGE_BLOCK') &&
              <Tooltip alt={item?.banned ? 'فعال سازی حساب کاربری' : 'غیر فعال سازی حساب کاربری'} placement='bottom' className="tooltip-pre-line">
                <span
                  onClick={() => handleShowModal('blockingUserModal', {accountId: item?.accountId, banned: item?.banned})}
                  className={`d-flex mx-2 font-20 cur-pointer ${item?.banned ? 'text-hover' : 'text-red-hover'}`}
                >
                  <Icon name={item?.banned ? 'check-square' : 'close-square'}/>
                </span>
              </Tooltip>
            }
            {
              findAccessInAccessList('OPERATOR_CHANGE_PASSWORD') &&
              <Tooltip alt={'تغییر کلمه عبور'} placement='bottom' className="tooltip-pre-line">
                <span
                  onClick={() => handleShowModal('changeAccountPasswordModal', {firstName: item?.firstName, lastName: item?.lastName, accountId: item?.accountId})}
                  className="d-flex text-hover mx-2 font-20 cur-pointer"
                >
                  <Icon name='key'/>
                </span>
              </Tooltip>
            }
            {
              findAccessInAccessList('OPERATOR_VIEW') &&
              <Tooltip alt={'ویرایش'} placement='bottom' className="tooltip-pre-line">
                <span
                  onClick={() => navigate(generatePath(ROUTES.USER_EDIT.PATH, {id: item?.id}))}
                  className="d-flex text-hover mx-2 font-18 cur-pointer"
                >
                  <Icon name='pen-square'/>
                </span>
              </Tooltip>
            }
            {
              findAccessInAccessList('OPERATOR_DELETE') &&
              <Tooltip alt={'حذف'} placement='bottom' className="tooltip-pre-line">
                <span
                  onClick={() => handleShowModal('deleteUserModal', item?.id)}
                  className="d-flex text-red-hover mx-2 font-20 cur-pointer"
                >
                  <Icon name='trash'/>
                </span>
              </Tooltip>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(UserCardsBoxItem);
import React, {useEffect, useMemo, useState} from "react";
import Modal from "../general/modal";
import BoxLoader from "../general/boxLoader";
import Icon from "../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import {connect, useSelector} from "react-redux";
import {useChangeStatusAppointment, useGetAppointment} from "../../../hook/request/appointments";
import {fullDate, MtoJDateFormat, MtoJHHMMFormat, yearTitle} from "../../../utils/date";
import {useForm} from "react-hook-form";
import {VISIT_MODE, VISIT_STATUS_ENUM} from "../../../constants/constant/enum";
import {generatePath, useNavigate} from "react-router-dom";
import {ROUTES} from "../../../constants";
import {TGIdNameData} from "../../../types/general";
import {useWindowSize} from "../../../hook/useWindowSize";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";


type ChangeStatusAppointmentModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  id: string,
  ehr?: boolean
}

type TChangeStatusFields = {
  id: number,
  patient: {
    id: number,
    firstName: string,
    lastName: string,
  },
  provider: {
    id: number,
    firstName: string
    lastName: string
  },
  healthUnit: {
    id: number,
    name: string
  },
  visitType: {
    id: number,
    name: string
  },
  status: {
    id: number,
    name: string
  },
  trackingCode: number,
  dateTime: any,
  updateDate: any,
}

const ChangeStatusAppointmentModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: ChangeStatusAppointmentModalProps) => {

  const navigate = useNavigate();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();  const [width] = useWindowSize();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    ehr: true
  });

  const [fieldValue, setFieldValue] = useState<TChangeStatusFields>({
    id: 0,
    patient: {
      id: 0,
      firstName: '',
      lastName: '',
    },
    provider: {
      id: 0,
      firstName: '',
      lastName: ''
    },
    healthUnit: {
      id: 0,
      name: ''
    },
    visitType: {
      id: 0,
      name: ''
    },
    status: {
      id: 0,
      name: ''
    },
    trackingCode: 0,
    dateTime: '',
    updateDate: '',
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TChangeStatusFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });

  // services
  const changeStatusAppointmentRequest = useChangeStatusAppointment({
    onSuccess: (status: TGIdNameData) => {
      handleSetShowModal();
      if(status.id === VISIT_STATUS_ENUM.IN_PROGRESS.value) {
        navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, {id: modalInfo?.id}), {state: {mode: VISIT_MODE.EDIT.value}})
      }
    }
  });
  const getAppointmentRequest = useGetAppointment();


  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'changeStatusAppointmentModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData.id
      }
      handleGetAppointment(data)
    }
  }, [modalData, modalName])

  const handleGetAppointment = (data: any) => {
    getAppointmentRequest.mutate(data);
  }

  useEffect(() => {
    if (getAppointmentRequest?.data?.data?.result) {
      const {
          id,
          patient,
          provider,
          healthUnit,
          visitType,
          status,
          trackingCode,
          dateTime,
          updateDate
        } = getAppointmentRequest.data.data.result,
        _fieldValue = {
          id,
          patient,
          provider,
          healthUnit,
          visitType,
          status,
          trackingCode,
          dateTime,
          updateDate
        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getAppointmentRequest.data])



  const changeStatusAppointmentCancel = (event: React.MouseEvent) => {
    event.preventDefault();
    const body = {
      id: modalInfo.id,
      status: {
        id: VISIT_STATUS_ENUM.PATIENT_IS_NOT_PRESENT.value,
        name: ''
      }
    };
    changeStatusAppointmentRequest.mutate(body);
  }

  const changeStatusAppointmentSubmit = (event: React.MouseEvent) => {
    event.preventDefault()
    const body = {
      id: modalInfo.id,
      status: {
        id: VISIT_STATUS_ENUM.IN_PROGRESS.value
      }
    };
    changeStatusAppointmentRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
      fullscreen={width <= 900 ? true : 'false'}
    >
      {
        changeStatusAppointmentRequest?.isPending ||
        getAppointmentRequest?.isPending
        && <BoxLoader type="cover"/>
      }
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              جزئیات نوبت بیمار
            </h4>
            {fieldValue?.patient &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {fieldValue?.patient?.firstName} {fieldValue?.patient?.lastName}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 px-4">
            <div className="form-group ">
              <div className='d-flex justify-content-between border form-control mb-3 pl-5 bg-gray'>
                <span>نام بیمار: </span>
                <span>{fieldValue?.patient?.firstName} {fieldValue?.patient?.lastName}</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 px-4">
            <div className="form-group ">
              <div className='d-flex justify-content-between border form-control pl-5 mb-3 bg-gray' aria-disabled>
                <span>نام پزشک: </span>
                <span>{fieldValue?.provider?.firstName} {fieldValue?.provider?.lastName}</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 px-4">
            <div className="form-group">
              <div className='d-flex justify-content-between border form-control pl-5 mb-3 bg-gray' aria-disabled>
                <span>نام مرکز: </span>
                <span>{fieldValue?.healthUnit?.name}</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 px-4">
            <div className="form-group ">
              <div className='d-flex justify-content-between border form-control pl-5 mb-3 bg-gray' aria-disabled>
                <span>تاریخ و ساعت حضور: </span>
                <span>
                  {
                    fullDate(fieldValue?.dateTime) + ' ' +
                    yearTitle(fieldValue?.dateTime) + ' - ' +
                    MtoJHHMMFormat(fieldValue?.dateTime)
                  }
                  </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 px-4">
            <div className="form-group ">
              <div className='d-flex justify-content-between border form-control pl-5 mb-3 bg-gray' aria-disabled>
                <span>زمان درخواست: </span>
                <span>
                  {
                    MtoJHHMMFormat(fieldValue?.updateDate) + ' - ' +
                    MtoJDateFormat(fieldValue?.updateDate)
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 px-4">
            <div className="form-group ">
              <div className='d-flex justify-content-between border form-control pl-5 mb-5 bg-gray' aria-disabled>
                <span>وضعیت نوبت: </span>
                <span className='fw-bold'>{fieldValue.status.name}</span>
              </div>
            </div>
          </div>
          {
            (fieldValue?.status.id === VISIT_STATUS_ENUM.RESERVED.value && !modalInfo?.ehr && (isProvider || findAccessInAccessList('APPOINTMENT_CHANGE_STATUS'))) &&
            <>
              <div className='col-12 col-md-6 px-4 mb-lg-0 mb-md-0 mb-2'>
                <button
                  type='submit'
                  className="btn btn-red rounded align-items-center fw-bold px-lg-5 px-4 py-3"
                  onClick={event => changeStatusAppointmentCancel(event)}
                >
                  لغو کردن نوبت
                </button>
                <button
                  type='submit'
                  className="btn btn-green rounded align-items-center fw-bold float-start px-lg-5 px-4 py-3"
                  onClick={event => changeStatusAppointmentSubmit(event)}
                >
                  تایید انجام نوبت
                </button>
              </div>
              <div className='col-12 col-md-6 px-4'>
                <button
                  type='button'
                  // onClick={() => navigate(ROUTES?.DOCTOR?.PATH)}
                  className="btn btn-gray rounded align-items-center fw-bold float-start px-lg-5 px-4 py-3"
                  onClick={() => setShowState(false)}
                >
                  انصراف
                </button>
              </div>
            </>
          }
        </div>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusAppointmentModal);
import * as React from "react"
import { SVGProps } from "react"
const AssignedDevicesCountAnimated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="animated"
    id="freepik_stories-cardiologist"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
    width={250}
    height={250}
  >
    <style>
      {
        "svg#freepik_stories-cardiologist:not(.animated) .animable {opacity: 0;}svg#freepik_stories-cardiologist.animated #freepik--Shadows--inject-417 {animation: 1.5s Infinite  linear heartbeat;animation-delay: 0s;}svg#freepik_stories-cardiologist.animated #freepik--Heartbeats--inject-417 {animation: 6s Infinite  linear shake;animation-delay: 0s;}svg#freepik_stories-cardiologist.animated #freepik--Heart--inject-417 {animation: 1.5s Infinite  linear heartbeat;animation-delay: 0s;}            @keyframes heartbeat {                0% {                    transform: scale(1);                }                10% {                    transform: scale(1.1);                }                30% {                    transform: scale(1);                }                40% {                    transform: scale(1);                }                50% {                    transform: scale(1.1);                }                60% {                    transform: scale(1);                }                100% {                    transform: scale(1);                }            }                    @keyframes shake {                10%, 90% {                    transform: translate3d(-1px, 0, 0);                  }                  20%, 80% {                    transform: translate3d(2px, 0, 0);                  }                  30%, 50%, 70% {                    transform: translate3d(-4px, 0, 0);                  }                  40%, 60% {                    transform: translate3d(4px, 0, 0);                  }            }        .animator-hidden { display: none; }"
      }
    </style>
    <g
      id="freepik--Shadows--inject-417"
      style={{
        transformOrigin: "295.87px 436.23px 0px",
      }}
      className="animable animator-hidden"
    >
      <ellipse
        cx={396.8}
        cy={436.23}
        rx={65.14}
        ry={14.48}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "396.8px 436.23px 0px",
        }}
        id="el1siaxv6rt0i"
        className="animable"
      />
      <ellipse
        cx={194.94}
        cy={436.23}
        rx={65.14}
        ry={14.48}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "194.94px 436.23px 0px",
        }}
        id="elmg3ulgyuuy"
        className="animable"
      />
    </g>
    <g
      id="freepik--Character--inject-417"
      style={{
        transformOrigin: "396.853px 259.137px 0px",
      }}
      className="animable animator-hidden"
    >
      <path
        d="M373.22,412.41s-5.28,7.84-7.23,9.53-12.5,7.83-14.86,8.68-2.18,2.33-1.57,3.6,4.17,4,13.95,3.6,10.64-.42,14.27-1.26,13.83-.21,13.83-.21,6.8-.22,8.33-1.91.94-13.77.94-13.77Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "375.079px 425.135px 0px",
        }}
        id="elu45n468pbsg"
        className="animable"
      />
      <path
        d="M400.88,429.86c-2.32.28-5.81.8-10.66,1.83-10,2.11-11.11,2.75-25.57,3a48.61,48.61,0,0,1-15.47-2,3.23,3.23,0,0,0,.38,1.55c.61,1.27,4.17,4,13.95,3.6s10.64-.42,14.27-1.27,13.83-.2,13.83-.2,6.8-.22,8.33-1.91C400.47,433.85,400.74,432,400.88,429.86Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "375.03px 433.87px 0px",
        }}
        id="elq2bipsvgdf"
        className="animable"
      />
      <path
        d="M409.05,416.86a36.52,36.52,0,0,1-3.36,7.63c-2.43,4.23-9.77,8.89-14.08,11.86s-4.14,4.23-1,5.71,15.26,2.12,19.3,1.91,16-3.6,17.73-3.81,3.82.22,5.38-3.17-.2-11.87-.2-11.87-10-7.83-14-8.68S411.23,414.53,409.05,416.86Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "410.986px 429.727px 0px",
        }}
        id="elimmyut0ppdm"
        className="animable"
      />
      <path
        d="M433,437a9.06,9.06,0,0,0,.63-3.41,33.6,33.6,0,0,0-6.56,2.56c-3.66,2.12-8,4.45-18,4.66a144.69,144.69,0,0,1-20.69-1.5c-.49,1.1.32,1.9,2.17,2.76,3.15,1.49,15.26,2.12,19.3,1.91s16-3.6,17.73-3.81S431.48,440.38,433,437Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "410.935px 438.805px 0px",
        }}
        id="eljo3436uu65"
        className="animable"
      />
      <path
        d="M366.28,300s3,41.38,4.54,51.33,3.62,48,3.6,49a57.56,57.56,0,0,1-.13,5.86c-.21.58-2.17.78-2.39,1.75a42.47,42.47,0,0,0-.35,7c.17,1,2.13,1.17,5.41,3.12S390.37,423,394.1,423s7.24.39,7.25-.2.24-10.93.31-14-.89-3.9-.64-6.44S400.37,300,400.37,300l3.31,1s2.07,65.19,2.68,73-.22,35.93-.22,35.93-1.78.78-1.8,1.56c-.07,3.48,1.14,7.35,1.73,10.76a28.17,28.17,0,0,1,10.63-.51c3.19.34,7,.57,10,1.8a18.72,18.72,0,0,0,6.15,1.62,4.92,4.92,0,0,0,2.23-3.32,16.59,16.59,0,0,0-1.56-9.37c-1.87-3.9-2.4-6.44-2.06-12.68s5-31.64,5.2-40,1.32-32.79,1.57-35.14,1.61-28.3,1.61-28.3L436,291l-69.8,2.53Z"
        style={{
          fill: "rgb(146, 227, 169)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "403.02px 358.08px 0px",
        }}
        id="elsw8ruldeq1"
        className="animable"
      />
      <path
        d="M381.26,304.13l19.21,13.79h.06c-.09-10.66-.16-17.92-.16-17.92l3.31,1s.22,6.93.54,16.54h0l22.13-14.2-23.49-11.09-16.73.61Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "403.805px 305.085px 0px",
        }}
        id="elzbw3i5l4mn"
        className="animable"
      />
      <path
        d="M384.14,131.3,357,143.09s-18.59,42-19.1,45.68,3.54,5.36,6.73,7.07,17.62,1.08,17.62,1.08l-6.17,103.87s6.19,2.36,13.89,4.51S390.49,310,390.49,310l2.84-11.37,2.53,12s20.89-.42,28.91-3,26.92-10.29,26.92-10.29l-4.4-73.63-1.15-15.87.86-19.09s9.51-2.14,9.57-4.71S451,142.68,450,140.32s-10.22-5.36-11.29-5.58-21.59-6.65-21.59-6.65S393.71,126.59,384.14,131.3Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "397.213px 219.246px 0px",
        }}
        id="elrwrf1muvvul"
        className="animable"
      />
      <path
        d="M389.43,125.73s-3.68.85-4.35,1.71-6.29,11.8-6.29,11.8l5.75,3-6.15,5.57,15.9,29.81L424.47,147l-6.37-4.07,5.71-5.15s-2.8-9.43-4.68-11.79a13.12,13.12,0,0,0-4.23-3.44Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "401.43px 150.085px 0px",
        }}
        id="el2vmibart2vg"
        className="animable"
      />
      <polygon
        points="387.31 124.01 388.38 133.88 395.23 164.76 414.71 131.09 416.46 120.16 386.97 119.94 387.31 124.01"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "401.715px 142.35px 0px",
        }}
        id="el0uep10blmh9"
        className="animable"
      />
      <path
        d="M386.74,82.41s-6.37,5.57-6,9.43a42,42,0,0,0,2.18,8.8l.5,6.43,1.51-.43-.63-10.08,7.67-6.22L405.7,91s4,4.07,5.48,5.15,4.88,3.43,4.88,3.43l1.1,8.36,1.73-.43s.54-4.93.78-5.79.95-13.51.79-16.08-8.82-9.87-17.62-11.15-14.06,3-15.83,5.14S386.74,82.41,386.74,82.41Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "400.604px 91.0978px 0px",
        }}
        id="el6qif8r6v607"
        className="animable"
      />
      <path
        d="M393.08,87.34s-8.77,7.29-8.81,9.22-.08,22.73,1,24.66,7.08,10.73,10.06,12.44,6,2.15,8.6.22,11-10.72,12.58-13.72.93-22.31.93-22.31,1,1.29-2.34-1.5a17.32,17.32,0,0,1-4.79-6.86S396.68,90.34,393.08,87.34Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "400.918px 111.243px 0px",
        }}
        id="elrp01485wf09"
        className="animable"
      />
      <path
        d="M417.39,97.85s0,.1,0,.26C417.72,98.27,417.39,97.85,417.39,97.85Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "417.463px 97.9982px 0px",
        }}
        id="elavjlbcg262s"
        className="animable"
      />
      <path
        d="M395.28,133.66c3,1.72,6,2.15,8.6.22s11-10.72,12.58-13.72c1.47-2.8,1-19.76.94-22a8.86,8.86,0,0,1-1.47-1l.13,2.5-.41,18.23-3.72,2.78a25.89,25.89,0,0,0-14.12-4.07c-8,.21-10.84,3.43-10.84,3.43l-2.7-23.38c0,1.93-.08,22.73,1,24.66S392.3,132,395.28,133.66Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "400.894px 115.9px 0px",
        }}
        id="elnh75q6zpwns"
        className="animable"
      />
      <path
        d="M396.33,106l0,10.94a2.78,2.78,0,0,0,3,1.07,3,3,0,0,0,2-2.15"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "398.83px 112.054px 0px",
        }}
        id="el6qppq0ggv0u"
        className="animable"
      />
      <path
        d="M417.66,104.93s1.22-6.43,2.93-6,2.28,4.07.89,8.57-3.76,4.51-3.76,4.51Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "419.96px 105.46px 0px",
        }}
        id="el62gcf18tsfi"
        className="animable"
      />
      <path
        d="M385,104.93s-.93-6.44-2.67-6-2.45,4.07-1.26,8.58,3.55,4.5,3.55,4.5Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "382.74px 105.459px 0px",
        }}
        id="elommmgxjvbt9"
        className="animable"
      />
      <path
        d="M403.07,103s5.18-.86,8.18,0"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "407.16px 102.809px 0px",
        }}
        id="elwdgwk4y7fwi"
        className="animable"
      />
      <path
        d="M394.68,102.57s-4.47-2.36-6.87-.86"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "391.245px 101.891px 0px",
        }}
        id="elzjm91a9swv"
        className="animable"
      />
      <path
        d="M408.56,107.5c0,1.19-.58,2.15-1.23,2.15s-1.16-1-1.14-2.15.58-2.14,1.24-2.14S408.59,106.32,408.56,107.5Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "407.375px 107.505px 0px",
        }}
        id="elbfy68tsszpp"
        className="animable"
      />
      <path
        d="M393.49,107.5c0,1.18-.57,2.14-1.23,2.14s-1.16-1-1.13-2.14.57-2.14,1.23-2.14S393.52,106.32,393.49,107.5Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "392.31px 107.5px 0px",
        }}
        id="elf2euh1zhi5i"
        className="animable"
      />
      <path
        d="M395.11,121.87a10.3,10.3,0,0,0,9.5-1.07"
        style={{
          fill: "none",
          stroke: "rgb(255, 255, 255)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "399.86px 121.684px 0px",
        }}
        id="eljexff3ihw"
        className="animable"
      />
      <polyline
        points="364.1 154.89 366.38 175.39 368.24 162.5"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "366.17px 165.14px 0px",
        }}
        id="el71jj0yz67hu"
        className="animable"
      />
      <path
        d="M429.85,161.41a20.12,20.12,0,0,0-1.54,4.62c-.48,3.7,2.72,18.34,2.72,18.34"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "429.646px 172.89px 0px",
        }}
        id="eljgolzsqwpd"
        className="animable"
      />
      <path
        d="M434.91,151.19s-1.1,2-2.4,4.57"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "433.71px 153.475px 0px",
        }}
        id="el6vlf4rqz5ol"
        className="animable"
      />
      <line
        x1={434.98}
        y1={187.28}
        x2={444.44}
        y2={180.28}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "439.71px 183.78px 0px",
        }}
        id="elcse8je1lqqs"
        className="animable"
      />
      <path
        d="M444.87,187.3l-38.95-1.37-22.05,13.66,56.34,12.31s11.21-2,14-4.88,3.94-10,4-13.27-2.09-11.91-2.09-11.91"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "421.041px 196.87px 0px",
        }}
        id="el50ewu945qik"
        className="animable"
      />
      <path
        d="M348.8,181.26l63.4-5.35,2.94,12.87-62.51,23.36s-9.09,2.15-13.06-3.43a20.18,20.18,0,0,1-3.73-15.87c1-4.5,4.94-9.43,4.94-9.43"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "375.318px 194.229px 0px",
        }}
        id="elffgx29fn225"
        className="animable"
      />
      <path
        d="M412.6,177.41s9.69-.21,12.44,1.93,9.94,7.94,9.94,7.94l-20.23-.62Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "423.79px 182.342px 0px",
        }}
        id="eloyq51dyd6t"
        className="animable"
      />
      <path
        d="M375.31,179.29s-12-5.08-13.8-4.88-8,6.24-8,6.24Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "364.41px 177.527px 0px",
        }}
        id="elof3yj09gj4m"
        className="animable"
      />
      <line
        x1={392.89}
        y1={283.73}
        x2={392.95}
        y2={290.03}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "392.92px 286.88px 0px",
        }}
        id="elpggo3hewu0s"
        className="animable"
      />
      <line
        x1={392.76}
        y1={205.06}
        x2={392.84}
        y2={278.64}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "392.8px 241.85px 0px",
        }}
        id="elmkh3n8b5tep"
        className="animable"
      />
      <path
        d="M434.38,248.77a13.89,13.89,0,0,0,3.07-1.16"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "435.915px 248.19px 0px",
        }}
        id="elauesr24x485"
        className="animable"
      />
      <path
        d="M410.45,246.24l.11,3.91s11.39.63,19.89-.6"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "420.45px 248.276px 0px",
        }}
        id="elaunznvwqb2"
        className="animable"
      />
      <path
        d="M414,245.07s13.5.59,23.16-1.75"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "425.58px 244.228px 0px",
        }}
        id="eld99ov6k6sw5"
        className="animable"
      />
      <path
        d="M362.28,245.07s7,4.49,19.33,2.93"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "371.945px 246.7px 0px",
        }}
        id="el02ti5w094vlp"
        className="animable"
      />
      <path
        d="M362.76,241.16s7.35,4.3,18.95,2.35"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "372.235px 242.589px 0px",
        }}
        id="elhrhbjfoonjv"
        className="animable"
      />
    </g>
    <g
      id="freepik--Heartbeats--inject-417"
      style={{
        transformOrigin: "184.365px 179.225px 0px",
      }}
      className="animable animator-active"
    >
      <g id="elb5a23e2s87w">
        <polyline
          points="27.25 135.01 39 135.01 45.19 117.39 51.07 146.77 56.63 107.49 62.2 139.34 67.77 124.5 73.02 133.78 84.16 133.78 88.8 83.98 94.98 169.96 100.86 117.08 105.81 147.07 111.68 133.78 253.64 133.78 258.9 155.43 265.08 113.98 270.03 142.75 275.6 119.55 281.48 133.78 291.06 133.78 297.25 142.44 303.13 112.75 308.38 168.72 314.26 88.93 319.52 142.13 325.7 110.89 330.65 133.47 341.48 133.47"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            opacity: 0.3,
            transformOrigin: "184.365px 126.97px 0px",
          }}
          className="animable"
        />
      </g>
      <g id="elqtyreug71">
        <polyline
          points="27.25 239.53 39 239.53 45.19 221.9 51.07 251.28 56.63 212 62.2 243.85 67.77 229.01 73.02 238.29 84.16 238.29 88.8 188.5 94.98 274.47 100.86 221.59 105.81 251.59 111.68 238.29 253.64 238.29 258.9 259.94 265.08 218.49 270.03 247.26 275.6 224.06 281.48 238.29 291.06 238.29 297.25 246.95 303.13 217.26 308.38 273.24 314.26 193.44 319.52 246.64 325.7 215.4 330.65 237.98 341.48 237.98"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            opacity: 0.3,
            transformOrigin: "184.365px 231.485px 0px",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Heart--inject-417"
      style={{
        transformOrigin: "175.039px 240.024px 0px",
      }}
      className="animable"
    >
      <path
        d="M72.87,331.32c-30,0-50.21-11.24-54.56-30.61-5-22.41,13.4-56.6,65.72-78.68,99.25-41.89,199.27-24.64,230.42,17.76A82.58,82.58,0,0,1,327,264.26l-8.14,2.5a74,74,0,0,0-11.3-21.93c-14.06-19.13-45.43-33-83.94-37-43.36-4.57-91.77,3.26-136.31,22.06-34.58,14.6-66.55,43-60.72,69,3.62,16.12,22.72,25,51.12,23.86,27.79-1.16,58.58-7.1,91.49-17.65l2.6,8.11c-33.64,10.78-65.18,16.85-93.74,18C76.32,331.29,74.58,331.32,72.87,331.32Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "172.261px 264.647px 0px",
        }}
        id="els7l0k9jjew"
        className="animable"
      />
      <path
        d="M104,286s8.47,25.4,32.32,41.56A228.18,228.18,0,0,0,226.61,363c34.37,5.39,50,3.34,57.72-7.44s19.24-34.12,5.64-84.14-18.21-46.69-18.21-46.69a43.61,43.61,0,0,0-12.32-22.58c-10.51-10.26-29.24-16.93-29.24-16.93s-7.95,9.49-24.63,9.49-36.17-6.93-48.49-6.41-28.47,16.93-40.79,56.18S104,286,104,286Z"
        style={{
          fill: "rgb(146, 227, 169)",
          transformOrigin: "200.134px 275.545px 0px",
        }}
        id="el6od0ydx5mx"
        className="animable"
      />
      <g id="eln1yp8uk1ilh">
        <path
          d="M261.5,332.15c1.27-3.17,1.9-5.7-1.9-12s-16.46-17.1-17.73-27.23-1.9-17.73,2.54-12,19.63,32.29,21.53,15.83-15.2-32.93-16.47-36.73,22.8,0,19.63-10.13-17.73-10.76-21.53-19.63-3.8-13.3-7.6-15.83c-2.85-1.9-31.07-16.32-48.58-21-2.71-.42-5.43-.91-8.11-1.42a7.66,7.66,0,0,0-4.49.83s7.36,15.25,12.43,19.68,19.63,4.43,18.36,11.4-.63,24.06,5.7,32.29,13.93-1.26,8.23,31,0,43.06,1.9,47.49,4.43,15.2-10.76,16.46c-9.42.79-10.33,4.49-8.75,7.49,6.77,1.76,13.69,3.22,20.71,4.32,26.84,4.21,42.26,3.88,51.4-1.59a7.79,7.79,0,0,0-1.31-4.52C271.63,348.61,260.24,335.31,261.5,332.15Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.58,
            transformOrigin: "228.401px 278.898px 0px",
          }}
          className="animable"
        />
      </g>
      <g id="eldlu2mez8iu">
        <path
          d="M143.09,215.64c-.81,7.33-17.36,24.63-29.06,36C104,284,104,286,104,286s8.47,25.4,32.32,41.56a222.11,222.11,0,0,0,41.89,22c-1.4-3.87-3.95-8.48-8.5-11.08-8.87-5.07-14.57-13.93-20.9-21.53s-10.13-5.7-15.2-11.4,0-22.79,8.24-25.33,7.59-10.13,8.86-18.36,10.81-28.45,17.73-32.93,9.5-20.26,9.5-20.26c2.27-7.37,1.17-14.26-2.89-18.31-6.72-1.29-12.93-2.32-17.95-2.11C148.28,188.72,144.36,204.24,143.09,215.64Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.58,
            transformOrigin: "141.551px 268.891px 0px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M104,286s8.47,25.4,32.32,41.56A228.18,228.18,0,0,0,226.61,363c34.37,5.39,50,3.34,57.72-7.44s19.24-34.12,5.64-84.14-18.21-46.69-18.21-46.69a43.61,43.61,0,0,0-12.32-22.58c-10.51-10.26-29.24-16.93-29.24-16.93s-7.95,9.49-24.63,9.49-36.17-6.93-48.49-6.41-28.47,16.93-40.79,56.18S104,286,104,286Z"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "200.134px 275.545px 0px",
        }}
        id="elx8y6mm3bijf"
        className="animable"
      />
      <path
        d="M208.68,209.85s8.17,9.43,16.74,18.26c4.64,4.78,12.56,25.49,9.51,39.95s-5.33,34.63.76,45.66,4.95,23.59,11.42,31.58,14.46,8.75,15.22,16.36"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "235.505px 285.755px 0px",
        }}
        id="elmb0uoixlc3o"
        className="animable"
      />
      <path
        d="M228.08,231s7.74,3.75,11.24,8a30.31,30.31,0,0,0,16.73,10.49c7.74,1.75,14.23,8,16,13.73"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "250.065px 247.11px 0px",
        }}
        id="elmvptnh979ip"
        className="animable"
      />
      <path
        d="M261.54,294.68c-.25-2.25-4-10-9.74-14.48s-6.49-10-8.49-13.23-7.74-3.75-7.74-3.75"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "248.555px 278.95px 0px",
        }}
        id="elehxqehc8u9f"
        className="animable"
      />
      <path
        d="M231.83,293.18s.75,3.5,10.24,9.24,11,13.23,13.23,17.48"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "243.565px 306.54px 0px",
        }}
        id="elr42xnlevct"
        className="animable"
      />
      <path
        d="M248.06,307.66s1.25,8,2.74,11.49"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "249.43px 313.405px 0px",
        }}
        id="elgjnxbzrk3t"
        className="animable"
      />
      <path
        d="M235.69,313.72s-2.11,1.18-2.61,15.91a56,56,0,0,0,6.49,28.22"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "236.307px 335.785px 0px",
        }}
        id="elb6la4qis8ep"
        className="animable"
      />
      <path
        d="M239.32,322.14a40.49,40.49,0,0,0,5.74,9.49c4,5,15.73,9.24,18,10.24"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "251.19px 332.005px 0px",
        }}
        id="el1jsdrns0b0i"
        className="animable"
      />
      <path
        d="M221.59,224.27s-1.5,3.75-2.24,12.49,3,12.23,3,12.23"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "220.802px 236.63px 0px",
        }}
        id="eld6bnettq82j"
        className="animable"
      />
      <path
        d="M167.05,190.7s3.88,13.87-1.67,32.73S139.87,258.37,131,268.35s-13.31,23.3-9.43,32.17,10.54,23.3,33.28,30"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "144.264px 260.61px 0px",
        }}
        id="elgu5bmpb1pmh"
        className="animable"
      />
      <path
        d="M161.5,233.41s2.77,3.33,0,17.75,3.33,24.4,3.33,24.4"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "162.804px 254.485px 0px",
        }}
        id="elr2o1nx8wykr"
        className="animable"
      />
      <path
        d="M123.23,280.56s-7.21,6.65-8.32,12.75S117.13,305,117.13,305"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "118.957px 292.78px 0px",
        }}
        id="el88y9slkzkm"
        className="animable"
      />
      <path
        d="M137.65,322.15s6.66,8.88,15,13.32"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "145.15px 328.81px 0px",
        }}
        id="el6mkftdhqa4o"
        className="animable"
      />
      <path
        d="M271.76,224.68a43.61,43.61,0,0,0-12.32-22.58c-10.51-10.26-29.24-16.93-29.24-16.93s-7.95,9.49-24.63,9.49c-1.94,0-3.92-.1-5.91-.26-2.81,4-4.11,8.42-2.3,12.83,0,0,1,3.34,5.9,5.13s8.72-2.56,14.37-.51,7.69,7.18,21,8,13.35.52,16.68,3.59S266.88,228.78,271.76,224.68Z"
        style={{
          fill: "rgb(196, 196, 196)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "234.143px 206.035px 0px",
        }}
        id="elf5gqfmtzxn9"
        className="animable"
      />
      <path
        d="M109.11,198.24s-7.7,19.51-7.7,27.46-3.07,43.1-1.79,54.65,11.54,6.67,18-.52,8.21-14.88,20.26-26.16S154.78,238,159.65,227.5s8.07-18.75,5.83-24.77-15.07-17.82-27.12-19.87S112.7,185.4,109.11,198.24Z"
        style={{
          fill: "rgb(196, 196, 196)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "132.758px 234.825px 0px",
        }}
        id="elp157kwqc0gd"
        className="animable"
      />
      <path
        d="M165.48,202.73s-11.2,2.09-16.65-2.22-12.94-7.72-14.53-17.71-3.17-36.77,3.86-49.48,16.12-18.16,16.12-18.16l-13.39-15.11a6.51,6.51,0,0,1,2.27-10.35l.2-.08a6.5,6.5,0,0,1,6.89,1.57L162,103.1a3.67,3.67,0,0,0,5.32-.1h0a3.67,3.67,0,0,0,.1-4.82l-11-13.11a5.68,5.68,0,0,1,3.08-9.2l.22,0a6.8,6.8,0,0,1,6.41,2.32l13.6,15.76a2.8,2.8,0,0,0,3.5.6h0a2.8,2.8,0,0,0,1.42-2.77l-2.34-20.33a5.9,5.9,0,0,1,7-6.51l.23,0a5.93,5.93,0,0,1,4.27,4.59l4.86,24.51s10.89.23,18.84,27.47,1.59,42.9.68,47.21-30,17.48-39.5,24.06A155.55,155.55,0,0,0,165.48,202.73Z"
        style={{
          fill: "rgb(146, 227, 169)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "177.198px 134.032px 0px",
        }}
        id="elu2b9susjxnq"
        className="animable"
      />
      <path
        d="M119.77,110.92a7.64,7.64,0,0,0-4,6.62,126.53,126.53,0,0,1-3.91,26.68,134.3,134.3,0,0,0-3.4,40.17c.23,5,.68,13.85.68,13.85s9.08-7.72,14.3-11.12,12.15,1.41,12.15,1.41S133.4,172.14,136.8,164s19.07-17.47,25.2-19.52,9.08-5.44,7.26-12.25-6.13-8-8.85-7.27-7.72,1.82-15.21,3-8.17-2.27-8.63-7.71c-.12-1.48-.19-2.84-.23-4A7.67,7.67,0,0,0,129,108.7,18.21,18.21,0,0,0,119.77,110.92Z"
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "138.979px 153.467px 0px",
        }}
        id="elfuxzljvknyo"
        className="animable"
      />
      <g id="elu4qdh3guj9">
        <ellipse
          cx={163.81}
          cy={133.78}
          rx={4.54}
          ry={7.94}
          style={{
            fill: "rgb(69, 69, 69)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "163.81px 133.78px 0px",
            transform: "rotate(-10.81deg)",
          }}
          className="animable"
        />
      </g>
      <line
        x1={162.01}
        y1={103.1}
        x2={169.4}
        y2={110.52}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "165.705px 106.81px 0px",
        }}
        id="elhn2nxeaq3qe"
        className="animable"
      />
      <line
        x1={179.79}
        y1={93.91}
        x2={185.05}
        y2={100.25}
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "182.42px 97.08px 0px",
        }}
        id="el85304wdfd88"
        className="animable"
      />
      <path
        d="M165.48,202.73S168,169.55,187.73,152s55.27-23.31,59.66-23.38c1.46,0,3.79,4.42,3.79,11.35s5.61,15.72,2.33,17.18-20.56,3.6-26,7.25-1.46,3.65,1.82,12,3.65,10.57-1.46,16.77-22.25,15.32-35,13.5-14.59-9.12-19-7.3S165.48,202.73,165.48,202.73Z"
        style={{
          fill: "rgb(153, 153, 153)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "210.009px 167.763px 0px",
        }}
        id="el4r3ucjcocic"
        className="animable"
      />
      <path
        d="M255.09,141.93c1.69,7.88,1,14.71-1.58,15.26s-6-5.39-7.69-13.27-1-14.71,1.57-15.26S253.4,134.05,255.09,141.93Z"
        style={{
          fill: "rgb(69, 69, 69)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "250.455px 142.925px 0px",
        }}
        id="el0jdwug9c84z9"
        className="animable"
      />
      <path
        d="M213.6,182.62a35.74,35.74,0,0,0-1.63,5.31"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "212.785px 185.275px 0px",
        }}
        id="eloqwyemo4psg"
        className="animable"
      />
      <path
        d="M227.48,164.44a43.74,43.74,0,0,0-11.68,13.5"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "221.64px 171.19px 0px",
        }}
        id="el9dkyaafdg5"
        className="animable"
      />
      <path
        d="M195.17,136.47a33.67,33.67,0,0,1,2.94-3.45"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "196.64px 134.745px 0px",
        }}
        id="elyf9dx88hxv"
        className="animable"
      />
      <path
        d="M182.58,156.87s4.44-8.37,9.2-15.59"
        style={{
          fill: "none",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "187.18px 149.075px 0px",
        }}
        id="eluo6vyigqzr"
        className="animable"
      />
      <path
        d="M220.12,410.12c-14.68,0-24.44-2.92-29.14-8.72-3.32-4.11-4-9.57-1.82-15.38,2.31-6.23,11.1-15.83,26.12-28.53,11-9.28,21.45-17,21.56-17l3.59,4.9c-11.34,8.29-41.63,32.14-45.57,42.77-1.41,3.82-1.13,7,.85,9.45,3.1,3.83,13.49,9.52,48.21,4.48l.87,6A174,174,0,0,1,220.12,410.12Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "216.346px 375.305px 0px",
        }}
        id="el6huv74qnq37"
        className="animable"
      />
      <path
        d="M222.11,395.07c-3.85,0-7.71-.15-11.54-.47-29.06-2.42-43.48-13.41-44.09-33.6l6.08-.19c.34,11.45,5,24.94,38.51,27.74s70.86-8.76,71.23-8.88l1.81,5.81C282.71,385.92,253,395.07,222.11,395.07Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "225.295px 377.94px 0px",
        }}
        id="eljckgu92byxb"
        className="animable"
      />
      <path
        d="M240.67,415.22a8.51,8.51,0,0,1-1.4-16.91c.07,0,7.1-1.2,14.37-3.11A8.51,8.51,0,0,1,258,411.67c-8.05,2.12-15.58,3.38-15.89,3.43A8.57,8.57,0,0,1,240.67,415.22Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "248.232px 405.067px 0px",
        }}
        id="elo86jpzuzfg"
        className="animable"
      />
      <path
        d="M170.34,374.33a8.51,8.51,0,0,1-7.75-5c-2.73-6-2.81-13.33-2.81-14.15a8.52,8.52,0,0,1,17,0,21.6,21.6,0,0,0,1.28,7.14,8.53,8.53,0,0,1-7.75,12Zm6.47-19.15h0Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "169.289px 360.787px 0px",
        }}
        id="elda9tchv4cxl"
        className="animable"
      />
      <path
        d="M322.52,321.61c5.28-9.08,15.14-31.05,6.83-58.07a6.69,6.69,0,0,0-12.79,3.94,59.48,59.48,0,0,1-5.12,46.59c-2.35-.7-5.38-1.51-8.86-2.24-8.73-1.84-15.22-1.91-28.51,2.67-12.45,4.3-42.17,25.88-45.52,28.33a6.69,6.69,0,0,0,7.89,10.8c8.65-6.32,33.05-23.4,42-26.48,10.94-3.77,15.1-3.55,21.39-2.23a93.74,93.74,0,0,1,10.59,2.86,33.33,33.33,0,0,1,7.08,15.34c2.65,17-25.34,27.65-40.37,33.35l-2.86,1.09a6.69,6.69,0,0,0,2.41,12.94,6.79,6.79,0,0,0,2.4-.45l2.79-1.07c18.58-7,53.16-20.15,48.85-47.91A46.07,46.07,0,0,0,322.52,321.61Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "279.254px 324.658px 0px",
        }}
        id="elj1kud86q5ig"
        className="animable"
      />
      <path
        d="M93.93,321.53l1.53,8.39c23.81-2.5,49.41-8.11,76.37-16.75l-2.6-8.11C142.58,313.61,117.33,319.12,93.93,321.53Z"
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "132.88px 317.49px 0px",
        }}
        id="elvx9mhdhmh4q"
        className="animable"
      />
      <g id="elf6q2kwtuyhm">
        <circle
          cx={238.02}
          cy={281.36}
          r={35.78}
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "238.02px 281.36px 0px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eluu6qpona3v">
        <circle
          cx={241.29}
          cy={281.36}
          r={35.78}
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "241.29px 281.36px 0px",
            transform: "rotate(-45deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M172.39,314.91l-3.71-11.58a458.24,458.24,0,0,0,62.67-25.12L236.92,289C235.83,289.58,209.81,302.91,172.39,314.91Z"
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "202.8px 296.56px 0px",
        }}
        id="eliukinhs8jab"
        className="animable"
      />
      <circle
        cx={238.02}
        cy={281.36}
        r={14.49}
        style={{
          fill: "rgb(38, 50, 56)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "238.02px 281.36px 0px",
        }}
        id="el1t4dg20hknh"
        className="animable"
      />
      <circle
        cx={240.47}
        cy={281.36}
        r={14.49}
        style={{
          fill: "rgb(255, 255, 255)",
          stroke: "rgb(38, 50, 56)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "240.47px 281.36px 0px",
        }}
        id="elt2ufloxzaes"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
)
export default AssignedDevicesCountAnimated

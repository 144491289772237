import * as React from "react"
import { SVGProps } from "react"

type BrokenVideoAddProps = SVGProps<SVGSVGElement> & {
  heightAndWidth?: number;
};

const BrokenVideoAdd = ({ heightAndWidth = 30, ...props }: BrokenVideoAddProps) => (
  <svg
    width={heightAndWidth}
    height={heightAndWidth}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.332 15.8333L29.4293 15.2847C32.6724 13.6631 34.294 12.8523 35.4797 13.5851C36.6654 14.3179 36.6654 16.1309 36.6654 19.7568V20.2432C36.6654 23.8691 36.6654 25.6821 35.4797 26.4149C34.294 27.1477 32.6724 26.3369 29.4293 24.7153L28.332 24.1667V15.8333Z"
      stroke="#2E57FF"
      strokeWidth={1.5}
    />
    <path
      d="M11.668 19.9997H15.8346M15.8346 19.9997H20.0013M15.8346 19.9997V24.1663M15.8346 19.9997V15.833"
      stroke="#2E57FF"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M3.33203 19.167V20.8337C3.33203 26.3128 3.33203 29.0524 4.84529 30.8963C5.12232 31.2338 5.43185 31.5434 5.76941 31.8204C7.61333 33.3337 10.3529 33.3337 15.832 33.3337C21.3112 33.3337 24.0507 33.3337 25.8947 31.8204C26.2322 31.5434 26.5417 31.2338 26.8188 30.8963C28.332 29.0524 28.332 26.3128 28.332 20.8337V19.167C28.332 13.6879 28.332 10.9483 26.8188 9.10437C26.5417 8.76681 26.2322 8.45728 25.8947 8.18026C24.0507 6.66699 21.3112 6.66699 15.832 6.66699C10.3529 6.66699 7.61333 6.66699 5.76941 8.18026C5.43185 8.45728 5.12232 8.76681 4.84529 9.10437C4.13226 9.9732 3.7552 11.0409 3.55581 12.5003"
      stroke="#2E57FF"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
)
export default BrokenVideoAdd

import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {PatientAttachmentService} from "../../../services/requests/ehr/patientAttachment";

const useGetPatientAttachments = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getPatientAttachments", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PatientAttachmentService.getPatientAttachmentsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetPatientAttachment = () => {
  return useMutation({
    mutationFn: PatientAttachmentService.getPatientAttachment,
  })
}

const useAddPatientAttachment = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: PatientAttachmentService.addPatientAttachment,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientAttachments"] })
          onSuccess();
        }
      })
    },
  })
}

const useDeletePatientAttachment = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PatientAttachmentService.deletePatientAttachment,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientAttachments"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetPatientAttachments,
  useGetPatientAttachment,
  useAddPatientAttachment,
  useDeletePatientAttachment
}

import * as yup from "yup";

const addPatientBiometricSchema = yup.object({
  observationDateTime: yup
    .date()
    .required("این فیلد الزامیست."),
});

const atLeastOneFieldRequiredBiometricSchema = yup.object().shape({
  height: yup.string().nullable(),
  weight: yup.string().nullable(),
  muac: yup.string().nullable(),
}).test('at-least-one-required', 'At least one field are required', (values) => {
  const { height, weight, muac} = values,
    requiredFields = [height, weight, muac].filter(Boolean);
  return requiredFields.length > 0;
});

const addPatientVitalSchema = yup.object({
  observationDateTime: yup
    .date()
    .required("این فیلد الزامیست."),
});

const atLeastOneFieldRequiredVitalSchema = yup.object().shape({
  bpSystolic: yup.string().nullable(),
  bpDiastolic: yup.string().nullable(),
  pulseRate: yup.string().nullable(),
  respiratoryRate: yup.string().nullable(),
  spo2: yup.string().nullable(),
  temperature: yup.string().nullable(),
}).test('at-least-one-required', 'At least one field are required', (values) => {
  const { bpSystolic, bpDiastolic, pulseRate, respiratoryRate, spo2, temperature } = values,
    requiredFields = [bpSystolic, bpDiastolic, pulseRate, respiratoryRate, spo2, temperature].filter(Boolean);
  return requiredFields.length > 0;
});

const addPatientVaccineSchema = yup.object({
  vaccine: yup
    .string()
    .required("این فیلد الزامیست."),
  vaccinationDateTime: yup
    .date()
    .required("این فیلد الزامیست."),
});

const addPatientConditionSchema = yup.object({
  condition: yup
    .string()
    .required("این فیلد الزامیست."),
  startDateTime: yup
    .date()
    .required("این فیلد الزامیست."),
});

const addPatientAllergySchema = yup.object({
  allergy: yup
    .string()
    .required("این فیلد الزامیست."),
  startDate: yup
    .date()
    .required("این فیلد الزامیست."),
});

const addPatientAttachmentSchema = yup.object({
  name: yup
    .string()
    .required("این فیلد الزامیست."),
});

const addPatientProgramSchema = yup.object({
  program: yup
    .string()
    .required("این فیلد الزامیست."),
  startDateTime: yup
    .date()
    .required("این فیلد الزامیست."),
});

const patientMisuseSchema = yup.object({
  substance: yup.string().required("این فیلد الزامیست."),
});

const patientFamilyHistorySchema = yup.object({
  relation: yup.string().required("این فیلد الزامیست."),
  icd11: yup.string().required("این فیلد الزامیست."),
  deceased: yup.string().required("این فیلد الزامیست."),
});

const patientCigaretteSchema = yup.object({
  frequency: yup
    .string()
    .required("این فیلد الزامیست."),
});

const patientSurgeryHistorySchema = yup.object({
  surgeryName: yup.string().required("این فیلد الزامیست."),
});


export {
  addPatientBiometricSchema,
  atLeastOneFieldRequiredBiometricSchema,
  addPatientVitalSchema,
  addPatientVaccineSchema,
  addPatientConditionSchema,
  addPatientAllergySchema,
  addPatientAttachmentSchema,
  addPatientProgramSchema,
  atLeastOneFieldRequiredVitalSchema,
  patientMisuseSchema,
  patientFamilyHistorySchema,
  patientCigaretteSchema,
  patientSurgeryHistorySchema
};

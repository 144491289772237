import * as yup from "yup";

const addDeviceSchema = yup.object({
  deviceCode: yup
    .string()
    .required("این فیلد الزامیست."),
  deviceType: yup
    .string()
    .required("این فیلد الزامیست."),
  imei: yup
    .string()
    .required("این فیلد الزامیست."),
});

const assignDeviceSchema = yup.object({
  healthUnit: yup
    .string()
    .required("این فیلد الزامیست."),
  account: yup
    .string()
    .required("این فیلد الزامیست."),
});

const approveDeviceSchema = yup.object({
  deviceCode: yup
    .string()
    .required("این فیلد الزامیست."),
  deviceType: yup
    .string()
    .required("این فیلد الزامیست."),
});

export {
  addDeviceSchema,
  assignDeviceSchema,
  approveDeviceSchema,
};

import {API_PATH} from "../../constants";
import {axiosRequest} from "../api";


export const SystemReportService = {
  getInsuranceStabilityPercentage(data: any){ return axiosRequest('POST', API_PATH.REPORT.STABILITY_PERCENTAGE, data) },
  getInsuranceResponseTimePercentage(data: any){ return axiosRequest('POST', API_PATH.REPORT.RESPONSE_TIME_PERCENTAGE, data) },
  getDashboardStatistics(data: any){ return axiosRequest('POST', API_PATH.REPORT.STATISTICS, data) },
  getDashboardProviderStatistics(data: any){ return axiosRequest('POST', API_PATH.REPORT.STATISTICS_PROVIDER, data) },
  getTotalVisitCountChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.TOTAL_VISIT_COUNT_CHART, data) },
  getVisitStatusChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.VISIT_STATUS_CHART, data) },
  getVisitIssuerChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.VISIT_ISSUER_CHART, data) },
  getVisitIssuerDailyChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.VISIT_ISSUER_DAILY_CHART, data) },
  getVisitGenderChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.VISIT_GENDER_CHART, data) },
  getVisitAgeChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.VISIT_AGE_CHART, data) },
  getPrescriptionIssuerDailyChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.PRESCRIPTION_ISSUER_DAILY_CHART, data) },
  getVisitDailyChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.VISIT_DAILY_CHART, data) },
  getTotalPatientCountChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.TOTAL_PATIENT_COUNT_CHART, data) },
  getPatientGenderChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.PATIENT_GENDER_CHART, data) },
  getPatientAgeChart(data: any){ return axiosRequest('POST', API_PATH.REPORT.PATIENT_AGE_CHART, data) },
}
import * as yup from "yup";

const addScheduleSchema = yup.object({
  provider: yup
    .string()
    .required("این فیلد الزامیست."),
  name: yup
    .string()
    .required("این فیلد الزامیست."),
  fromDate: yup
    .string()
    .required("این فیلد الزامیست."),
});

const editScheduleSchema = yup.object({
  name: yup
    .string()
    .required("این فیلد الزامیست."),
  fromDate: yup
    .string()
    .required("این فیلد الزامیست."),
});

const addItemToScheduleSchema = yup.object({
  healthUnit: yup
    .string()
    .required("این فیلد الزامیست."),
  visitType: yup
    .string()
    .required("این فیلد الزامیست."),
  dayOfWeek: yup
    .string()
    .required("این فیلد الزامیست."),
  fromTime: yup
    .string()
    .required("این فیلد الزامیست."),
  toTime: yup
    .string()
    .required("این فیلد الزامیست."),
  capacity: yup
    .string()
    .required("این فیلد الزامیست."),
});

const closeScheduleSchema = yup.object({
  toDate: yup
    .string()
    .required("این فیلد الزامیست."),
});

export {
  addScheduleSchema,
  editScheduleSchema,
  addItemToScheduleSchema,
  closeScheduleSchema
};
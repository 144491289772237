import React, {useMemo} from "react";
import { useEffect, useState } from "react";
import {connect, useSelector} from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { Dispatch } from "redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import {useAddSurgery, useGetListSurgery} from "../../../../../../hook/request/ehr/surgery";
import {useGetProviderInsurer} from "../../../../../../hook/request/setting";
import Modal from "../../../../general/modal";
import BoxLoader from "../../../../general/boxLoader";
import Icon from "../../../../general/icon";
import SelectField from "../../../../general/form/selectField";
import DatePickerField from "../../../../general/form/datePickerField";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../../store/selector/general";
import {general} from "../../../../../../store/action";
import {patientSurgeryHistorySchema} from "../../../../../../schema/patient-schema";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

// کامپوننت مربوط مودال اتصال بیمه که پزشک می تواند با
// اطلاعات که وارد می کند سابقه جراحی راثبت کند
// Parent:PatientSummary

type ModalInfoProps = {
  id: number;
  patientId: number;
  handleGetPatientSummary: any;
}

type IModalInfo = {
  surgeryName: string;
  surgeryDate: any;
}

type AddPatientSurgeryModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

const AddPatientSurgeryModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPatientSurgeryModalProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>();
  const { getQueryParams } = useMySearchParams();
  const fieldValueInitialState = {
    surgeryDate: "",
    surgeryName: "",
  }
  const [fieldValue, setFieldValue] = useState<IModalInfo>(fieldValueInitialState);
  const [surgeryListOptions, setSurgeryOptions] = useState([]);

  const requestGetListSurgery = useGetListSurgery(
    getQueryParams({
      pageNumber: 0,
      perPage: 10,
      search: "",
    }),
    modalName === 'showAddSurgery'
  );

  const AddSurgery = useAddSurgery({
    onSuccess: () => {
      handleSetShowModal();
      modalInfo?.handleGetPatientSummary({id: modalInfo?.patientId});
    },
  })

  useEffect(() => {
    if (requestGetListSurgery?.data?.data.result) {
      const { data } = requestGetListSurgery?.data?.data.result;
      const _data = data.map((item: any) => {
        return { label: item.faName, value: item.id };
      });

      setSurgeryOptions(_data);
    }
  }, [requestGetListSurgery?.data?.data.result]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IModalInfo>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    // @ts-ignore
    resolver: yupResolver(patientSurgeryHistorySchema),
  });

  useEffect(() => {
    if (modalName === "showAddSurgery" && { isShowModalData }) {
      setShowModal(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalName, modalData]);

  // services
  const providerInsurer = useGetProviderInsurer({
    onSuccess: () => {
      handleSetShowModal();
    },
  });

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);
  
  const addPatientSurgeryModal: SubmitHandler<IModalInfo> = (data) => {
    const {surgeryDate, surgeryName} = data
     const  body = {
        id: 0,
        patient: {
          id: modalInfo?.patientId
        },
        surgery:{
          id: surgeryName
        },
        surgeryDate
      };
    AddSurgery.mutate(body)
  };

  const handleSetShowModal = () => {
    setShowModal(false);
    handleHideModal();
    setFieldValue(fieldValueInitialState)
  };

  // function async for serach user react-select
  // const loadOptions = async (inputValue: string) => {
  //   console.log(inputValue);

  //   if (inputValue?.length > 0) {
  //     searchParamsSurgeryList({
  //       ...searchParamsSurgeryList,
  //       search: inputValue,
  //     });
  //   }

  //   return surgeryListOptions.map((item: any) => {
  //     return {
  //       label: item.label ? item.label : item.faName,
  //       value: item.id,
  //     };
  //   });
  // };

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showModal}
      noCloseBtn={true}
      className="w-1000  mw-100"
      bodyClassName="py-3 px-3 px-lg-5 pos-rel"
    >
      {/* loading */}
      {providerInsurer?.isPending && <BoxLoader type="cover" />}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className="d-flex mb-4 fs-3 px-2">
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {/* اتصال {modalInfo?.name} */}
              فرم افزودن سابقۀ جراحی
            </h4>
          </div>
          <span
            className="text-red-hover align-content-center mt-2"
            onClick={() => handleSetShowModal()}
          >
            <Icon name="close-square" />
          </span>
        </div>
        <form onSubmit={handleSubmit(addPatientSurgeryModal)}>
          <div className="row">
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <SelectField
                  name="surgeryName"
                  placeholder="نام جراحی"
                  control={control}
                  // send params search
                  // searchParams={searchParamsSurgeryList}
                  // setSearchParams={setSearchParamsSurgeryList}
                  error={errors}
                  options={surgeryListOptions}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <DatePickerField
                  name="surgeryDate"
                  placeholder="تاریخ شروع"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-0 mr-auto mt-auto pb-1">
                {
                  (isProvider || findAccessInAccessList('EHR_SURGERY_CREATE')) &&
                  <button
                    type="submit"
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    افزودن
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

// get states
const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

// call actions
const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) =>
    dispatch(general.handleShowModal(data, body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPatientSurgeryModal);

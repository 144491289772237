import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useMySearchParams} from "../../../../../../../hook/useMySearchParams";
import Modal from "../../../../../general/modal";
import BoxLoader from "../../../../../general/boxLoader";
import SearchBox from "../../../../../general/searchBox";
import Icon from "../../../../../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../../../store/selector/general";
import {general} from "../../../../../../../store/action";
import Pagination from "../../../../../general/table/pagination";
import {useWindowSize} from "../../../../../../../hook/useWindowSize";
import {
  useGetDraftPrescriptionParaclinicalItemList
} from "../../../../../../../hook/request/visit/draftPrescriptionParaclinicalItem";
import FavoriteParaclinicalItemRowResponsive from "./dataList/favoriteParaclinicalItemRowResponsive";
import FavoriteParaclinicalItemRow from "./dataList/favoriteParaclinicalItemRow";
import EmptyBox from "../../../../../general/emptyBox";

type FavoriteParaclinicalItemModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type ModalInfoProps = {
  id: string;
  visitId: string;
  patientData?: any;
  index?: number;
  item?: any;
  handleParaclinicalModalSubmit: any;
  fieldValue: any;
}

const FavoriteParaclinicalItemModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: FavoriteParaclinicalItemModalProps) => {

  const [width] = useWindowSize();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    visitId: '',
    patientData: '',
    handleParaclinicalModalSubmit: null,
    fieldValue: null
  });
  const filterValuesInitialState = {
    pageNumber: "0",
    perPage: "10",
    search: '',
  }
  const { getQueryParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>(filterValuesInitialState);
  const [fieldValue, setFieldValue] = useState([]);
  const [favoriteParaclinicalItemsList, setFavoriteParaclinicalItemsList] = useState([]);

  // services
  const getDraftPrescriptionParaclinicalItemListRequest = useGetDraftPrescriptionParaclinicalItemList(
    getQueryParams(filterValues),modalName === 'favoriteParaclinicalItemModal');

  useEffect(() => {
    showState && getDraftPrescriptionParaclinicalItemListRequest.refetch();
  }, [filterValues])

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'favoriteParaclinicalItemModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  useEffect(() => {
    if (getDraftPrescriptionParaclinicalItemListRequest?.data?.data?.result) {
      const { data } = getDraftPrescriptionParaclinicalItemListRequest.data.data.result
      setFavoriteParaclinicalItemsList(data);
    }
  }, [getDraftPrescriptionParaclinicalItemListRequest.data])


  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue([])
    setFilterValues(filterValuesInitialState);
    handleHideModal();
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    let _filterValues = {
      ...filterValues,
      pageNumber: "0",
      perPage: filterValues?.perPage,
      search: value
    }
    setFilterValues(_filterValues)
  }

  const addPrescriptionParaclinicalItems = () => {
    handleSetShowModal();
    modalInfo.handleParaclinicalModalSubmit(fieldValue, modalInfo?.index ?? -1)
  }

  const pushFavoritePrescriptionParaclinical = (item: any, checked: boolean) => {
    setFieldValue((prev: any) => {
      if (checked) {
        // Add the item if it is not already in the list
        if (!prev.find((i: any) => i.id === item.id)) {
          return [...prev, item];
        }
      } else {
        // Remove the item if it exists in the list
        return prev.filter((i: any) => i.id !== item.id);
      }
      return prev;
    })
  }

  const doChangePage = (param: any) => {
    // updateSearchParams({
    //   pageNumber: param?.index,
    //   perPage: filterValues?.perPage,
    // })
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
      fullscreen={width <= 900 ? true : 'false'}
    >
      {getDraftPrescriptionParaclinicalItemListRequest?.isLoading && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              لیست علاقه‌مندی خدمات پاراکلینیک
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <div className="d-flex px-2">
          <div className="form-group ms-md-auto mb-4 w-100">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="نام خدمت"
            />
          </div>
        </div>
        <section className="table-cont table-responsive">
          {
            width < 900 ?
              favoriteParaclinicalItemsList.length > 0 ?
                favoriteParaclinicalItemsList.map((item: any, key: number) => {
                  return (
                    <FavoriteParaclinicalItemRowResponsive
                      key={key}
                      item={item}
                      pushFavoriteParaclinicalItem={pushFavoritePrescriptionParaclinical}
                    />
                  )
                }) : <EmptyBox/>
              :
              <>
                <div className="px-2">
                  <div className="ps-4">
                    <div className="d-flex align-items-center col-12 w-100 fw-bold ltr">
                      <div className="col-1 text-center ms-3"></div>
                      <div className="col-2 text-center">کد خدمت</div>
                      <div className="col-2 text-center">نوع خدمت</div>
                      <div className="col-4 text-center">نام خدمت</div>
                      <div className="col-1 text-center">تعداد</div>
                      <div className="col-2 text-center">اورژانسی</div>
                    </div>
                  </div>
                </div>
                {
                  favoriteParaclinicalItemsList.length > 0 ?
                    favoriteParaclinicalItemsList.map((item: any, key: number) => {
                      return (
                        <FavoriteParaclinicalItemRow
                          key={key}
                          item={item}
                          pushFavoriteParaclinicalItem={pushFavoritePrescriptionParaclinical}
                        />
                      )
                    }) : <EmptyBox/>
                }
              </>
          }
          <Pagination
            paginationData={{...filterValues, ...favoriteParaclinicalItemsList}}
            doChangePage={doChangePage}
          />
        </section>
        <div className="col-12 px-4 d-flex justify-content-end mt-2">
          <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
            <button
              type='button'
              className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
              onClick={() => handleSetShowModal()}
            >
              انصراف
            </button>
            <button
              type='submit'
              className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
              onClick={addPrescriptionParaclinicalItems}
            >
              {"افزودن به نسخه"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteParaclinicalItemModal);
import React, { useState } from 'react';
import Icon from '../icon';
import { Controller } from 'react-hook-form';

type PasswordFieldProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value?: string;
  placeholder: string;
  className?: string;
  error: any;
  control?: any;
}

const PasswordField = ({
  onChange,
  name,
  value,
  placeholder,
  className = '',
  error,
  control
}: PasswordFieldProps) => {
  const [showPass, setShowPass] = useState(false);
  let showError = error?.[name] && error?.[name]?.message;
  return(
    <>
      <div className={`inp-box ${showError ? 'invalid-field' : ''}`}>
        <span className="inp-icon"><Icon name="lock" /></span>
        {
          control &&
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => (
                <input
                  className={`form-control ps-5 ${className}`}
                  type={showPass ? 'text' : 'password'}
                  value={value}
                  name={name}
                  onChange={onChange}
                  placeholder={placeholder}
                />
              )}
            />
        }
        <span onClick={() => setShowPass(!showPass)} className="inp-icon left-mode font-16 cur-pointer">
          { showPass ? <Icon name="hide" /> : <Icon name="show" /> }
        </span>
      </div>
      {showError && <p className="text-danger font-10 my-1 me-2">{error?.[name]?.message}</p>}
    </>
  )
}

export default PasswordField;
import { useEffect, useMemo, useState } from "react";
import { ROUTES } from "../../../constants";
import BoxLoader from "../../components/general/boxLoader";
import NumberField from "../../components/general/form/numberField";
import SelectField from "../../components/general/form/selectField";
import TextField from "../../components/general/form/textField";
import {useNavigate, useSearchParams} from "react-router-dom";
import ImageUploaderBox from "../../components/utility/imageUploader/imageUploaderBox";
import { useGetDoctor, useGetSpecialitiesList, useUpdateDoctorProfile } from "../../../hook/request/doctors";
import { TAddDoctorFields } from "../../../types/doctor";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editDoctorSchema } from "../../../schema/doctor-schema";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import { localStorageGet } from "../../../utils/localStorage";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const DoctorProfile = () => {

  const navigate = useNavigate();
  const { getQueryParams } = useMySearchParams();
  const [searchParams] = useSearchParams();
  const [specialtiesFilterValues, setSpecialtiesFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [fieldValue, setFieldValue] = useState<TAddDoctorFields>({
    firstName: '',
    lastName: '',
    specialty: '',
    medId: '',
    nationalCode: '',
    mobile: '',
    password: ''
  });
  const [avatarData, setAvatarData] = useState({
    avatar: {
      id: 0,
      imageUrl: "",
      imageData: {
        name: ""
      }
    }
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddDoctorFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(editDoctorSchema),
  });
  const [specialitiesList, setSpecialitiesList] = useState([]);

  // services
  const getSpecialitiesListRequest = useGetSpecialitiesList(getQueryParams(specialtiesFilterValues));
  const getDoctorRequest = useGetDoctor();
  const updateDoctorProfileRequest = useUpdateDoctorProfile();

  useEffect(() => {
    let _SpecialtiesFilterValues = {};
    _SpecialtiesFilterValues = {
      ...specialtiesFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setSpecialtiesFilterValues(_SpecialtiesFilterValues)
  }, [searchParams]);

  useEffect(() => {
    const _userData = localStorageGet('USER_DATA'),
      id = _userData?.providerId;
    getDoctorRequest.mutate({id});
  }, [])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getDoctorRequest?.data?.data?.result) {
      const { avatar, firstName, lastName, specialty, medId, nationalCode, mobile } = getDoctorRequest.data.data.result,
        _fieldValue = {
          firstName, lastName, medId, nationalCode, mobile,
          specialty: specialty?.id,
          // specialty: { label: specialty?.name, value: specialty?.id },
        };
      setFieldValue(_fieldValue);
      setAvatarData({
        avatar: {
          id: avatar?.id,
          imageUrl: avatar?.name,
          imageData: {
            name: ""
          }
        }
      })
    }
  }, [getDoctorRequest.data])

  useEffect(() => {
    if (getSpecialitiesListRequest?.data?.data?.result) {
      const { data } = getSpecialitiesListRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id }
        ))
      setSpecialitiesList(_data);
    }
  }, [getSpecialitiesListRequest.data])

  const updateDoctorProfileSubmit: SubmitHandler<TAddDoctorFields> = (data) => {
    const { firstName, lastName, specialty, medId, nationalCode, mobile, password } = data,
      { avatar } = avatarData,
      body = {
        firstName, lastName, medId, nationalCode, mobile, password,
        specialty: {
          id: specialty,
          name: ""
        },
        avatar: avatar?.id ? {
            id: avatar?.id,
            name: avatar?.imageData?.name
          } : null
      };
    updateDoctorProfileRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getSpecialitiesListRequest?.isPending ||
          getDoctorRequest?.isPending ||
          updateDoctorProfileRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(updateDoctorProfileSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">افزودن تصویر نمایه</h4>
            </div>
            <div className="col-12 px-2 px-lg-4">
              <div className="form-group mb-5">
                <ImageUploaderBox
                  setFieldValue={setAvatarData}
                  fieldValue={avatarData}
                  name='avatar'
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات پزشک</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="firstName">نام</label>
                <TextField
                  name="firstName"
                  placeholder="نام"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="lastName">نام خانوادگی</label>
                <TextField
                  name="lastName"
                  placeholder="نام خانوادگی"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="specialty">تخصص</label>
                <SelectField
                  options={specialitiesList}
                  name="specialty"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="medId">‌شماره نظام پزشکی</label>
                <TextField
                  name="medId"
                  placeholder="شماره نظام پزشکی"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="nationalCode">‌کد ملی</label>
                <NumberField
                  name="nationalCode"
                  format='##########'
                  className="ltr plc-right"
                  placeholder='کدملی'
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={1}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="mobile">‌شماره همراه</label>
                <NumberField
                  name="mobile"
                  format='##########'
                  className="ltr plc-right"
                  placeholder="شماره همراه"
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={11}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-4 pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.ACCOUNT?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  ویرایش
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DoctorProfile;
import { axiosRequest } from '../api';
import { API_PATH } from '../../constants';

export const SettingService = {
    getProviderInsurer(data:any){
        return axiosRequest('POST',API_PATH.SETTING.GET_PROVIDER_INSURER,data)
    },
    AddProviderInsurerSave(data:any){
        return axiosRequest('POST',API_PATH.SETTING.SAVE_PROVIDER_INSURER,data)
    }
//   getLoginHistoryList(params){ return axiosRequest('GET', API_PATH.ACCOUNT.LOGIN_HISTORY(params)) },
//   changeAccountPassword(data){ return axiosRequest('POST', API_PATH.ACCOUNT.CHANGE_PASSWORD_FORCE, data) },
//   blockingAccount(data){ return axiosRequest('POST', API_PATH.ACCOUNT.BLOCKING, data) },
//   accountChangePassword(data){ return axiosRequest('POST', API_PATH.ACCOUNT.CHANGE_PASSWORD, data) },
}
import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {ROUTES} from "../../../constants";
import {PatientVaccineService} from "../../../services/requests/ehr/patientVaccine";

const useGetPatientVaccines = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getPatientVaccines", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PatientVaccineService.getPatientVaccinesList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetPatientVaccine = () => {
  return useMutation({
    mutationFn: PatientVaccineService.getPatientVaccine,
  })
}

const useAddPatientVaccine = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: PatientVaccineService.addPatientVaccine,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientVaccines"] })
          onSuccess();
        }
      })
    },
  })
}

const useDeletePatientVaccine = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PatientVaccineService.deletePatientVaccine,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientVaccines"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetPatientVaccines,
  useGetPatientVaccine,
  useAddPatientVaccine,
  useDeletePatientVaccine
}

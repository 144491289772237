import {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { getCellValue, handleChange } from "../../../../../utils/utils";
import SwitchField from "../../../general/form/switchField";
import { useAddRelatedOrganization } from "../../../../../hook/request/organizations";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

const AddRelationTableRowItem = ({
  item,
}) => {
  
  const { id } = useParams();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [fieldValue, setFieldValue] = useState({
    related: item?.related
  });

  useEffect(() => {
    setFieldValue({
      related: item?.related
    });
  }, [item?.related]);

  // services
  const addRelatedOrganizationRequest = useAddRelatedOrganization();

  const addRelatedSubmit = (e) => {
    handleChange(e, setFieldValue, fieldValue);
    const { checked: _value } = e.target,
      body = {
        related: _value,
        originUnit: { id },
        relatedUnit: { id: item?.id }
      };
    addRelatedOrganizationRequest.mutate(body)
  }

  return(
    <tr>
      <td>{getCellValue(item?.name)}</td>
      <td>{getCellValue(item?.telNo)}</td>
      <td>{getCellValue(item?.address)}</td>
      <td>
        <div className="d-inline-flex justify-content-center pos-rel">
          {
            (
              addRelatedOrganizationRequest?.isPending
            ) && (
              <div className='position-absolute top-0 start-0 end-0 bottom-0 translate-middless d-flex align-items-center justify-content-center bg-secondary bg-opacity-50 rounded zIndex-1'>
                <div className="spinner-border spinner-border-sm"></div>
              </div>
            )
          }
          {
            (findAccessInAccessList('HEALTH_ORGANIZATION_CREATE') || findAccessInAccessList('HEALTH_ORGANIZATION_EDIT')) &&
            <SwitchField
              onChange={addRelatedSubmit}
              value={fieldValue?.related}
              name="related"
            />
          }
        </div>
      </td>
    </tr>
  )
}

export default AddRelationTableRowItem;

import { useEffect, useMemo, useState } from "react";
import BoxLoader from "../../components/general/boxLoader";
import TextField from "../../components/general/form/textField";
import PasswordField from "../../components/general/form/passwordField";
import saaIranLogo from '../../../assets/images/svg/saa-iran-logo-fill.svg';
import Image from "../../components/general/image";
import { useGetCaptcha, useLogin } from "../../../hook/request/auth";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TLoginFields } from "../../../types/auth";
import { loginSchema } from "../../../schema/login-schema";
import Icon from "../../components/general/icon";
import {useGetMyProviderInfo} from "../../../hook/request/doctors";
import {useGetAccessProcess, useGetMyOperatorInfo} from "../../../hook/request/users";
import {localStorageGet} from "../../../utils/localStorage";
import NumberField from "../../components/general/form/numberField";

const Login = () => {

  const [fieldValue, setFieldValue] = useState<TLoginFields>({
    username: '',
    password: '',
    captchaImage: "",
    captchaValue: "",
    token: ""
  })
  const {
    control,
    resetField,
    handleSubmit,
    // watch,
    // getValues,
    // setError,
    // register,
    // trigger,
    // setValue,
    formState: { errors },
  } = useForm<TLoginFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(loginSchema),
  });

  // services
  const getCaptchaRequest = useGetCaptcha();
  const loginRequest = useLogin({
    onSuccess: () => {
      handleGetAccountData();
    },
    onError: () => {
      resetField("captchaValue");
    }
  });
  const getDoctorRequest = useGetMyProviderInfo();
  const getUserRequest = useGetMyOperatorInfo();
  const getAccessProcessRequest = useGetAccessProcess();

  useEffect(() => {
    if (getCaptchaRequest?.data?.data?.result) {
      setFieldValue({
        ...fieldValue,
        ...getCaptchaRequest?.data?.data.result
      })
    }
  }, [getCaptchaRequest.data]);

  const updateCaptcha = () => {
    resetField("captchaValue");
    getCaptchaRequest.refetch();
  }

  const loginSubmit: SubmitHandler<TLoginFields> = (data) => {
    const { username, password, captchaValue } = data,
      { token: captchaKey } = fieldValue,
      body = { mobile: username, captchaKey, captchaValue, password };
    loginRequest.mutate(body);
  }

  const handleGetAccountData = () => {
    const _userData = localStorageGet('USER_DATA'),
      isProvider = _userData?.providerId > 0;
    isProvider ?
      getDoctorRequest.mutate({}) :
      getUserRequest.mutate({});
    getAccessProcessRequest.mutate({})
  }

  return(
    <div className="main-box d-flex pos-rel">
      {
        (
          getCaptchaRequest?.isFetching ||
          loginRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="inner-box pos-rel">
        <div className="w-500 mx-100 bg-white rounded my-0 my-xl-4 mx-0 mx-xl-5 px-0 px-xl-5">
          <div className="text-center">
            <div className="mx-auto mt-5 mb-4">
              <Image src={saaIranLogo} alt="Saa Iran Logo"/>
            </div>
            <div className="mb-4 pb-3">
              <h2 className="font-weight-medium font-18">به پنل مدیریت صاایران خوش آمدید</h2>
              <p className="text-gray1 mb-2">هــــــر روز بـــــــهتر از دیــــــروز</p>
            </div>
          </div>
          <div className="form-box external-form mx-5 mb-5">
            <form onSubmit={handleSubmit(loginSubmit)}>
              <div className="form-group mb-4 pb-1">
                {/* <label className="inp-lbl mr-2" htmlFor="username">نام کاربری</label> */}
                <NumberField
                  name="username"
                  placeholder="نام کاربری"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={11}
                />
              </div>
              <div className="form-group mb-4 pb-3">
                {/* <label className="inp-lbl mr-2" htmlFor="password">رمز عبور</label> */}
                <PasswordField
                  name="password"
                  placeholder="کلمه عبور"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                />
              </div> 
              <div className="d-flex justify-content-center mx-auto text-center mb-4 pos-rel">
                {
                  fieldValue?.captchaImage ?
                    <Image src={fieldValue?.captchaImage ? `data:image/png;base64,${fieldValue?.captchaImage}` : ""}/> :
                    <BoxLoader className="text-loader" />
                }
                <button
                  type="button"
                  onClick={updateCaptcha}
                  className="btn btn-white d-flex align-items-center justify-content-center font-20 mr-1 px-3"
                >
                  <Icon name="refresh" />
                </button>
              </div>
              <div className="form-group mb-4 pb-1">
                <NumberField
                  name="captchaValue"
                  placeholder="عبارت داخل کادر را وارد کنید"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={5}
                />
              </div>
              <div className="form-group text-center mt-4 pt-4">
                <button
                  type="submit"
                  className="btn btn-primary px-5"
                >
                  ورود به پنل
                </button>
              </div>
            </form>
            {/* <div className="d-flex align-items-center justify-content-between mt-4">
              <Link to={ROUTES?.RESET_PASSWORD?.PATH} className="text-primary text-center font-12">{'auth.forgot_password_link'}</Link>
            </div> */}
          </div>
          {/* <p className="text-gray font-12 font-en text-center copyright-text">{'auth.copyright_content'}</p> */}
        </div>
      </div>
    </div>
  )
}

export default Login;

// const mapStateToProps = (state) => ({
//   loginData: loginSelector(state),
//   isSuccessLogin: isSuccessLoginSelector(state),
//   isRequestingLogin: isRequestingLoginSelector(state),
//   isErrorLogin: isErrorLoginSelector(state),
// });

// const mapDispatchToProps = (dispatch) => ({
//   doLogin: (data) => dispatch(auth.login(data)),
//   doLoginReset: () => dispatch(auth.loginReset()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Login);
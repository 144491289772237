import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BoxLoader from "../../../components/general/boxLoader";
import TextField from "../../../components/general/form/textField";
import {ROUTES} from "../../../../constants";
import TextAreaField from "../../../components/general/form/textAreaField";
import {TAddProgramFields} from "../../../../types/program";
import {addProgramSchema} from "../../../../schema/program-schema";
import {useAddProgram, useGetProgram} from "../../../../hook/request/program";




const AddProgram = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [fieldValue, setFieldValue] = useState<TAddProgramFields>({
    name: '',
    description: '',
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddProgramFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addProgramSchema),
  });

  // services
  const getProgramRequest = useGetProgram();
  const addProgramRequest = useAddProgram();

  useEffect(() => {
    id && getProgramRequest.mutate({id});
  }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getProgramRequest?.data?.data?.result) {
      const { name, description, maxa } = getProgramRequest.data.data.result,
        _fieldValue = {
          name, description, maxa
        };
      setFieldValue(_fieldValue);
    }
  }, [getProgramRequest.data])


  const addProgramSubmit: SubmitHandler<TAddProgramFields> = (data) => {
    const { name, description } = data,
      body = {
        ...(id ? {id} : {}),
        name, description,
      };
    addProgramRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getProgramRequest?.isPending ||
          addProgramRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(addProgramSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات برنامه آموزش درمانی</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">نام</label>
                <TextField
                  name="name"
                  placeholder="نام"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  placeholder="توضیاحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.PROGRAM?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  { id ? "ویرایش برنامه" : "افزودن برنامه" }
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProgram;
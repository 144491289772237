import {useWindowSize} from "../../../hook/useWindowSize";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import React, {useEffect, useMemo, useState} from "react";
import SearchBox from "../../components/general/searchBox";
import Pagination from "../../components/general/table/pagination";
import {useGetAppointments} from "../../../hook/request/appointments";
import AppointmentsCardsBox from "../../components/appointments/dataList/appointmentsCardsBox";
import AppointmentsTable from "../../components/appointments/dataList/appointmentsTable";
import ChangeStatusAppointmentModal from "../../components/appointments/changeStatusAppointmentModal";
import SelectField from "../../components/general/form/selectField";
import {useForm} from "react-hook-form";


const dataListTitles = [
  "نام و نام خانوادگی بیمار",
  "نام مرکز",
  "نام پزشک",
  "تاریخ و ساعت حضور",
  "نوع نوبت",
  "وضعیت",
  "جزئیات",
]

const selectFilterList = [
  {
    value: 100,
    label: "ثبت شده"
  },
  {
    value: 200,
    label: "اتمام شده"
  },
  {
    value: 300,
    label: "لغو شده توسط بیمار"
  },
  {
    value: 400,
    label: "عدم حضور بیمار"
  },
  {
    value: 500,
    label: "لغو شده توسط پزشک"
  },
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  status?: string;
}


const AppointmentsList = () => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
    status: "",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TFilterValues>({
    defaultValues: useMemo(() => filterValues, [filterValues]),
  });

  // services
  const getAppointmentsRequest = useGetAppointments(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
      status: searchParams.get("status") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);



  useEffect(() => {
    getAppointmentsRequest?.data?.data?.result &&
      setAppointmentsData(getAppointmentsRequest?.data?.data.result)
  }, [getAppointmentsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  const openFilterDropDown = (param: any) => {
    if(param?.target?.value != null) {
      const {value} = param?.target?.value
      setFilterValues({
        ...filterValues,
        status: value
      })
    } else {
      setFilterValues({
        ...filterValues,
        status: ''
      })
    }
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی نوبت"
            />
          </div>
          <SelectField
            options={selectFilterList}
            name="status"
            placeholder='وضعیت راانتخاب کنید'
            className='w-25'
            control={control}
            isClearable={true}
            error={errors}
            onChange={(e) => openFilterDropDown(e)}
            returnObjectValue={true}
          />
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <AppointmentsCardsBox
                dataList={appointmentsData}
                dataListTitles={dataListTitles}
                isRequesting={getAppointmentsRequest.isPending}
              /> :
              <AppointmentsTable
                dataList={appointmentsData}
                dataListTitles={dataListTitles}
                isRequesting={getAppointmentsRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...appointmentsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>

      <ChangeStatusAppointmentModal/>
    </>
  );
};

export default AppointmentsList;